import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import DiscoverSideBar from "../components/DiscoverSideBar";
import Note from "../components/Note";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  getAllDiscoveredStrategyApi,
  getDiscoverStrategyByIdApi,
  getStrategyCategoriesApi
} from "../api/strategyapi";
import SkeletonLoader from "../components/componentCommon/SkeletonLoader";

const Discover = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get("filter");
  const navigate = useNavigate();
  const [showFullText, setShowFullText] = useState(false);
  const [discoverSelectedData, setDiscoverSelectedData] = useState("");
  const [strategies, setStrategies] = useState();
  const [isDiscoverLoading, setIsDiscoverLoading] = useState(true) ;
  const [strategycategories,setStrategyCategories]=useState([]);
  const discoverData = [
    {
      id: 1,
      filter: "trend",
      icon: "/icons/Group.png",
      heading: "Trend following",
      backgroudColor: "#e7f4fc",
      subHeadingColor: "#46444C ",
      text: "First things first. Trend Trading has stood the test of time. And that too for a good reason - it works! Documentation of trend trading dates back to the 19th and 20th centuries, and it continues to be popular to this day. If you’re a trend trader, then you’re basically asking the market to ‘show its hand.’ In other words, you analyze the data available to you and use it as an advantage in determining the direction of the trend.",
    },
    {
      id: 2,
      icon: "/icons/price-action.png",
      filter: "price_action",
      heading: "Price action",
      backgroudColor: "#feecfa",
      subHeadingColor: "#46444C ",
      text: "They say, “Trade what you see, not what you think.” But what does that mean? Suppose you are using two indicators: the RSI and the Stochastic. Now, what if one is overbought and the other indicator is oversold, at the same time? Won’t you have conflicting signals? Indeed! But no worries! You can adjust the settings of your RSI indicator and make it oversold, so now, both your RSI and Stochastic indicator are sending out the same “signal”. But instead of manipulating an indicator to fit your bias, you need to look out for what the price is telling you. And then use the indicators as a confirmation of the signal or the trend.",
    },
    {
      id: 3,
      icon: "/icons/Mean-Reversion.png",
      filter: "meanreversion",
      heading: "Mean Reversion",
      backgroudColor: "#fffffb",
      subHeadingColor: "#46444C ",
      customClass: "bg-color-grey",
      text: "Are prices high or low on a relative basis or has the price deviated too much from the mean price? The law of mean reversal means that if a price moves away from the mean price, it tends to return to the original mean price. This is going to give you better clarity of overbought and oversold levels. You can make better trade decisions about when to enter or exit a trade.",
    },
    {
      id: 4,
      icon: "/icons/Mumentum.png",
      filter: "momentum",
      heading: "Momentum",
      backgroudColor: "#dde8fe",
      subHeadingColor: "#46444C ",
      text: "The trend is your friend until the end when it bends.” If you’re a Bullish trader, you might continue to ride the momentum higher and higher and head towards a destructive ending. And if you’re a bearish trader you might fight the trend and miss opportunity after opportunity when it looks like the market is ready to break. Momentum indicators are used to determine the rate at which the stock is gaining or falling in the market. Avoid missing opportunities using these strategies.",
    },
    {
      id: 5,
      icon: "/icons/miscellaneous.png",
      filter: "miscellaneous",
      backgroudColor: "#e8e8ff",
      subHeadingColor: "#46444C ",
      heading: "Miscellaneous",
      text: "Miscellaneous",
    },
    {
      id: 6,
      icon: "/icons/pivot-point.png",
      filter: "pivot_point",
      backgroudColor: "#ddfff4",
      subHeadingColor: "#46444C ",
      heading: "Pivot points",
      text: "pivot points",
    },
    {
      id: 7,
      filter: "overlays",
      icon: "/icons/overlay.png",
      backgroudColor: "#DDE8FE",
      subHeadingColor: "#46444C ",
      heading: "Overlays",
      text: "Overlays",
    },
    {
      id: 8,
      icon: "/icons/options-stratergy.png",
      filter: "options_strategies",
      backgroudColor: "#e8e8ff",
      subHeadingColor: "#46444C ",
      heading: "Options strategies",
      text: 'Options trading strategies run the gamut from simple, "one-legged" trades to exotic “multi-legged” beasts that seem like they’ve emerged from a fantasy novel. But simple or complex, what all strategies have in common is that they’re based on two fundamental option types: calls and puts. Let’s have a look, shall we?',
    },
    {
      id: 9,
      icon: "/icons/intraday.png",
      filter: "intraday",
      backgroudColor: "#ffeeed",
      subHeadingColor: "#46444C ",
      heading: "Intraday",
      text: "It may seem exciting, but the life of a day trader is life on the edge. You don’t want to leave any stone unturned and head towards a possible ‘oops moment.’ Unexpected events or trading results can occur at a given point on any given day. You need to have a few solid trading strategies well-rehearsed in your mind. And if you’ve been having specific trading-related issues, create a plan for how you will handle them. Risk management, discipline, trading balances, and the economic calendar are a few things you can’t overlook.",
    },
    {
      id: 10,
      icon: "/icons/Vector.png",
      filter: "swing_trading",
      backgroudColor: "#ddfff4",
      subHeadingColor: "#46444C ",
      heading: "Swing trading",
      text: "Trades with greater stop losers that last between a few days to a few months! Swing trading requires you to be patient and find the right strategies that suit your trading style and that can produce results, an edge, or a profit. Ah, you can make use of the ‘paper trade’ option in Streak so that you can check your strategies for profits and execute them. Paper trading means making hypothetical trades as if you were trading real funds.",
    },
    {
      id: 11,
      icon: "/icons/Position-trading.png",
      backgroudColor: "#e3ffe0",
      subHeadingColor: "#FFFFFB ",
      heading: "Position trading",
      text: "With targets as high as several hundred or thousand ticks, this might pull you through bull and bear markets, economic recessions, and booms over the years. But you need to hold on. Hold on until the trend peaks, ignore popular opinions and do your own research & analysis. This trading philosophy seeks to exploit the bulk of a trend's upwards move and all the strategies revolve around that.",
    },
    {
      id: 11,
      icon: "/icons/Multi-time-frame.png",
      filter: "multi_time_frame_analysis",
      backgroudColor: "#E7F4FC",
      subHeadingColor: "#46444C ",
      heading: "Multi time frame analysis",
      text: "",
    },
  ];

  useEffect(() => {
    if (filter === null) getDiscoveredStrategies();
    if (filter) getStrategiesById(filter);
    getStrategyCategories();
  }, []);

  const getDiscoveredStrategies = async () => {
    const result = await getAllDiscoveredStrategyApi();
    console.log("getDiscoveredStrategies", result);

    const updatedResult = result?.map((info) => {
      const card = discoverData.find((card) => card.id === info.id);
      return card
        ? {
            ...info,
            icon: card.icon,
            subtext: card.subtext,
            class: card.class,
            filter: card.filter,
          }
        : info;
    });

    console.log("updatedResult", updatedResult);
    setStrategies(updatedResult);
    setIsDiscoverLoading(false)
  };

  const getStrategyCategories = async () => {
    const result = await getStrategyCategoriesApi();
    if (result) setStrategyCategories(result);
  };
  const getStrategiesById = async (id) => {
    const result = await getDiscoverStrategyByIdApi({
      category_id: id,
    });
    console.log("getStrategiesById", result);
    if (result) setStrategies(result);
  };

  useEffect(() => {
    if (filter !== "") {
      const data = discoverData.filter((item) => item.id == filter);
      console.log("checkkkdata", data);
      setDiscoverSelectedData(data[0]);
    }
  }, [filter]);

  const text =
    "The strategy uses one indicator to check for positive trend in the stock and the other indicates begining of a positive move. Trending markets are ideal for this to work well. Comparing MACD with MACD signal depicts positive momentum and RSI crossover confirms initiation of a new bullish move.";

  const wordsToShowInitially = 15;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const displayText =
    showFullText || text.split(" ").length <= wordsToShowInitially
      ? text
      : text.split(" ").slice(0, wordsToShowInitially).join(" ");

  return (
    <>
      <section className="mobile-view">
        <Container className="container web-cotainer">
          <Row className="pt-4">
            <Col lg={9} className="pe-5">
              <Note />
              {filter !== null && (
                <Row className="pt-4">
                  <Col lg md sm xs className="discover-single-card">
                    <Card
                      style={{
                        background: discoverSelectedData?.backgroudColor,
                      }}
                    >
                      <Card.Body>
                        <Row className="">
                          <Col
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            className="d-flex align-items-center justify-content-center "
                          >
                            {/* <i className="fa fa-plus"></i> */}
                            <img
                              src={discoverSelectedData?.icon}
                              alt=""
                              className={discoverSelectedData?.customClass}
                            />
                          </Col>
                          <Col lg={10} md={10} sm={10} xs={10}>
                            <span className="fs-5 fw-bold">
                              {discoverSelectedData?.heading}
                            </span>
                            <br />
                            <span
                              className="discover-details lh-1 text-justify"
                              style={{
                                color: discoverSelectedData?.subHeadingColor,
                              }}
                            >
                              {discoverSelectedData?.text}
                            </span>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
              <Row className="pt-4">
                <Container className="title-section p-0">
                  <Col className="d-flex justify-content-between align-items-center">
                    <div>
                      <i
                        className="fa fa-chevron-left pointer-cursor "
                        onClick={() => navigate(-1)}
                      ></i>
                      <span>
                        {filter !== null
                          ? discoverSelectedData?.heading
                          : "Discover"}
                      </span>
                    </div>
                    <div>
                      <Button
                        className="btn-color"
                        onClick={() => navigate("/strategies")}
                      >
                        <i className="fa fa-plus"></i>
                        Create New Strategy
                      </Button>
                    </div>
                  </Col>
                </Container>
              </Row>
              {
                // isDiscoverLoading ? Array.from({ length: discoverData?.length }).map((_, i) => {
                //   return (
                //     <SkeletonLoader
                //       width="100%"
                //       height="150px"
                //       borderRadius="8px"
                //     />
                //   );
                // })

              // : 
              strategies?.map((item) => (
                <Row
                  className="discover-card mt-3 pointer-cursor"
                  onClick={() =>
                    navigate(`/strategies/${item?.strategy_id}`, {
                      state: true,
                    })
                  }
                >
                  <Card>
                    <Card.Body className="p-0">
                      <Row>
                        <Col
                          lg={2}
                          md={2}
                          sm={12}
                          className="d-flex flex-column discover-stocks-tab"
                        >
                          <span
                            className="d-flex justify-content-center  align-items-center stock-number"
                            style={{ height: "80%" }}
                          >
                            {item?.stock_count}
                          </span>
                          <span
                            className="d-flex  justify-content-center align-items-center stock-title"
                            style={{ height: "20%" }}
                          >
                            {item?.stock_count === 1 ? "Stock" : "Stocks"}
                          </span>
                        </Col>
                        <Col lg={7} md={7} sm={12} xs={12} className="py-4">
                          <Container className="fw-bold">
                          <span>{item?.strategy_name?.length>30?`${item?.strategy_name?.slice(0,30)}...`:item?.strategy_name}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <i className="fa fa-star"></i>
                          </Container>
                          <Container className="sub-text">
                            {displayText}
                            {text.split(" ").length > wordsToShowInitially && (
                              <span>
                                {!showFullText ? "..." : " "}
                                <Link onClick={toggleText}>
                                  {showFullText ? "Read Less" : "Read More"}
                                </Link>
                              </span>
                            )}
                          </Container>
                          
                          <Row className="mt-4">
                            <Col
                              lg
                              sm
                              md
                              xs
                              className="d-flex justify-content-center"
                            >
                              <Button
                                variant="outline-primary"
                                className="rounded-circle p-0 border-0"
                              >
                                <img src="/icons/Ellipse9.png" alt="img" />
                                {/* <i className="fa fa-plus"></i> */}
                              </Button>
                              &nbsp;
                              <Button
                                variant="outline-primary"
                                className="rounded-circle p-0 border-0"
                              >
                                {/* <i className="fa fa-plus"></i> */}
                                <img src="/icons/Ellipse 12.png" alt="img" />
                              </Button>
                              &nbsp;
                              <Button
                                variant="outline-primary"
                                className="rounded-circle p-0 border-0"
                              >
                                {/* <i className="fa fa-plus"></i> */}
                                <img src="/icons/Ellipse 10.png" alt="img" />
                              </Button>
                              &nbsp;
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={3} md={3} sm={12} xs={12}>
                          {/* Chart */}
                          <div className="end-0 top-50 translate-middle-y next-icon">
                            <i className="fa fa-chevron-right"></i>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Row>
              ))}
              {strategies?.length === 0 && (
                <div style={{ marginTop: "10%", textAlign: "center" }}>
                  <span className="p-0">No Strategies Available</span>
                </div>
              )}
            </Col>
            <Col lg={3} className="px-4" id="discover-side-bar">
              <DiscoverSideBar strategycategories={strategycategories} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Discover;
