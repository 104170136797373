import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  VerifyOtpAfterSignUp,
  checkExistPhoneNo,
  expireOtpApi,
  genrateOtp,
  loginApi,
} from "../api/api";
import { getZerodhaToken, saveData } from "../Session";
import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../firebase.config";


function Login() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [otpError, setOtpError] = useState();
  const [timer, setTimer] = useState(60);
  const [mobileNoError, setMobileNoError] = useState("");

  const [showInput, setShowInput] = useState(false);
  const [otp, setOtp] = useState();
  const [getOtp, setGetOtp] = useState();
  const [showLoginOtpBtn, setShowLoginOtpBtn] = useState(false);
  const [confirmResult, setConfirmResult] = useState();
  const [retryCount, setRetryCount] = useState(0); 
  const navigate = useNavigate();

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const validatedInput = inputValue.replace(/[^0-9]/g, "");
    setMobileNumber(validatedInput);
    if (validatedInput.length !== 10) {
      setShowInput(false);
    }
  };

  const handleChangeOtpInput = (e, index) => {
    const value = e.target.value;
    console.log("Otp", value);

    if (!isNaN(value) && value.length <= 1) {
      // Update the corresponding digit in the OTP array
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index] = value;
      // onOtpDigitsChange(newOtpDigits ,'' ,timer);
      const numberArray = newOtpDigits.map((str) =>
        str === "" ? null : parseInt(str, 10)
      );
      const concatenatedString = numberArray.join("");
      console.log("concatenatedString", concatenatedString);
      setOtpDigits(newOtpDigits);
      // Move to the next input field if a digit is entered
      if (value.length === 1 && index < 5) {
        document.getElementsByName(`otpInput${index + 1}`)[0].focus();
      }

      if (concatenatedString.length === 6) {
        setOtp(concatenatedString);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        // console.log("prevTimer",prevTimer);
        if (prevTimer === 0) {
          clearInterval(intervalId);
          handleExipreOtp();
          // setBtnShow(false);
          // onOtpDigitsChange(otpDigits,0);
          return 0; // Clear the interval when the timer reaches 0
          // You can perform additional actions when the timer expires
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [timer]); // Empty dependency array ensures that the effect runs only once on mount

  const handleExipreOtp = async () => {
    try {
      const expireOtpResponce = await expireOtpApi(mobileNumber, getOtp);
      console.log("expireOtpResponce", expireOtpResponce);
      setOtpDigits(["", "", "", "", "", ""]);
      setOtpError("Your OTP is expired please click on Resend OTP");
    } catch (error) {
      console.log("Error", error);
    }
  };
  const onClickResendOtp = () => {
    handelOtpSend();
    setOtpError("");
    setOtpDigits(["", "", "", "", "", ""]);
  };
  const handelOtpSend = async () => {
    try {
      const isVerifyMobile = await checkExistPhoneNo(mobileNumber);
      if (isVerifyMobile) {
        onCaptchVerify();
        const appVerifier = window.recaptchaVerifier;
        const options = {
          expiration: 180,
        };
        const countryCode = "+91";
        const formatPh1 = countryCode + mobileNumber;
        console.log("FORMATPH", formatPh1);
        signInWithPhoneNumber(auth, formatPh1, appVerifier, options)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setConfirmResult(confirmationResult);
            alert('OTP sent successfully!');
            console.log("FORMATPHconfirmationResult", confirmationResult);
            // setGetOtp(responce);
            setTimer(180);
            setMobileNoError("");
            setOtpError("");
            setShowInput(true);
            setShowLoginOtpBtn(true);
          })
          .catch((error) => {
            console.log("PHONEERROR", error);
            if (error.code === "auth/captcha-check-failed") {
              // setMobileNoError("reCAPTCHA verification failed. Please try again.");
              console.log("Error reCAPTCHA verification failed. Please try again");
            } else if (error.code === "auth/too-many-requests") {
              // setMobileNoError("Too many requests. Please try again later.");
              console.log("Error Too many requests. Please try again later.");
              const newRetryCount = retryCount + 1;
              setRetryCount(newRetryCount);
              const backoffTime = Math.pow(2, newRetryCount) * 1000; // Exponential backoff
              setTimeout(() => {
                handelOtpSend();
              }, backoffTime);
            } else {
              // setMobileNoError(error.message);
            }
          });
      } else {
        setMobileNoError("Please enter registered mobile number");
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };
  const handleSubmit = async (e) => {
    if(confirmResult && otpDigits.length==6){
    const verifyOtp=await abc(confirmResult, otpDigits)
    console.log("Result of confirm OTP", verifyOtp)
    if(verifyOtp ){
    const userData = await loginApi(mobileNumber);
    const setSessionData = await saveData(userData);
    if (userData?.activePlan?.length === 0) {
      console.log("activeplan", userData);
      localStorage.removeItem('IsCreated');
      navigate("/subscribe");
    } else {
      localStorage.removeItem('IsCreated');
      navigate("/dashboard");
    }
    }else{
      // setOtpError("Please enter correct OTP");
      console.log("Please enter correct OTP")
    }
    }
    else
    {
      console.log("Result of otp",)
    }
  };
  const handleBypass = async () => {
    const isVerifyMobile = await checkExistPhoneNo(mobileNumber);
    if (isVerifyMobile) {
    const userData = await loginApi(mobileNumber);
    const setSessionData = await saveData(userData);
    if (userData?.activePlan?.length === 0) {
      console.log("activeplan", userData);
      localStorage.removeItem('IsCreated');
      navigate("/subscribe");
    } else {
      localStorage.removeItem('IsCreated');
      navigate("/dashboard");
    }
  }
  else
  {
    setMobileNoError("Please enter registered mobile number"); 
  }
  };

  const abc = async (confirm123, code) => {
    try {
      console.log("@@",confirm123, code)
      const otpCode = code.join("");
      console.log("Inside confirm try", confirm123, otpCode);
      await confirm123.confirm(otpCode);
      return true;
    } catch (error) {
      if (error.code === "auth/invalid-verification-code") {
        console.log("Invalid verification code.");
      } else if (error.code === "auth/code-expired") {
        console.log("Verification code has expired.");
      } else {
        console.log(
          "Something went wrong while confirming OTP:",
          error.message
        );
      }
      return false;
    }
  };

  function onCaptchVerify() {
    console.log("captcha verified...");
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            handelOtpSend();
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        },
        auth
      );
    }
  }
  return (
    <div>
      <section>
        <div className="container-fluid py-5">
          {showInput ? (
            <>
              <h4 className="text-center fw-bold pt-5">YOU HAVE GOT MESSAGE</h4>
              <p className="text-center">
                An OTP has been sent to your<br></br>registered mobile number
              </p>
              <h6 className="text-center text-dark fw-bold">
                Please enter OTP to verify your email
              </h6>
              <div className="d-flex justify-content-center otp-input pt-3">
                {otpDigits.map((digit, index) => (
                  <input
                    style={{ color: "#2D2E8D" }}
                    key={index}
                    type="number"
                    className="rounded m-1 fw-bolder"
                    maxLength={1}
                    name={`otpInput${index}`}
                    value={digit}
                    onChange={(e) => handleChangeOtpInput(e, index)}
                  />
                ))}
              </div>
              <div className="text-center">
                <span className="text-error">{otpError}</span>
              </div>
              <div className="d-flex justify-content-center py-3">
                <p className="pe-5 text-dark">
                  Didn't receive OTP?{" "}
                  <span
                    style={{ color: "#2D2E8D" }}
                    onClick={() => {
                      onClickResendOtp(); // Assuming onClickResendOtp is a function
                      timer || setTimer(45);
                    }}
                  >
                    Resend
                  </span>
                </p>
                <p className="ps-3">
                  {Math.floor(timer / 60)}:{timer % 60 < 10 ? "0" : ""}
                  {timer % 60}
                </p>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary btn-color"
                  onClick = {handleSubmit}
                >
                  &emsp;&emsp;&emsp;&emsp;{"Log in"}&emsp;&emsp;&emsp;&emsp;
                </button>
              </div>
            </>
          ) : (
            <div className="text-center pt-4">
              <h1 className="pt-5" style={{ color: "#6c6db3" }}>
                Sign in to <span className="fw-bolder d-inline">ProductName </span>
              </h1>
              <input
                type="number"
                placeholder="Enter mobile no.."
                className="pt-0 mt-4 sign-in-dal-roti"
                maxLength={10}
                value={mobileNumber}
                onChange={handleChange}
              />

              <span className="text-error"> {mobileNoError}</span>
              <br></br>
              <button
                className="btn btn-primary btn-color my-4"
                onClick={() => handleBypass()}
              >
                {" "}
                &emsp;&emsp;&emsp;&emsp;{"Bypass"}&emsp;&emsp;&emsp;&emsp;
              </button>

              <div id="recaptcha-container"></div>
              <div className="pt-5">
                <button
                  className="btn btn-primary btn-color"
                  onClick={handelOtpSend}
                >
                  &emsp;&emsp;&emsp;&emsp;{"Send OTP"}&emsp;&emsp;&emsp;&emsp;
                </button>
              </div>
              <div className="d-flex my-5 justify-content-center">
                {" "}
                <hr className="" style={{ width: "15%" }}></hr>
                <span className="px-2 pt-1 text-secondary">Or</span>
                <hr className="" style={{ width: "15%" }}></hr>
              </div>
              <div className="d-flex justify-content-center" id="social-btn">
                <button className="btn btn-default m-1 border-0  sign-up-social-btn d-flex align-items-center">
                  {" "}
                  <img
                    src="facebook (1).png"
                    height={20}
                    width={20}
                    className="img-fluid"
                    alt="facebook"
                  />{" "}
                  &nbsp;Login with Facebook
                </button>
                <button className="btn btn-default m-1 border-0 sign-up-social-btn d-flex align-items-center">
                  {" "}
                  <img
                    src="google.png"
                    height={20}
                    width={20}
                    className="img-fluid"
                    alt="google"
                  />
                  &nbsp;Login with Google
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}


export default Login;
