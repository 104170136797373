import React from 'react'
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { Button, Col, Form, Row } from "react-bootstrap";
function Addzerodhamodal({show,handleClose,zerodhadata,onChange,addnewaccount,editzerodhamodal,updatenewaccount}) {
  return (
   <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
    {editzerodhamodal?<Modal.Title>Update Account</Modal.Title>:<Modal.Title>Add Account</Modal.Title>}
    </Modal.Header>
    <Modal.Body>
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formAccountName">
            <Form.Label column sm="3">
              Account Name
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                placeholder="Enter Account Name"
                name="accountName"
                value={zerodhadata.accountName}
                onChange={onChange} // Handle input change
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formApiKey">
            <Form.Label column sm="3">
              API Key
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                placeholder="Enter API Key"
                name='apiKey'
                value={zerodhadata.apiKey}
                onChange={onChange} 
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formSecretKey">
            <Form.Label column sm="3">
              Secret Key
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                placeholder="Enter Secret Key"
                name="apiSecret"
                value={zerodhadata.apiSecret}
                onChange={onChange} 
                required
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
       {editzerodhamodal?( <Button variant="primary" onClick={updatenewaccount}>
        Update
        </Button>):( <Button variant="primary" onClick={addnewaccount}>
        Add
        </Button>)} 
      </Modal.Footer>
   </Modal>
  )
}

export default Addzerodhamodal