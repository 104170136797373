import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useNavigate } from "react-router-dom";
const Content = () => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  const getfreedemo = () => {
    navigate("/contact");
  };
  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1">
              <h1>The modern way to trade</h1>
              <h2 style={{ color: "white", fontWeight: "500" }}>
                ProductName lets you plan and manage your trades without coding, on
                the go. We have made systematic trading, accessible and
                affordable to all.
              </h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <div
                  className="btn-get-started scrollto pointer-cursor"
                  onClick={getfreedemo}
                >
                  Get Free Demo
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img">
              <img src="mainLog.png" className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="services" className="services section-bg">
          <div className="container">
            <div className="section-title">
              <h1 style={{ color: "#2a3c9d" }}>ProductName Highlights</h1>
              <h2 className="text-center dalrotipara">
                ProductName provides all you need to trade in this modern age in one
                platform. <br></br>Following are the three key features we would
                like to highlight, <br></br>and there is a lot more in store.
              </h2>
            </div>

            <div className="row">
              <div className="col-xl-4 col-md-4 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="icon-box">
                  <div className="icon text-center">
                    <img
                      src="highlight1.png"
                      className="img-fluid highlight"
                    ></img>
                  </div>
                  <h4 className="text-center">Strategies</h4>
                  <div
                    className="d-flex pt-4"
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <div style={{ width: "10%" }}>
                      <div
                        style={{
                          width: "28px",
                          height: "28px",
                          backgroundColor: "#0291d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          color: "white",
                          marginTop: "6px",
                          marginLeft: "20px",
                        }}
                      >
                        1
                      </div>
                    </div>
                    <div
                      className="ps-4"
                      style={{ width: "90%", fontSize: "22px" }}
                    >
                      Create or copy a <br></br>strategy
                    </div>
                  </div>
                  <div
                    className="d-flex pt-1"
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <div style={{ width: "10%" }}>
                      <div
                        style={{
                          width: "28px",
                          height: "28px",
                          backgroundColor: "#0291d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          color: "white",
                          marginTop: "6px",
                          marginLeft: "20px",
                        }}
                      >
                        2
                      </div>
                    </div>
                    <div
                      className="ps-4"
                      style={{ width: "90%", fontSize: "22px" }}
                    >
                      Backtest in <br></br>Seconds
                    </div>
                  </div>
                  <div
                    className="d-flex pt-1 pb-4"
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <div style={{ width: "10%" }}>
                      <div
                        style={{
                          width: "28px",
                          height: "28px",
                          backgroundColor: "#0291d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          color: "white",
                          marginTop: "6px",
                          marginLeft: "20px",
                        }}
                      >
                        3
                      </div>
                    </div>
                    <div
                      className="ps-4"
                      style={{ width: "90%", fontSize: "22px" }}
                    >
                      Deploy live or paper <br></br>trade
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 d-flex align-items-stretch mt-xl-0">
                <div className="icon-box">
                  <div className="icon text-center">
                    <img
                      src="highlight2.png"
                      className="img-fluid highlight"
                    ></img>
                  </div>
                  <h4 className="text-center">Technicals</h4>
                  <div
                    className="d-flex pt-4"
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <div style={{ width: "10%" }}>
                      <div
                        style={{
                          width: "28px",
                          height: "28px",
                          backgroundColor: "#0291d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          color: "white",
                          marginTop: "6px",
                          marginLeft: "20px",
                        }}
                      >
                        1
                      </div>
                    </div>
                    <div
                      className="ps-4"
                      style={{ width: "90%", fontSize: "22px" }}
                    >
                      Create custom <br></br>watchlists
                    </div>
                  </div>
                  <div
                    className="d-flex pt-1"
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <div style={{ width: "10%" }}>
                      <div
                        style={{
                          width: "28px",
                          height: "28px",
                          backgroundColor: "#0291d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          color: "white",
                          marginTop: "6px",
                          marginLeft: "20px",
                        }}
                      >
                        2
                      </div>
                    </div>
                    <div
                      className="ps-4"
                      style={{ width: "90%", fontSize: "22px" }}
                    >
                      Get quick technical <br></br>snapshots
                    </div>
                  </div>
                  <div
                    className="d-flex pt-1 pb-4"
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <div style={{ width: "10%" }}>
                      <div
                        style={{
                          width: "28px",
                          height: "28px",
                          backgroundColor: "#0291d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          color: "white",
                          marginTop: "6px",
                          marginLeft: "20px",
                        }}
                      >
                        3
                      </div>
                    </div>
                    <div
                      className="ps-4"
                      style={{ width: "90%", fontSize: "22px" }}
                    >
                      Make unbiased <br></br> trading decisions
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 d-flex align-items-stretch mt-4 mt-xl-0">
                <div className="icon-box">
                  <div className="icon text-center">
                    <img
                      alt="nt found"
                      src="highlight3.png"
                      className="img-fluid highlight"
                    ></img>
                  </div>
                  <h4 className="text-center">Scanner</h4>
                  <div
                    className="d-flex pt-4"
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <div style={{ width: "10%" }}>
                      <div
                        style={{
                          width: "28px",
                          height: "28px",
                          backgroundColor: "#0291d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          color: "white",
                          marginTop: "6px",
                          marginLeft: "20px",
                        }}
                      >
                        1
                      </div>
                    </div>
                    <div
                      className="ps-4"
                      style={{ width: "90%", fontSize: "22px" }}
                    >
                      Scan any sectors in <br></br> seconds
                    </div>
                  </div>
                  <div
                    className="d-flex pt-1"
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <div style={{ width: "10%" }}>
                      <div
                        style={{
                          width: "28px",
                          height: "28px",
                          backgroundColor: "#0291d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          color: "white",
                          marginTop: "6px",
                          marginLeft: "20px",
                        }}
                      >
                        2
                      </div>
                    </div>
                    <div
                      className="ps-4"
                      style={{ width: "90%", fontSize: "22px" }}
                    >
                      Scan with <br></br> pre-market data
                    </div>
                  </div>
                  <div
                    className="d-flex pt-1 pb-4"
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <div style={{ width: "10%" }}>
                      <div
                        style={{
                          width: "28px",
                          height: "28px",
                          backgroundColor: "#0291d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          color: "white",
                          marginTop: "6px",
                          marginLeft: "20px",
                        }}
                      >
                        3
                      </div>
                    </div>
                    <div
                      className="ps-4"
                      style={{ width: "90%", fontSize: "22px" }}
                    >
                      Take live to get <br></br>notified
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="cta" className="cta">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-8 text-center text-lg-start">
                <h1>Claim Your 7 day free trial</h1>
                <h2 style={{ color: "white", fontWeight: "500" }}>
                  Get your 7 day free trial, and experience ProductName without
                  <br /> any commitment. You will not be charged till
                  <br /> you choose a plan
                </h2>
              </div>
              <div
                style={{
                  height: "20%",
                  width: "20%",
                  display: "flex",
                }}
                className="col-lg-3 col-md-4 cta-btn-container text-center"
              >
                <Link
                  to="/signup"
                  style={{ fontSize: 18, fontWeight: "500" }}
                  className="btn btn-default cta-btn align-middle"
                >
                  Start Free Trial
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 col-md-4 col-sm-4 col-6 text-center">
                <img
                  src="checkblue.png"
                  className="icon img-fluid"
                  alt="icon"
                />
                <h5 className="icon-name">50 Back Text Per Day</h5>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6 text-center">
                <img
                  src="checkblue.png"
                  className="icon img-fluid"
                  alt="icon"
                />
                <h5 className="icon-name">50 Scans Per Day</h5>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6 text-center">
                <img
                  src="checkblue.png"
                  className="icon img-fluid"
                  alt="icon"
                />
                <h5 className="icon-name">5 Live Deployment at a time</h5>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6 text-center">
                <img
                  src="checkblue.png"
                  className="icon img-fluid"
                  alt="icon"
                />
                <h5 className="icon-name">Renko</h5>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6 text-center">
                <img
                  src="checkblue.png"
                  className="icon img-fluid"
                  alt="icon"
                />
                <h5 className="icon-name">MCX (Commodities)</h5>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6 text-center">
                <img
                  src="checkblue.png"
                  className="icon img-fluid"
                  alt="icon"
                />
                <h5 className="icon-name">Trailing StopLoss</h5>
              </div>
            </div>
          </div>
        </section>
        <section id="pricing" className="pricing">
          <div className="container">
            <div className="row text-left">
              <div className="col-lg-6 col-md-6">
                <div
                  className="portfolio-img"
                  style={{
                    background: "white",
                  }}
                >
                  <img
                    src="img2.png"
                    className="img-fluid"
                    alt=""
                    height={300}
                    width={280}
                  />
                </div>

                <h1 style={{ fontSize: 38, fontWeight: "600" }}>
                  Unlock Features
                  <br /> with our Paid Plan
                </h1>

                <Link to="/Pricing" className="btn btn-primary sub-btn">
                  Subscribe Now
                </Link>
              </div>

              <div className="col-lg-6 col-md-6 mt-4 mt-lg-0">
                <div className="box">
                  <ul>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; 1000{" "}
                      <span className="sub-text">Backtests per day </span>
                    </li>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; 100{" "}
                      <span className="sub-text">
                        Live strategies at a time{" "}
                      </span>
                    </li>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; Unlimited{" "}
                      <span className="sub-text">Scans per day</span>{" "}
                    </li>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; Heikin-Ashi <span className="sub-text"></span>
                    </li>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; 10{" "}
                      <span className="sub-text">
                        {" "}
                        entry and exit conditions
                      </span>
                    </li>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; Trailing stoploss{" "}
                      <span className="sub-text"></span>
                    </li>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; Renko(in basic strategy){" "}
                      <span className="sub-text"></span>
                    </li>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; MCX (Commodities)
                    </li>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; Options (NEO-OPT)
                    </li>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; Multi-time frame & <br /> Multi-time frame
                      completed
                    </li>
                    <li>
                      {/* <i className="fa fa-check-circle"></i>  */}
                      <img
                        src="icon-images/checklist.png"
                        className="img-fluid"
                        heigth={10}
                        width={30}
                      />
                      &nbsp; 1 hour{" "}
                      <span className="sub-text">
                        handholding session on annual plan{" "}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Super" className="cta super-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 text-center text-lg-start section1">
                <h1 className="ps-5 ms-5">Super Fast trading solution </h1>
                <ul className="ps-5 ms-5">
                  <li>
                    <img
                      src="icon-images/checklist.png"
                      className="img-fluid"
                      heigth={10}
                      width={30} alt="nt found"
                    />{" "}
                    &nbsp;
                    {/* <i className="fa fa-check"></i>  */}
                    50 Back Test Per Day
                  </li>
                  <li>
                    {/* <i className="fa fa-check-circle"></i>  */}
                    <img
                      src="icon-images/checklist.png"
                      className="img-fluid"
                      heigth={10}
                      width={30} alt="nt found"
                    />
                    &nbsp; 50 Back Test Per Day
                  </li>
                  <li>
                    {/* <i className="fa fa-check-circle"></i>  */}
                    <img
                      src="icon-images/checklist.png"
                      className="img-fluid"
                      heigth={10}
                      width={30} alt="nt found"
                    />
                    &nbsp; 50 Back Test Per Day
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 cta-btn-container text-center">
                {/* <video width="100%" height="100%" controls>
                    <source src="movie.mp4" type="video/mp4" />
                    <source src="movie.ogg" type="video/ogg" />
                    Your browser does not support the video tag.
               </video> */}
                <div className="video-container">
                  {/* <video
                    ref={videoRef}
                    width="100%"
                    height="100%"
                    autoPlay
                    muted
                    loop
                    onClick={handleVideoClick}
                  >
                    <source src="/video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                  <div className="video-overlay" onClick={handleVideoClick}>
                    {isPlaying ? (
                      <PauseIcon className="play-pause-icon" />
                    ) : (
                      <PlayArrowIcon className="play-pause-icon" />
                    )}
                  </div>
                </div>
                {/* <div className="video">
                  <img src="chart.jpg" />
                  <div className="buttons">
                    <h4>Awesome Features</h4>
                    <button type="button" className="btn btn-primary">
                      <i className="fa fa-play"></i>
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section id="Market" className="pricing market">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <h1>Move with the market</h1>
                <p style={{ fontSize: 20 }}>
                  Download the<strong> ProductName app </strong> and never miss a
                  beat{" "}
                </p>
                <p className="sub-title" style={{ fontSize: 19 }}>
                  Start your journey today{" "}
                </p>
                <div className="row">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                    <span className="icon">
                      <img className="img-fluid" src="letter-a.png" />
                    </span>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                    <span className="icon">
                      <img className="img-fluid" src="google-play.png" />
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-6 col-3">
                    <span className="icon-scan">
                      {/* <i className="bx bx-qr-scan"></i> */}
                      <img
                        src="icon-images/qr-code.png"
                        className="img-fluid"
                      />
                    </span>
                  </div>
                  <div className="col-lg-9 col-md-2 col-sm-6 col-9 py-5">
                    <p className="download-text" style={{ fontSize: 20 }}>
                      Scan and Download
                    </p>
                    <p style={{ fontSize: 19 }}>
                      Use your android or IOS device
                    </p>
                    <p style={{ fontSize: 19 }}>to download the app</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 mt-4 mt-lg-0">
                <img className="img-fluid" src="img3.png" />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Content;
