import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { getInstrumentsAPI } from "../../api/api";
import { getZerodhaToken } from "../../Session";
const container = {
  position: "fixed",
  top: 0,
  right: 0,
  width: "30vw",
  height: "100vh",
  background: "white",
  zIndex: 9999,
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  padding: "10px",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
};

const StocksTechnicalList = ({
  technicalData,
  storeSelectedItems,
  setShowList,
  selectedStock,
  updatelist,
}) => {
  // technicalData if selectedStock is not there
  // console.log("+++", selectedStock, technicalData);
  const [newStockes, setNewStockes] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [stocksPerPage] = useState(50);
  const [allStocks, setAllStocks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSelected, setIsSelected] = useState([]);
  const [selectedItems, setSelectedItems] = useState(
    selectedStock && selectedStock.length >= 0 && technicalData == null
      ? selectedStock
      : technicalData
  );
  const [allStockStore, setAllStockStore] = useState([]);
  const prevSelectedItemsRef = useRef();
  // console.log("{technical3}", selectedStock);
  useEffect(() => {
    // console.log("selectedItems>>>", selectedItems);
    const simulatedSelectedItems = selectedItems.map((item) => ({
      name: item?.name?.replace(/^"(.*)"$/, "$1"),
      scripCode: item?.scripCode,
    }));

    // console.log("simulatedSelectedItems", simulatedSelectedItems);
    // console.log("newStockes", newStockes);

    const newStockesSelection = newStockes.map((item) =>
      simulatedSelectedItems.some(
        (selectedItem) =>
          selectedItem.name === item.name.replace(/^"(.*)"$/, "$1") &&
          selectedItem.scripCode === item.instrument_token
      )
    );
    setIsSelected(newStockesSelection);

    // console.log("isSelected", newStockesSelection);
  }, [selectedItems, newStockes]);

  const fetchData = async () => {
    // console.log("calling function....")
    const token = getZerodhaToken();
    // console.log("accessToken-", token);
    try {
      const response = await getInstrumentsAPI({
        api_key: "3wxj01wpd2rnnn9c",
        access_token: token,
      });

      const data = response.data;
      // console.log("stockDATA@", data);

      // Split data into lines
      const lines = data.split("\n");

      // Get field names from the first line
      const fieldNames = lines[0].split(",").map((field) => field.trim());

      // Create JSON objects for each line
      const jsonData = [];
      for (let i = 1; i < lines.length; i++) {
        const values = lines[i].split(",");

        // Check if the line has the correct number of fields
        if (values.length === fieldNames.length) {
          const jsonEntry = {};
          for (let j = 0; j < fieldNames.length; j++) {
            jsonEntry[fieldNames[j]] = values[j].trim();
          }
          jsonData.push(jsonEntry);
        } else {
          console.error(
            `Line ${
              i + 1
            } does not have the correct number of fields. Content: ${lines[i]}`
          );
        }
      }

      // console.log("jsonData", jsonData);
      let sss = jsonData?.filter((item) => item.exchange == "NSE");
      // console.log("jsonDataFilter", sss);

      let sortedJsonData = jsonData?.sort(
        (a, b) => a.tradingsymbol.length - b.tradingsymbol.length
      );
      // console.log("sortedJsonData@", sortedJsonData);
      setAllStockStore(sortedJsonData);

      const first20Stocks = jsonData?.slice(0, 20);

      setAllStocks(first20Stocks);
      setNewStockes(first20Stocks);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const deBounceSearch = debounce((value) => {
    // console.log("value", value)
    setSearchTerm(value);
    const filteredStocks = allStockStore?.filter((item) => {
      const name = item.tradingsymbol.toLowerCase();
      return name.includes(value.trim().toLowerCase());
    });

    // console.log("valuuueSearch", filteredStocks);
    let arr = filteredStocks?.slice(0, 3000);
    // console.log("arr", arr)
    setNewStockes(arr);
  }, 1000);
  const handleSearchChange = (e) => {
    const { value } = e.target;
    deBounceSearch(value);
  };

  const handleDoneClick = () => {
    // console.log("setselecteditem", selectedItems);
    // console.log("searchTerm", searchTerm);

    storeSelectedItems(selectedItems);
    setShowList(false);
    updatelist(selectedItems);
  };

  const handleAddToSelected = (item) => {
    const { name, instrument_token } = item;

    const isSelected = selectedItems.some(
      (selectedItem) =>
        selectedItem.name == name && selectedItem.scripCode == instrument_token
    );

    if (isSelected) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter(
          (selectedItem) =>
            !(
              selectedItem.name == name &&
              selectedItem.scripCode == instrument_token
            )
        )
      );
    } else {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        { name: name.replace(/^"(.*)"$/, "$1"), scripCode: instrument_token },
      ]);
    }
  };
  // console.log("selectedItems>>", selectedItems);
  const handleRemoveFromSelected = (index) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems.splice(index, 1);
    setSelectedItems(updatedSelectedItems);
  };

  return (
    <div style={container}>
      {/* Search Box */}
      <div
        style={{
          marginBottom: "10px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            width: "60%",
          }}
        >
          <i
            style={{
              position: "absolute",
              marginLeft: "10px",
              width: "20px",
              height: "20px",
            }}
            className="fa-solid fa-magnifying-glass"
          ></i>
          <input
            type="text"
            placeholder="Search stocks..."
            // value={searchTerm}
            onChange={handleSearchChange}
            style={{
              borderRadius: "12px",
              border: "1px solid lightgray",
              padding: "8px",
              paddingLeft: "40px",
              width: "100%",
            }}
          />
        </div>
        <button
          className="btn-color"
          style={{
            padding: "8px",
            background: "#0312f5",
            color: "white",
            borderRadius: "8px",
            border: "none",
            width: "30%",
          }}
          onClick={handleDoneClick}
        >
          Done
        </button>
      </div>

      {/* Display selected items */}
      <div
        style={{
          justifyContent: "space-around",
          marginBottom: "10px",
          overflowX: "auto",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {selectedItems?.map((selectedItem, index) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "4px",
              background: "lightgray",
              borderRadius: "18px",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "12px",
              fontSize: "12px",
            }}
            key={index}
          >
            <i
              className="fa-solid fa-circle-check"
              style={{ color: "#03C03C", marginLeft: "5px" }}
            ></i>
            <div style={{ marginLeft: "6px" }}>{selectedItem.name}</div>

            <i
              style={{
                marginLeft: "10px",
                width: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleRemoveFromSelected(index)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
        ))}
      </div>

      {/* Wrapper for Stock List */}
      {newStockes?.length > 0 ? (
        <div
          style={{
            flex: 1,
            overflowY: "auto",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          {newStockes?.map((item, index) => {
            const isItemSelected = selectedItems.some(
              (selectedItem) => selectedItem === item
            );
            const isAlreadySelecetd =
              isSelected.length > 0 && isSelected[index];
            const isClickable = !isItemSelected && !isAlreadySelecetd;

            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "15px",
                  marginTop: "15px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {/* Stock Details */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "40%",
                  }}
                >
                  <img
                    src="algoTradeImageLogo.png"
                    alt="stock logo"
                    height={40}
                    width={40}
                  />
                  <div style={{ width: "100%", marginLeft: "14px" }}>
                    <div style={{ fontSize: "16px" }}>
                      {" "}
                      {item?.tradingsymbol}
                    </div>
                    <div style={{ fontSize: "12px" }}>{item?.exchange}</div>
                  </div>
                </div>

                <i
                  className={
                    isItemSelected || isAlreadySelecetd
                      ? "fa-solid fa-check-circle text-success"
                      : "fa-solid fa-circle-plus"
                  }
                  style={{ fontSize: "24px", cursor: "pointer" }}
                  onClick={isClickable ? () => handleAddToSelected(item) : null}
                ></i>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default StocksTechnicalList;
