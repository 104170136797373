const indicatorEntryConfigs = {
    Ulcer: { ulcerField: "close", ulcerPeriod: 14, ulcerOffset: 0 },
    "TriMA (Triangular Moving Average)": {
        tmaField: "close",
        tmaPeriod: 20,
        tmaOffset: 0,
    },
    "VWMA (Volume Weighted Moving Average)": {
        vwmaField: "close",
        vwmaPeriod: 20,
        vwmaOffset: 0,
    },
    "DEMA (Double Exponential Moving Average)": {
        field: "close",
        period: 20,
        offset: 0,
    },
    "TEMA (Triple Exponential Moving Average)": {
        temaField: "close",
        temaPeriod: 20,
        temaOffset: 0,
    },
    "Open price": { period: 2, offset: 0 },
    "Low price": { lowOffset: 0 },
    "High price": { highOffset: 0 },
    Close: { closeOffset: 0 },
    "SMA (Simple Moving Average)": { field: "close", period: 20, offset: 0 },
    "WMA (Weighted Moving Average)": {
        wmaField: "close",
        wmaPeriod: 20,
        wmaOffset: 0,
    },
    "EMA (Exponential Moving Average)": {
        field: "close",
        period: 20,
        offset: 0,
    },
    "Money Flow Index": { mfiPeriod: 20, mfiOffset: 0 },
    "Williams %R": { wrOffset: 0, wrPeriod: 14 },
    "Moving Average Deviation (MA Dev)": {
        madField: "close",
        madPeriod: 14,
        madType: "SMA",
        madOffset: 0,
        madPop: "point",
    },
    "MACD (Moving Average Convergence/Divergence)": {
        macd_fastMaPeriod: 12,
        macd_slowMaPeriod: 26,
        macd_signalPeriod: 9,
        macdOffset: 0,
    },
    "OBV (On Balance Volume)": { obvOffset: 0 },
    "Aroon-Down": { aroonDownPeriod: 14, aroonDownOffset: 0 },
    "Aroon-Up": { aroonUpPeriod: 14, aroonUpOffset: 0 },
    "Awesome Oscillator": { aOscilatorOffset: 0 },
    "VWAP (Volume Weighted Average Price)": { vwapOffset: 0 },
    "Parabolic SAR": { psarMinimumAF: 0.02, psarMaximumAF: 0.2, psarOffset: 0 },
    "Vortex Indicator": {
        vortexIPeriod: 14,
        vortexISignal: "+ve",
        vortexIOffset: 0,
    },
    Number: { period: 35 },
    "Volume of a candle": { offset: 0 },
    "Donchian Channel": {
        donchianChannel: "lower",
        donchianHighPeriod: 20,
        donchianLowPeriod: 20,
        donchianOffset: 0,
    },
    Stochastic: {
        stochasticPeriod: 14,
        stochasticType: "%k",
        stochasticSmooth: "yes",
        stochasticOffset: 0,
    },
    "Chaikin Money Flow": { chaikinPeriod: 20, chaikinOffset: 0 },
    "MACD-signal": {
        macdsFastMaPeriod: 12,
        macdsSlowMaPeriod: 26,
        macdsSignalPeriod: 9,
        macdsOffset: 0,
    },
    "Commodity Channel Index": { cciPeriod: 20, cciOffset: 0 },
    "Price Rate of Change": { rocField: "close", rocPeriod: 14, rocOffset: 0 },
    "RSI (Relative Strength Index)": { rsiPeriod: 14, rsiOffset: 0 },
    "RSI Moving Average": {
        rsimaPeriod: 14,
        rsimaType: "SMA",
        rsimaMaPeriod: 20,
        rsimaOffset: 0,
    },
    Supertrend: { superTPeriod: 7, superTMultiply: 3, superTOffset: 0 },
    "TR (True Range)": { trOffset: 0 },
    "ATR (Average True Range)": { atrPeriod: 14, atrOffset: 0 },
    "NATR (Normalized Average True Range)": { natrPeriod: 14, natrOffset: 0 },
    "Bollinger %B": {
        bolingerBField: "close",
        bolingerBPeriod: 20,
        bolingerBDev: 2,
        bolingerBType: "SMA",
        bolingerBOffset: 0,
    },
    "MFI Moving Average (Money Flow Index Moving Average)": {
        mfiMAPeriod: 20,
        mfiMAMAPeriod: 20,
        mfiMAType: "SMA",
        mfiMAOffset: 0,
    },
    "Know Sure Thing": {
        kstField: "close",
        kstLTRoc: 10,
        kstLTSma: 10,
        kstRoc: 15,
        kstLSma: 10,
        kstHRoc: 20,
        kstHSma: 10,
        kstHeRoc: 30,
        kstHeSma: 15,
        kstSPeriod: 9,
        kstType: "kst",
        kstOffset: 0,
    },
    "Ichimoku": {
        ichiConversion: 9,
        ichiBase: 26,
        leadingSpanB: 52,
        laggingSpan: 26,
        ichiLine: "conversion",
        cloudShift: "yes",
        ichiOffset: 0,
    },
    "MOM (Momentum Indicator)": { period: 14, offset: 0 },
    "Standard Deviation": {
        stdPeriod: 14,
        stdField: "close",
        stdDev: 2,
        maType: "SMA",
        offset: 0
    },
    "Ultimate Oscillator": {
        length1: 7,
        length2: 14,
        length3: 28,
        offset: 0,
    },
    "Nth Candle": {
        field: "high",
        range: "min",
        num: 1,
    },
    "MACD-histogram": {
        macdsFastMaPeriod: 12,
        macdsSlowMaPeriod: 26,
        macdsSignalPeriod: 9,
        macdsOffset: 0,
    },
    "Median Price": {
        period: 14,
        offset: 0,
    },
    "Chande Momentum Oscillator": { period: 14, offset: 0 },
    "ATR Trailing Stoploss": { multiplier: 3, period: 14, offset: 0 },
    "Choppiness Index": { period: 14, offset: 0 },
    "Central Pivot Range": { signal: "pp" },
    "Camarilla Pivot Points": { signal: "pp" },
    "Pivot Points": { signal: "r1", type: "standard", continous: "yes" },
    "Center Of Gravity": { rocField: "close", rocPeriod: 14, rocOffset: 0 },
    "Elder Ray Index": { period: 14, power: "bull", offset: 0 },
    "Elder Force Index": { period: 14, offset: 0 },
    "UBB (Upper Bollinger Band)": { field: "close", period: 20, dev: 2, type: "SMA", offset: 0 },
    "MBB (Middle Bollinger Band)": { field: "close", period: 20, dev: 2, type: "SMA", offset: 0 },
    "LBB (Lower Bollinger Band)": { field: "close", period: 20, dev: 2, type: "SMA", offset: 0 },
    "Bollinger Bandwidth": { field: "close", period: 20, dev: 2, type: "SMA", offset: 0 },
    "Phase Power Moving Average": { field: "close", powerLength: 30, phase: 0, power: 2, offset: 0 },
    "-DI (Minus Directional Index)": { period: 14, offset: 0 },
    "TSI (True Strength Indicator)": { field: "close", type: "tsi", long: 25, short: 13, signal: 13, offset: 0 },
    "ADX (Average Directional Index)": { period: 14, offset: 0 },
    "Time Series Forecast": { field: "close", period: 20, offset: 0 },
    "TII (Trend Intensity Index)": { field: "close", type: "TII", period: 25, signal: 13, offset: 0 },
    "Linear Regression Forecast": { field: "close", period: 20, offset: 0 },
    "Open Interest Moving Average": { type: "SMA", period: 20, offset: 0 },
    "NVI (Negative Volume Index)": { field: "close", type: "SMA", period: 25, line: "index", offset: 0 },
    "Option Greeks": { type: "delta", dividend: 0, interest: 10, offset: 0 },
    "Detrended Price Oscillator": { field: "close", period: 14, type: "SMA", displacement: "no", offset: 0 },
    "Klinger Volume Oscillator": { period: 13, short: 34, long: 55, type: "klinger", offset: 0 },
    "VWAP Standard Deviation": { period: 14, dev: 2, type: "SMA", offset: 0 },
    "McGinley Dynamic Indicator": { period: 14, offset: 0 },
    "True Range Moving Average": { period: 14, type: "atr", maType: "SMA", maPeriod: 20, offset: 0 },
    "Vortex Moving Average": { period: 14, signal: "+ve", type: "SMA", maPeriod: 20, offset: 0 },
    "Trade Volume Index": { value: 0.5, offset: 0 },
    "Swing Index": { limit: 0.5, offset: 0 },
    "DI (Plus Directional Index)": { period: 14, offset: 0 },
    "Keltner Channel": { period: 14, band: "median", maType: "SMA", shift: 5, offset: 0 },
    "VWAP Moving Average": { type: "SMA", period: 20, offset: 0 },
    "Median Price Moving Average": { period: 14, type: "SMA", maPeriod: 20, offset: 0 },
    "Moving Average": { field: "close", period: 14, type: "SMA", offset: 0 },
    "Moving Average Volume": { type: "SMA", period: 20, offset: 0 },
    "Bollinger %B Moving Average": { field: "open", bPeriod: 20, deviation: 2, bType: "SMA", maType: "SMA", maPeriod: 20, offset: 0 },
    "ADX Moving Average (Average Directional Index Moving Average)": { period: 14, type: "adx", maType: "SMA", maPeriod: 20, offset: 0 },
    "Day High Low": { option: "high" },
    "Chande Kroll Stop": { p: 10, x: 1, q: 9, mode: "stoplong", offset: 0 },
    "Ehler Fisher": { period: 14, line: "ef", offset: 0 },
    "Price Rate Of Change Moving Average (Proc Moving Average)": { field: "close", period: 14, type: "SMA", maPeriod: 20, offset: 0 },
    "MACD OI (Moving Average Convergence/Divergence Open Interest)": {
        fastMaPeriod: 12,
        slowMaPeriod: 26,
        signalPeriod: 9,
        type: "macd",
        offset: 0,
    },
    "Previous N": {field: "close",offset: 0, candle : "min"} ,
    "Intraday Momentum Index Moving Average (IMI Moving Average)" : {period: 14, type:"SMA", maPeriod: 20, offset: 0} ,
    "Opening Range" : {field: "high", range: "min"} ,
    "Choppiness Index Moving Average": { period: 14, type: "SMA", maPeriod: 20, offset: 0 },
    "CCI Moving Average (Commodity Channel Index Moving Average)": { period: 14, type: "SMA", maPeriod: 20, offset: 0 },
    "Schaff Trend Cycle": { period: 13, field: "close", short: 23, long: 50, type: "EMA", offset: 0 },
    "Aroon Oscillator" : {period : 14, offset: 0} ,
    "Stochastic RSI": {period: 14,type: "%k",smooth: "yes",offset: 0},
    "PVI (Positive Volume Index)": { field: "close", type: "SMA", period: 25, line: "index", offset: 0 },
    "Twiggs Money Flow" : {period: 14, offset: 0} ,
    "ATR Bands": { period: 14, shift: 2.5, field: "close", band: "top", offset: 0 },
    "Stochastic Momentum Index": { type: "%k", kPeriod: 10, smoothPeriod: 3, doublePeriod: 3, dPeriod : 10, offset: 0} ,
    "Open interest" : {offset : 0} ,
    "Candle Time": { type: "minuteofcandle"},
    "Coppock curve": { field: "close", period: 10, sroc: 11, lroc: 14, offset: 0 },
    "Alligator": { signal: "jaw", jawPeriod: 13, jawOffset: 8, teethPeriod: 8, teethOffset: 5, lipsPeriod: 5, lipsOffset: 3 , offset: 0  },
    "Volume Oscillator": { short: 12, long: 26, pop: "pct", offset: 0 },
    "OBV Moving Average (On Balance Volume Moving Average)": { type: "SMA", period: 20, offset: 0 },
    "Intraday Momentum Index": { period: 14, offset: 0 },
  
};


const indicatorExitConfigs = {
    "Ulcer": { e_ulcerField: "close", e_ulcerPeriod: 14, e_ulcerOffset: 0 },
    "TriMA (Triangular Moving Average)": { e_tmaField: "close", e_tmaPeriod: 20, e_tmaOffset: 0 },
    "VWMA (Volume Weighted Moving Average)": { e_vwmaField: "close", e_vwmaPeriod: 20, e_vwmaOffset: 0 },
    "DEMA (Double Exponential Moving Average)": { e_field: "close", e_period: 20, e_offset: 0 },
    "TEMA (Triple Exponential Moving Average)": { e_temaField: "close", e_temaPeriod: 20, e_temaOffset: 0 },
    "Open price": { e_period: 2, e_offset: 0 },
    "Low price": { e_lowOffset: 0 },
    "High price": { e_highOffset: 0 },
    "Close": { e_closeOffset: 0 },
    "SMA (Simple Moving Average)": { e_field: "close", e_period: 20, e_offset: 0 },
    "WMA (Weighted Moving Average)": { e_wmaField: "close", e_wmaPeriod: 20, e_wmaOffset: 0 },
    "EMA (Exponential Moving Average)": { e_field: "close", e_period: 20, e_offset: 0 },
    "Money Flow Index": { e_mfiPeriod: 20, e_mfiOffset: 0 },
    "Williams %R": { e_wrOffset: 0, e_wrPeriod: 14 },
    "Moving Average Deviation (MA Dev)": { e_madField: "close", e_madPeriod: 2, e_madType: 0, e_madOffset: 0, e_madPop: 0 },
    "MACD (Moving Average Convergence/Divergence)": { e_macd_fastMaPeriod: 12, e_macd_slowMaPeriod: 26, e_macd_signalPeriod: 9, e_macdOffset: 0 },
    "OBV (On Balance Volume)": { e_obvOffset: 0 },
    "Aroon-Down": { e_aroonDownPeriod: 14, e_aroonDownOffset: 0 },
    "Aroon-Up": { e_aroonUpPeriod: 14, e_aroonUpOffset: 0 },
    "Awesome Oscillator": { e_aOscilatorOffset: 0 },
    "VWAP (Volume Weighted Average Price)": { e_vwapOffset: 0 },
    "Parabolic SAR": { e_psarMinimumAF: 0.02, e_psarMaximumAF: 0.2, e_psarOffset: 0 },
    "Vortex Indicator": { e_vortexIPeriod: 14, e_vortexISignal: "+ve", e_vortexIOffset: 0 },
    "Number": { e_period: 35 },
    "Volume of a candle": { e_offset: 0 },
    "Donchian Channel": { e_donchianChannel: "lower", e_donchianHighPeriod: 2, e_donchianLowPeriod: 2, e_donchianOffset: 0 },
    "Stochastic": { e_stochasticPeriod: 14, e_stochasticType: "%k", e_stochasticSmooth: "yes", e_stochasticOffset: 0 },
    "Chaikin Money Flow": { e_chaikinPeriod: 20, e_chaikinOffset: 0 },
    "MACD-signal": { e_macdsFastMaPeriod: 12, e_macdsSlowMaPeriod: 26, e_macdsSignalPeriod: 9, e_macdsOffset: 0 },
    "Commodity Channel Index": { e_cciPeriod: 20, e_cciOffset: 0 },
    "Price Rate of Change": { e_rocField: "close", e_rocPeriod: 14, e_rocOffset: 0 },
    "RSI (Relative Strength Index)": { e_rsiPeriod: 14, e_rsiOffset: 0 },
    "RSI Moving Average": { e_rsimaPeriod: 14, e_rsimaType: "SMA", e_rsimaMaPeriod: 20, e_rsimaOffset: 0 },
    "Supertrend": { e_superTPeriod: 7, e_superTMultiply: 3, e_superTOffset: 0 },
    "TR (True Range)": { e_trOffset: 0 },
    "ATR (Average True Range)": { e_atrPeriod: 14, e_atrOffset: 0 },
    "NATR (Normalized Average True Range)": { e_natrPeriod: 14, e_natrOffset: 0 },
    "Bollinger %B": { e_bolingerBField: "close", e_bolingerBPeriod: 20, e_bolingerBDev: 2, e_bolingerBType: "SMA", e_bolingerBOffset: 0 },
    "MFI Moving Average (Money Flow Index Moving Average)": { e_mfiMAPeriod: 20, e_mfiMAMAPeriod: 20, e_mfiMAType: "SMA", e_mfiMAOffset: 0 },
    "Know Sure Thing": { e_kstField: "close", e_kstLTRoc: 10, e_kstLTSma: 10, e_kstRoc: 15, e_kstLSma: 10, e_kstHRoc: 20, e_kstHSma: 10, e_kstHeRoc: 30, e_kstHeSma: 15, e_kstSPeriod: 9, e_kstType: "kst", e_kstOffset: 0 },
    "Ichimoku": { e_ichiConversion: 9, e_ichiBase: 26, e_leadingSpanB: 52, e_laggingSpan: 26, e_ichiLine: "conversion", e_cloudShift: "yes", e_ichiOffset: 0 },
    "MOM (Momentum Indicator)": { period: 14, offset: 0 },
    "CHANDE MOMENTUM OSCILLATOR": { period: 26, offset: 52 },
    "Standard Deviation": { e_stdPeriod: 14, e_stdField: "close", e_stdDev: 2, e_maType: "SMA", e_offset: 0 },
    "Ultimate Oscillator": { e_length1: 7, e_length2: 14, e_length3: 28, e_offset: 0 },
    "Nth Candle": { e_field: "high", e_range: "min", e_num: 1 },
    "MACD-histogram": { e_macdsFastMaPeriod: 12, e_macdsSlowMaPeriod: 26, e_macdsSignalPeriod: 9, e_macdsOffset: 0 },
    "Median Price": { e_period: 14, e_offset: 0 },
    "Chande Momentum Oscillator": { e_period: 14, e_offset: 0 },
    "ATR Trailing Stoploss": { e_multiplier: 2, e_period: 14, e_offset: 0 },
    "Choppiness Index": { e_period: 14, e_offset: 0 },
    "Central Pivot Range": { e_signal: "pp" },
    "Camarilla Pivot Points": { e_signal: "pp" },
    "Pivot Points": { e_signal: "pp", e_type: "standard", e_continous: "no" },
    "Elder Ray Index": { e_period: 14, e_power: "bull", e_offset: 0 },
    "Elder Force Index": { e_period: 14, e_offset: 0 },
    "UBB (Upper Bollinger Band)": { e_field: "close", e_period: 20, e_dev: 2, e_type: "SMA", e_offset: 0 },
    "MBB (Middle Bollinger Band)": { e_field: "close", e_period: 20, e_dev: 2, e_type: "SMA", e_offset: 0 },
    "LBB (Lower Bollinger Band)": { e_field: "close", e_period: 20, e_dev: 2, e_type: "SMA", e_offset: 0 },
    "Bollinger Bandwidth": { e_field: "close", e_period: 20, e_dev: 2, e_type: "SMA", e_offset: 0 },
    "Phase Power Moving Average": { e_field: "close", e_powerLength: 30, e_phase: 0, e_power: 2, e_offset: 0 },
    "-DI (Minus Directional Index)": { e_period: 14, e_offset: 0 },
    "TSI (True Strength Indicator)": { e_field: "close", e_type: "tsi", e_long: 25, e_short: 13, e_signal: 13, e_offset: 0 },
    "ADX (Average Directional Index)": { e_period: 14, e_offset: 0 },
    "Time Series Forecast": { e_field: "close", e_period: 20, e_offset: 0 },
    "TII (Trend Intensity Index)": { e_field: "close", e_type: "TII", e_period: 25, e_signal: 13, e_offset: 0 },
    "Linear Regression Forecast": { e_field: "close", e_period: 20, e_offset: 0 },
    "Open Interest Moving Average": { e_type: "SMA", e_period: 20, e_offset: 0 },
    "NVI (Negative Volume Index)": { e_field: "close", e_type: "SMA", e_period: 25, e_line: "index", e_offset: 0 },
    "Option Greeks": { e_type: "delta", e_dividend: 0, e_interest: 10, e_offset: 0 },
    "Detrended Price Oscillator": { e_field: "close", e_period: 14, e_type: "SMA", e_displacement: "no", e_offset: 0 },
    "Klinger Volume Oscillator": { e_period: 13, e_short: 34, e_long: 55, e_type: "klinger", e_offset: 0 },
    "VWAP Standard Deviation": { e_period: 14, e_dev: 2, e_type: "SMA", e_offset: 0 },
    "McGinley Dynamic Indicator": { e_period: 14, e_offset: 0 },
    "True Range Moving Average": { e_period: 14, e_type: "atr", e_maType: "SMA", e_maPeriod: 20, e_offset: 0 },
    "Vortex Moving Average": { e_period: 14, e_signal: "+ve", e_type: "SMA", e_maPeriod: 20, e_offset: 0 },
    "Trade Volume Index": { e_value: 0.5, e_offset: 0 },
    "Swing Index": { e_limit: 0.5, e_offset: 0 },
    "DI (Plus Directional Index)": { e_period: 14, e_offset: 0 },
    "Keltner Channel": { e_period: 14, e_band: "median", e_maType: "SMA", e_shift: 5, e_offset: 0 },
    "VWAP Moving Average": { e_type: "SMA", e_period: 20, e_offset: 0 },
    "Median Price Moving Average": { e_period: 14, e_type: "SMA", e_maPeriod: 20, e_offset: 0 },
    "Moving Average": { e_field: "close", e_period: 14, e_type: "SMA", e_offset: 0 },
    "Moving Average Volume": { e_type: "SMA", e_period: 20, e_offset: 0 },
    "Bollinger %B Moving Average": { e_field: "open", e_bPeriod: 20, e_deviation: 2, e_bType: "SMA", e_maType: "SMA", e_maPeriod: 20, e_offset: 0 },
    "ADX Moving Average (Average Directional Index Moving Average)": { e_period: 14, e_type: "adx", e_maType: "SMA", e_maPeriod: 20, e_offset: 0 },
    "Day High Low": { e_option: "high" },
    "Chande Kroll Stop": { e_p: 10, e_x: 1, e_q: 9, e_mode: "stoplong", e_offset: 0 },
    "Ehler Fisher": { e_period: 14, e_line: "ef", e_offset: 0 },
    "Price Rate Of Change Moving Average (Proc Moving Average)": { e_field: "close", e_period: 14, e_type: "SMA", e_maPeriod: 20, e_offset: 0 },
    "MACD OI (Moving Average Convergence/Divergence Open Interest)": {
        e_fastMaPeriod: 12,
        e_slowMaPeriod: 26,
        e_signalPeriod: 9,
        e_type: "macd",
        e_offset: 0,
    },
    "Previous N": { e_field: "close", e_offset: 0, e_candle: "min" },
    "Intraday Momentum Index Moving Average (IMI Moving Average)": { e_period: 14,e_type: "SMA", e_maPeriod: 20, e_offset: 0 },
    "Opening Range": { e_field: "high", e_range: "min" },
    "Choppiness Index Moving Average": { e_period: 14, e_type: "SMA", e_maPeriod: 20, e_offset: 0 },
     "CCI Moving Average (Commodity Channel Index Moving Average)" : { e_period: 14, e_type: "SMA", e_maPeriod: 20, e_offset: 0 },
    "Schaff Trend Cycle": { e_period: 13, e_field: "close", e_short: 23, e_long: 50, e_type: "EMA", e_offset: 0 },
    "Aroon Oscillator" : {e_period : 14, e_offset : 0} ,
    "Stochastic RSI": { e_period: 14, e_type: "%k", e_smooth: "yes", e_offset: 0 } ,
    "PVI (Positive Volume Index)": { e_field: "close", e_type: "SMA", e_period: 25, e_line: "index", e_offset: 0 },
    "Twiggs Money Flow" : {e_period : 14, e_offset:0},
    "ATR Bands": { e_period: 14, e_shift: 2.5, e_field: "close", e_band: "top", e_offset: 0 },
    "Stochastic Momentum Index": { e_type: "%k", e_kPeriod: 10, e_smoothPeriod: 3, e_doublePeriod: 3, e_dPeriod: 10, e_offset: 0 },
    "Open interest" : {e_offset : 0},
    "Candle Time": { e_type: "minuteofcandle" } ,
    "Coppock curve": { e_field: "close", e_period: 10, e_sroc: 11, e_lroc: 14, e_offset: 0 },
    "Alligator": { e_signal: "jaw", e_jawPeriod: 13, e_jawOffset: 8, e_teethPeriod: 8, e_teethOffset: 5, e_lipsPeriod: 5, e_lipsOffset: 3, e_offset: 0 },
    "Volume Oscillator": { e_short: 12, e_long: 26, e_pop: "pct", e_offset: 0 },
    "OBV Moving Average (On Balance Volume Moving Average)": { e_type: "SMA", e_period: 20, e_offset: 0 },
    "Intraday Momentum Index": { e_period: 14, e_offset: 0 },


};

export { indicatorEntryConfigs, indicatorExitConfigs }