import axios from "axios";
import { API_BASE_URL } from "../config";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
export const loginapi = async (email,password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/users/adminLogin`, {
      email:email,
      password:password
    });
    return response.data;
  } catch (error) {
    console.log("Error in Login",error);
    alert("Something went wrong... try again");
  }
};
export const updateprofiledataofuser=async(id,nm,value)=>{
  try{
          const response=await axios.post(`${API_BASE_URL}/adminpanel/UpdateProfileData`,{userId:id,col:nm,val:value});
          return response.data;
    }
  catch(error)
  {
    console.log("Error",error);
  }
}
export const getzerodhaacc = async (id) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetAccount`,
      {
        user_id: id,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
