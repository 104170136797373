// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.page-button {
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid #ccc;
    background-color: white;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
}

.page-button.active {
    background: linear-gradient(to right, #2d3597, #0e84d1);
    color: white;
    border: none;
}

.page-button:hover:not(.active) {
    background-color: #f0f0f0;
}

.page-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.page-info {
    margin: 0 15px;
    font-size: 16px;
    color: #333;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/styles/pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,iCAAiC;IACjC,kBAAkB;AACtB;;AAEA;IACI,uDAAuD;IACvD,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,cAAc;IACd,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".pagination-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 20px 0;\n}\n\n.page-button {\n    padding: 5px 10px;\n    margin: 0 5px;\n    border: 1px solid #ccc;\n    background-color: white;\n    color: #333;\n    cursor: pointer;\n    transition: background-color 0.3s;\n    border-radius: 5px;\n}\n\n.page-button.active {\n    background: linear-gradient(to right, #2d3597, #0e84d1);\n    color: white;\n    border: none;\n}\n\n.page-button:hover:not(.active) {\n    background-color: #f0f0f0;\n}\n\n.page-button.disabled {\n    cursor: not-allowed;\n    opacity: 0.5;\n}\n.page-info {\n    margin: 0 15px;\n    font-size: 16px;\n    color: #333;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
