import React from "react";
import "../../styles/checkTest.css";

const checkValidIndicator = [
 "crosses above",
  "crosses below",
  "higher than",
  "lower than",
  "higher than equal to",
  "lower than equal to",
  "equal to",
//   "&&",
//   "||",
  "+",
  "-",
  "/",
  "*",
  "Close",
  "Open price",
  "High price",
  "Low price",
  "Ulcer",
  "DEMA (Double Exponential Moving Average)",
//   "TEMA (Triple Exponential Moving Average)",
  "TriMA (Triangular Moving Average)",
//   "VWMA (Volume Weighted Moving Average)",
  "SMA (Simple Moving Average)",
  "EMA (Exponential Moving Average)",
  "WMA (Weighted Moving Average)",
  "Number",
  "Money Flow Index",
//   "Williams %R",
//   "Moving Average Deviation (MA Dev)",
  "MACD (Moving Average Convergence/Divergence)",
//   "OBV (On Balance Volume)",
//   "Aroon-Down",
//   "Aroon-Up",
  "Awesome Oscillator",
  "VWAP (Volume Weighted Average Price)",
//   "Parabolic SAR",
  "Vortex Indicator",
  "Volume of a candle",
//   "Donchian Channel",
  "Stochastic",
  "Chaikin Money Flow",
  "MACD-histogram",
  "MACD-signal",
//   "Commodity Channel Index",
//   "Center Of Gravity",
  "Price Rate of Change",
  "RSI (Relative Strength Index)",
  "RSI Moving Average",
  "TR (True Range)",
  "ATR (Average True Range)",
//   "NATR (Normalized Average True Range)",
//   "Supertrend",
//   "Bollinger %B",
  //   "MFI Moving Average (Money Flow Index Moving Average)",
  "Ichimoku",
//   "Know Sure Thing",
//   "Elder Force Index",
  "MOM (Momentum Indicator)",
  // "Standard Deviation",
  "Ultimate Oscillator",
  "TriMA (Triangular Moving Average)",
//   "Nth Candle",
//   "Chande Momentum Oscillator",
  "Median Price",
//   "ATR Trailing Stoploss",
  "Choppiness Index",
//   "Central Pivot Range",
//   "Camarilla Pivot Points",
//   "Pivot Points",
//   "Elder Ray Index",
//   "UBB (Upper Bollinger Band)",
//   "MBB (Middle Bollinger Band)",
//   "LBB (Lower Bollinger Band)",
//   "Bollinger Bandwidth",
];
// const conditionHide = [
//   "crosses above",
//   "crosses below",
//   "higher than",
//   "lower than",
//   "higher than equal to",
//   "lower than equal to",
//   "equal to",
//   "&&",
//   "||",
//   "+",
//   "-",
//   "/",
//   "*"
// ];
const CheckTest = ({ isTest }) => {
    console.log("isTest", isTest) ;
  const isTested = checkValidIndicator?.includes(isTest);
//   const shouldHide = conditionHide?.includes(isTest) ;
//   if (shouldHide) return null ;
    return (
      <div
        className="utTestContainer"
        style={{
          color: isTested ? "green" : "orange",
          border: `2px solid ${isTested ? "green" : "orange"}`,
        }}
      >
        {isTested ? "T" : "UT"}
      </div>
    );
};

export default CheckTest;
