import React, { useEffect, useState } from 'react'
import "../../styles/inputSearch.css";
import SearchIcon from "@mui/icons-material/Search";

const InputSearch = ({
  dataList,
  setDataList,
  originalDataList,
  isReset,
  isScanner,
}) => {
  const [value, setValue] = useState("");
  const handleChange = (e) => {
    let searchValue = e.target.value;
    setValue(searchValue);
    if (searchValue == "") {
      console.log("SEARCH", dataList);
      setDataList(originalDataList);
    } else {
      let res = dataList?.filter((item) =>{
        let name = isScanner == "true" ? item.scanner_name : item.strategy_name; ;
        return name.includes(searchValue);
      }
      );
      setDataList(res);
    }
  };
  useEffect(() => {
    setValue("");
  }, [isReset]);
  return (
    <>
      <div className="search-container">
        <label for="Search" className="sr-only">
          Search
        </label>
        <input
          type="text"
          id="Search"
          placeholder="Search name ..."
          className="search-input"
          onChange={handleChange}
          value={value}
        />
        <span className="search-icon">
          <button type="button" className="search-button">
            <SearchIcon className="material-icons" />
          </button>
        </span>
      </div>
    </>
  );
};

export default InputSearch