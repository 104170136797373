import axios from "axios";
import { API_BASE_URL } from "../config";
// const API_BASE_URL = "http://192.168.1.22:8008/api/v1";
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "http://3.111.147.17:8008/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "http://localhost:8008/api/v1";
// const myAPI_BASE_URL = "http://192.168.1.3:8008/api/v1"
export const zerodhaGetAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetAccount`,
      data
    );
    if (response.data.success) {
      return response.data.accountDetails;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Zerroda Error : ", error);
  }
};

export const zerodhaAddAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaAddAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error : ", error);
  }
};

export const zerodhaEditAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaEditAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error : ", error);
  }
};

export const zerodhaDeleteAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaDeleteAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaLogin = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaLogin`,
      data
    );
    if (response.data.success) {
      return response.data.loginURL;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaSessionGenrated = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGenerateSession`,
      data
    );
    if (response.data.success) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaMargin = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetMargin`,
      data
    );
    if (response.data.success) {
      return response.data.marginDetails;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaHoldings = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetHoldings`,
      data
    );
    if (response.data.success) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaPositions = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetPositions`,
      data
    );
    if (response.data.success) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaOrders = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetOrders`,
      data
    );
    if (response.data.success) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const zerodhaGetAccountApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetAccount`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

// http://localhost:8008/api/v1/adminpanel/AdminZerodhaGetAccount
export const zerodhaAdminGetAccountApi = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/adminpanel/AdminZerodhaGetAccount`);
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
export const AddNewAccount=async(data)=>{
  try
  {
    const response=await axios.post(`${API_BASE_URL}/adminpanel/AdminZerodhaAddAccount`,data);
    if(response.data.success)
      return response.data;
    else
      return false;
  } catch(error)
  {
    console.log("Error:",error);
  }
}
export const checkzerodhaacc = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/checkZerodhActive`,
      data
    );
    if (response.data) {
      return response?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};
export const EditNewAccount=async(data)=>{
  try
  {
    const response=await axios.post(`${API_BASE_URL}/adminpanel/AdminZerodhaEditAccount`,data);
    if(response.data.success)
      return response.data;
    else
      return false;
  } catch(error)
  {
    console.log("Error:",error);
  }
}
// http://localhost:8008/api/v1/adminpanel/AdminZerodhaDeleteAccount
export const DeleteAccount=async(data)=>{
  try
  {
    const response=await axios.post(`${API_BASE_URL}/adminpanel/AdminZerodhaDeleteAccount`,data);
    if(response.data.success)
      return response.data;
    else
      return false;
  } catch(error)
  {
    console.log("Error:",error);
  }
}
// export const getZerodhaLogin = async (data) => {
//   try {
//     const response = await axios.post(
//       `${API_BASE_URL}/demataccount/ZerodhaLogin`,
//       data
//     );
//     if (response.data.success) {
//       return response.data.loginURL;
//     } else {
//       return false;
//     }
//   } catch (error) {
//     console.log("Error  : ", error);
//   }
// };
