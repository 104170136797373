import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Table,
} from "react-bootstrap";
import SideBarSingleStrategy from "./SideBarSingelStrategy";
import {
  editStrategyNameApi,
  getBackTestApi,
  getBackTestTransactionsApi,
  getSingleStrategyDetailsApi,
  getCandleDataApi,
  checkPreviouslyDeployed,
  getDiscoveredBackTestResult,
  getSingleSavedDiscoveredStrategyDetails,
  getDiscoveredBackTestTransactionsApi,
} from "../../api/strategyapi";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CreateDeployPopupsingle from "./CreateDeployPopupsingle";
import {
  deleteStrategyApi,
  deployStrategyApi,
  getAllStrategyApi,
} from "../../api/strategyapi";
import StrategyViewModal from "./StrategyViewModal";
import moment from "moment";
import BackTestResultStrategy from "./BacktestResultStrategy";
import { getSingleSavedStrategyData, getStockLTPDataAPI } from "../../api/api";
import { getUser, getUserPlan, getZerodhaToken } from "../../Session";
import dayjs, { Dayjs } from "dayjs";
import { getBackTestResultApi } from "../../api/strategyapi";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import EditStrategyModal from "./EditStrategyModal";
import DeleteStrategyModal from "./DeleteStrategyModel";
import CreateStrategyPopup from "./CreateStrategyPopup";
import axios from "axios";
// import StrategyBacktestTable from "./SubComponents/StrategyBacktestTable";
import SkeletonLoader from "../componentCommon/SkeletonLoader";
import CustomAlert from "../componentCommon/CustomAlert";
const StrategyDetailComponent = lazy(() =>
  import("./SubComponents/StrategyDetailComponent")
);
const StrategyBacktestTable = lazy(() =>
  import("./SubComponents/StrategyBacktestTable")
);

moment.locale("en");

let count = 0;
let data, functiontext2;
const SingleStrategy = () => {
  const navigate = useNavigate();
  const [optionMenu, setOptionMenu] = useState(false);
  const [cumulativeData, setCumulativeData] = useState([]);
  const [totalSignal, setTotalSignal] = useState("");
  const [totalWins, setTotalWins] = useState("");
  const [totalLosses, setTotalLosses] = useState("");
  const [winningStreak, setWinnigStreak] = useState("");
  const [losingStreak, setLosingstreak] = useState("");
  const [maxDD, setMaxDD] = useState("");
  const [strategyDetails, setStrategyDetails] = useState("");
  const [profitloss, setProfitloss] = useState(null);
  const [deployval, setDeployval] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [show, setShow] = useState(false);
  const [openDeployModal, setOpentDeployModal] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [termCondition, settermCondition] = useState(false);
  const [strategyId, setStrategyId] = useState();
  const [trading_type, set_trading_Type] = useState(1);
  const [strategyItemData, setStrategyItemData] = useState();
  const [transactionData, setTransactionData] = useState([]);
  const [viewOpen, setViewOpen] = useState(false);
  const [scriptCode, setScriptCode] = useState("");
  const [isCompleted, setIsCompleted] = useState(true);
  const [showDropDown, setShowDropDown] = useState(false);
  const [candleSticksData, setCandleStickData] = useState(null);
  const [showEditStrategyModal, setShowEditStrategyMoal] = useState(false);
  const [shareData, setShareData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedShare, setSelectedShare] = useState(null);
  const [formData, setFormData] = useState();
  const [showCreatePopup, setShowCreatePopup] = useState();
  const elementRef = useRef(null);
  const dropdownRef = useRef(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [seletedStockId, setSelectedStockId] = useState(null);
  const [isAllBackTestResult, setAllBackTestResult] = useState(1);
  const [transactionDataLength, setTransactionDataLength] = useState(false);
  const [strategyNameError, setStrategyNameError] = useState(null);
  const [strategiesName, setStrategiesName] = useState();
  const [hover, setHover] = useState(false);
  const [isSelectCalender, setIsSelectedCalender] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment(strategyDetails?.end_date).format("YYYY-MM-DD")
  );
  const user = getUser();
  const accessToken = getZerodhaToken();
  const [strategiesLoading, setStrategiesLoading] = useState(true);
  const [backtestLoading, setBacktestLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState(null);

  const showScroll = () => {
    const scrollValue = elementRef.current.scrollLeft;
  };
  const [series, setSeries] = useState([
    {
      // name: "Desktops",
      data: [50, 300, 55, 500, 100],
    },
  ]);

  const [showMM, setShowMM] = useState(false);

  const [options, setOptions] = useState({
    chart: {
      stroke: {
        show: true,
        curve: "stepline",
        lineCap: "butt",
        colors: "#33b2df",
        width: 0.5,
        dashArray: 0,
      },
      // id: "basic-bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      // sparkline: {
      //   enabled: true
      // }
    },
    stroke: {
      curve: "stepline",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#76C253"],
    title: {
      //   text: "Openeing Range Break",
      align: "left",
      style: {
        fontWeight: "500",
        fontSize: "16px",
        padding: 0,
        margin: 0,
      },
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      //   min: 0,
      // max: 600,
      tickAmount: 6,
      show: true,
      labels: {
        show: true,
        align: "left",
        minWidth: 0,
        maxWidth: 160,
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value) => {
          return value;
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
  });

  const [candleSeries, setCandleSeries] = useState([]);

  const getServerData = (start, stop, callback) => {
    // const
    var now = new Date().getTime();
    var data = [];
    var current = start;

    const result = [];
    if (selectedShare !== null) {
      for (let i = 0; i < selectedShare?.length; i++) {
        const timestamp = selectedShare.data[i][0];
        if (timestamp >= start && timestamp <= stop) {
          result.push(selectedShare[i]);
        }
      }
    }

    const candledata = [
      {
        name: "candle",
        data: result,
      },
    ];

    setCandleSeries(candledata);
    callback(result);
  };
  const [candleOption, setCandleOption] = useState({
    chart: {
      height: 200,
      width: 100,
      type: "candlestick",
      zoom: {
        autoScaleYaxis: true,
        enabled: true,
        type: "x",
      },
    },
    title: {
      text: "CandleStick Chart",
      align: "left",
    },
    xaxis: {
      type: "category",
    },
    tooltip: {
      enabled: true,
    },
  });

  const featchBackTestResult = async () => {
    const user = getUser();
    const token = getZerodhaToken();
    let api_endpoint =
      location?.state === true
        ? getDiscoveredBackTestResult
        : getBackTestResultApi;
    const result = await api_endpoint({
      id: id,
      user_id: user.id,
      api_key: "3wxj01wpd2rnnn9c",
      access_token: token,
    });

    // setShareData(result?.shares);
    setCumulativeData(result);
    const isAllBackTestResult = result?.shares?.filter(
      (item) => item?.isCompleted === 0
    ).length;
    setAllBackTestResult(isAllBackTestResult);
    // setSelectedShare(result?.shares[0]);
    const temp = result?.shares[0]?.data;
    let tokens = result?.shares?.map((item) => parseInt(item.script_code));
    getStocksData(tokens, result?.shares);

    // getServerData(now - 30 * 86400000, now  ,result?.shares[0].data ,'');

    if (selectedRow !== null) {
      // setShowMM(true);
      // handleShowGraph(selectedRow);
      const clickedItem = result?.shares[selectedRow];
      const graphData = [
        {
          name: clickedItem?.stock_name,
          data: clickedItem?.graphData,
        },
      ];
      setSeries(graphData);

      // setTransactionDetails(clickedItem?.transactionDetails)

      setSelectedShare(clickedItem);
    }
  };

  useEffect(() => {}, [transactionDetails, series, isAllBackTestResult]);

  useEffect(() => {
    count = 0;
    setDeployval();
    featchBackTestResult();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      featchBackTestResult();
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (isAllBackTestResult > 0 && seletedStockId) {
      const interval = setInterval(() => {
        featchBackTestResult();
        if (seletedStockId !== null) {
          fetchTransctionDetails(seletedStockId);
        }
      }, 5000);
      return () => clearInterval(interval);
    } else {
    }
  }, [seletedStockId]);

  function extractRecordsWithSimilarDistance(array, numRecords) {
    const step = Math.floor(array.length / numRecords); // Calculate the step size
    const extractedRecords = [];

    for (let i = 0; i < numRecords; i++) {
      const index = i * step; // Calculate the index to extract
      extractedRecords.push(array[index]); // Extract the record
    }

    return extractedRecords;
  }

  const handleShowGraph = async (index) => {
    setSelectedDate(moment(strategyDetails?.end_date).format("YYYY-MM-DD"));
    const clickedItem = shareData[index];
    const user = getUser();
    const accessToken = getZerodhaToken();
    const candleDataResult = await getCandleDataApi({
      id: id,
      user_id: user.id,
      api_key: "3wxj01wpd2rnnn9c",
      access_token: accessToken,
    });
    let data=[];
    if(Array.isArray(candleDataResult) && candleDataResult?.length>0)
    {
      data = candleDataResult[0]?.data || [];
    }
    else
    {
      data=[];
    }
  
    const mostRecentDate = new Date(
      Math.max(...data.map((candle) => new Date(candle[0])))
    );
    const candledata = [
      {
        name: "candle",
        data: (function () {
          const data = candleDataResult[0]?.data || [];
          const candleInterval = strategyDetails?.candle_interval_id;

          if (
            candleInterval === "1m" ||
            candleInterval === "3m" ||
            candleInterval == "5m"
          ) {
            if (data.length === 0) {
              return [];
            }

            const mostRecentDate = new Date(
              Math.max(...data.map((candle) => new Date(candle[0])))
            );

            return data
              .filter((candle) => {
                const candleDate = new Date(candle[0]);
                return (
                  candleDate.toDateString() === mostRecentDate.toDateString()
                );
              })
              .map((candle) => ({
                x: moment.utc(candle[0]).format("DD MMM YY HH:mm"),
                y: [candle[1], candle[2], candle[3], candle[4]],
              }));
          } else {
            return (
              data.length > 100
                ? data.slice(data.length - 70, data.length)
                : data
            ).map((candle) => ({
              x: moment.utc(candle[0]).format("DD MMM YY HH:mm"),
              y: [candle[1], candle[2], candle[3], candle[4]],
            }));
          }
        })(),
      },
    ];
    const graphData = [
      {
        name: clickedItem?.stock_name,
        data: clickedItem?.graphData,
      },
    ];
    setIsCompleted(clickedItem.isCompleted);
    fetchTransctionDetails(clickedItem?.id, clickedItem?.isCompleted);
    setSelectedStockId(clickedItem?.id);
    setSelectedRow(index);
    setSelectedShare(clickedItem);
    setCandleSeries(candledata);
    let temp = clickedItem.data;
    const now = new Date().getTime();
    const start = now - 30 * 86400000;
    const stop = now;
    setCandleStickData(candleDataResult[0]?.data);
    setSeries(graphData);
    setShowMM(!showMM);
  };

  function findResultsForDate(data, targetDate) {
    const currentDate = new Date();
    const targetDateString = targetDate
      ? new Date(targetDate).toISOString().split("T")[0]
      : new Date(data[data?.length - 1][0]).toISOString().split("T")[0];
    // Filter records up to the targetDate
    const filteredData = data.filter((entry) => {
      const timestamp = entry[0];
      const date = new Date(timestamp);

      const entryDateString = date?.toISOString().split("T")[0];
      return entryDateString <= targetDateString;
    });

    let mostRecentDate;
    if (targetDate) {
      mostRecentDate = new Date(`${targetDate}T00:00:00Z`);
    } else {
      const candleInterval = strategyDetails?.candle_interval_id;
      if (
        candleInterval == "30m" ||
        candleInterval == "60m" ||
        candleInterval == "1d"
      ) {
        let resData = filteredData.slice(-70);
        return resData;
      } else {
        const date = new Date(data[data?.length - 1][0]);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        mostRecentDate = new Date(`${formattedDate}T00:00:00Z`);
      }
    }
    const formattedDate = mostRecentDate?.toISOString().split("T")[0];
    let final = data.filter((entry) => {
      const timestamp = entry[0];
      const entryDateString = timestampToDateString(timestamp);
      return entryDateString == formattedDate;
    });
    function timestampToDateString(timestamp) {
      const date = new Date(timestamp);
      return date.toISOString().split("T")[0];
    }

    // Get the last 70 records from the filtered data
    const last70Records = filteredData.slice(-70);
    const candleInterval = strategyDetails?.candle_interval_id;
    return candleInterval == "1m" ||
      candleInterval == "3m" ||
      candleInterval == "5m" ||
      candleInterval === "10m" ||
      candleInterval === "15m" ||
      candleInterval === "30m" ||
      candleInterval === "60m" ||
      candleInterval === "1d"
      ? final
      : last70Records;
  }

  const handleDateWiseGraph = (date) => {
    const data = candleSticksData;
    setSelectedDate(date);
    setIsSelectedCalender(!isSelectCalender);
    const candleInterval = strategyDetails?.candle_interval_id;
    const resultsForDate = findResultsForDate(data, date);
    const candledata = [
      {
        name: "candle",
        data: (function () {
          let processedData;
          if (
            candleInterval === "1m" ||
            candleInterval === "3m" ||
            candleInterval === "5m" ||
            candleInterval === "10m" ||
            candleInterval === "15m" ||
            candleInterval === "30m" ||
            candleInterval === "60m" ||
            candleInterval === "1d"
          ) {
            processedData = resultsForDate;
          } else {
            processedData =
              resultsForDate?.length > 70
                ? resultsForDate.slice(0, 70)
                : resultsForDate;
          }

          return processedData.map((candle) => ({
            x: moment.utc(candle[0]).format("DD MMM YY HH:mm"),
            y: [candle[1], candle[2], candle[3], candle[4]],
          }));
        })(),
      },
    ];

    setCandleSeries(candledata);
  };

  const numberToWords = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convert = (n) => {
      if (n < 10) return units[n];
      if (n < 20) return teens[n - 10];
      if (n < 100) return tens[Math.floor(n / 10)] + " " + units[n % 10];
      if (n < 1000)
        return units[Math.floor(n / 100)] + " Hundred " + convert(n % 100);
      if (n < 1000000)
        return convert(Math.floor(n / 1000)) + " Thousand " + convert(n % 1000);
      if (n < 1000000000)
        return (
          convert(Math.floor(n / 1000000)) + " Million " + convert(n % 1000000)
        );
      if (n < 1000000000000)
        return (
          convert(Math.floor(n / 1000000000)) +
          " Billion " +
          convert(n % 1000000000)
        );
      return "Number is too large to convert.";
    };

    if (num === 0) return "Zero";
    return convert(num);
  };

  useEffect(() => {}, [setSelectedItems]);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // You can perform additional logic here if needed when the component updates
  }, [transactionData, viewOpen]);

  useEffect(() => {}, [deployval]);
  useEffect(() => {}, [openDeployModal]);

  useEffect(() => {
    handleCumelyTest();
    setTimeout(() => {
      handleStrategyDetails();
    }, 2000);
  }, []);

  useEffect(() => {}, [openDeployModal, viewOpen]);

  const selectinstrumentrow = (item, isChecked) => {
    if (isChecked) {
      count++;
      item.quantity = item.quntity;
      item.max_allocation = formData?.max_allocation;
      item.position_sizing_type = formData?.position_sizing_type;
      item.holding_type = formData?.holding_type;
      item.daily_strategy_cycle = formData?.daily_strategy_cycle;
      item.previous_day = formData?.previous_day;
      if (item?.position_sizing_type === "risk based")
        item.maxSlPerTrade = formData?.max_allocation;

      setSelectedItems([...selectedItems, item]);
    } else {
      count--;
      setSelectedItems(
        selectedItems.filter((selectedItems) => selectedItems.id !== item.id)
      );
    }
    if (count == 0) {
      setDeployval("");
    } else {
      setDeployval(count);
    }
  };

  const handleClickEditName = async (name) => {
    if (name.length === 0) {
      setStrategyNameError("Please enter strategy name");
    } else {
      const user = getUser();
      const data = {
        name: name,
        strategy_id: id,
        user_id: user.id,
      };
      const result = await editStrategyNameApi(data);
      if (result.msg === "Name Already Exists") {
        setStrategyNameError(result.msg);
      } else {
        setStrategyNameError(null);
        //
        setStrategyDetails((prevState) => ({
          ...prevState,
          strategy_name: name,
        }));
        setShowEditStrategyMoal(false);
      }
    }
  };
  const handleMenuShow = () => {
    setOptionMenu(!optionMenu);
  };
  const handleCumelyTest = async () => {
    const result = await getBackTestApi({ id: id, user_id: user.id });
    // const result = await getBackTestApi({id : 90});
    const buyTradesCountbuy = result?.data?.filter(
      (trade) => trade.status === "buy"
    ).length;
    const buyTradesCountsell = result?.data?.filter(
      (trade) => trade.status === "sell"
    ).length;
    setTotalSignal(result?.cumulativeSignalNumber);
    // setCumulativeData(result);
    setTotalWins(result?.cumulativeWins);
    setTotalLosses(result?.cumulativeLose);
    setWinnigStreak(result?.cumulativewinStreak);
    setLosingstreak(result?.cumulativeloseStreak);
    setMaxDD(result?.cumulativemaxDrawdown);
    setProfitloss(result?.cumulativetotalProfitLoss);
    setShareData(result?.shares);
    setBacktestLoading(false);
  };

  const handleStrategyDetails = async () => {
    let strategyData;
    if (location.state) {
      strategyData = await getSingleSavedDiscoveredStrategyDetails({
        strategy_id: id,
        user_id: user?.id,
      });
      setStrategyDetails(strategyData[0]);
      if (strategyData[0]?.entire_object)
        setFormData(strategyData[0]?.entire_object);
      setStrategiesName(strategyData[0]?.strategy_name);
    } else {
      strategyData = await getSingleStrategyDetailsApi({ id: id });

      setStrategyDetails(strategyData?.data[0]);
      setStrategiesLoading(false);
      if (strategyData?.data[0]?.entire_object)
        setFormData(JSON.parse(strategyData?.data[0]?.entire_object));
      setStrategiesName(strategyData?.data[0]?.strategy_name);
    }
  };

  const handleDeployed = async (isChecked, selectedItems) => {
    if (formData?.max_allocation === "" || formData?.max_allocation === null) {
      // Assuming selectedItems is your array of objects
      let isCondition = selectedItems.some(
        (item) => item.quantity === 0 || item.quantity === ""
      );

      if (isCondition) {
        console.log(
          "At least one item has max_allocation of 0 or empty string."
        );
        // alert("Quantity should be greater than 0");
        setAlertMessage({
          message: "Quantity should be greater than 0",
          type: "alert-info",
        });
        return;
      } else {
        console.log(
          "None of the items have max_allocation of 0 or empty string."
        );
      }
    }
    if (
      formData?.max_allocation !== "" &&
      (formData?.position_sizing_type === "capital based" ||
        formData?.position_sizing_type === "-")
    ) {
      // Assuming selectedItems is your array of objects
      let isCondition = selectedItems.some(
        (item) => item.max_allocation === 0 || item.max_allocation === ""
      );

      if (isCondition) {
        console.log(
          "At least one item has max_allocation of 0 or empty string."
        );
        // alert("Please enter max allocation");
        setAlertMessage({
          message: "Please enter max allocation",
          type: "alert-info",
        });
        return;
      } else {
        console.log(
          "None of the items have max_allocation of 0 or empty string."
        );
      }
    }
    if (
      formData?.max_allocation !== "" &&
      formData?.position_sizing_type === "risk based"
    ) {
      // Assuming selectedItems is your array of objects
      let isCondition = selectedItems.some(
        (item) => item.maxSlPerTrade === 0 || item.maxSlPerTrade === ""
      );

      if (isCondition) {
        console.log(
          "At least one item has maxSlPerTrade of 0 or empty string."
        );
        // alert("Please enter max sl per trade");
        setAlertMessage({
          message: "Please enter max sl per trade",
          type: "alert-info",
        });
        return;
      } else {
        console.log(
          "None of the items have maxSlPerTrade of 0 or empty string."
        );
      }
    }
    if (isChecked) {
      const accessToken = getZerodhaToken();

      const updatedItems = selectedItems?.map((item) => ({
        ...item,
      }));

      const result = await deployStrategyApi({
        strategy_id: id,
        trading_type: trading_type,
        api_key: "3wxj01wpd2rnnn9c",
        access_token: accessToken,
        stocks: selectedItems,
      });

      setOpentDeployModal(false);
      settermCondition(false);
      if (result) {
        // alert("Deployed Successfully");
        setAlertMessage({
          message: "Deployed Successfully",
          type: "alert-success",
        });
        navigate("/deployed?tab=paper");
      }
    } else {
      settermCondition(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDataShow = async (Id, secriptCode) => {
    setScriptCode(secriptCode);
    const result = await getBackTestTransactionsApi({
      id: Id,
    });
    return result.data;
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  const handleDataExport = async () => {
    // Gather all the data from your table
    if (selectedItems.length !== 0) {
      const excelHeading = [
        "Date",
        "Time",
        "P&L",
        "Buy/Sell",
        "Quantity",
        "Trigger Type",
      ];

      const transactionData_ = await handleDataShow(selectedItems[0]?.id);

      const dataToExport = transactionData_?.map((item) => ({
        date: moment(item?.date).format("DD-MMM-YYYY"),
        time: moment(item?.date).format("hh:mm:ss"),
        "P&L": item?.pnl, // P&L property should be quoted since it contains special characters
        "buy/sell": item?.status, // buy/sell property should be quoted since it contains special characters
        quantity: item?.quantity,
        "trigger-type": item?.trigger_type, // trigger-type property should be quoted since it contains special characters
      }));
      dataToExport.unshift(excelHeading);
      const csvContent =
        "data:text/csv;charset=utf-8," +
        dataToExport.map((row) => Object.values(row).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "share_data.csv");
      document.body.appendChild(link);
      link.click();
    } else {
      // alert("Please Select stock");
      setAlertMessage({
        message: "Please Select stock",
        type: "alert-info",
      });
    }
  };
  const handleClickModalOpen = (item) => {
    if (selectedItems?.length == 0) {
      // alert("Please Select Stock");
      setAlertMessage({
        message: "Please Select stock",
        type: "alert-info",
      });
    } else {
      setOpentDeployModal(true);
      setStrategyItemData(item);
      setStrategyId(item?.id);
    }
  };

  const fetchTransctionDetails = async (_id, completedStatus) => {
    if (location.state) {
      const data = await getDiscoveredBackTestTransactionsApi({ id: _id });
      setTransactionDetails(data?.data);
      if (completedStatus === 1 && data?.data?.length === 0) {
        setTransactionDataLength(true);
      }
    } else {
      const data = await getBackTestTransactionsApi({ id: _id });
      setTransactionDetails(data?.data);
      console.log("BACKTEST", data?.data);
      if (completedStatus === 1 && data?.data?.length === 0) {
        setTransactionDataLength(true);
      }
    }
  };

  const getDiscoverStrategyData = async (NewStrategyName) => {
    const user = await getUser();
    const token = getZerodhaToken();

    if (location.state) {
      const strategyData = await getSingleSavedDiscoveredStrategyDetails({
        strategy_id: id,
        user_id: user?.id,
      });
      setStrategyDetails(strategyData[0]);
      if (strategyData[0]?.entire_object) {
        delete strategyData[0].entire_object.isAdmin;
        strategyData[0].entire_object.strategy_name = NewStrategyName;
        strategyData[0].entire_object.access_token = token;
        strategyData[0].entire_object.user_id = user?.id;
        setFormData(strategyData[0]?.entire_object);
      }
      setStrategiesName(NewStrategyName);
      navigate("/createstrategies", {
        state: {
          existingData: strategyData[0]?.entire_object,
        },
      });
    }
  };

  const getSingleStrategyData = async () => {
    const user = await getUser();
    if (location.state) {
      const strategyData = await getSingleSavedDiscoveredStrategyDetails({
        strategy_id: id,
        user_id: user?.id,
      });
      setStrategyDetails(strategyData[0]);
      if (strategyData[0]?.entire_object) {
        strategyData[0].entire_object.access_token = getZerodhaToken();

        setFormData(strategyData[0]?.entire_object);
        setStrategiesName(strategyData[0]?.strategy_name);
        strategyData.data[0].entire_object.strategy_name =
          strategyData?.data[0]?.strategy_name;
      }

      navigate("/createstrategies", {
        state: {
          existingData: strategyData[0]?.entire_object,
        },
      });
    } else {
      const strategyData = await getSingleSavedStrategyData({
        user_id: user.id,
        strategy_id: id,
      });

      if (strategyData?.data[0]) {
        strategyData.data[0].entire_object.strategy_id =
          strategyData?.data[0]?.strategy_id;
        strategyData.data[0].entire_object.strategy_name =
          strategyData?.data[0]?.strategy_name;
      }
      navigate("/createstrategies", {
        state: {
          existingData: strategyData?.data[0]?.entire_object,
        },
      });
    }
  };

  const toggleMenu = (index) => {
    setShowDropDown(!showDropDown);
  };

  const onFormChange = (newFormData) => {
    if (newFormData) setFormData(newFormData);
  };

  const getUpdatedSelected = (updatedItems) => {
    if (
      updatedItems &&
      updatedItems[0]?.position_sizing_type === "risk based"
    ) {
      updatedItems.forEach((item) => {
        item.maxSlPerTrade = item.max_allocation;
      });
    }

    if (updatedItems?.length > 0) setSelectedItems(updatedItems);
  };

  const getStocksData = async (tokens, shareData) => {
    const queryParams = tokens?.map((token) => `i=${token}`).join("&");

    if (tokens?.length > 0) {
      const response = await getStockLTPDataAPI({
        apiKey: "3wxj01wpd2rnnn9c",
        token: accessToken,
        queryParams: queryParams,
      });
      if (response) {
        if (response.data) updateStocksDataNew(shareData, response?.data);
      }
    }
  };

  const updateStocksDataNew = (stocks, data) => {
    // Iterate through each item in the response data
    Object.keys(data).forEach((instrumentToken) => {
      const stockToUpdate = stocks.find(
        (stock) => stock.script_code == instrumentToken
      );

      // If the stock is found in the initial array
      if (stockToUpdate) {
        const newData = data[instrumentToken];
        stockToUpdate.ltp = newData.last_price;
        stockToUpdate.closePrice = newData.ohlc.close;
      }
    });
    setShareData(stocks);
    onFormChange(formData);
    setBacktestLoading(false);
  };

  const handleConfirmDelete = (id) => {
    setShow(true);
    setStrategyId(id);
  };

  const handleConfirm = async () => {
    const data = await deleteStrategyApi({ strategy_id: strategyId });
    handleClose();
    if (data) {
      alert("Strategy Deleted!");
      navigate("/strategies/viewall");
    }
  };
  const handelClickCreateStrategy = (NewStrategyName) => {
    getDiscoverStrategyData(NewStrategyName);
  };
  useEffect(() => {
    if (alertMessage) {
      setTimeout(() => {
        setAlertMessage(null);
      }, 1500);
    }
  }, [alertMessage]);
  return (
    <>
      {alertMessage && (
        <CustomAlert
          message={alertMessage.message}
          type={alertMessage.type}
          setAlertMessage={setAlertMessage}
        />
      )}
      <section className="mobile-view">
        <div className="container web-cotainer">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <Row>
                <Container className="title-section p-0">
                  <Col className="d-flex justify-content-between align-items-center">
                    <div>
                      <i
                        className="fa fa-chevron-left pointer-cursor "
                        onClick={() => navigate(-1)}
                      ></i>
                      &nbsp;<span>My Strategy</span>
                    </div>
                  </Col>
                </Container>
              </Row>
              {strategiesLoading ? (
                <SkeletonLoader
                  width="100%"
                  height="100px"
                  borderRadius="8px"
                />
              ) : (
                <Suspense
                  fallback={
                    <SkeletonLoader
                      width="100%"
                      height="100px"
                      borderRadius="8px"
                    />
                  }
                >
                  <StrategyDetailComponent
                    strategyDetails={strategyDetails}
                    location={location}
                    setShowEditStrategyMoal={setShowEditStrategyMoal}
                    setStrategyNameError={setStrategyNameError}
                    getSingleStrategyData={getSingleStrategyData}
                    setShowCreatePopup={setShowCreatePopup}
                    handleConfirmDelete={handleConfirmDelete}
                    handleDataExport={handleDataExport}
                    toggleMenu={toggleMenu}
                    showDropDown={showDropDown}
                    dropdownRef={dropdownRef}
                    handleClickModalOpen={handleClickModalOpen}
                    deployval={deployval}
                    formData={formData}
                  />
                </Suspense>
              )}
              {backtestLoading ? (
                <SkeletonLoader
                  width="100%"
                  height="150px"
                  borderRadius="8px"
                  marginTop="5%"
                />
              ) : (
                <>
                  <Suspense
                    fallback={
                      <SkeletonLoader
                        width="100%"
                        height="150px"
                        borderRadius="8px"
                        marginTop="5%"
                      />
                    }
                  >
                    <StrategyBacktestTable
                      numberToWords={numberToWords}
                      strategyDetails={strategyDetails}
                      cumulativeData={cumulativeData}
                      shareData={shareData}
                      selectedRow={selectedRow}
                      selectinstrumentrow={selectinstrumentrow}
                      handleShowGraph={handleShowGraph}
                      showMM={showMM}
                      selectedShare={selectedShare}
                      handleDateWiseGraph={handleDateWiseGraph}
                      showScroll={showScroll}
                      elementRef={elementRef}
                      options={options}
                      series={series}
                      candleOption={candleOption}
                      candleSeries={candleSeries}
                      transactionDetails={transactionDetails}
                      isCompleted={isCompleted}
                      transactionDataLength={transactionDataLength}
                      selectedDate={selectedDate}
                    />
                  </Suspense>
                </>
              )}
            </div>
            {/* <div className="col-lg-3" id="backetest-result-side-bar">
              <SideBarSingleStrategy strategyDetails={strategyDetails} />
            </div> */}
          </div>
        </div>
      </section>
      <CreateDeployPopupsingle
        openDeployModal={openDeployModal}
        setOpentDeployModal={setOpentDeployModal}
        handleClose={handleClose}
        strategy={strategyItemData}
        termCondition={termCondition}
        settermCondition={settermCondition}
        handleDeployed={handleDeployed}
        trading_type={trading_type}
        set_trading_Type={set_trading_Type}
        selectedItems={selectedItems}
        strategyDetails={strategyDetails}
        formData={formData}
        onFormChange={onFormChange}
        getUpdatedSelected={getUpdatedSelected}
      />
      <StrategyViewModal
        transactionData={transactionData}
        viewOpen={viewOpen}
        setViewOpen={setViewOpen}
      />
      <EditStrategyModal
        showEditPopup={showEditStrategyModal}
        setEditPopup={setShowEditStrategyMoal}
        handleClickEditName={handleClickEditName}
        nameError={strategyNameError}
        strategiesName={strategiesName}
        setStrategiesName={setStrategiesName}
      />
      <DeleteStrategyModal
        show={show}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />

      <CreateStrategyPopup
        showCreatePopup={showCreatePopup}
        handelClickCreateStrategy={handelClickCreateStrategy}
        setShowCreatePopup={setShowCreatePopup}
        // nameError={strategiesNameError}
        // setError={setStrategiesNameError}
      />
    </>
  );
};

export default SingleStrategy;
