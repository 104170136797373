import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useMenuContext } from "./StrategyCreateComponent";
import { getfunctionTypeWisefunction } from "../../api/strategyapi";
import CompartorModal from "./ComparatorModal";
import "./Strategy.css";
import FormulaTab from "./FormulaTab";
import moment from "moment";
import CandleInterval from "../modal/CandleInterval";
import PeriodMaxModal from "../modal/PeriodMaxModal";
import PeriodMinModal from "../modal/PeriodMinModal";
import replaceNewData from "../../Utils/replaceNewData";
import getCandleId from "../../Utils/CommonFunction/getCandleId";
import updateHandleModalData from "../../Utils/CommonFunction/handleModalData";
import GetFunctionofHandle from "../../Utils/CommonFunction/GetFunctionofHandle";
import handleRemoveCondition from "../../Utils/CommonFunction/handleRemoveCondition";
import FunctionList from "../componentCommon/FunctionList";
import mergeArrays from "../../Utils/CommonFunction/mergeArrays";
import IndicatorModal from "../componentCommon/IndicatorModal";
import modalConfigs from "../../Utils/CommonFunction/modalConfigArray";

const selectedItemStyle = {
  backgroundColor: "#00aeee", // Customize the background color as needed
  fontWeight: "bold", // Add additional styles if needed
  border: "1px solid blue",
};

let eflag = 0;

const StrategyEntryComponent = ({
  formData,
  onFormChange,
  allStocks,
  save,
}) => {
  const { menu, setMenu, allStockStore } = useMenuContext();
  console.log("FORMDATA", formData, save);
  const divRef = useRef(null);
  const [flag, setFlag] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [functionType, setFunctionType] = useState([]);
  const [showSMA, setIsShow] = useState(false);
  const [showSMA2, setIsShow2] = useState(false);
  const [showSMA3, setIsShow3] = useState(false);
  const [conditionTab, setConditionTab] = useState(false);
  const [mode, setMode] = useState("");
  const [remove, setRemove] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(false);
  const [selectedItem, setSelectedItem] = useState("1");
  const [currentId, setCurrentId] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [innerAdd, setInnerAdd] = useState("");
  const [outerAdd, setOuterAdd] = useState("");
  //Modal state variables
  const [showPeriodMaxModal, setPeriodMaxModal] = useState(false);
  const [showPeriodMinModal, setPeriodMinModal] = useState(false);
  const [showCandleModal, setShowCandleModal] = useState(false);
  const [confirmRemove, setconfirmRemove] = useState(false);
  const [instance, setInstance] = useState();
  const [isSelectMax, setIsSelectMax] = useState(false);
  const [isSelectMin, setIsSelectMin] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [removedInsideMath, setRemovedInsideMath] = useState(false);
  const [mathIndexRemove, setMathIndexRemove] = useState(-1);
  const isIncludeMathFunction = useRef(null);
  const [showModal, setShowModal] = useState(null);
  const [showInstead, setAddInstead] = useState({
    function_id: 0,
    function_type_id: 0,
    isOpen: false,
    index: -1,
  });
  const handleEdit = (instanceData, index) => {
    console.log("MathFn", instanceData, index);
    if (instanceData?.function_type_id == 2) {
      setRemovedInsideMath(true);
    }
    setAddInstead({
      function_id: instanceData?.function_id,
      function_type_id: instanceData?.function_type_id,
      isOpen: true,
      index: index,
    });
    // call dropdown function particular type id //
    handleClickAll(instanceData?.function_type_id?.toString());
  };

  const handleGetFunction1 = (item) => {
    console.log("click", item, formData);
    setSearchQuery("");
    setShowDropdown(false);
    let replaceData = replaceNewData(formData, item, showInstead);
    formData = replaceData;
    // console.log("REPLACE", formData )
    if (replaceData) {
      setAddInstead({
        function_id: 0,
        function_type_id: 0,
        isOpen: false,
        index: -1,
      });
    }
  };
  useEffect(() => {
    eflag = 0;
  }, []);

  useEffect(() => {
    if (save) handleNextEnteryBtn();
  }, [save]);
  useEffect(() => {
    // console.log("functionsBox777>", formData.functionsBox777);
    if (eflag === 1) {
      updateCandleIntervalId();
      updateCandleInterval();
    }
    handleClickAll("1");
  }, [formData.candleIntervalId]);

  const updateCandleInterval = () => {
    // Create a copy of the original data
    // console.log("<><>",formData.functionsBox777);
    let updatedData = [];
    if (Array.isArray(formData?.functionsBox777)) {
      updatedData = [...formData.functionsBox777];
    }
    let candleId = getCandleId(formData);

    updatedData.forEach((item) => {
      item.candleIntervalId = candleId;
    });

    formData.functionsBox777 = updatedData;
    onFormChange(formData);
  };

  const updateCandleIntervalId = () => {
    const updatedIndicators = { ...formData.indicators };
    let candleId = getCandleId(formData);
    for (const key in updatedIndicators) {
      if (updatedIndicators.hasOwnProperty(key)) {
        const indicator = updatedIndicators[key];
        // Check if candleIntervalId property exists
        console.log("UPDATEINTERVAL", indicator);
        if (indicator.hasOwnProperty("candleIntervalId")) {
          indicator.candleIntervalId = candleId; // Replace 'newCandleIntervalValue' with your desired value
        }
        if (indicator?.body) {
          if (Array.isArray(indicator.body)) {
            indicator.body.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body1) {
          if (Array.isArray(indicator.body1)) {
            indicator.body1.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body2) {
          if (Array.isArray(indicator.body2)) {
            indicator.body2.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
      }
    }
    formData.indicators = updatedIndicators;
    onFormChange(formData);
  };

  const getEntryText = async () => {
    const divElement = document.getElementById("strategyBox");
    if (divElement) {
      const hasInputs = divElement.querySelector("input, textarea") !== null;
      let existingContent = divElement.innerText;
      existingContent = existingContent?.replace(
        "{input1Placeholder}",
        formData?.input1
      );
      console.log("CHECKING", existingContent);

      let text1 = handleReplacement(existingContent);
      formData.functionText1 = existingContent;
      onFormChange(formData);
    }
  };
  const toggleDropdown = () => {
    setRemove(false);
    setShowDropdown(true);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    toggleDropdown();
  };

  const handleChangeBuyCellBtn = (val) => {
    console.log("Came1-");
    onFormChange({
      ...formData,
      position_id: val,
    });
  };

  const handleModalData = (data) => {
    updateHandleModalData(
      data,
      formData,
      onFormChange,
      getEntryText,
      itemIndex,
      setIsShow,
      instance
    );
  };

  const handleCandleModal = (data) => {
    console.log("MAXMININTERVAl", data);
    if (data?.candleIntervalId)
      formData.indicators[formData.clickedFunction].candleIntervalId =
        data?.candleIntervalId;

    formData.functionsBox777[itemIndex].candleIntervalId =
      data?.candleIntervalId;
    if (formData.indicators[formData.clickedFunction]?.body)
      formData.indicators[formData.clickedFunction].body[
        itemIndex
      ].candleIntervalId = data?.candleIntervalId;

    if (formData.indicators[formData.clickedFunction][instance])
      formData.indicators[formData.clickedFunction][
        instance
      ][0].candleIntervalId = data?.candleIntervalId;
    onFormChange(formData);
    onFormChange({
      ...formData,
    });
  };
  const handleRemiceInstead = () => {
    setAddInstead((prev) => ({ ...prev, isOpen: false }));
  };
  const handleSetInstead = () => {
    setAddInstead({
      function_id: 0,
      function_type_id: 0,
      isToggle: false,
    });
  };
  const handleGetFunction = (item) => {
    console.log("SELECT", item);
    GetFunctionofHandle(
      item,
      formData,
      onFormChange,
      setInnerAdd,
      setOuterAdd,
      handleRemiceInstead,
      setCurrentId,
      setConditionTab,
      handleClickAll,
      setSelectedOption1,
      setMode,
      setIsSelectMax,
      setIsSelectMin,
      setSearchQuery,
      setShowDropdown,
      setRemovedInsideMath,
      removedInsideMath,
      mathIndexRemove,
      flag,
      setFlag,
      outerAdd,
      isIncludeMathFunction,
      innerAdd,
      instance
    );
  };
  useEffect(() => {
    setTimeout(() => {
      eflag = 1;
    }, 2000);
  }, []);

  const handleStartTime = (newValue) => {
    var dateObject = moment(newValue, "HH:mm");

    if (dateObject.isValid()) {
      var selectedTime = dateObject.format("HH:mm");

      onFormChange((prevFormData) => ({
        ...prevFormData,
        start_time: selectedTime,
        starttime: newValue,
      }));
    } else {
      console.log("Invalid time");
    }
  };
  const handleEndTime = (newValue) => {
    var dateObject = moment(newValue, "HH:mm");

    if (dateObject.isValid()) {
      var selectedTime = dateObject.format("HH:mm");

      onFormChange((prevFormData) => ({
        ...prevFormData,
        end_time: selectedTime, // Assuming you have an 'end_time' field in formData
        endtime: newValue,
      }));
    } else {
      console.log("Invalid time");
    }
  };

  const handleButtonClick = (index) => {
    console.log("ITEM>", formData.functionsBox2[index]);
  };

  const handleClickAll = async (type) => {
    console.log("maxType", type);
    setSelectedItem(type);
    const data = await getfunctionTypeWisefunction({ type_id: type });
    setFunctionType(data);
  };
  const handleConfirmRemove = () => {
    setconfirmRemove(true);
  };
  const handleNextEnteryBtn = () => {
    getEntryText();
    let text1 = handleReplacement(formData.functionText1);
    console.log(
      "formula?",
      formData.functionsBox777,
      text1
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line !== "" && !line.startsWith("15 Minutes"))
    );
    console.log("FORMDATACHECK", formData, save);
    mergeArrays(
      formData.functionsBox777,
      text1
        .split("\n")
        .map((line) => line.trim())
        .filter(
          (line) =>
            line !== "" &&
            !line.startsWith("1minute") &&
            !line.startsWith("3minute") &&
            !line.startsWith("5minute") &&
            !line.startsWith("10minute") &&
            !line.startsWith("15minute") &&
            !line.startsWith("30minute") &&
            !line.startsWith("60minute") &&
            !line.startsWith("1day") &&
            !line.startsWith("2h") &&
            !line.startsWith("3h") &&
            !line.startsWith("4h") &&
            !line.startsWith("week") &&
            !line.startsWith("month")
        ),
      onFormChange,
      formData,
      isSelectMax,
      isSelectMin,
      isIncludeMathFunction
    );

    setMenu("exist");
  };
  const getIndex = (index, instance) => {
    console.log("getIndex", instance, index, formData.functionsBox777);
    setItemIndex(index);
    setInstance(instance);
  };

  // handleAdd //
  const handleAdd = (instanceData, index) => {
    toggleDropdown();
  };

  const handleRemoveButtonClick = (
    instanceToRemove,
    indexToRemove,
    mInstance
  ) => {
    console.log("REMOVE", instanceToRemove, indexToRemove, mInstance);
    handleRemoveCondition(
      formData,
      onFormChange,
      instanceToRemove,
      indexToRemove,
      mInstance,
      setMathIndexRemove,
      setRemovedInsideMath,
      isIncludeMathFunction
    );
  };

  const handleAddButtonClick = (instanceName, bodyInstance) => {
    console.log(
      "ADDING",
      instanceName,
      bodyInstance,
      formData.indicators[instanceName]
    );
    formData.selectedOption3 = instanceName;
    setInstance(bodyInstance);
  };

  const handleReplacement = (text) => {
    console.log("checkText-", text);
    let modifiedText = text.replace(/lower than or equal to/gi, "<=");
    modifiedText = modifiedText.replace(/higher than or equal to/gi, ">=");
    modifiedText = modifiedText.replace(/lower than/gi, "<");
    modifiedText = modifiedText.replace(/higher than/gi, ">");
    modifiedText = modifiedText.replace(/> equal to/gi, ">=");
    modifiedText = modifiedText.replace(/< equal to/gi, "<=");
    modifiedText = modifiedText.replace(/and/gi, "&&");
    modifiedText = modifiedText.replace(/or/gi, "||");
    modifiedText = modifiedText.replace(/equal to/gi, "===");
    modifiedText = modifiedText.replace(/crosses above/gi, ">");
    modifiedText = modifiedText.replace(/crosses below/gi, ">");

    // Add more replacements as needed

    return modifiedText;
  };
  const RemoveFormula = () => {
    let ans = window.confirm("Are you sure you want to remove Formula?");
    if (ans === true) {
      setFlag(0);
      setRemove(true);
      onFormChange((prevState) => ({
        ...prevState,
        functions: [],
        functionsBox: [],
        functionsBox2: [],
        functionsBox777: [],
        functionsMainBox: [],
        indicators: {},
        mode: "",
        selectedOption1: "",
        order: [],
        isIncludeMathFunction: false,
      }));
      handleSetInstead();
      isIncludeMathFunction.current = false;
    }
  };

  const closeModal = () => {
    setShowModal(null);
  };
  useEffect(() => {
    console.log("showModal", showModal);
  }, [showModal]);
  return (
    <>
      <div className="row">
        <h6 className="fw-bolder mt-5">Position I would take</h6>
        <div
          className="col-lg-12 col-md-12 col-sm-12 col"
          id="entry-bye-cell-card"
        >
          <Card>
            <Card.Body>
              <ListGroup horizontal>
                <ListGroup.Item
                  action
                  variant="primary"
                  className={formData.position_id === 1 ? "active" : ""}
                  onClick={() => {
                    handleChangeBuyCellBtn(1);
                  }}
                >
                  &nbsp;&nbsp;&nbsp; Buy &nbsp;&nbsp;&nbsp;
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className={formData.position_id === 2 ? "sellactive" : ""}
                  variant="primary"
                  onClick={() => {
                    handleChangeBuyCellBtn(2);
                  }}
                  // onClick={()=>{handleChangeCharType(2)}}
                >
                  &nbsp;&nbsp;&nbsp; Sell &nbsp;&nbsp;&nbsp;
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
        <div className=" mt-5 pb-2 d-flex align-items-center justify-content-between">
          <div>
            <h6 className="fw-bolder">Entry Condition</h6>
          </div>
          <div></div>
        </div>
        <div className="input-group mb-3" id="tab-function-type-dropdown">
          <div className="d-flex row" style={{ minWidth: "300px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Form.Control
                type="text"
                placeholder="What would you like to add?"
                className="strategy-entry-search"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleDropdown();
                }}
                value={searchQuery}
                onChange={handleSearch}
              />
              <span
                className="close-icon"
                onClick={() => {
                  setSearchQuery("");
                  setShowDropdown(false);
                }}
                style={{ cursor: "pointer", marginLeft: "-25px" }}
              >
                X{/* Replace 'X' with your close iconX */}
              </span>
            </div>

            {/* Dropdown menu */}
            {showDropdown && (
              <FunctionList
                showInstead={showInstead}
                formData={formData}
                isSelectMax={isSelectMax}
                removedInsideMath={removedInsideMath}
                selectedItem={selectedItem}
                selectedItemStyle={selectedItemStyle}
                handleClickAll={handleClickAll}
                handleGetFunction1={handleGetFunction1}
                handleGetFunction={handleGetFunction}
                getEntryText={getEntryText}
                searchQuery={searchQuery}
                functionType={functionType}
                remove={remove}
                isIncludeMathFunction={isIncludeMathFunction}
                onFormChange={onFormChange}
              />
            )}
          </div>
        </div>
        {conditionTab || formData?.conditionTab ? (
          <div className="row" id="strategy-entry-condition-card" ref={divRef}>
            <div className="col-lg-12 p-0">
              <Card>
                <Card.Body>
                  {/* bg-danger */}
                  <div className="row">
                    <div
                      className="col-lg-12 p-3"
                      id="strategyBox"
                      style={{
                        display: "contents",
                      }}
                    >
                      <FormulaTab
                        formData={formData}
                        handleRemoveButtonClick={handleRemoveButtonClick}
                        onFormChange={onFormChange}
                        handleButtonClick={handleButtonClick}
                        setShowDropdown={setShowDropdown}
                        setInnerAdd={setInnerAdd}
                        setOuterAdd={setOuterAdd}
                        handleAddButtonClick={handleAddButtonClick}
                        outerAdd={outerAdd}
                        allStocks={allStockStore}
                        getIndex={getIndex}
                        setAddInstead={setAddInstead}
                        showInstead={showInstead}
                        handleEdit={handleEdit}
                        handleAdd={handleAdd}
                        setPeriodMaxModal={setPeriodMaxModal}
                        setPeriodMinModal={setPeriodMinModal}
                        setShowModal={setShowModal}
                        setShowCandleModal={setShowCandleModal}
                      />
                      &nbsp; &nbsp;
                      {formData.isIncludeMathFunction && (
                        <div
                          onClick={() => {
                            setShowDropdown(true);
                            setOuterAdd(true);
                          }}
                          className="pluseIcon"
                        >
                          <i className="fa-solid fa-plus"></i>
                        </div>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <div className="row">
                <div
                  // className="col-lg-12 d-flex flex-row-reverse"
                  className="col-lg-12 d-flex flex-row-reverse"
                  style={{ marginTop: "10px" }}
                >
                  <span
                    className="text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => RemoveFormula()}
                  >
                    Remove
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="box my-3">
            <div className="text-center">
              <div className="filecircle mx-auto">
                <i className="fa-regular fa-file"></i>
              </div>
              <h5 className="fw-bolder text-dark pt-3 pb-1">Add a condition</h5>
              <p className="para">
                Add an entity condition by searching <br></br>for indicators and
                comparators
              </p>
            </div>
          </div>
        )}
        <div className="pt-3">
          <h6 className="fw-bolder">Entry Time</h6>
        </div>
        <div id="entry-time-card">
          <Card>
            <Card.Body className="entry-time-card-body">
              <div
                className="d-flex justify-content-between my-3"
                style={{ width: "100%" }}
              >
                <div className="d-flex align-items-center">
                  <p className="mb-0">Start time </p>&nbsp;&nbsp;
                  <input
                    style={{ minWidth: "150px" }}
                    type="time"
                    value={formData.start_time || ""}
                    onChange={(e) => handleStartTime(e.target.value)}
                    className="w-25 form-control timer-form-control-sm"
                  />
                </div>
                <div className="d-flex align-items-center">
                  <p className="mb-0 ms-2">End time</p> &nbsp;&nbsp;
                  <input
                    style={{ minWidth: "150px" }}
                    type="time"
                    value={formData.end_time || ""}
                    onChange={(e) => handleEndTime(e.target.value)}
                    className="w-25 form-control timer-form-control-sm"
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      {modalConfigs?.map((name, index) => {
        console.log(
          "RENDERING",
          `Rendering ${name} with setIsShow:`,
          setIsShow
        );
        return (
          <IndicatorModal
            key={index}
            show={name === showModal}
            setIsShow={closeModal}
            handleModalData={handleModalData}
            mainData={formData}
            clickedFunction={formData?.clickedFunction}
            index={itemIndex}
            instance={instance}
            name={name}
          />
        );
      })}
      <PeriodMaxModal
        setIsShow={setPeriodMaxModal}
        showPeriodMaxModal={showPeriodMaxModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PeriodMinModal
        setIsShow={setPeriodMinModal}
        showPeriodMinModal={showPeriodMinModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <CompartorModal
        showSMA={showSMA3}
        setIsShow={setIsShow3}
        type={formData.ctype}
        typeName={formData.selectedOption2}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <CandleInterval
        show={showCandleModal}
        setIsShow={setShowCandleModal}
        handleModalData={handleCandleModal}
        mainData={formData}
        clickedFunction={formData.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
    </>
  );
};
export default StrategyEntryComponent;
