import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { useMenuContextScanner } from "./ScannerCreateComponent";
import SideBarScanOn from "./SideBarScanOn";
import { addNewScannerAPI, getStocksUnderIndices } from "../../api/scannerapi";
import ScannerConditionComponent from "./ScannerConditionComponent";
import { ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CustomAlert from "../componentCommon/CustomAlert";
import { checkConditionValid } from "../../Utils/checkConditionValid";
const ScannerParameterComponent = ({
  formData,
  onFormChange,
  stocks,
  scannerName,
  edit,
  // editDiscover,
  handleChangeCharType,
  setActiveButton,
  activeButton,
}) => {
  const navigate = useNavigate();

  useEffect(()=>{
    console.log("<><><>",formData)
},[formData]);

  const { menu, setMenu, setStockItemName, stockItemName } =
    useMenuContextScanner();
  const [showList, setShowList] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState("");
  const [getText, setGetText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  useEffect(() => {}, [selectedIndices]);

useEffect(()=>{
      console.log("2222222222222",formData);
},[formData]);

  useEffect(() => {
    const fetchScanData = async () => {
      if (!edit && formData) {
        setStockItemName(stocks?.length > 0 && stocks[0]?.name); // Update the displayed stock item name (optional)
        handelScanItem(stocks?.length > 0 && stocks[0]);
        setShowList(false); // Hide the dropdown after selecting an option
        formData.scan_on = stocks?.length > 0 && stocks[0]?.name;
        onFormChange(formData);
      }
    };

    fetchScanData();
  }, [stocks]);

  const handelScanItem = async (items) => {
    let stocks = await fetchStockUnderIndices(items);
    // const newStockarray=stocks.map((s)=>({
    //         ...s,
    //         exch: "n",
    //         exchType: "c",
    // }))
   if (formData) {
     formData.stocks = [];
   }
    onFormChange(formData);
  };
  const handleChange = (e) => {
    const { value } = e.target;

    setSelectedIndices(value);
    setStockItemName(stocks[value]?.name); // Update the displayed stock item name (optional)
    setShowList(false); // Hide the dropdown after selecting an option
    if(formData) {
      formData.selectedIndices = value;
      formData.scan_on = stocks[value]?.name;
      formData.scan_on_id = stocks[value]?.id;
    }
    onFormChange(formData);
    handelScanItem(stocks[value]);
  };

  const fetchStockUnderIndices = async (items) => {
    try {
      const response = await getStocksUnderIndices({
        id: items?.id,
      });

      return response;
    } catch (error) {
      console.error("Error fetching stocks:", error);
    }
  };

  const handleClickCandleInterval = (timeid) => {
    let startDate = "";
    let endDate = "";
    if (timeid === 1) {
      startDate = moment().format("YYYY-MM-DD");
      endDate = moment().subtract(31, "days").format("YYYY-MM-DD");
    } else if (
      timeid === 3 ||
      timeid === 5 ||
      timeid === 10 ||
      timeid === 15 ||
      timeid === 30
    ) {
      startDate = moment().format("YYYY-MM-DD");
      endDate = moment().subtract(90, "days").format("YYYY-MM-DD"); // Assign
    } else if (timeid === 60) {
      startDate = moment().format("YYYY-MM-DD");
      endDate = moment().subtract(1, "year").format("YYYY-MM-DD"); // Assign
    } else {
      startDate = moment().format("YYYY-MM-DD");
      endDate = moment().subtract(5, "year").format("YYYY-MM-DD"); // Assign
    }

    onFormChange({
      ...formData,
      candleIntervalId: timeid,
      candle_interval_id:
        timeid === 1
          ? "1m"
          : timeid === 3
          ? "3m"
          : timeid === 5
          ? "5m"
          : timeid === 10
          ? "10m"
          : timeid === 15
          ? "15m"
          : timeid === 30
          ? "30m"
          : timeid === 60
          ? "60m"
          : timeid === "1day"
          ? "1d"
          : "",
      start_date: dayjs(startDate),
      end_date: dayjs(endDate),
    });
  };

  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    onFormChange({
      ...formData,
      [name]: value,
    });
  };

  const addNewScanner = async () => {
    // if (editDiscover) {
    //   delete formData.scanner_id;
    //   delete formData.isAdmin;
    //   onFormChange(formData);
    // }
    const dataSources = [
      { data: formData?.indicators, source: "entry" },
    ].filter(({ data }) => data);
    let isMathFnValid = checkConditionValid(dataSources);
    if (isMathFnValid.length > 0) {
      setLoading(false);
      let message = isMathFnValid.join("\n");
      setAlertMessage({ message, type: "alert-primary" });
      return;
    }
    if (formData?.selectedIndices) {
      setLoading(true);
      setGetText(true);
      const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
      };
      if (formData?.indicators && isEmptyObject(formData?.indicators)) {
        alert("Please add condition to create scanner");
        setLoading(false);
      } else {
        setTimeout(async () => {
          try {
            console.log("SCANDATA", formData);
            const addNew = await addNewScannerAPI(formData);
            console.log("addNew", addNew);
            if (addNew) setLoading(false);
            if (
              addNew?.scanner_id === undefined ||
              addNew?.scanner_id === "undefined"
            ) {
              alert("Scanner not created successfully Please try again...");
              navigate("/scanners");
            } else {
              localStorage.removeItem("IsCreated");
              const scanner_id = addNew?.scanner_id;
              localStorage.setItem(`${scanner_id}`, scanner_id);
              navigate(`/scanner/result/${addNew?.scanner_id}`, {
                state: {
                  strategyId: addNew?.scanner_id,
                  isCreated: true,
                },
              });
              setGetText(false);
            }
          } catch (error) {
            console.error("Error fetching:", error);
          }
        }, 2000);
        setTimeout(() => {
          setLoading(false);
        }, 10000);
      }
    } else {
      alert("Please Select Scan Item...");
      window.location.hash = "#goto";
    }
  };
  
  useEffect(() => {
    if (alertMessage) {
      let wpm = 225; // average words reading per minute
      let words = alertMessage?.message?.split(/\s+/).length;
      let timeout = Math.ceil(words / wpm) * 15000;
      setTimeout(() => {
        setAlertMessage(null);
      }, timeout);
    }
  }, [alertMessage]);

  return (
    <>
     {alertMessage && (
        <CustomAlert
          message={alertMessage.message}
          type={alertMessage.type}
          setAlertMessage={setAlertMessage}
        />
      )}
    <div id="goto">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(1.5px)",
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row" id="scanner-create-parameter">
        <h4 className="fw-bolder mb-4" style={{ marginLeft: -10 }}>
          {scannerName}
        </h4>

        <Card className="p-0">
          <Card.Body className="p-0">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold">Scan On</Form.Label>
                <Form.Select
                  style={{ width: "30%" }}
                  id="indicesSelect"
                  name="Indices"
                  value={formData?.selectedIndices!=='' || 
                    formData?.selectedIndices!=null || 
                    formData?.selectedIndices!=='null' ?
                    formData?.selectedIndices:""}
                  onChange={handleChange}
                  onClick={() => setShowList(!showList)}
                >
                  <option value = "" disabled>
                    Please Select Scan Item
                  </option>
                  {stocks?.map((stock, index) => (
                    <option key={index} value={index}>
                      {stock?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        <div className="pt-3 p-0" id="create-strategy-chart-tab">
          <h6>Chart</h6>
          <div
            className="col-lg-12 technical-nifty-tab"
            id="technical-nifty-tab"
          >
            <Card>
              <Card.Body>
                <div className="row p-0 justify-content-between">
                  <div className="col-lg-2 p-0">
                    <div style={{ marginLeft: "20px" }}>
                      <span className="fw-bold">Chart Type</span>
                      {/* chart type for scanner */}
                      <div id="strategy-chart-type-btn" className="pt-2">
                        <ListGroup
                          horizontal
                          style={{ height: "60%" }}
                          className="p-0"
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <strong>Candlestick</strong>
                              </Tooltip>
                            }
                          >
                            <ListGroup.Item
                              action
                              variant="primary"
                              style={{ cursor: "pointer" }}
                              className={
                                formData?.chart_type === 1 ? "active" : ""
                              }
                              onClick={() => {
                                handleChangeCharType(1);
                              }}
                            >
                               {activeButton === 1 ? <img src="/candle1white.png" alt="nt found" 
                              style={{height:"22px",width:"22px"}}></img>:<img src="/candle1black.png" alt="nt found" 
                              style={{height:"22px",width:"22px"}}></img>}
                            </ListGroup.Item>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <strong>Heikin-Ashi</strong>
                              </Tooltip>
                            }
                          >
                            <ListGroup.Item
                              action
                              variant="primary"
                              style={{ cursor: "pointer" }}
                              className={
                                formData?.chart_type === 2 ? "active" : ""
                              }
                              onClick={() => {
                                handleChangeCharType(2);
                              }}
                            >
                              {activeButton === 2 ? <img src="/candle2white.png" alt="nt found" 
                              style={{height:"22px",width:"22px"}}></img>:<img src="/candle2black.png" alt="nt found" 
                              style={{height:"22px",width:"22px"}}></img>}
                            </ListGroup.Item>
                          </OverlayTrigger>
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-7 col-md-7 col-sm-7 me-auto"
                    style={{ paddingLeft: "125px" }}
                  >
                    <div>
                      <span className="fw-bold">Candle Interval</span>
                    </div>
                    <ListGroup
                      horizontal
                      style={{ height: "60%" }}
                      className="pt-2 p-0"
                    >
                      {[1, 3, 5, 10, 15, 30, 60, "1day"].map((interval) => (
                        <ListGroup.Item
                          key={interval}
                          action
                          variant="primary"
                          className={`custom-list-item${
                            formData?.candleIntervalId === interval
                              ? " active"
                              : ""
                          }`}
                          onClick={() => handleClickCandleInterval(interval)}
                        >
                          {typeof interval === "number"
                            ? `${interval}min`
                            : interval}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <br />
        <br />
        <ScannerConditionComponent
          formData={formData}
          onFormChange={onFormChange}
          getText={getText}
        />

        <div className="p-0 pt-4" id="scanner-review-description">
          <Card className="p-0">
            <Card.Body className="p-0">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="fw-bold">Scanner Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={formData?.description}
                  onChange={handleChangeValues}
                  rows={4}
                  placeholder="Add your scanner description here"
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex align-items-end flex-column p-0">
          <div className="ml-auto pt-3">
            <Button className="btn-color" onClick={addNewScanner}>
              Scan and Save
            </Button>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default ScannerParameterComponent;
