import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const TopMoverRightSideBar2 = ({ topmovers, toplossers }) => {
  // console.log("first", topmovers);
  const navigate = useNavigate();

  const handleNavigateSymbol = (item) => {
    // console.log("item", item)
    const queryParams = new URLSearchParams();
    queryParams.set("id", item.instrument_token);
    queryParams.set("itemName", item.stock_name);
    navigate(`/symbol?${queryParams.toString()}`);
  };
  const handleViewAllLooser = () => {};

  return (
    <div className="col-lg-3 col-sm-12 right-side-bar" id="right-side-bar">
      <div
        className="row deploye-data"
        style={{
          height: "auto",
          background: "#f6f6f6",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          <div className="text-center">
            <h6 className="fw-bold">Top Gainers</h6>
          </div>
          {/* <div style={{color: "#6baddb"}}>See More</div> */}
        </div>
        <div className="sub-text p-2 text-center">
          {topmovers ? (
            topmovers?.slice(0, 5).map((item, i) => (
              <div
                key={i}
                className="my-3 row align-item-center pointer-cursor"
                onClick={() => handleNavigateSymbol(item)}
              >
                <div className="col-lg-4 col-sm-4 col-md-4 col-4">
                  <div className="d-flex align-items-center">
                    <img
                      src="/algoTradeImageLogo.png"
                      style={{ height: "30px", width: "30px" }}
                      alt="nt found"
                    ></img>
                    &nbsp;&nbsp;
                    <div
                      className="text-uppercase text-first fw-bold"
                      style={{ fontSize: "12px" }}
                    >
                      {item?.stock_name}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-md-3 col-3 p-2">
                  {/* <Chart options={options} series={series} type="line" height="40" /> */}
                </div>
                <div className="col-5 col-sm-5 col-md-5 col-5 amount-text text-end">
                  <div style={{ fontSize: "16px" }}>
                    <i className="fa fa-rupee"></i> &nbsp;
                    {item?.last_price}
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    {/* <i className="fa fa-plus"></i>  &nbsp; */}+{" "}
                    {item?.percentageChange.toFixed(3)} %
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No TopMovers found</div>
          )}
          <div onClick={handleViewAllLooser}>view all</div>
        </div>
        <hr />
        {/* /// top lossers // */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          <div className="text-center">
            <h6 className="fw-bold">Top Lossers</h6>
          </div>
          {/* <div style={{color: "#6baddb"}}>See More</div> */}
        </div>
        <div className="sub-text p-2 text-center">
          {toplossers ? (
            toplossers?.slice(0, 5).map((item, i) => (
              <div
                key={i}
                className="my-3 row align-item-center pointer-cursor"
                onClick={() => handleNavigateSymbol(item)}
              >
                <div className="col-lg-4 col-sm-4 col-md-4 col-4">
                  <div className="d-flex align-items-center">
                    <img
                      src="/algoTradeImageLogo.png"
                      style={{ height: "30px", width: "30px" }}
                      alt="nt found"
                    ></img>
                    &nbsp;&nbsp;
                    <div
                      className="text-uppercase text-first fw-bold"
                      style={{ fontSize: "12px" }}
                    >
                      {item?.stock_name}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-md-3 col-3 p-2">
                  {/* <Chart options={options} series={series} type="line" height="40" /> */}
                </div>
                <div className="col-5 col-sm-5 col-md-5 col-5 text-danger text-end">
                  <div style={{ fontSize: "16px" }}>
                    <i className="fa fa-rupee"></i> &nbsp;
                    {item?.last_price}
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    {/* <i className="fa fa-minus"></i> &nbsp; */}
                    {item?.percentageChange.toFixed(3)} %
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No TopMovers found</div>
          )}
          <div onClick={handleViewAllLooser}> view all</div>
        </div>
      </div>
    </div>
  );
};

export default TopMoverRightSideBar2;
