// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.utTestContainer{
        width: 20px;
        height: 20px;
        border: 1px solid #7AB1DD;
        background-color: white;
        font-size: xx-small;
        font-weight: bold;
        border-radius: "10%";
        text-align: center;
        line-height: 18px;
        color: rgb(0, 145, 255);
        cursor: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/checkTest.css"],"names":[],"mappings":"AAAA;QACQ,WAAW;QACX,YAAY;QACZ,yBAAyB;QACzB,uBAAuB;QACvB,mBAAmB;QACnB,iBAAiB;QACjB,oBAAoB;QACpB,kBAAkB;QAClB,iBAAiB;QACjB,uBAAuB;QACvB,YAAY;AACpB","sourcesContent":[".utTestContainer{\n        width: 20px;\n        height: 20px;\n        border: 1px solid #7AB1DD;\n        background-color: white;\n        font-size: xx-small;\n        font-weight: bold;\n        border-radius: \"10%\";\n        text-align: center;\n        line-height: 18px;\n        color: rgb(0, 145, 255);\n        cursor: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
