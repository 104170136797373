import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useMenuContext } from "../Components/Strategies/StrategyCreateComponent";
import { IMAGE_URL } from "../config";
import { DataContent } from "../context/manageContent";
const container = {
  position: "fixed",
  top: 0,
  right: 0,
  width: "30vw",
  height: "100vh",
  background: "white",
  zIndex: 9999,
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  padding: "10px",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
};

const StocksList = ({
  storeSelectedItems,
  setShowList,
  selectedStock,
  setTransparentBackground,
  setIsListDone,
  isListDone
}) => {
  const { menu, setMenu, newStockes, setNewStockes, allStockStore } =
    useMenuContext();
  const contentData = useContext(DataContent) ;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState(selectedStock);
 
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };
  const deBounceSearch = debounce((value) => {
    setSearchTerm(value);
    const filteredStocks = allStockStore?.filter((item) => {
      const name = item.tradingsymbol.toLowerCase();
      return name.includes(value.trim().toLowerCase());
    });
    let arr = filteredStocks.slice(0, 3000);
    setNewStockes(arr);
  }, 1000);
  const handleSearchChange = (e) => {
    const { value } = e.target;
    deBounceSearch(value);
  };
  const handleDoneClick = () => {
    // Triggered when the "Done" button is clicked
    console.log("selectedItems", selectedItems);
    storeSelectedItems(selectedItems);
    setShowList(false);
    setTransparentBackground(false);
    setIsListDone(!isListDone);
  };

  const handleAddToSelected = (item) => {
    setSelectedItems([...selectedItems, item]);
  };

  const handleRemoveFromSelected = (index) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems.splice(index, 1);
    setSelectedItems(updatedSelectedItems);
  };
 
  return (
    <>
        <div style={container}>
          <div
            style={{
              marginBottom: "10px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                width: "60%",
              }}
            >
              <i
                style={{
                  position: "absolute",
                  marginLeft: "10px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                className="fa-solid fa-magnifying-glass"
              ></i>
              <input
                type="text"
                placeholder="Search stocks"
                // value={searchTerm}
                onChange={handleSearchChange}
                style={{
                  borderRadius: "12px",
                  border: "1px solid lightgray",
                  padding: "8px",
                  paddingLeft: "40px", // Adjust to accommodate the icon width and spacing
                  width: "100%",
                }}
              />
            </div>
            <button
              className="btn-color"
              style={{
                padding: "8px",
                background: "#0312f5",
                color: "white",
                borderRadius: "8px",
                border: "none",
                width: "30%",
              }}
              onClick={handleDoneClick}
            >
              Done
            </button>
          </div>

          {/* Display selected items */}
          <div
            style={{
              justifyContent: "space-around",
              marginBottom: "10px",
              overflowX: "auto",
              width: "100%",
              display: "flex",
              flexWrap: "wrap", // Ensure items wrap to the next line when there's not enough space
              justifyContent: "flex-start",
            }}
          >
            {selectedItems?.map((selectedItem, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "4px",
                  background: "lightgray",
                  borderRadius: "18px",
                  marginRight: "10px",
                  marginTop: "10px",
                  marginBottom: "12px",
                  fontSize: "12px",
                }}
                key={index}
              >
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#03C03C", marginLeft: "5px" }}
                ></i>
                <div style={{ marginLeft: "6px" }}>
                  {selectedItem.tradingsymbol}
                </div>

                <i
                  style={{
                    marginLeft: "10px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRemoveFromSelected(index)}
                  className="fa-solid fa-xmark"
                ></i>
              </div>
            ))}
          </div>

          {/* Wrapper for Stock List */}
          {newStockes?.length > 0 ? (
            <div
              style={{
                flex: 1,
                overflowY: "auto",
                width: "100%",
                marginBottom: "20px", // Adjust as needed
              }}
            >
              {newStockes?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: "15px",
                    marginTop: "15px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  {/* Stock Details */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "40%",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}${contentData?.featured_image}`}
                      alt="stock logo"
                      height={40}
                      width={40}
                    />
                    <div style={{ width: "100%", marginLeft: "14px" }}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item?.tradingsymbol}
                      </div>
                      <div style={{ fontSize: "12px" }}>
                        {item?.exchange}
                        {/* {item?.Exch === "N" ? "NSE" : "BSE"} */}
                      </div>
                    </div>
                  </div>
                  {selectedItems?.some(
                    (selectedItem) =>
                      selectedItem?.tradingsymbol === item?.tradingsymbol &&
                      selectedItem?.exchange === item?.exchange
                  ) ? (
                    <i
                      className="fa-solid fa-circle-check"
                      style={{
                        color: "#03C03C",
                        marginLeft: "5px",
                        fontSize: "24px",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="fa-solid fa-circle-plus"
                      style={{ fontSize: "24px", cursor: "pointer" }}
                      onClick={() => handleAddToSelected(item)}
                    ></i>
                  )}
                  {/* <div>{item?.tick_size}</div> */}
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
    </>
  );
};

export default StocksList;
