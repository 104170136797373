const extractKeys = (formData, isExit) =>  {
    const checkForMathKey = [];
    const minMaxKeys = [];
    const validFunctions = [
        "Period min",
        "Period max",
        "Symbol",
        "Floor",
        "Ceil",
        "Abs",
    ];
    const additionalValidFunctions = ["Max", "Min"];
    if (formData) {
        let indicators = isExit === "exit" ? formData?.exit_indicators : formData?.indicators ;
        let indicatorKeys = Object.keys(indicators);
        const filteredArray = indicatorKeys?.filter(item =>
            validFunctions.some(validFunction => item.startsWith(validFunction))
        );
        const filteredMathArray = indicatorKeys?.filter(item =>
            additionalValidFunctions.some(validFunctions => item.startsWith(validFunctions))
        );
        checkForMathKey.push(...filteredArray);
        minMaxKeys.push(...filteredMathArray);
    }
    return { checkForMathKey, minMaxKeys } ;
}

export default extractKeys ;