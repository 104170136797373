import React from "react";

const CandleInterval = ({
  index,
  instanceType,
  data,
  formData,
  instanceName,
  setShowCandleModal,
  getIndex,
  isExit,
}) => {
  console.log("ISCaling", "calling", isExit, data);
  const handleClickInterval = () => {
    if (formData) {
      formData[isExit ? "selectedOption2" : "selectedOption1"] = data?.name;
      formData.clickedFunction = instanceName;
      setShowCandleModal(true);
      getIndex(index, instanceType);
    }
  };
  return (
    <div
      className="hover-underline"
      style={{ cursor: "pointer" }}
      onClick={handleClickInterval}
    >
      {data?.candleIntervalId}
    </div>
  );
};

export default CandleInterval;
