import React, { useState } from "react";
import CandleInterval from "./CandleInterval";
import { fieldMappings, modalMap } from "../../Utils/InstanceDisplayMap";
const InstanceDisplay = (props) => {
  const {
    instanceData,
    formData,
    instanceName,
    index,
    isShowingAddButton,
    handleAdd,
    handleEdit,
    handleRemoveButtonClick,
    setShowCandleModal,
    getIndex,
    buttonStyle,
    addButton,
    handleAddButtonClick,
    setShowDropdown,
    setInnerAdd,
    setOuterAdd,
    isMathFunction,
    instance,
    isExit,
    setShowModal,
  } = props;
  console.log("instanceData", instanceData?.name);
  const renderButtonContent = () => {
    if (!instanceData?.name) return "";
   
    const fields =
      fieldMappings(isExit, instanceData)[instanceData?.name] || [];
        console.log("fieldValues", fields);

    let fieldValues;
    if (Array.isArray(fields)) {
      fieldValues = fields
        .map((field) => {
          const value = instanceData[field];
          return value !== undefined && value !== null ? value : ""; // Ensure it is included
        })
        .join(",");
    } else {
      fieldValues = instanceData[fields] || "";
    }
    if (fields === "and" || fields === "or") return fields;
    console.log("checkData", instanceData?.name);

    if (fieldValues) return `${instanceData.name} (${fieldValues})`;
    
    return `${instanceData.name}`;
  };
  const handleButtonClick = () => {
    if (formData) {
      formData[isExit ? "selectedOption2" : "selectedOption1"] =
        instanceData?.name;
      formData.clickedFunction = instanceName;
      if (isMathFunction) {
        getIndex(index, instance);
      }
    }
  console.log("INSTANCENAME", instanceData.name);
    setShowModal(modalMap[instanceData?.name] || null);
  };
  
  console.log("isIncludes", instanceName);
  return (
    <div className="d-flex">
      {instanceData?.add === true && (
        <button
          onClick={() => {
            setShowDropdown(true);
            setInnerAdd(true);
            setOuterAdd(false);
            handleAddButtonClick(instanceName);
          }}
          style={addButton}
        >
          {"Add"}
        </button>
      )}
      {/* // below code  is for indicators // */}
      {isMathFunction ? (
        <>
          {instanceData?.name !== "Number" &&
            instanceData?.name !== "&&" &&
            instanceData?.name !== "||" &&
            instanceData?.name !== "+" &&
            instanceData?.name !== "-" &&
            instanceData?.name !== "*" &&
            instanceData?.name !== "/" &&
            !instanceName.startsWith("comparator") &&
            !instanceName.startsWith("operators") && (
              <CandleInterval
                index={index}
                instanceType={instance}
                data={instanceData}
                formData={formData}
                instanceName={instanceName}
                setShowCandleModal={setShowCandleModal}
                getIndex={getIndex}
                isExit={isExit}
              />
            )}
          &ensp;
          {instanceData?.name && (
            <button
              onClick={handleButtonClick}
              style={{
                ...buttonStyle,
                color: "#0e6efd",
                fontWeight: "500",
              }}
            >
              {renderButtonContent()}
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveButtonClick(instanceName, index, instance);
                }}
                className={`fa-solid fa-xmark ${
                  isExit ? "iconExitContainer" : "iconContainer"
                }`}
                style={{
                  color: "#7AB1DD",
                }}
              ></i>
              &ensp;
            </button>
          )}
        </>
      ) : isShowingAddButton ? (
        <button
          className="stratergyAddBtn"
          onClick={() => {
            handleAdd(instanceData, index);
          }}
        >
          Add
        </button>
      ) : (
        <>
          {instanceData?.name !== "Number" &&
            instanceData?.name !== "Nth Candle" &&
            instanceData?.name !== "&&" &&
            instanceData?.name !== "||" &&
            !instanceName.startsWith("comparator") &&
            !instanceName.startsWith("operators") && (
              <CandleInterval
                index={index}
                instanceType=""
                data={instanceData}
                formData={formData}
                instanceName={instanceName}
                setShowCandleModal={setShowCandleModal}
                getIndex={getIndex}
              />
            )}
          &ensp;
          {instanceData?.name && (
            <button
              onClick={handleButtonClick}
              style={{
                ...buttonStyle,
                color: "#0e6efd",
                fontWeight: "500",
              }}
            >
              {/* // this is checking that formula is test or not */}
              {renderButtonContent()}
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(instanceData, index);
                }}
                className={`fa-solid fa-xmark ${
                  isExit ? "iconExitContainer" : "iconContainer"
                }`}
                style={{
                  color: "#7AB1DD",
                }}
              ></i>
              &ensp;
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default InstanceDisplay;
