import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
const apiFields = {
  "5paisa": [
    { name: "appName", label: "App Name" },
    { name: "appSource", label: "App Source" },
    { name: "appUserid", label: "User iD" },
    { name: "password", label: "Password" },
    { name: "appUserkey", label: "User Key" },
    { name: "appEnckey", label: "Encreption Key" },
    { name: "client_id", label: "Client Id" },
  ],
  upstock: [
    { name: "code", label: "Code" },
    { name: "client_id", label: "Client ID" },
    { name: "client_secret", label: "Client Secret" },
    { name: "redirect_uri", label: "Redirect URI" },
    { name: "grant_type", label: "Grant Type" },
  ],
  angelone: [
    { name: "clientcode", label: "Client Code" },
    { name: "password", label: "Password" },
    { name: "totp", label: "TOTP" },
    { name: "state", label: "State" },
  ],
  hdfc: [
    { name: "api_key", label: "API Key" },
    { name: "token_id", label: "Token ID" },
    { name: "username", label: "Username" },
    { name: "password", label: "Password" },
  ],
  icici: [
    { name: "AppKey", label: "App Key" },
    { name: "UserID", label: "User ID" },
    { name: "App_Session", label: "App Session" },
  ],
  iifl: [
    { name: "appName", label: "App Name" },
    { name: "appVer", label: "App Version" },
    { name: "key", label: "Key" },
    { name: "osName", label: "OS Name" },
    { name: "requestCode", label: "Request Code" },
    { name: "userId", label: "User ID" },
    { name: "password", label: "Password" },
  ],
  aliceblue: [
    { name: "userId", label: "User ID" },
    { name: "encKey", label: "Encryption Key" },
    { name: "apikey", label: "API Key" },
  ],
  axis: [
    { name: "client-id", label: "Client ID" },
    { name: "subAccountID", label: "Sub Account ID" },
    { name: "encryption-key", label: "Encryption Key" },
    { name: "authtoken", label: "Auth Token" },
  ],
  fyers: [
    { name: "api_key", label: "API Key" },
    { name: "access_token", label: "Access Token" },
  ],
};
const TradingForm = ({ tradeName, title, onSubmitDetails }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePassword = () => {
    setPasswordVisible((prev) => !prev);
  };
 
  return (
    <>
      <div className="col-lg-12">
        <div className="info-div">
          <h2 className="mobile-title">{title}</h2>
          <div className="card mb4">
            <div className="card-body">
              <Form onSubmit={handleSubmit(onSubmitDetails)}>
                {apiFields[tradeName]
                  ?.reduce((acc, field, index) => {
                    if (index % 2 === 0) {
                      acc.push([field]);
                    } else {
                      acc[acc.length - 1].push(field);
                    }
                    return acc;
                  }, [])
                  .map((pair, id) => (
                    <Row key={id}>
                      {pair?.map(({ name, label }, id) => {
                        return (
                          <Form.Group as={Col} sm="6" className="mb-3" key={id}>
                            <Form.Label>{label}</Form.Label>
                            <p className="passwordToggle">
                              <Form.Control
                                id={name}
                                type={
                                  name == "password" && passwordVisible
                                    ? "password"
                                    : "text"
                                }
                                placeholder={label}
                                {...register(name, {
                                  required: `${label} is required`,
                                  minLength:
                                    name === "password"
                                      ? {
                                          value: 6,
                                          message:
                                            "Password must be at least 6 characters",
                                        }
                                      : undefined,
                                  pattern:
                                    name === "password"
                                      ? {
                                          value:
                                            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).+$/,
                                          message:
                                            "Password must include at least one uppercase,lowercase, one number, and one special character",
                                        }
                                      : undefined,
                                })}
                              />
                              {name == "password" && (
                                <span onClick={togglePassword}>
                                  <i
                                    className={
                                      passwordVisible
                                        ? "fas fa-eye-slash"
                                        : "fas fa-eye"
                                    }
                                  />
                                </span>
                              )}
                            </p>

                            <p className="text-danger mb-0">
                              {errors[name] && (
                                <span>{errors[name].message}</span>
                              )}
                            </p>
                          </Form.Group>
                        );
                      })}
                    </Row>
                  ))}
                <Row className=" justify-content-md-center">
                  <Col xs lg="2">
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradingForm