import React, { useEffect, useLayoutEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { getUser } from "../Session";

const Header = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  useEffect(() => {
    const user = getUser();
    console.log("{{", user);
  }, []);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };
  const pathName = useLocation().pathname;
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]);

  return (
    <>
      <div
        id="header"
        className="px-5 container-fluid d-flex align-items-center fixed-top header"
      >
        <h1 className="logo me-auto">
          {" "}
          <Link to="/" onClick={() => setMobileNavOpen(false)}>
            <img src="AlgoTradeImage.png" className="logoweb"></img>
          </Link>
        </h1>
        <nav
          id="navbar"
          className={`navbar ${isMobileNavOpen ? "navbar-mobile" : ""}`}
        >
          {!getUser() ? (
            <ul>
              <li>
                <Link
                  className={`nav-link scrollto ${
                    pathName === "/" ? "active" : null
                  }`}
                  to="/Pricing"
                  onClick={() => setMobileNavOpen(false)}
                  style={{ color: "#2C3092", fontWeight: "500" }}
                >
                  Pricing
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  to="/login"
                  className={`nav-link scrollto ${
                    pathName === "/login" ? "active" : null
                  }`}
                  onClick={() => setMobileNavOpen(false)}
                  style={{ color: "#2C3092", fontWeight: "500" }}
                >
                  Log in
                </Link>
              </li>
              <li>
                <Link
                  to="/signup"
                  className={`getstarted scrollto ${
                    pathName === "/signup" ? " active" : null
                  }`}
                  onClick={() => setMobileNavOpen(false)}
                  style={{ color: "#2C3092", fontWeight: "500" }}
                >
                  Start Free Trial
                </Link>
              </li>
            </ul>
          ) : (
            <div>
              <a href="/dashboard">
                <i
                  className="fa-solid fa-house"
                  aria-hidden="true"
                  style={{ fontSize: "30px" }}
                ></i>
              </a>
            </div>
          )}

          <i
            className={`fa ${
              isMobileNavOpen
                ? "fa-times fa-lg mobile-nav-toggle"
                : "fa-bars fa-lg mobile-nav-toggle"
            }`}
            onClick={toggleMobileNav}
          ></i>
        </nav>
      </div>
    </>
  );
};

export default Header;
