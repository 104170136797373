import React, { useContext, useState } from "react";
import { IMAGE_URL } from "../../config";
import { DataContent } from "../../context/manageContent";

const SelStockBox = ({ item, onRemove, index }) => {
  console.log("selItem>", item);
  const [isHovered, setIsHovered] = useState(false);
  const contentData = useContext(DataContent) ;
  const handleRemoveClick = () => {
    // Call the callback function passed from the parent component
    onRemove(index);
  };
  return (
    <div
      style={{
        position: "relative",
        height: "160px",
        width: "160px",
        borderRadius: "10px",
        border: "1px solid lightgray",
        background: "white",
        padding: "12px",
        marginRight: "20px",
        transition: "opacity 0.3s ease",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={`${IMAGE_URL}${contentData?.featured_image}`}
          alt="stock logo"
          height={40}
          width={40}
        />

        <div style={{ marginLeft: "6px" }}>
          <div
            style={{
              fontSize: "12px",
              wordBreak: "break-word",
              overflowWrap: "break-word",
              flexWrap: "wrap",
              width: "100%",
              maxHeight: "30px",
              fontWeight: "500",
            }}
          >
            {item?.tradingsymbol}
          </div>
          <div style={{ fontSize: "12px", marginTop: "6px" }}>
            {item?.exchange}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "40px" }}>
        <div
          style={{
            fontSize: "15px",
            fontWeight: "600",
            color:
              item?.ltp !== 0 && item?.closePrice !== 0
                ? item.ltp > item.closePrice
                  ? "#008000"
                  : "#BA0F04"
                : "#060606",
          }}
        >
          <span style={{ fontSize: "18px" }}>{item?.ltp}</span>
          <br />
          {item.ltp && item.closePrice && (
            <>
              {(((item.ltp - item.closePrice) / item.closePrice) * 100).toFixed(
                3
              )}
              %
            </>
          )}
        </div>
      </div>
      {isHovered && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Light color overlay
            borderRadius: "10px",
          }}
        />
      )}
      {/* Remove icon */}
      <div
        style={{
          position: "absolute",
          top: "35%",
          right: "5%",
          padding: "8px",
          cursor: "pointer",
          opacity: isHovered ? 1 : 0,
          transition: "opacity 0.3s ease",
        }}
        onClick={handleRemoveClick}
      >
        <i className="fa-solid fa-trash" style={{ fontSize: "20px" }}></i>
      </div>
    </div>
  );
};
export default SelStockBox;
