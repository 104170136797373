import React, { useEffect, useState } from "react";
import "./Strategy.css";
import EMA_I from "./Indicators/EMA_I";
import SMA_I from "./Indicators/SMA_I";
import Close_I from "./Indicators/Close_I";
import Number_I from "./Indicators/Number_I";
import SearchableDropdown from "./SearchDropdown";
import CandleInterval from "../../mcomponents/formulaTabComponent/CandleInterval";
import InstanceDisplay from "../../mcomponents/formulaTabComponent/InstanceDisplay";

const buttonStyle = {
  padding: "3px",
  marginRight: "10px",
  fontSize: "14px",
  backgroundColor: "white",
  color: "black",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  border: "1px solid #1579c9",
  position: "relative",
};

const addButton = {
  backgroundColor: "transparent", // No background color
  color: "#0e6efd", // Text color
  fontWeight: "500",
  fontSize: "16px",
  height: "30px",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px dashed #1579c9", // Dashed border
  borderRadius: "4px",
  cursor: "pointer",
  transition: "border-color 0.3s", // Only transition for border color
  marginRight: "5px",
};
const ExitFormulaTab = ({ ...restProps }) => {
  const {
    formData,
    allStocks,
    handleRemoveButtonClick,
    onFormChange,
    handleButtonClick,
    setShowDropdown,
    setInnerAdd,
    setOuterAdd,
    handleAddButtonClick,
    outerAdd,
    setShowClose,
    setShowOpen,
    setShowLowPrice,
    setShowHighPrice,
    setShowUlcer,
    setShowDEMA,
    setShowTEMA,
    setShowTMA,
    setShowVWMA,
    setShowSMA,
    setShowEMA,
    setShowNumber,
    setShowMoneyFlowIndex,
    setShowWilliams,
    setShowMad,
    setShowObv,
    setShowAroonDown,
    setShowAroonUp,
    setShowOscilator,
    setShowVWAP,
    setShowpsar,
    setShowVortexI,
    setShowVolume,
    setShowDonchian,
    setShowStochastic,
    setShowChaikin,
    setShowMacdSignal,
    setShowCCI,
    setShowRoc,
    setShowRSI,
    setShowRSIMA,
    setShowTR,
    setShowATR,
    setShowNATR,
    setShowSuperT,
    setShowBB,
    setShowMfiMa,
    setShowIchimoku,
    setShowKST,
    setShowCandleModal,
    getIndex,
    setShowWMA,
    setShowMacd,
    setShowMOM,
    showInstead,
    handleEdit,
    handleAdd,
    setPeriodMaxModal,
    setPeriodMinModal,
    setisStdDeviation,
    setShowUltimateOscillator,
    setShowNthCandle,
    setMedianPrice,
    setShowChande,
    setShowATRTrailModal,
    setShowChoppiness,
    setCentralRange,
    setCamrillaPivot,
    setShowPivotPoint,
    setShowElderRay,
    setBollingUML,
    setPowerMoving,
    setShowDI,
    setShowTsi,
    setShowTII,
    setShowOpenInterest,
    setShowNVI,
    setShowGreeks,
    setShowDetrended,
    setShowKlinger,
    setShowModal,
  } = restProps;

  console.log("Exit_restProps", formData);

  return (
    <>
      {Object.entries(formData?.exit_indicators || {}).map(
        ([instanceName, instanceData], index) => {
          const isShowingAddButton =
            showInstead?.isOpen &&
            showInstead?.function_id == instanceData?.function_id &&
            showInstead.index == index;

          return (
            <span
              key={instanceName}
              className={`ms-2 ${
                instanceData?.name === "&&" || instanceData?.name === "||"
                  ? "w-100 py-2"
                  : ""
              }`}
            >
              {instanceData?.name === "Max" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body1 &&
                          instanceData?.body1?.map((item, index) => {
                            let data = item
                              ? item
                              : instanceData?.body
                              ? instanceData?.body[0]
                              : instanceData;
                            return (
                              <>
                                <InstanceDisplay
                                  instanceData={data}
                                  formData={formData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowClose={setShowClose}
                                  setShowOpen={setShowOpen}
                                  setShowHighPrice={setShowHighPrice}
                                  setShowLowPrice={setShowLowPrice}
                                  setShowRSI={setShowRSI}
                                  setShowUlcer={setShowUlcer}
                                  setShowDEMA={setShowDEMA}
                                  setShowTEMA={setShowTEMA}
                                  setShowTMA={setShowTMA}
                                  setShowVWMA={setShowVWMA}
                                  setShowSMA={setShowSMA}
                                  setShowEMA={setShowEMA}
                                  setShowWMA={setShowWMA}
                                  setShowNumber={setShowNumber}
                                  setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                                  setShowWilliams={setShowWilliams}
                                  setShowMad={setShowMad}
                                  setShowMacd={setShowMacd}
                                  setShowObv={setShowObv}
                                  setShowAroonDown={setShowAroonDown}
                                  setShowAroonUp={setShowAroonUp}
                                  setShowOscilator={setShowOscilator}
                                  setShowVWAP={setShowVWAP}
                                  setShowpsar={setShowpsar}
                                  setShowVortexI={setShowVortexI}
                                  setShowVolume={setShowVolume}
                                  setShowDonchian={setShowDonchian}
                                  setShowStochastic={setShowStochastic}
                                  setShowChaikin={setShowChaikin}
                                  setShowMacdSignal={setShowMacdSignal}
                                  setShowCCI={setShowCCI}
                                  setShowRoc={setShowRoc}
                                  setShowRSIMA={setShowRSIMA}
                                  setShowTR={setShowTR}
                                  setShowATR={setShowATR}
                                  setShowNATR={setShowNATR}
                                  setShowSuperT={setShowSuperT}
                                  setShowBB={setShowBB}
                                  setShowMfiMa={setShowMfiMa}
                                  setShowIchimoku={setShowIchimoku}
                                  setShowKST={setShowKST}
                                  setShowMOM={setShowMOM}
                                  setisStdDeviation={setisStdDeviation}
                                  setShowUltimateOscillator={
                                    setShowUltimateOscillator
                                  }
                                  setShowNthCandle={setShowNthCandle}
                                  isShowingAddButton={isShowingAddButton}
                                  handleAdd={handleAdd}
                                  handleEdit={handleEdit}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  buttonStyle={buttonStyle}
                                  addButton={addButton}
                                  handleAddButtonClick={handleAddButtonClick}
                                  setShowDropdown={setShowDropdown}
                                  setInnerAdd={setInnerAdd}
                                  setOuterAdd={setOuterAdd}
                                  isMathFunction="true"
                                  instance="body1"
                                  isExit="true"
                                  setMedianPrice={setMedianPrice}
                                  setShowChande={setShowChande}
                                  setShowATRTrailModal={setShowATRTrailModal}
                                  setShowChoppiness={setShowChoppiness}
                                  setCentralRange={setCentralRange}
                                  setCamrillaPivot={setCamrillaPivot}
                                  setShowPivotPoint={setShowPivotPoint}
                                  setShowElderRay={setShowElderRay}
                                  setBollingUML={setBollingUML}
                                  setPowerMoving={setPowerMoving}
                                  setShowDI={setShowDI}
                                  setShowTsi={setShowTsi}
                                  setShowTII={setShowTII}
                                  setShowOpenInterest={setShowOpenInterest}
                                  setShowNVI={setShowNVI}
                                  setShowGreeks={setShowGreeks}
                                  setShowDetrended={setShowDetrended}
                                  setShowKlinger={setShowKlinger}
                                  setShowModal={setShowModal}
                                />
                              </>
                            );
                          })}                        
                          <button
                            onClick={() => {
                              console.log(
                                "instanceData?.name",
                                instanceData,
                                instanceName
                              );
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body1");
                            }}
                            // style={addButton}
                            className="stratergyAddBtn"
                          >
                            {"Add"}
                          </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body2?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <InstanceDisplay
                              instanceData={data}
                              formData={formData}
                              instanceName={instanceName}
                              index={index}
                              setShowClose={setShowClose}
                              setShowOpen={setShowOpen}
                              setShowHighPrice={setShowHighPrice}
                              setShowLowPrice={setShowLowPrice}
                              setShowRSI={setShowRSI}
                              setShowUlcer={setShowUlcer}
                              setShowDEMA={setShowDEMA}
                              setShowTEMA={setShowTEMA}
                              setShowTMA={setShowTMA}
                              setShowVWMA={setShowVWMA}
                              setShowSMA={setShowSMA}
                              setShowEMA={setShowEMA}
                              setShowWMA={setShowWMA}
                              setShowNumber={setShowNumber}
                              setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                              setShowWilliams={setShowWilliams}
                              setShowMad={setShowMad}
                              setShowMacd={setShowMacd}
                              setShowObv={setShowObv}
                              setShowAroonDown={setShowAroonDown}
                              setShowAroonUp={setShowAroonUp}
                              setShowOscilator={setShowOscilator}
                              setShowVWAP={setShowVWAP}
                              setShowpsar={setShowpsar}
                              setShowVortexI={setShowVortexI}
                              setShowVolume={setShowVolume}
                              setShowDonchian={setShowDonchian}
                              setShowStochastic={setShowStochastic}
                              setShowChaikin={setShowChaikin}
                              setShowMacdSignal={setShowMacdSignal}
                              setShowCCI={setShowCCI}
                              setShowRoc={setShowRoc}
                              setShowRSIMA={setShowRSIMA}
                              setShowTR={setShowTR}
                              setShowATR={setShowATR}
                              setShowNATR={setShowNATR}
                              setShowSuperT={setShowSuperT}
                              setShowBB={setShowBB}
                              setShowMfiMa={setShowMfiMa}
                              setShowIchimoku={setShowIchimoku}
                              setShowKST={setShowKST}
                              setShowMOM={setShowMOM}
                              setisStdDeviation={setisStdDeviation}
                              setShowUltimateOscillator={
                                setShowUltimateOscillator
                              }
                              setShowNthCandle={setShowNthCandle}
                              isShowingAddButton={isShowingAddButton}
                              handleAdd={handleAdd}
                              handleEdit={handleEdit}
                              handleRemoveButtonClick={handleRemoveButtonClick}
                              setShowCandleModal={setShowCandleModal}
                              getIndex={getIndex}
                              buttonStyle={buttonStyle}
                              addButton={addButton}
                              handleAddButtonClick={handleAddButtonClick}
                              setShowDropdown={setShowDropdown}
                              setInnerAdd={setInnerAdd}
                              setOuterAdd={setOuterAdd}
                              isMathFunction="true"
                              instance="body2"
                              isExit="true"
                              setMedianPrice={setMedianPrice}
                              setShowChande={setShowChande}
                              setShowATRTrailModal={setShowATRTrailModal}
                              setShowChoppiness={setShowChoppiness}
                              setCentralRange={setCentralRange}
                              setCamrillaPivot={setCamrillaPivot}
                              setShowPivotPoint={setShowPivotPoint}
                              setShowElderRay={setShowElderRay}
                              setBollingUML={setBollingUML}
                              setPowerMoving={setPowerMoving}
                              setShowDI={setShowDI}
                              setShowTsi={setShowTsi}
                              setShowTII={setShowTII}
                              setShowOpenInterest={setShowOpenInterest}
                              setShowNVI={setShowNVI}
                              setShowGreeks={setShowGreeks}
                              setShowDetrended={setShowDetrended}
                              setShowKlinger={setShowKlinger}
                              setShowModal={setShowModal}
                            />
                          );
                        })}
                        &nbsp;&nbsp;
                          <button
                            onClick={() => {
                              console.log("instanceData?.name", instanceName);
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body2");
                            }}
                            className="stratergyAddBtn"
                          >
                            {"Add"}
                          </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Min" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body1 &&
                          instanceData?.body1?.map((item, index) => {
                            let data = item
                              ? item
                              : instanceData?.body
                              ? instanceData?.body[0]
                              : instanceData;
                            return (
                              <>
                                <InstanceDisplay
                                  instanceData={data}
                                  formData={formData}
                                  instanceName={instanceName}
                                  index={index}
                                  setShowClose={setShowClose}
                                  setShowOpen={setShowOpen}
                                  setShowHighPrice={setShowHighPrice}
                                  setShowLowPrice={setShowLowPrice}
                                  setShowRSI={setShowRSI}
                                  setShowUlcer={setShowUlcer}
                                  setShowDEMA={setShowDEMA}
                                  setShowTEMA={setShowTEMA}
                                  setShowTMA={setShowTMA}
                                  setShowVWMA={setShowVWMA}
                                  setShowSMA={setShowSMA}
                                  setShowEMA={setShowEMA}
                                  setShowWMA={setShowWMA}
                                  setShowNumber={setShowNumber}
                                  setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                                  setShowWilliams={setShowWilliams}
                                  setShowMad={setShowMad}
                                  setShowMacd={setShowMacd}
                                  setShowObv={setShowObv}
                                  setShowAroonDown={setShowAroonDown}
                                  setShowAroonUp={setShowAroonUp}
                                  setShowOscilator={setShowOscilator}
                                  setShowVWAP={setShowVWAP}
                                  setShowpsar={setShowpsar}
                                  setShowVortexI={setShowVortexI}
                                  setShowVolume={setShowVolume}
                                  setShowDonchian={setShowDonchian}
                                  setShowStochastic={setShowStochastic}
                                  setShowChaikin={setShowChaikin}
                                  setShowMacdSignal={setShowMacdSignal}
                                  setShowCCI={setShowCCI}
                                  setShowRoc={setShowRoc}
                                  setShowRSIMA={setShowRSIMA}
                                  setShowTR={setShowTR}
                                  setShowATR={setShowATR}
                                  setShowNATR={setShowNATR}
                                  setShowSuperT={setShowSuperT}
                                  setShowBB={setShowBB}
                                  setShowMfiMa={setShowMfiMa}
                                  setShowIchimoku={setShowIchimoku}
                                  setShowKST={setShowKST}
                                  setShowMOM={setShowMOM}
                                  setisStdDeviation={setisStdDeviation}
                                  setShowUltimateOscillator={
                                    setShowUltimateOscillator
                                  }
                                  setShowNthCandle={setShowNthCandle}
                                  isShowingAddButton={isShowingAddButton}
                                  handleAdd={handleAdd}
                                  handleEdit={handleEdit}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  buttonStyle={buttonStyle}
                                  addButton={addButton}
                                  handleAddButtonClick={handleAddButtonClick}
                                  setShowDropdown={setShowDropdown}
                                  setInnerAdd={setInnerAdd}
                                  setOuterAdd={setOuterAdd}
                                  isMathFunction="true"
                                  instance="body1"
                                  isExit="true"
                                  setMedianPrice={setMedianPrice}
                                  setShowChande={setShowChande}
                                  setShowATRTrailModal={setShowATRTrailModal}
                                  setShowChoppiness={setShowChoppiness}
                                  setCentralRange={setCentralRange}
                                  setCamrillaPivot={setCamrillaPivot}
                                  setShowPivotPoint={setShowPivotPoint}
                                  setShowElderRay={setShowElderRay}
                                  setBollingUML={setBollingUML}
                                  setPowerMoving={setPowerMoving}
                                  setShowDI={setShowDI}
                                  setShowTsi={setShowTsi}
                                  setShowTII={setShowTII}
                                  setShowOpenInterest={setShowOpenInterest}
                                  setShowNVI={setShowNVI}
                                  setShowGreeks={setShowGreeks}
                                  setShowDetrended={setShowDetrended}
                                  setShowKlinger={setShowKlinger}
                                  setShowModal={setShowModal}
                                />
                              </>
                            );
                          })}
                          <button
                            onClick={() => {
                              console.log(
                                "instanceData?.name",
                                instanceData,
                                instanceName
                              );
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body1");
                            }}
                            // style={addButton}
                            className="stratergyAddBtn"
                          >
                            {"Add"}
                          </button>
                        
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body2?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                setShowClose={setShowClose}
                                setShowOpen={setShowOpen}
                                setShowHighPrice={setShowHighPrice}
                                setShowLowPrice={setShowLowPrice}
                                setShowRSI={setShowRSI}
                                setShowUlcer={setShowUlcer}
                                setShowDEMA={setShowDEMA}
                                setShowTEMA={setShowTEMA}
                                setShowTMA={setShowTMA}
                                setShowVWMA={setShowVWMA}
                                setShowSMA={setShowSMA}
                                setShowEMA={setShowEMA}
                                setShowWMA={setShowWMA}
                                setShowNumber={setShowNumber}
                                setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                                setShowWilliams={setShowWilliams}
                                setShowMad={setShowMad}
                                setShowMacd={setShowMacd}
                                setShowObv={setShowObv}
                                setShowAroonDown={setShowAroonDown}
                                setShowAroonUp={setShowAroonUp}
                                setShowOscilator={setShowOscilator}
                                setShowVWAP={setShowVWAP}
                                setShowpsar={setShowpsar}
                                setShowVortexI={setShowVortexI}
                                setShowVolume={setShowVolume}
                                setShowDonchian={setShowDonchian}
                                setShowStochastic={setShowStochastic}
                                setShowChaikin={setShowChaikin}
                                setShowMacdSignal={setShowMacdSignal}
                                setShowCCI={setShowCCI}
                                setShowRoc={setShowRoc}
                                setShowRSIMA={setShowRSIMA}
                                setShowTR={setShowTR}
                                setShowATR={setShowATR}
                                setShowNATR={setShowNATR}
                                setShowSuperT={setShowSuperT}
                                setShowBB={setShowBB}
                                setShowMfiMa={setShowMfiMa}
                                setShowIchimoku={setShowIchimoku}
                                setShowKST={setShowKST}
                                setShowMOM={setShowMOM}
                                setisStdDeviation={setisStdDeviation}
                                setShowUltimateOscillator={
                                  setShowUltimateOscillator
                                }
                                setShowNthCandle={setShowNthCandle}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance="body2"
                                isExit="true"
                                setMedianPrice={setMedianPrice}
                                setShowChande={setShowChande}
                                setShowATRTrailModal={setShowATRTrailModal}
                                setShowChoppiness={setShowChoppiness}
                                setCentralRange={setCentralRange}
                                setCamrillaPivot={setCamrillaPivot}
                                setShowPivotPoint={setShowPivotPoint}
                                setShowElderRay={setShowElderRay}
                                setBollingUML={setBollingUML}
                                setPowerMoving={setPowerMoving}
                                setShowDI={setShowDI}
                                setShowTsi={setShowTsi}
                                setShowTII={setShowTII}
                                setShowOpenInterest={setShowOpenInterest}
                                setShowNVI={setShowNVI}
                                setShowGreeks={setShowGreeks}
                                setShowDetrended={setShowDetrended}
                                setShowKlinger={setShowKlinger}
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                          <button
                            onClick={() => {
                              console.log("instanceData?.name", instanceName);
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body2");
                            }}
                            // style={addButton}
                            className="stratergyAddBtn"
                          >
                            {"Add"}
                          </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Floor" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                setShowClose={setShowClose}
                                setShowOpen={setShowOpen}
                                setShowHighPrice={setShowHighPrice}
                                setShowLowPrice={setShowLowPrice}
                                setShowRSI={setShowRSI}
                                setShowUlcer={setShowUlcer}
                                setShowDEMA={setShowDEMA}
                                setShowTEMA={setShowTEMA}
                                setShowTMA={setShowTMA}
                                setShowVWMA={setShowVWMA}
                                setShowSMA={setShowSMA}
                                setShowEMA={setShowEMA}
                                setShowWMA={setShowWMA}
                                setShowNumber={setShowNumber}
                                setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                                setShowWilliams={setShowWilliams}
                                setShowMad={setShowMad}
                                setShowMacd={setShowMacd}
                                setShowObv={setShowObv}
                                setShowAroonDown={setShowAroonDown}
                                setShowAroonUp={setShowAroonUp}
                                setShowOscilator={setShowOscilator}
                                setShowVWAP={setShowVWAP}
                                setShowpsar={setShowpsar}
                                setShowVortexI={setShowVortexI}
                                setShowVolume={setShowVolume}
                                setShowDonchian={setShowDonchian}
                                setShowStochastic={setShowStochastic}
                                setShowChaikin={setShowChaikin}
                                setShowMacdSignal={setShowMacdSignal}
                                setShowCCI={setShowCCI}
                                setShowRoc={setShowRoc}
                                setShowRSIMA={setShowRSIMA}
                                setShowTR={setShowTR}
                                setShowATR={setShowATR}
                                setShowNATR={setShowNATR}
                                setShowSuperT={setShowSuperT}
                                setShowBB={setShowBB}
                                setShowMfiMa={setShowMfiMa}
                                setShowIchimoku={setShowIchimoku}
                                setShowKST={setShowKST}
                                setShowMOM={setShowMOM}
                                setisStdDeviation={setisStdDeviation}
                                setShowUltimateOscillator={
                                  setShowUltimateOscillator
                                }
                                setShowNthCandle={setShowNthCandle}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit="true"
                                setMedianPrice={setMedianPrice}
                                setShowChande={setShowChande}
                                setShowATRTrailModal={setShowATRTrailModal}
                                setShowChoppiness={setShowChoppiness}
                                setCentralRange={setCentralRange}
                                setCamrillaPivot={setCamrillaPivot}
                                setShowPivotPoint={setShowPivotPoint}
                                setShowElderRay={setShowElderRay}
                                setBollingUML={setBollingUML}
                                setPowerMoving={setPowerMoving}
                                setShowDI={setShowDI}
                                setShowTsi={setShowTsi}
                                setShowTII={setShowTII}
                                setShowOpenInterest={setShowOpenInterest}
                                setShowNVI={setShowNVI}
                                setShowGreeks={setShowGreeks}
                                setShowDetrended={setShowDetrended}
                                setShowKlinger={setShowKlinger}
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Ceil" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                setShowClose={setShowClose}
                                setShowOpen={setShowOpen}
                                setShowHighPrice={setShowHighPrice}
                                setShowLowPrice={setShowLowPrice}
                                setShowRSI={setShowRSI}
                                setShowUlcer={setShowUlcer}
                                setShowDEMA={setShowDEMA}
                                setShowTEMA={setShowTEMA}
                                setShowTMA={setShowTMA}
                                setShowVWMA={setShowVWMA}
                                setShowSMA={setShowSMA}
                                setShowEMA={setShowEMA}
                                setShowWMA={setShowWMA}
                                setShowNumber={setShowNumber}
                                setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                                setShowWilliams={setShowWilliams}
                                setShowMad={setShowMad}
                                setShowMacd={setShowMacd}
                                setShowObv={setShowObv}
                                setShowAroonDown={setShowAroonDown}
                                setShowAroonUp={setShowAroonUp}
                                setShowOscilator={setShowOscilator}
                                setShowVWAP={setShowVWAP}
                                setShowpsar={setShowpsar}
                                setShowVortexI={setShowVortexI}
                                setShowVolume={setShowVolume}
                                setShowDonchian={setShowDonchian}
                                setShowStochastic={setShowStochastic}
                                setShowChaikin={setShowChaikin}
                                setShowMacdSignal={setShowMacdSignal}
                                setShowCCI={setShowCCI}
                                setShowRoc={setShowRoc}
                                setShowRSIMA={setShowRSIMA}
                                setShowTR={setShowTR}
                                setShowATR={setShowATR}
                                setShowNATR={setShowNATR}
                                setShowSuperT={setShowSuperT}
                                setShowBB={setShowBB}
                                setShowMfiMa={setShowMfiMa}
                                setShowIchimoku={setShowIchimoku}
                                setShowKST={setShowKST}
                                setShowMOM={setShowMOM}
                                setisStdDeviation={setisStdDeviation}
                                setShowUltimateOscillator={
                                  setShowUltimateOscillator
                                }
                                setShowNthCandle={setShowNthCandle}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit="true"
                                setMedianPrice={setMedianPrice}
                                setShowChande={setShowChande}
                                setShowATRTrailModal={setShowATRTrailModal}
                                setShowChoppiness={setShowChoppiness}
                                setCentralRange={setCentralRange}
                                setCamrillaPivot={setCamrillaPivot}
                                setShowPivotPoint={setShowPivotPoint}
                                setShowElderRay={setShowElderRay}
                                setBollingUML={setBollingUML}
                                setPowerMoving={setPowerMoving}
                                setShowDI={setShowDI}
                                setShowTsi={setShowTsi}
                                setShowTII={setShowTII}
                                setShowOpenInterest={setShowOpenInterest}
                                setShowNVI={setShowNVI}
                                setShowGreeks={setShowGreeks}
                                setShowDetrended={setShowDetrended}
                                setShowKlinger={setShowKlinger}
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Abs" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                setShowClose={setShowClose}
                                setShowOpen={setShowOpen}
                                setShowHighPrice={setShowHighPrice}
                                setShowLowPrice={setShowLowPrice}
                                setShowRSI={setShowRSI}
                                setShowUlcer={setShowUlcer}
                                setShowDEMA={setShowDEMA}
                                setShowTEMA={setShowTEMA}
                                setShowTMA={setShowTMA}
                                setShowVWMA={setShowVWMA}
                                setShowSMA={setShowSMA}
                                setShowEMA={setShowEMA}
                                setShowWMA={setShowWMA}
                                setShowNumber={setShowNumber}
                                setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                                setShowWilliams={setShowWilliams}
                                setShowMad={setShowMad}
                                setShowMacd={setShowMacd}
                                setShowObv={setShowObv}
                                setShowAroonDown={setShowAroonDown}
                                setShowAroonUp={setShowAroonUp}
                                setShowOscilator={setShowOscilator}
                                setShowVWAP={setShowVWAP}
                                setShowpsar={setShowpsar}
                                setShowVortexI={setShowVortexI}
                                setShowVolume={setShowVolume}
                                setShowDonchian={setShowDonchian}
                                setShowStochastic={setShowStochastic}
                                setShowChaikin={setShowChaikin}
                                setShowMacdSignal={setShowMacdSignal}
                                setShowCCI={setShowCCI}
                                setShowRoc={setShowRoc}
                                setShowRSIMA={setShowRSIMA}
                                setShowTR={setShowTR}
                                setShowATR={setShowATR}
                                setShowNATR={setShowNATR}
                                setShowSuperT={setShowSuperT}
                                setShowBB={setShowBB}
                                setShowMfiMa={setShowMfiMa}
                                setShowIchimoku={setShowIchimoku}
                                setShowKST={setShowKST}
                                setShowMOM={setShowMOM}
                                setisStdDeviation={setisStdDeviation}
                                setShowUltimateOscillator={
                                  setShowUltimateOscillator
                                }
                                setShowNthCandle={setShowNthCandle}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit="true"
                                setMedianPrice={setMedianPrice}
                                setShowChande={setShowChande}
                                setShowATRTrailModal={setShowATRTrailModal}
                                setShowChoppiness={setShowChoppiness}
                                setCentralRange={setCentralRange}
                                setCamrillaPivot={setCamrillaPivot}
                                setShowPivotPoint={setShowPivotPoint}
                                setShowElderRay={setShowElderRay}
                                setBollingUML={setBollingUML}
                                setPowerMoving={setPowerMoving}
                                setShowDI={setShowDI}
                                setShowTsi={setShowTsi}
                                setShowTII={setShowTII}
                                setShowOpenInterest={setShowOpenInterest}
                                setShowNVI={setShowNVI}
                                setShowGreeks={setShowGreeks}
                                setShowDetrended={setShowDetrended}
                                setShowKlinger={setShowKlinger}
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Symbol" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <SearchableDropdown
                          instanceName={instanceName}
                          formData={formData}
                          onFormChange={onFormChange}
                          stocks={allStocks}
                        />
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                setShowClose={setShowClose}
                                setShowOpen={setShowOpen}
                                setShowHighPrice={setShowHighPrice}
                                setShowLowPrice={setShowLowPrice}
                                setShowRSI={setShowRSI}
                                setShowUlcer={setShowUlcer}
                                setShowDEMA={setShowDEMA}
                                setShowTEMA={setShowTEMA}
                                setShowTMA={setShowTMA}
                                setShowVWMA={setShowVWMA}
                                setShowSMA={setShowSMA}
                                setShowEMA={setShowEMA}
                                setShowWMA={setShowWMA}
                                setShowNumber={setShowNumber}
                                setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                                setShowWilliams={setShowWilliams}
                                setShowMad={setShowMad}
                                setShowMacd={setShowMacd}
                                setShowObv={setShowObv}
                                setShowAroonDown={setShowAroonDown}
                                setShowAroonUp={setShowAroonUp}
                                setShowOscilator={setShowOscilator}
                                setShowVWAP={setShowVWAP}
                                setShowpsar={setShowpsar}
                                setShowVortexI={setShowVortexI}
                                setShowVolume={setShowVolume}
                                setShowDonchian={setShowDonchian}
                                setShowStochastic={setShowStochastic}
                                setShowChaikin={setShowChaikin}
                                setShowMacdSignal={setShowMacdSignal}
                                setShowCCI={setShowCCI}
                                setShowRoc={setShowRoc}
                                setShowRSIMA={setShowRSIMA}
                                setShowTR={setShowTR}
                                setShowATR={setShowATR}
                                setShowNATR={setShowNATR}
                                setShowSuperT={setShowSuperT}
                                setShowBB={setShowBB}
                                setShowMfiMa={setShowMfiMa}
                                setShowIchimoku={setShowIchimoku}
                                setShowKST={setShowKST}
                                setShowMOM={setShowMOM}
                                setisStdDeviation={setisStdDeviation}
                                setShowUltimateOscillator={
                                  setShowUltimateOscillator
                                }
                                setShowNthCandle={setShowNthCandle}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit="true"
                                setMedianPrice={setMedianPrice}
                                setShowChande={setShowChande}
                                setShowATRTrailModal={setShowATRTrailModal}
                                setShowChoppiness={setShowChoppiness}
                                setCentralRange={setCentralRange}
                                setCamrillaPivot={setCamrillaPivot}
                                setShowPivotPoint={setShowPivotPoint}
                                setShowElderRay={setShowElderRay}
                                setBollingUML={setBollingUML}
                                setPowerMoving={setPowerMoving}
                                setShowDI={setShowDI}
                                setShowTsi={setShowTsi}
                                setShowTII={setShowTII}
                                setShowOpenInterest={setShowOpenInterest}
                                setShowNVI={setShowNVI}
                                setShowGreeks={setShowGreeks}
                                setShowDetrended={setShowDetrended}
                                setShowKlinger={setShowKlinger}
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Period min" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <button
                          onClick={() => {
                            setPeriodMinModal(true);
                            formData.selectedOption2 = instanceName;
                            formData.clickedFunction = instanceName;
                          }}
                          style={{
                            ...addButton,
                            background: "white",
                          }}
                        >
                          {formData.exit_indicators[instanceName].period}
                        </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                setShowClose={setShowClose}
                                setShowOpen={setShowOpen}
                                setShowHighPrice={setShowHighPrice}
                                setShowLowPrice={setShowLowPrice}
                                setShowRSI={setShowRSI}
                                setShowUlcer={setShowUlcer}
                                setShowDEMA={setShowDEMA}
                                setShowTEMA={setShowTEMA}
                                setShowTMA={setShowTMA}
                                setShowVWMA={setShowVWMA}
                                setShowSMA={setShowSMA}
                                setShowEMA={setShowEMA}
                                setShowWMA={setShowWMA}
                                setShowNumber={setShowNumber}
                                setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                                setShowWilliams={setShowWilliams}
                                setShowMad={setShowMad}
                                setShowMacd={setShowMacd}
                                setShowObv={setShowObv}
                                setShowAroonDown={setShowAroonDown}
                                setShowAroonUp={setShowAroonUp}
                                setShowOscilator={setShowOscilator}
                                setShowVWAP={setShowVWAP}
                                setShowpsar={setShowpsar}
                                setShowVortexI={setShowVortexI}
                                setShowVolume={setShowVolume}
                                setShowDonchian={setShowDonchian}
                                setShowStochastic={setShowStochastic}
                                setShowChaikin={setShowChaikin}
                                setShowMacdSignal={setShowMacdSignal}
                                setShowCCI={setShowCCI}
                                setShowRoc={setShowRoc}
                                setShowRSIMA={setShowRSIMA}
                                setShowTR={setShowTR}
                                setShowATR={setShowATR}
                                setShowNATR={setShowNATR}
                                setShowSuperT={setShowSuperT}
                                setShowBB={setShowBB}
                                setShowMfiMa={setShowMfiMa}
                                setShowIchimoku={setShowIchimoku}
                                setShowKST={setShowKST}
                                setShowMOM={setShowMOM}
                                setisStdDeviation={setisStdDeviation}
                                setShowUltimateOscillator={
                                  setShowUltimateOscillator
                                }
                                setShowNthCandle={setShowNthCandle}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit="true"
                                setMedianPrice={setMedianPrice}
                                setShowChande={setShowChande}
                                setShowATRTrailModal={setShowATRTrailModal}
                                setShowChoppiness={setShowChoppiness}
                                setCentralRange={setCentralRange}
                                setCamrillaPivot={setCamrillaPivot}
                                setShowPivotPoint={setShowPivotPoint}
                                setShowElderRay={setShowElderRay}
                                setBollingUML={setBollingUML}
                                setPowerMoving={setPowerMoving}
                                setShowDI={setShowDI}
                                setShowTsi={setShowTsi}
                                setShowTII={setShowTII}
                                setShowOpenInterest={setShowOpenInterest}
                                setShowNVI={setShowNVI}
                                setShowGreeks={setShowGreeks}
                                setShowDetrended={setShowDetrended}
                                setShowKlinger={setShowKlinger}
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Period max" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <button
                          onClick={() => {
                            setPeriodMaxModal(true);
                            formData.selectedOption2 = instanceName;
                            formData.clickedFunction = instanceName;
                          }}
                          style={{
                            ...addButton,
                            background: "white",
                          }}
                        >
                          {formData.exit_indicators[instanceName].period}
                        </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                setShowClose={setShowClose}
                                setShowOpen={setShowOpen}
                                setShowHighPrice={setShowHighPrice}
                                setShowLowPrice={setShowLowPrice}
                                setShowRSI={setShowRSI}
                                setShowUlcer={setShowUlcer}
                                setShowDEMA={setShowDEMA}
                                setShowTEMA={setShowTEMA}
                                setShowTMA={setShowTMA}
                                setShowVWMA={setShowVWMA}
                                setShowSMA={setShowSMA}
                                setShowEMA={setShowEMA}
                                setShowWMA={setShowWMA}
                                setShowNumber={setShowNumber}
                                setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                                setShowWilliams={setShowWilliams}
                                setShowMad={setShowMad}
                                setShowMacd={setShowMacd}
                                setShowObv={setShowObv}
                                setShowAroonDown={setShowAroonDown}
                                setShowAroonUp={setShowAroonUp}
                                setShowOscilator={setShowOscilator}
                                setShowVWAP={setShowVWAP}
                                setShowpsar={setShowpsar}
                                setShowVortexI={setShowVortexI}
                                setShowVolume={setShowVolume}
                                setShowDonchian={setShowDonchian}
                                setShowStochastic={setShowStochastic}
                                setShowChaikin={setShowChaikin}
                                setShowMacdSignal={setShowMacdSignal}
                                setShowCCI={setShowCCI}
                                setShowRoc={setShowRoc}
                                setShowRSIMA={setShowRSIMA}
                                setShowTR={setShowTR}
                                setShowATR={setShowATR}
                                setShowNATR={setShowNATR}
                                setShowSuperT={setShowSuperT}
                                setShowBB={setShowBB}
                                setShowMfiMa={setShowMfiMa}
                                setShowIchimoku={setShowIchimoku}
                                setShowKST={setShowKST}
                                setShowMOM={setShowMOM}
                                setisStdDeviation={setisStdDeviation}
                                setShowUltimateOscillator={
                                  setShowUltimateOscillator
                                }
                                setShowNthCandle={setShowNthCandle}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance="body"
                                isExit="true"
                                setMedianPrice={setMedianPrice}
                                setShowChande={setShowChande}
                                setShowATRTrailModal={setShowATRTrailModal}
                                setShowChoppiness={setShowChoppiness}
                                setCentralRange={setCentralRange}
                                setCamrillaPivot={setCamrillaPivot}
                                setShowPivotPoint={setShowPivotPoint}
                                setShowElderRay={setShowElderRay}
                                setBollingUML={setBollingUML}
                                setPowerMoving={setPowerMoving}
                                setShowDI={setShowDI}
                                setShowTsi={setShowTsi}
                                setShowTII={setShowTII}
                                setShowOpenInterest={setShowOpenInterest}
                                setShowNVI={setShowNVI}
                                setShowGreeks={setShowGreeks}
                                setShowDetrended={setShowDetrended}
                                setShowKlinger={setShowKlinger}
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;&nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            // Add any logic for removing the item from the state
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark fnEditIcon`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}
              {instanceData?.name !== "Max" &&
                instanceData?.name !== "Min" &&
                instanceData?.name !== "Floor" &&
                instanceData?.name !== "Ceil" &&
                instanceData?.name !== "Abs" &&
                instanceData?.name !== "Symbol" &&
                instanceData?.name !== "Period min" &&
                instanceData?.name !== "Period max" && (
                  <InstanceDisplay
                    instanceData={instanceData}
                    formData={formData}
                    instanceName={instanceName}
                    index={index}
                    setShowClose={setShowClose}
                    setShowOpen={setShowOpen}
                    setShowHighPrice={setShowHighPrice}
                    setShowLowPrice={setShowLowPrice}
                    setShowRSI={setShowRSI}
                    setShowUlcer={setShowUlcer}
                    setShowDEMA={setShowDEMA}
                    setShowTEMA={setShowTEMA}
                    setShowTMA={setShowTMA}
                    setShowVWMA={setShowVWMA}
                    setShowSMA={setShowSMA}
                    setShowEMA={setShowEMA}
                    setShowWMA={setShowWMA}
                    setShowNumber={setShowNumber}
                    setShowMoneyFlowIndex={setShowMoneyFlowIndex}
                    setShowWilliams={setShowWilliams}
                    setShowMad={setShowMad}
                    setShowMacd={setShowMacd}
                    setShowObv={setShowObv}
                    setShowAroonDown={setShowAroonDown}
                    setShowAroonUp={setShowAroonUp}
                    setShowOscilator={setShowOscilator}
                    setShowVWAP={setShowVWAP}
                    setShowpsar={setShowpsar}
                    setShowVortexI={setShowVortexI}
                    setShowVolume={setShowVolume}
                    setShowDonchian={setShowDonchian}
                    setShowStochastic={setShowStochastic}
                    setShowChaikin={setShowChaikin}
                    setShowMacdSignal={setShowMacdSignal}
                    setShowCCI={setShowCCI}
                    setShowRoc={setShowRoc}
                    setShowRSIMA={setShowRSIMA}
                    setShowTR={setShowTR}
                    setShowATR={setShowATR}
                    setShowNATR={setShowNATR}
                    setShowSuperT={setShowSuperT}
                    setShowBB={setShowBB}
                    setShowMfiMa={setShowMfiMa}
                    setShowIchimoku={setShowIchimoku}
                    setShowKST={setShowKST}
                    setShowMOM={setShowMOM}
                    setisStdDeviation={setisStdDeviation}
                    setShowUltimateOscillator={setShowUltimateOscillator}
                    setShowNthCandle={setShowNthCandle}
                    isShowingAddButton={isShowingAddButton}
                    handleAdd={handleAdd}
                    handleEdit={handleEdit}
                    handleRemoveButtonClick={handleRemoveButtonClick}
                    setShowCandleModal={setShowCandleModal}
                    getIndex={getIndex}
                    buttonStyle={buttonStyle}
                    addButton={addButton}
                    handleAddButtonClick={handleAddButtonClick}
                    setShowDropdown={setShowDropdown}
                    setInnerAdd={setInnerAdd}
                    setOuterAdd={setOuterAdd}
                    isMathFunction=""
                    instance=""
                    isExit="true"
                    setMedianPrice={setMedianPrice}
                    setShowChande={setShowChande}
                    setShowATRTrailModal={setShowATRTrailModal}
                    setShowChoppiness={setShowChoppiness}
                    setCentralRange={setCentralRange}
                    setCamrillaPivot={setCamrillaPivot}
                    setShowPivotPoint={setShowPivotPoint}
                    setShowElderRay={setShowElderRay}
                    setBollingUML={setBollingUML}
                    setPowerMoving={setPowerMoving}
                    setShowDI={setShowDI}
                    setShowTsi={setShowTsi}
                    setShowTII={setShowTII}
                    setShowOpenInterest={setShowOpenInterest}
                    setShowNVI={setShowNVI}
                    setShowGreeks={setShowGreeks}
                    setShowDetrended={setShowDetrended}
                    setShowKlinger={setShowKlinger}
                    setShowModal={setShowModal}
                  />
                )}
            </span>
          );
        }
      )}
    </>
  );
};

export default ExitFormulaTab;
