import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const NumberInput = ({
  name,
  value,
  onChange,
  placeholder,
  readOnly,
  disabled,
  label,
}) => {
  const [isOffset, setIsOffset] = useState(false);

  const handleKeyPress = (event) => {
    if (label !== "Multiply" && label !== "Standard Deviations") {
      const charCode = event.charCode;
      // Only allow numeric characters
      if (
        (charCode < 48 || charCode > 57) && // not a number
        charCode !== 43 && // not a '+'
        charCode !== 45 // not a '-'
      ) {
        event.preventDefault();
      }
    }
    if (label === "Offset") {
      const inputValue = event.target.value;
      const keyPressed = String.fromCharCode(event.charCode);
      const newValue = inputValue + keyPressed;
      if (newValue && !isNaN(newValue)) {
        if (parseFloat(newValue) > 0) {
          event.preventDefault(); // Prevent the input
          const negativeValue = `-${Math.abs(parseFloat(newValue))}`; // Convert to negative
          console.log("keyPressed", negativeValue);
          setIsOffset(true);
          onChange({ target: { value: negativeValue, name: name } }); // Update value with negative number
        } 
      }
    }
  };
  useEffect(() => {
      setTimeout(() => {
        setIsOffset(false);
      }, 1500);
      }, [isOffset]);
  return (
    <>
      <Form.Control
        type="number"
        name={name}
        value={value}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
      />
      {isOffset && (
        <p className="mt-1 text-danger">Offset can only be 0 or less</p>
      )}
    </>
  );
};

export default NumberInput;
