import React, { useEffect } from "react";
import "../../styles/CustomAlert.css";

const CustomAlert = ({
  message,
  type,
  setAlertMessage,
  onConfirm,
  onCancel,
  isConfirmation,
}) => {
  console.log("message", message, type);
  const onClose = () => {
    setAlertMessage(false);
  };

  useEffect(() => {
    if (!isConfirmation) {
      const timer = setTimeout(onClose, 2000);
      return () => clearTimeout(timer);
    }
  }, [isConfirmation]);
  return (
    <>
      <div className={`custom-alert`}>
        <div className="innerDiv">
          <div className={`alert ${type}`} role="alert">
            <span className="alert-close" onClick={onClose}>
              &times;
            </span>
            <div style={{ display: "flex" }}>
              {type === "alert-success" ? (
                <i className="fa-solid fa-circle-check alert-icon"></i>
              ) : (
                <i class="fa-solid fa-circle-info alert-icon"></i>
              )}
              {message?.split(".").map((part, index) => (
                <p key={index}>{part.trim()}</p>
              ))}
            </div>
            {isConfirmation && (
              <div className="confirmation-buttons m-2">
                <button className="btn btn-danger m-2" onClick={onConfirm}>
                  Yes
                </button>
                <button className="btn btn-secondary" onClick={onCancel}>
                  No
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomAlert;
