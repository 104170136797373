import React, { useState, useRef, useEffect } from "react";
import { useMenuContextScanner } from "./ScannerCreateComponent";
import { Button, Card, Form, ListGroup } from "react-bootstrap";
import { getfunctionTypeWisefunction } from "../../api/strategyapi";
import FormulaTab from "../strategy/FormulaTab";
import CompartorModal from "../../components/strategy/ComparatorModal";
import CandleInterval from "../modal/CandleInterval";
import PeriodMaxModal from "../modal/PeriodMaxModal";
import PeriodMinModal from "../modal/PeriodMinModal";
import replaceNewData from "../../Utils/replaceNewData";
import getCandleId from "../../Utils/CommonFunction/getCandleId";
import updateHandleModalData from "../../Utils/CommonFunction/handleModalData";
import GetFunctionofHandle from "../../Utils/CommonFunction/GetFunctionofHandle";
import handleRemoveCondition from "../../Utils/CommonFunction/handleRemoveCondition";
import FunctionList from "../componentCommon/FunctionList";
import mergeArrays from "../../Utils/CommonFunction/mergeArrays";
import IndicatorModal from "../componentCommon/IndicatorModal";
import modalConfigs from "../../Utils/CommonFunction/modalConfigArray";


let eflag = 0;

const ScannerConditionComponent = ({
  formData,
  onFormChange,
  allStocks,
  getText,
}) => {
  const { menu, setMenu, storeAllStocks } = useMenuContextScanner();
  const [flag, setFlag] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [functionType, setFunctionType] = useState([]);
  const [showSMA, setIsShow] = useState(false);
  const [showSMA3, setIsShow3] = useState(false);
  const [conditionTab, setConditionTab] = useState(false);
  const [mode, setMode] = useState("");
  const [remove, setRemove] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(false);
  const [selectedItem, setSelectedItem] = useState("1");
  const [currentId, setCurrentId] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [innerAdd, setInnerAdd] = useState("");
  const [outerAdd, setOuterAdd] = useState("");
  //Modal state variables
  const [showPeriodMaxModal, setPeriodMaxModal] = useState(false);
  const [showPeriodMinModal, setPeriodMinModal] = useState(false);
  const [showCandleModal, setShowCandleModal] = useState(false);
  const [isSelectMax, setIsSelectMax] = useState(false);
  const [isSelectMin, setIsSelectMin] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [instance, setInstance] = useState();
  const [removedInsideMath, setRemovedInsideMath] = useState(false);
  const isIncludeMathFunction = useRef(null);
  const [mathIndexRemove, setMathIndexRemove] = useState(-1);
  const [showModal, setShowModal] = useState(null);

  const selectedItemStyle = {
    backgroundColor: "#00aeee", // Customize the background color as needed
    fontWeight: "bold", // Add additional styles if needed
    border: "1px solid blue",
  };
  console.log("SCANNERDATA", formData, onFormChange);
  const [showInstead, setAddInstead] = useState({
    function_id: 0,
    function_type_id: 0,
    isOpen: false,
    index: -1,
  });
  const handleEdit = (instanceData, index) => {
    console.log("instanceData", instanceData, index);
    setAddInstead({
      function_id: instanceData?.function_id,
      function_type_id: instanceData?.function_type_id,
      isOpen: true,
      index: index,
    });

    // call dropdown function particular type id //
    handleClickAll(instanceData?.function_type_id?.toString());
  };

  const handleGetFunction1 = (item) => {
    console.log("click", item, formData);
    setSearchQuery("");
    setShowDropdown(false);
    let replaceData = replaceNewData(formData, item, showInstead);
    formData = replaceData;
    // console.log("REPLACE", formData )
    if (replaceData) {
      setAddInstead({
        function_id: 0,
        function_type_id: 0,
        isOpen: false,
        index: -1,
      });
    }
  };
  useEffect(() => {
    // console.log("33first",formData);
  }, [formData]);
  const handleSetInstead = () => {
    setAddInstead({
      function_id: 0,
      function_type_id: 0,
      isToggle: false,
    });
  };
  const handleRemiceInstead = () => {
    setAddInstead((prev) => ({ ...prev, isOpen: false }));
  };
  const handleGetFunction = (item) => {
    console.log("GETFUN", item);
    GetFunctionofHandle(
      item,
      formData,
      onFormChange,
      setInnerAdd,
      setOuterAdd,
      handleRemiceInstead,
      setCurrentId,
      setConditionTab,
      handleClickAll,
      setSelectedOption1,
      setMode,
      setIsSelectMax,
      setIsSelectMin,
      setSearchQuery,
      setShowDropdown,
      setRemovedInsideMath,
      removedInsideMath,
      mathIndexRemove,
      flag,
      setFlag,
      outerAdd,
      isIncludeMathFunction,
      innerAdd,
      instance
    );
  };

  const handleCandleModal = (data) => {
    console.log("IntervalData", data);
    if (data?.candleIntervalId && formData)
      formData.indicators[formData.clickedFunction].candleIntervalId =
        data?.candleIntervalId;

    formData.functionsBox777[itemIndex].candleIntervalId =
      data.candleIntervalId;

    if (formData?.indicators[formData?.clickedFunction]?.body)
      formData.indicators[formData?.clickedFunction].body[
        itemIndex
      ].candleIntervalId = data?.candleIntervalId;

    console.log("INTERVAL", formData);
    onFormChange(formData);
    handleNextEnteryBtn();
  };

  useEffect(() => {
    if (eflag === 1) {
      updateCandleIntervalId();
      updateCandleInterval();
    }
    handleClickAll("1");
  }, [formData?.candleIntervalId]);
  useEffect(() => {
    handleNextEnteryBtn();
  }, [getText]);
  const updateCandleInterval = () => {
    // Create a copy of the original data
    let updatedData = [];
    if (Array.isArray(formData?.functionsBox777)) {
      updatedData = [...formData?.functionsBox777];
    } else {
      console.log("undefined formData.functionsBox777");
    }
    let candleId = getCandleId(formData);

    updatedData?.forEach((item) => {
      item.candleIntervalId = candleId;
    });
    if (formData) formData.functionsBox777 = updatedData;
    onFormChange(formData);
  };

  const updateCandleIntervalId = () => {
    // const updatedIndicators;

    // if(formData?.indicators)
    const updatedIndicators = { ...formData?.indicators };

    let candleId = getCandleId(formData);
    for (const key in updatedIndicators) {
      if (updatedIndicators?.hasOwnProperty(key)) {
        const indicator = updatedIndicators[key];
        // Check if candleIntervalId property exists
        if (indicator?.hasOwnProperty("candleIntervalId")) {
          // Update candleIntervalId property value

          indicator.candleIntervalId = candleId; // Replace 'newCandleIntervalValue' with your desired value
        }
        if (indicator?.body) {
          if (Array.isArray(indicator?.body)) {
            indicator.body.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body1) {
          if (Array.isArray(indicator?.body1)) {
            indicator.body1.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
        if (indicator?.body2) {
          if (Array.isArray(indicator?.body2)) {
            indicator.body2.forEach((item) => {
              if (item.hasOwnProperty("candleIntervalId")) {
                item.candleIntervalId = candleId;
              }
            });
          }
        }
      }
    }
    // if(indicators)
    if (formData) {
      formData.indicators = updatedIndicators;
    }

    onFormChange(formData);
  };

  const handleClickAll = async (type) => {
    setSelectedItem(type);
    const data = await getfunctionTypeWisefunction({ type_id: type });

    setFunctionType(data);
  };

  useEffect(() => {
    handleClickAll("1");
  }, []);

  useEffect(() => {
    console.log("storeAllStocks>>", storeAllStocks);
  }, [storeAllStocks]);

  useEffect(() => {
    setTimeout(() => {
      eflag = 1;
    }, 2000);
  }, []); // This effect runs once when the component mounts

  const getEntryText = async () => {
    // Get the element by its ID
    const divElement = document.getElementById("strategyBox");

    // Check if the div element exists
    if (divElement) {
      // Get the existing text content of the div
      let existingContent = divElement.innerText;

      // Replace {input1Placeholder} with formData?.input1 value
      existingContent = existingContent.replace(
        "{input1Placeholder}",
        formData?.input1
      );
      let text1 = handleReplacement(existingContent);

      if (formData) {
        formData.functionText1 = existingContent;
      }
      onFormChange(formData);
      // setGotText(!gotText);

      // Do something with the modified text content
    }
  };

  const toggleDropdown = () => {
    setRemove(false);
    setShowDropdown(true);
  };

  const handleModalData = (data) => {
    updateHandleModalData(
      data,
      formData,
      onFormChange,
      getEntryText,
      itemIndex,
      setIsShow,
      instance
    );
  };

  const handleSMAComparator = (data) => {
    setIsShow3(false);
    if (formData?.ctype === "c1") {
      onFormChange({
        ...formData,
        comparator: data?.field,
      });
    }
    if (formData?.ctype === "c2") {
      onFormChange({
        ...formData,
        comparator2: data?.comparator2,
      });
    }
    if (formData?.ctype === "c3") {
      onFormChange({
        ...formData,
        comparator3: data?.comparator3,
      });
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    toggleDropdown();
  };

  const handleReplacement = (text) => {
    console.log("checkText-", text);
    let modifiedText = text?.replace(/lower than or equal to/gi, "<=");
    modifiedText = modifiedText?.replace(/higher than or equal to/gi, ">=");
    modifiedText = modifiedText?.replace(/lower than/gi, "<");
    modifiedText = modifiedText?.replace(/higher than/gi, ">");
    modifiedText = modifiedText?.replace(/> equal to/gi, ">=");
    modifiedText = modifiedText?.replace(/< equal to/gi, "<=");
    modifiedText = modifiedText?.replace(/and/gi, "&&");
    modifiedText = modifiedText?.replace(/or/gi, "||");
    modifiedText = modifiedText?.replace(/equal to/gi, "===");
    modifiedText = modifiedText?.replace(/crosses above/gi, ">");
    modifiedText = modifiedText?.replace(/crosses below/gi, ">");

    // Add more replacements as needed

    return modifiedText;
  };

  const handleAddButtonClick = (instanceName, bodyInstance) => {
    if (formData) {
      formData.selectedOption3 = instanceName;
    }

    setInstance(bodyInstance);
  };

  const handleRemoveButtonClick = (
    instanceToRemove,
    indexToRemove,
    mInstance
  ) => {
    handleRemoveCondition(
      formData,
      onFormChange,
      instanceToRemove,
      indexToRemove,
      mInstance,
      setMathIndexRemove,
      setRemovedInsideMath,
      isIncludeMathFunction
    );
  };

  const handleButtonClick = (index) => {};

  const handleAdd = (instanceData, index) => {
    toggleDropdown();
  };

  const handleNextEnteryBtn = () => {
    getEntryText();
    let text1 = handleReplacement(formData?.functionText1);
    mergeArrays(
      formData?.functionsBox777,
      text1
        ?.split("\n")
        .map((line) => line.trim())
        .filter(
          (line) =>
            line !== "" &&
            !line?.startsWith("1minute") &&
            !line?.startsWith("3minute") &&
            !line?.startsWith("5minute") &&
            !line?.startsWith("10minute") &&
            !line?.startsWith("15minute") &&
            !line?.startsWith("30minute") &&
            !line?.startsWith("60minute") &&
            !line?.startsWith("1day") &&
            !line?.startsWith("week") &&
            !line?.startsWith("month") &&
            !line?.startsWith("2h") &&
            !line?.startsWith("3h") &&
            !line?.startsWith("4h")
        ),
      onFormChange,
      formData,
      isSelectMax,
      isSelectMin,
      isIncludeMathFunction
    );

    // setMenu("review");
  };

  const getIndex = (index, instance) => {
    setItemIndex(index);
    setInstance(instance);
  };
  const RemoveFormula = () => {
    let ans = window.confirm("Are you sure, you want to remove formula?");
    if (ans === true) {
      setFlag(0);
      setRemove(true);
      onFormChange((prevState) => ({
        ...prevState,
        functions: [],
        functionsBox: [],
        functionsBox2: [],
        functionsBox777: [],
        functionsMainBox: [],
        indicators: {},
        mode: "",
        selectedOption1: "",
        order: [],
        isIncludeMathFunction: false,
      }));
      handleSetInstead();
      isIncludeMathFunction.current = false;
    }
  };
    const closeModal = () => {
      setShowModal(null);
    };

  return (
    <div className="row" id="scanner-condition-tab">
      <div className=" mt-5 pb-2 d-flex align-items-center justify-content-between">
        <div>
          <h6 className="fw-bolder">Entry Condition</h6>
        </div>
        <div></div>
      </div>
      <div className="input-group mb-3" id="tab-function-type-dropdown">
        <div className="d-flex row" style={{ minWidth: "300px" }}>
          {/* Input box */}
          <div
            // ref={conditionDropdownRef}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Form.Control
              type="text"
              placeholder="What would you like to add?"
              className="strategy-entry-search"
              onClick={(event) => {
                event.stopPropagation();
                toggleDropdown();
              }}
              value={searchQuery}
              onChange={handleSearch}
            />
            <span
              className="close-icon"
              onClick={() => {
                setSearchQuery("");
                setShowDropdown(false);
              }}
              style={{ cursor: "pointer", marginLeft: "-25px" }}
            >
              X
            </span>
          </div>
          {/* Dropdown menu */}
          {showDropdown && (
            <FunctionList
              showInstead={showInstead}
              formData={formData}
              isSelectMax={isSelectMax}
              removedInsideMath={removedInsideMath}
              selectedItem={selectedItem}
              selectedItemStyle={selectedItemStyle}
              handleClickAll={handleClickAll}
              handleGetFunction1={handleGetFunction1}
              handleGetFunction={handleGetFunction}
              getEntryText={getEntryText}
              searchQuery={searchQuery}
              functionType={functionType}
              remove={remove}
              isIncludeMathFunction={isIncludeMathFunction}
              onFormChange={onFormChange}
            />
          )}
        </div>
      </div>
      {formData?.conditionTab ? (
        <div className="row" id="strategy-entry-condition-card">
          <div className="col-lg-12 p-0">
            <Card>
              <Card.Body>
                <div className="row">
                  <div
                    className="col-lg-12 p-3"
                    id="strategyBox"
                    style={{
                      display: "contents",
                    }}
                  >
                    <FormulaTab
                      formData={formData}
                      handleRemoveButtonClick={handleRemoveButtonClick}
                      onFormChange={onFormChange}
                      handleButtonClick={handleButtonClick}
                      setShowDropdown={setShowDropdown}
                      setInnerAdd={setInnerAdd}
                      setOuterAdd={setOuterAdd}
                      handleAddButtonClick={handleAddButtonClick}
                      outerAdd={outerAdd}
                      getIndex={getIndex}
                      allStocks={storeAllStocks}
                      setAddInstead={setAddInstead}
                      showInstead={showInstead}
                      handleEdit={handleEdit}
                      handleAdd={handleAdd}
                      setPeriodMaxModal={setPeriodMaxModal}
                      setPeriodMinModal={setPeriodMinModal}
                      setShowModal={setShowModal}
                      setShowCandleModal={setShowCandleModal}
                    />
                    &nbsp; &nbsp;
                    {formData.isIncludeMathFunction && (
                      <div
                        onClick={() => {
                          setShowDropdown(true);
                          setOuterAdd(true);
                        }}
                        className="pluseIcon"
                      >
                        <i className="fa-solid fa-plus"></i>
                      </div>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div
              className="row"
              style={{ marginTop: "12px", marginBottom: "16px" }}
            >
              <div className="col-lg-12 d-flex flex-row-reverse">
                <span
                  className="text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => RemoveFormula()}
                >
                  Remove
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="box my-3">
          <div className="text-center">
            <div className="filecircle mx-auto">
              <i className="fa-regular fa-file"></i>
            </div>
            <h5 className="fw-bolder text-dark pt-3 pb-1">Add a condition</h5>
            <p className="para">
              Add an entity condition by searching <br></br>for indicators and
              comparators
            </p>
          </div>
        </div>
      )}
      <CompartorModal
        showSMA={showSMA3}
        setIsShow={setIsShow3}
        handleSMAClickedPage={handleSMAComparator}
        type={formData?.ctype}
        typeName={formData?.selectedOption2}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      {modalConfigs?.map((name, index) => {
        console.log(
          "RENDERING",
          `Rendering ${name} with setIsShow:`,
          setIsShow
        );
        return (
          <IndicatorModal
            key={index}
            show={name === showModal}
            setIsShow={closeModal}
            handleModalData={handleModalData}
            mainData={formData}
            clickedFunction={formData?.clickedFunction}
            index={itemIndex}
            instance={instance}
            name={name}
          />
        );
      })}
      <CandleInterval
        show={showCandleModal}
        setIsShow={setShowCandleModal}
        handleModalData={handleCandleModal}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        instance={instance}
      />
      <PeriodMaxModal
        setIsShow={setPeriodMaxModal}
        showPeriodMaxModal={showPeriodMaxModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
      <PeriodMinModal
        setIsShow={setPeriodMinModal}
        showPeriodMinModal={showPeriodMinModal}
        handleModalData={handleModalData}
        mainData={formData}
        clickedFunction={formData?.clickedFunction}
        index={itemIndex}
        instance={instance}
      />
    </div>
  );
};

export default ScannerConditionComponent;
