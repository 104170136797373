import * as React from 'react';
import { GetSingleUser} from "../api/adminapi";
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import moment from "moment";
import { Button } from 'react-bootstrap';
import { useState ,useEffect} from 'react';
import axios from 'axios';
import { updateprofiledataofuser } from '../api/apiService';
import { API_BASE_URL } from '../config';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const SingleUser = () => {
    const { id } = useParams();
    console.log("PARAM",id);
    const [user,setUser]=useState();
    const [activeplan,setActiveplan]=useState([])
    const [planhistory,setPlanhistory]=useState([]);
    const [saletransactions,setSaletransactions]=useState([]);
    const [imageData, setImageData] = useState(null);
    const [formData,setFormdata]=useState({ user_name: '',
    birth_date: '',
    phone: '',
    email: ''})
    // const [profileImageData, setProfileImageData] = useState(null);
    const fetchsingleuser = async () => {
        const result =  await GetSingleUser(id);
        console.log("result@PP",result?.user,result.user.birth_date,moment(result.user.birth_date).format('DD-MM-YYYY'));
        setUser(result.user);
        // setFormdata(...formData,)
        setFormdata({...formData,
            user_name:result.user.user_name,
            phone:result.user.phone,
            birth_date:result.user.birth_date!= null ? moment(result.user.birth_date).format('YYYY-MM-DD'):"-",
            email:result.user.email
           })
        setImageData(result.user.profile_image);
        setActiveplan(result?.activePlan[0]);
        setPlanhistory(result?.planHistory[0])
        setSaletransactions(result?.saleTransactions[0]);
      }
      useEffect(()=>{
        fetchsingleuser();
      },[id])
      const [value, setValue] = React.useState(0);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

const handleblur=async(field)=>{
   const value=formData[field];
    // updateuserdata(field,value);
    console.log("000",user.id,field,value);
    const result=await updateprofiledataofuser(user.id,field,value);
    console.log("00",result);

}
      const handleImageUpload = async (p) => {
        console.log("$$$",id,p);
        try {
            const response = await axios.post(`${API_BASE_URL}/adminpanel/UpdateProfileImage`, {
                userId: id,
                imageData: p
            });
            if (response.data.success) {
                    console.log("@@",response);
            } else {
                
            }
        } catch (error) {
           console.log("Error",error)
        }
    };
   const updatedata=(e,field)=>{
    console.log("nn",e.target.value,field)
     setFormdata({...formData,[field]:e.target.value})
   }
 
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
    
        reader.onabort = () => console.log('File reading was aborted');
        reader.onerror = () => console.log('File reading has failed');
        reader.onloadstart = () => console.log('File reading has started');
        reader.onloadend = () => {
            if (reader.readyState === FileReader.DONE) { 
                setImageData(reader.result);
                console.log("Image data:", reader.result);
                handleImageUpload(reader.result);
            }
        };
    
        if (file) {
            reader.readAsDataURL(file);
            handleImageUpload(reader.result);
        }
    };
    
    return(
    <>
    <div class="page">
    <div class="page-content container-fluid">
        <div class="row">
        <div className="col-lg-3 noprint">
            <div className="card card-shadow text-center">
                <div className="card-block">
                    <label htmlFor="upload-input">
                        <div
                            className="profile-image-container"
                            style={{ position: "relative", display: "inline-block" }}
                        >
                            <img
                                id="member-profile-image"
                                style={{ width: "100%" }}
                                src={imageData ?imageData: "/images/avatar-placeholder.png"}
                                alt="Profile"
                            />
                            <div
                                className="edit-icon"
                                style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                    borderRadius: "50%",
                                    padding: "15px",
                                    display: "block",
                                }}
                                onClick={handleImageUpload}
                            >
                                <i className="fa fa-pencil"></i>
                            </div>
                        </div>
                        {/* <p onClick={handleImageUpload}>PPPPPP</p> */}
                    </label>
                    <input
                        type="file"
                        id="upload-input"
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                    />
                    <div className="text-right">
                        <a className=" delete-user" data-target="#delete-user" data-toggle="modal">
                            <i className="fa fa-trash"></i> Delete
                        </a>
                    </div>
                </div>
            </div>
        </div>

            <div class="col-lg-9">
                <div class="panel">
                <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab  icon={<i className="fa fa-user-circle font-26" aria-hidden="true"></i>} 
         {...a11yProps(0)} />
          <Tab icon={<i class="fa fa-tag font-26" aria-hidden="true"></i>}  {...a11yProps(1)} />
          <Tab icon={<i className="fa fa-tags font-26" aria-hidden="true"></i>}  {...a11yProps(2)} />
          <Tab icon={<i className="fa fa-list font-26" aria-hidden="true"></i>}  {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <div class="row">
            <div class="col-md-12">
              <h4>User Details</h4>
            </div>
       </div>
       <div class="row">
           <div class="form-group col-md-6">
               <label class="form-control-label" for="fname">User Name</label>
               <input type="text" class="form-control" id="username" 
               onChange={(e)=>updatedata(e,'user_name')} onBlur={()=>handleblur('user_name')} name="user_name" value={formData.user_name} />
           </div>
           <div class="form-group col-xl-6">
               <label class="form-control-label"> Date of Birth</label>
               <input type="date" class="form-control" id="birth_date" name="birth_date" onBlur={()=>handleblur('birth_date')} onChange={(e)=>updatedata(e,'birth_date')} 
               value={formData.birth_date}/>
           </div>
       </div>
      <div className="row pt-5">
          <div class="form-group col-md-6">
              <label class="form-control-label" for="fname">Phone</label>
              <input type="text" onChange={(e)=>updatedata(e,'phone')} onBlur={()=>handleblur('phone')} class="form-control edit-data" id="phone" name="phone" value={formData.phone}/>
          </div>
          <div class="form-group col-md-6">
              <label class="form-control-label" for="lname">Email</label>
              <input type="text" onChange={(e)=>updatedata(e,'email')} onBlur={()=>handleblur('email')} class="form-control edit-data" id="email" name="email" value={formData.email}/>
          </div>
      </div>
      <div className='text-end'>
      {/* <Button className="btn-color my-4" variant="primary" >Update</Button> */}
                                </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div class="tab-pane" id="user-subscription" role="tabpanel">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>Active Subscription Plan</h4>
                                    </div>
                                </div>
                               
                                    <div class="row">
                                        <div class="col-md-12">
                                            <table class="table table-hover table-bordered dataTable table-striped w-full mt-10" id="active-plan">
                                                <thead>
                                                    <tr>
                                                        <th>Plan</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>                    
                                                <tbody>
                                                    {activeplan ?( <tr>
                                                        <td>{activeplan?.plan_name}</td>
                                                        <td>{activeplan?.start_date}</td>
                                                        <td>{activeplan?.end_date} </td>
                                                        <td> <a href="#" id="checkDetails">view details</a> </td>
                                                    </tr>):( <tr class="text-center">
                                                        <td colspan="4"> No Active Subscription Plan</td>
                                                    </tr>)}  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <div class="row">
                                        <div class="col-md-12">
                                            <button class="btn btn-success float-right" id="add-subscription"><i class="fa fa-plus"></i> Add Subscription</button><br/><br/>
                                            <table class="table table-hover table-bordered dataTable table-striped w-full mt-10" id="active-plan">
                                                <thead>
                                                    <tr>
                                                        <th>Plan</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>                    
                                                <tbody>
                                                    <tr class="text-center">
                                                        <td colspan="4"> No Active Subscription Plan</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}
                            </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <div class="tab-pane" id="user-history" role="tabpanel">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>Subscription Plan History</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        {planhistory  ? ( <table class="table table-hover table-bordered dataTable table-striped w-full mt-10" id="plan-history">
                                                <thead>
                                                    <tr>
                                                        <th>Plan</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>                    
                                                <tbody>
                                                        <tr>
                                                            <td>{planhistory?.plan_name}</td>
                                                            <td>{planhistory?.start_date}</td>
                                                            <td>{planhistory?.end_date}</td>
                                                            <td>
                                                           {planhistory?.is_active===1?(  <span className="badge badge-success">Active</span>):(<span class="badge badge-danger">Expired</span>)}
                                                            </td>
                                                        </tr>
                                                 
                                                </tbody>
                                            </table>):( <table class="table table-hover table-bordered dataTable table-striped w-full mt-10" id="plan-history">
                                                <thead>
                                                    <tr>
                                                        <th>Plan</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                    </tr>
                                                </thead>                    
                                                <tbody>
                                                    <tr class="text-center">
                                                        <td colspan="3">No Records Found</td>
                                                    </tr>
                                                </tbody>
                                            </table>)}
                                    </div>
                                </div>
                            </div>
                           
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <div class="tab-pane" id="user-transactions" role="tabpanel">
                                <div class="row">
                                    <div class="col-md-12">
                                        <table class="table table-hover table-bordered dataTable table-striped w-full mt-10" id="user-payments-transactions">
                                            <thead>
                                                <tr>
                                                    <th>Sale Id</th>
                                                    <th>Plan</th>
                                                    <th>Amount</th>
                                                    <th>Transaction ID</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>                    
                                            <tbody>
                                                {saletransactions ?( <tr>
                                                            <td>{saletransactions?.sale_id}</td>
                                                            <td>{saletransactions?.plan_name}</td>
                                                            <td>{saletransactions?.paid_amount}</td>
                                                            <td>{saletransactions?.razorpay_transaction_id}</td>
                                                            <td>{saletransactions?.start_date}</td>
                                                        </tr>):( <tr class="text-center">
                                                        <td colspan="5">No Records Found</td>
                                                    </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
      </CustomTabPanel>
    </Box>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="user-image-capture" aria-hidden="false" aria-labelledby="user-image-capture" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-simple">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title">Capture Profile Picture</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-7">
                        <div id="camera-container" style={{marginTop: "-50px"}}></div>
                    </div>
                    <div class="col-md-5">
                        <div class="control-group file-upload" id="file-upload1">
                            <div class="image-box text-center">
                                <img src="/images/avatar-placeholder.png" alt="" />
                            </div>
                        </div>
                    </div>                
                    <div class="col-md-12 text-center">
                        <button class="btn btn-primary" id="capture">Capture</button>
                        <label> OR </label>
                        <span class="btn btn-warning btn-file">Select Image
                            <input type="file" class="fileinput" name="files" id="selectImg" data-filename-placement="inside" title="Upload Image"/>
                        </span>
                        <button class="btn btn-success" id="imgUpload" style={{display: "none"}}>Upload Image</button>
                    </div>
                </div>                
            </div>            
        </div>
    </div>
</div>
<div class="modal fade" id="delete-user" aria-hidden="false" aria-labelledby="delete-user" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-simple">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title">Delete User</h4>
            </div>
            <div class="modal-body">
                <form action="/app/users/delete" method="POST">
                    <div class="row">
                        <div class='col-md-12'>
                            <input type="hidden" name="user_id" value="<%= user.id %>" /> 
                            <p>Are you sure you want to DELETE  User - <span class="member-name"></span></p>
                        </div>                    
                    </div>
                    <div class="row">
                        <div class='col-md-12'>
                            <button type="submit" class="btn btn-primary"> Delete</button>
                        </div>                    
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="delete-pass" aria-hidden="false" aria-labelledby="delete-pass" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-simple">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title">Delete Pass</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class='col-md-12'>
                        <input type="hidden" name="barcode_id" value="<%= user.barcode_id %>" />
                        <p>Are you sure want to DELETE this pass?</p>
                    </div>                    
                </div>
                <div class="row">
                    <div class='col-md-12'>
                        <button type="button" class="btn btn-primary" id="deletePassBtn"> Delete</button>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>
        </>
    )
}

export default SingleUser;