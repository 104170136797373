import React, { useState, useMemo, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import StrategyEntryComponent from "./StrategyEntryComponent";
import StrategyPositionComponent from "./StrategyPositionComponent";
import StrategyReviewComponent from "./StrategyReviewComponent";
import ExistCondition from "./ExistCondtion";
import dayjs from "dayjs";
import moment from "moment";
import axios from "axios";
import { getZerodhaToken } from "../../Session";
import { getInstrumentsAPI } from "../../api/api";


const MenuContext = React.createContext();

const StrategyCreateComonent = (props) => {
  const location = useLocation();
  const token = getZerodhaToken();
  const { strategyName,  setStrategiesNameError, setCreateStratgyPage,categorynm } = props;
  const [stocks, setStocks] = useState([]);
  const [allStocks, setAllStocks] = useState([]);
  const [newStockes, setNewStockes] = useState([]);
  const [existingStrategy, setExistingStrategy] = useState(false);
  const [allStockStore, setAllStockStore] = useState([]);
  const [activeButton, setActiveButton] = useState(1);
  const [positionFormData, setPositionFormData] = useState({
    function_name: 2,
    strategy_name: strategyName,
    stock_name: "Nifty 50",
    position_id: 1,
    holding_type: "MIS",
    candleIntervalId: 1,
    category_id:categorynm,
    candle_interval_id: "1m",
    quantity: 1,
    entry_condition: "",
    isAdmin:1,
    stop_loss: "5",
    target_profit: "5",
    trailing_sl: "",
    max_allocation: "",
    max_quantity: 0,
    position_sizing_type: "-",
    start_time: "00:00",
    end_time: "23:59",
    initial_capital: "100000",
    start_date: moment().subtract(29, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    description: "",
    user_id: JSON.parse(sessionStorage.getItem("user"))?.id,
    exch: "b",
    exchType: "c",
    scripCode: "500112",
    interval: "1m",
    fromDate: "",
    endDate: "",
    input1: "10",
    conditionTab: false,
    selectedOption1: "",
    selectedOption2: "",
    mode: "",
    comparator: "higher than",
    comparator2: "lower than",
    comparator3: "higher than",
    noStock: true,
    function_id: 83,
    tpslType: "per",
    firstFunctionId: "",
    secondFunctionId: "",
    functionsMainBox: [],
    functionsBox: [],
    functionsBox2: [],
    functionsBox777: [],
    inner_function1: {},
    inner_function2: {},
    inner_function3: {},
    operator: "and",
    function_id1: "",
    period1: "",
    offset1: "0",
    functionText1: "",
    exit_condition: "lower than",
    count: 0,
    IndicatorFormula: "",
    indicators: {},
    order: [],
    stocks: [],
    starttime: dayjs(
      moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    ),
    endtime: dayjs(
      moment().set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
    ),
    //Exit condtion data
    exit_indicators: {},
    functionsBox888: [],
    functionsBox3: [],
    previous_day: 1,
    daily_strategy_cycle: "-",

    api_key: "3wxj01wpd2rnnn9c",
    access_token: token,
  });
  useEffect(() => {
    console.log("first,positionFormData", positionFormData);
  }, [positionFormData]);

  const [save, setSave] = useState();
  const fetchInstruments = async () => {
    const token = getZerodhaToken();
    console.log("accessToken-", token);
    try {
      const response = await getInstrumentsAPI({
        api_key: "3wxj01wpd2rnnn9c",
        access_token: token,
      });

      const data = response.data;
      console.log("stockDATASTRATEGY", data);

      // Split data into lines
      const lines = data.split("\n");

      // Get field names from the first line
      const fieldNames = lines[0].split(",").map((field) => field.trim());

      // Create JSON objects for each line
      const jsonData = [];
      for (let i = 1; i < lines?.length; i++) {
        const values = lines[i].split(",");

        // Check if the line has the correct number of fields
        if (values?.length === fieldNames?.length) {
          const jsonEntry = {};
          for (let j = 0; j < fieldNames?.length; j++) {
            jsonEntry[fieldNames[j]] = values[j].trim();
          }
          jsonData.push(jsonEntry);
        } else {
          console.error(
            `Line ${
              i + 1
            } does not have the correct number of fields. Content: ${lines[i]}`
          );
        }
      }

      console.log("jsonData", jsonData);
      let sss = jsonData?.filter((item) => item.exchange == "NSE");
      console.log("jsonDataFilter", sss);

      let sortedJsonData = jsonData?.sort(
        (a, b) => a.tradingsymbol?.length - b.tradingsymbol?.length
      );
      console.log("sortedJsonData@", sortedJsonData);
      setAllStockStore(sortedJsonData);

      const first20Stocks = jsonData?.slice(0, 20);
      setAllStocks(first20Stocks);
      setNewStockes(first20Stocks);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };

  useEffect(() => {
    // fetchData();
    // fetchCSVData();
    fetchInstruments();
  }, []);

  useEffect(() => {
    console.log("HIt111", token, location?.state);
    if (location?.state != undefined) {
      location.state.existingData.access_token = token;
      setPositionFormData(location?.state?.existingData);
    }
  }, [location?.state]);

  useEffect(() => {
    console.log("HITTT2++++++", positionFormData);
  }, [positionFormData]);

  const [menu, setMenu] = useState("position");

  const handlePositionFormChange = (newFormData) => {
    console.log("newFormData->", newFormData);
    setPositionFormData(newFormData);
  };
  const handleSave = () => {
    setSave(true);
  };

  const handleSaveFalse = () => {
    setSave(false);
  };
  const handleChangeCharType = (buttonNumber) => {
    setActiveButton(buttonNumber);
    setPositionFormData((prevFormData) => ({
      ...prevFormData,
      chart_type: buttonNumber,
    }));
  };
  const renderComponent = useMemo(() => {
    return (
      <>
        <StrategyPositionComponent
          formData={positionFormData}
          onFormChange={handlePositionFormChange}
          stocks={stocks}
          setStocks={setStocks}
          allStocks={allStocks}
          save={save}
          strategyName={
            positionFormData?.strategy_name
              ? positionFormData?.strategy_name
              : strategyName
          }
          activeButton={activeButton}
          handleChangeCharType={handleChangeCharType}
          
        />

        <StrategyEntryComponent
          formData={positionFormData}
          onFormChange={handlePositionFormChange}
          allStocks={allStocks}
          handleNext={menu}
          save={save}
        />

        <ExistCondition
          formData={positionFormData}
          onFormChange={handlePositionFormChange}
          save={save}
        />

        <StrategyReviewComponent
          formData={positionFormData}
          onFormChange={handlePositionFormChange}
          stocks={stocks}
          setStocks={setStocks}
          handleSave={handleSave}
          handleSaveFalse={handleSaveFalse}
          setCreateStratgyPage={setCreateStratgyPage}
        />
      </>
    );
  }, [menu, positionFormData, stocks, allStocks, save, activeButton]);

  return (
    <>
      <MenuContext.Provider
        value={{ menu, setMenu, newStockes, setNewStockes, allStockStore }}
      >
        <section
          className="mobile-view"
          style={{
            background: positionFormData.noStock ? "white" : "white",
          }}
        >
          <div className="container web-cotainer">
            {/* <StrategyCreateBarComponent
              strategyName={
                positionFormData?.strategy_name
                  ? positionFormData?.strategy_name
                  : strategyName
              }
              setCreateStratgyPage={setCreateStratgyPage}
              formData={positionFormData}
              stocks={stocks}
            /> */}
            {/* <br />
            <br /> */}
            <div className="pt-4">{renderComponent}</div>
          </div>
        </section>
      </MenuContext.Provider>
    </>
  );
};

export default StrategyCreateComonent;

export const useMenuContext = () => useContext(MenuContext);
