import React, { useEffect, useState, useMemo } from "react";
import "./Strategy.css";
import SMA_I from "./Indicators/SMA_I";
import EMA_I from "./Indicators/EMA_I";
import Number_I from "./Indicators/Number_I";
import Close_I from "./Indicators/Close_I";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import SearchableDropdown from "./SearchDropdown";
import CandleInterval from "../../mcomponents/formulaTabComponent/CandleInterval";
import InstanceDisplay from "../../mcomponents/formulaTabComponent/InstanceDisplay";

const buttonStyle = {
  padding: "3px",
  marginRight: "10px",
  fontSize: "14px",
  backgroundColor: "white",
  color: "black",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  border: "1px solid #1579c9",
  position: "relative",
};

const addButton = {
  backgroundColor: "transparent", // No background color
  color: "#0e6efd", // Text color
  fontWeight: "500",
  fontSize: "16px",
  height: "30px",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px dashed #1579c9", // Dashed border
  borderRadius: "4px",
  cursor: "pointer",
  transition: "border-color 0.3s", // Only transition for border color
  marginRight: "5px",
};
const FormulaTab = ({ ...restProps }) => {
  const {
    formData,
    handleRemoveButtonClick,
    onFormChange,
    setShowDropdown,
    setInnerAdd,
    setOuterAdd,
    handleAddButtonClick,
    allStocks,
    setShowCandleModal,
    getIndex,
    showInstead,
    handleEdit,
    handleAdd,
    setPeriodMaxModal,
    setPeriodMinModal,
    setShowModal,
  } = restProps;
  
  return (
    <>
      {Object.entries(formData?.indicators || {}).map(
        ([instanceName, instanceData], index) => {
          const isShowingAddButton =
            showInstead?.isOpen &&
            showInstead?.function_id == instanceData?.function_id &&
            showInstead.index == index;
          console.log("instanceName", instanceName);
          console.log("instanceData", instanceData);
          return (
            <span
              key={instanceName}
              className={`ms-2 ${
                instanceData?.name === "&&" || instanceData?.name === "||"
                  ? "w-100 py-2"
                  : ""
              }`}
            >
              {instanceData?.name === "Max" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body1 &&
                          instanceData?.body1?.map((item, index) => {
                            let data = item
                              ? item
                              : instanceData?.body
                              ? instanceData?.body[0]
                              : instanceData;
                            return (
                              <>
                                <InstanceDisplay
                                  instanceData={data}
                                  formData={formData}
                                  instanceName={instanceName}
                                  index={index}
                                  isShowingAddButton={isShowingAddButton}
                                  handleAdd={handleAdd}
                                  handleEdit={handleEdit}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  buttonStyle={buttonStyle}
                                  addButton={addButton}
                                  handleAddButtonClick={handleAddButtonClick}
                                  setShowDropdown={setShowDropdown}
                                  setInnerAdd={setInnerAdd}
                                  setOuterAdd={setOuterAdd}
                                  isMathFunction="true"
                                  instance="body1"
                                  isExit=""
                                  setShowModal={setShowModal}
                                />
                              </>
                            );
                          })}
                        {/* {instanceData?.body1?.length === 0 && (
                          <button
                            onClick={() => {
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body1");
                            }}
                            className="stratergyAddBtn"
                          >
                            {"Add"}
                          </button>
                        )} */}
                        <button
                          onClick={() => {
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName, "body1");
                          }}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body2?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance="body2"
                                isExit=""
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        {/* {instanceData?.body2?.length === 0 && (
                          <button
                            onClick={() => {
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body2");
                            }}
                            className="stratergyAddBtn"
                          >
                            {"Add"}
                          </button>
                        )} */}
                        <button
                          onClick={() => {
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName, "body2");
                          }}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Min" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body1 &&
                          instanceData?.body1?.map((item, index) => {
                            let data = item
                              ? item
                              : instanceData?.body
                              ? instanceData?.body[0]
                              : instanceData;
                            return (
                              <>
                                <InstanceDisplay
                                  instanceData={data}
                                  formData={formData}
                                  instanceName={instanceName}
                                  index={index}
                                  isShowingAddButton={isShowingAddButton}
                                  handleAdd={handleAdd}
                                  handleEdit={handleEdit}
                                  handleRemoveButtonClick={
                                    handleRemoveButtonClick
                                  }
                                  setShowCandleModal={setShowCandleModal}
                                  getIndex={getIndex}
                                  buttonStyle={buttonStyle}
                                  addButton={addButton}
                                  handleAddButtonClick={handleAddButtonClick}
                                  setShowDropdown={setShowDropdown}
                                  setInnerAdd={setInnerAdd}
                                  setOuterAdd={setOuterAdd}
                                  isMathFunction="true"
                                  instance="body1"
                                  isExit=""
                                  setShowModal={setShowModal}
                                />
                              </>
                            );
                          })}
                        {/* {instanceData?.body1?.length === 0 && (
                          <button
                            onClick={() => {
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body1");
                            }}
                            className="stratergyAddBtn"
                          >
                            {"Add"}
                          </button>
                        )} */}
                        <button
                          onClick={() => {
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName, "body1");
                          }}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body2?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance="body2"
                                isExit=""
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        {/* {instanceData?.body2?.length === 0 && (
                          <button
                            onClick={() => {
                              setShowDropdown(true);
                              setInnerAdd(true);
                              setOuterAdd(false);
                              handleAddButtonClick(instanceName, "body2");
                            }}
                            className="stratergyAddBtn"
                          >
                            {"Add"}
                          </button>
                        )} */}
                        <button
                          onClick={() => {
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName, "body2");
                          }}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        )
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Floor" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit=""
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer `}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Ceil" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit=""
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          // style={addButton}
                          className="stratergyAddBtn"
                        >
                          {/* <i class="fa-solid fa-plus"></i> &nbsp; */}
                          {"Add"}
                          {/* &ensp; */}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                            // handleRemoveButtonClick(instanceName, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Abs" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit=""
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Symbol" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <SearchableDropdown
                          instanceName={instanceName}
                          formData={formData}
                          onFormChange={onFormChange}
                          stocks={allStocks}
                        />
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit=""
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            console.log("instanceData?.name", instanceName);
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Period min" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <button
                          onClick={() => {
                            setPeriodMinModal(true);
                            formData.selectedOption1 = instanceName;
                            formData.clickedFunction = instanceName;
                          }}
                          style={{
                            ...addButton,
                            background: "white",
                          }}
                        >
                          {formData.indicators[instanceName].period}
                        </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit=""
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name === "Period max" && (
                <div>
                  {isShowingAddButton ? (
                    <button
                      className="stratergyAddBtn"
                      onClick={() => {
                        handleAdd(instanceData, index);
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className="functionButton">
                        {instanceData?.name} &nbsp;(&nbsp;
                        <button
                          onClick={() => {
                            setPeriodMaxModal(true);
                            formData.selectedOption1 = instanceName;
                            formData.clickedFunction = instanceName;
                          }}
                          style={{
                            ...addButton,
                            background: "white",
                          }}
                        >
                          {formData.indicators[instanceName].period}
                        </button>
                        &nbsp; <div style={{ marginTop: "10px" }}>,</div>
                        {instanceData?.body?.map((item, index) => {
                          let data = item
                            ? item
                            : instanceData?.body
                            ? instanceData?.body[0]
                            : instanceData;
                          return (
                            <>
                              <InstanceDisplay
                                instanceData={data}
                                formData={formData}
                                instanceName={instanceName}
                                index={index}
                                isShowingAddButton={isShowingAddButton}
                                handleAdd={handleAdd}
                                handleEdit={handleEdit}
                                handleRemoveButtonClick={
                                  handleRemoveButtonClick
                                }
                                setShowCandleModal={setShowCandleModal}
                                getIndex={getIndex}
                                buttonStyle={buttonStyle}
                                addButton={addButton}
                                handleAddButtonClick={handleAddButtonClick}
                                setShowDropdown={setShowDropdown}
                                setInnerAdd={setInnerAdd}
                                setOuterAdd={setOuterAdd}
                                isMathFunction="true"
                                instance=""
                                isExit=""
                                setShowModal={setShowModal}
                              />
                            </>
                          );
                        })}
                        &nbsp;&nbsp;
                        <button
                          onClick={() => {
                            setShowDropdown(true);
                            setInnerAdd(true);
                            setOuterAdd(false);
                            handleAddButtonClick(instanceName);
                          }}
                          className="stratergyAddBtn"
                        >
                          {"Add"}
                        </button>
                        &nbsp;
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(instanceData, index);
                          }}
                          className={`fa-solid fa-xmark iconContainer`}
                          style={{
                            color: "#7AB1DD",
                            marginTop: "-30px",
                            cursor: "pointer",
                          }}
                        ></i>
                        )
                      </div>
                    </>
                  )}
                </div>
              )}

              {instanceData?.name !== "Max" &&
                instanceData?.name !== "Min" &&
                instanceData?.name !== "Floor" &&
                instanceData?.name !== "Ceil" &&
                instanceData?.name !== "Abs" &&
                instanceData?.name !== "Symbol" &&
                instanceData?.name !== "Period min" &&
                instanceData?.name !== "Period max" && (
                  <InstanceDisplay
                    instanceData={instanceData}
                    formData={formData}
                    instanceName={instanceName}
                    index={index}
                    isShowingAddButton={isShowingAddButton}
                    handleAdd={handleAdd}
                    handleEdit={handleEdit}
                    handleRemoveButtonClick={handleRemoveButtonClick}
                    setShowCandleModal={setShowCandleModal}
                    getIndex={getIndex}
                    buttonStyle={buttonStyle}
                    addButton={addButton}
                    handleAddButtonClick={handleAddButtonClick}
                    setShowDropdown={setShowDropdown}
                    setInnerAdd={setInnerAdd}
                    setOuterAdd={setOuterAdd}
                    isMathFunction=""
                    instance=""
                    isExit=""
                    setShowModal={setShowModal}
                  />
                )}
            </span>
          );
        }
      )}
    </>
  );
};

export default FormulaTab;
