import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DeployedBox from "../../mcomponents/DeployedBox";
import { Menu, MenuItem } from "@mui/material";
import { getUser, getZerodhaToken } from "../../Session";
import {
  addNewScannerAPI,
  deleteScannerAPI,
  getDiscoverScannerResultApi
} from "../../api/scannerapi";
import moment from "moment";
import LogBarChart from "./LogChart";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getStockLTPDataAPI } from "../../api/api";
import DeleteStrategyModal from "../Strategies/DeleteStrategyModel";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SkeletonLoader from "../componentCommon/SkeletonLoader";
import ScannerTable from "./subComponent/ScannerTable";
export const SingleScanner = () => {
  let flag = 0;
  const navigate = useNavigate();
  const location = useLocation();

  const strategyid = location.pathname.split("/");
  const strategyId = location.state?.strategyId;
  const isCreated = location.state?.isCreated;
  const [isCreatedClick, setIsCreatedClick] = useState(isCreated);
  const [onClickDisable, setonClickDisable] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [copyScannerShow, setCopyScannerShow] = useState(false);
  const { scanid } = useParams();
  const [scannerData, setScannerData] = useState([]);
  const [data, setData] = useState([]);
  const [scannerNameError, setScannerNameError] = useState(false);
  const [scanCondition, setScanCondition] = useState(false);
  const [isConditionLoading, setIsConditionLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [mainObj, setMainObj] = useState();
  const [candleId, setCandleId] = useState();
  const [isLive, setIsLive] = useState(false);
  const [logData, setLogData] = useState(false);
  const [graph, setGraph] = useState(false);
  const [isHide, setHide] = useState(false);
  const [clickLive, setCLickLive] = useState(false);
  const [searchOn, setSearchOn] = useState(false);
  const [show, setShow] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allDataLength, setAllDataLength] = useState();
  const [length, setLength] = useState(false);
  const [scannerName, setScannerName] = useState(false);
  const [cnt, setCnt] = useState();
  const [ltpData, setLtpData] = useState();
  const [clickeddata, setClickeddata] = useState(null);
  const user = getUser();
  const token = getZerodhaToken();
  const [isDisabled, setIsDisabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [otherpage, setOtherpage] = useState(null);
  const [intervalId, setIntervalId] = useState();
 const [renderagain,setRenderagain]=useState(null);
 const [scandate,setDate]=useState();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(()=>{
        console.log("STAET",onClickDisable);
        console.log("STAET RENDER",renderagain);
  },[onClickDisable,renderagain]);

  
useEffect(()=>{
        console.log("onclickdisable#####",onClickDisable);
        console.log("onclickdisable#####2",isCreatedClick);
},[onClickDisable,isCreatedClick]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const checkFirst = localStorage.getItem("IsCreated");
    const storedStartTimeKey = `${scanid}-startTime`;
    const storedStartTime = localStorage.getItem(storedStartTimeKey);

    if (isCreatedClick && checkFirst==null) {
      const currentTime = Date.now();
      const elapsedTime = storedStartTime ? currentTime - Number(storedStartTime) : 0;
      const remainingTime = 40000 - elapsedTime;

      // If the timeout has already elapsed
      if (remainingTime <= 0) {
        localStorage.setItem("IsCreated", true);
        localStorage.removeItem(`${scanid}`);
        setIsCreatedClick(false);
      } else {
        // If there is remaining time, set a timeout
        setTimeout(() => {
          localStorage.setItem("IsCreated", true);
          localStorage.removeItem(`${scanid}`);
          localStorage.removeItem(storedStartTimeKey); 
          setIsCreatedClick(false);
        }, remainingTime);

        // Optional: Update localStorage with current time when action starts
        localStorage.setItem(storedStartTimeKey, currentTime);
      }
    }

    if (storedStartTime) {
      const elapsedTime = Date.now() - storedStartTime;
      const remainingTime = 40000 - elapsedTime;
      if (remainingTime > 0) {
        console.log(`Timeout remaining: ${remainingTime} ms`);

        setTimeout(() => {
          console.log(`Removing item: ${storedStartTimeKey}`);
          localStorage.removeItem(storedStartTimeKey); 
          localStorage.removeItem(`${scanid}`); 
          setonClickDisable(false);
          setIsConditionLoading(false);
          setrenderingagain();
        }, remainingTime);
      } else {
        localStorage.removeItem(storedStartTimeKey); 
        localStorage.removeItem(`${scanid}`);
        setonClickDisable(false);
        setIsConditionLoading(false);
        setrenderingagain();
      }
    }
  }, [isCreatedClick,onClickDisable, scanid]);


const setrenderingagain=()=>{
  setRenderagain(false);
}
  const handleEditMenu = () => {
    setIsCreatedClick(true);
    setonClickDisable(true);
    if (scannerData.length > 0) {
      let data = {};
      if (scannerData.length > 0 && scannerData[0]?.entire_object) {
        data = JSON.parse(scannerData[0]?.entire_object);
      }
      if (scannerData.length > 0 && scannerData[0]) {
        if (scannerData[0]?.scanner_id)
          data.scanner_id = scannerData[0]?.scanner_id;
      }
      localStorage.removeItem("IsCreated");
      localStorage.setItem("IsNavigate", true) ;
      console.log("meee",scannerData[0]?.entire_object);
      navigate(
        `/scanners/page?scannid=${scannerData[0]?.scanner_id}&scannername=${scannerName}`,
        {
          state: data,
        }
      );
      handleClose();
    } else {
      let data = mainObj;
      if (allData && allData.length > 0)
        data.scanner_id = allData && allData[0]?.scanner_id;
      localStorage.removeItem("IsCreated");
      localStorage.setItem("IsNavigate", true);
      console.log("meee",data);
      navigate(
        `/scanners/page?scannid=${allData[0]?.scanner_id}&scannername=${scannerName}`,
        {
          state: data,
        }
      );
      handleClose();
    }
  };

  const handleDelete = () => {
    setShow(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setShow(false);
  };
  useEffect(() => {
    fetchScannerResult();
  }, []);
  useEffect(() => {
    if (strategyId) {
      setTimeout(() => {
       fetchScannerResult();
      }, 40000);
    } else {
      setTimeout(() => {
       fetchScannerResult();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (mainObj?.scan_on === "NIFTY 50" && allDataLength < 50) ||
        (mainObj?.scan_on === "NIFTY 100" && allDataLength < 101) ||
        (mainObj?.scan_on === "Energy" && allDataLength < 30) ||
        (mainObj?.scan_on === "Consumer Discretionary" && allDataLength < 30) ||
        (mainObj?.scan_on === "Industrials" && allDataLength < 30) ||
        (mainObj?.scan_on === "Health Care" && allDataLength < 30) ||
        (mainObj?.scan_on === "Information Technology" && allDataLength < 30) ||
        (mainObj?.scan_on === "Utilities" && allDataLength < 26)
      )
        fetchScannerResult();
    }, 2000);
    return () => {
      clearInterval(interval); // Clear interval when component is unmounted
    };
  }, [location.pathname, allDataLength]);

  const setValue = (prop) => {
    setClickeddata(prop);
    setOtherpage(prop);
  };
  const fetchScannerResult = async () => {
    try {
      const result = await getDiscoverScannerResultApi({
        id: scanid,
        user_id: user.id,
        api_key: "3wxj01wpd2rnnn9c",
        access_token: token,
      });
      setAllData(result?.data);
      setAllDataLength(result?.data?.length);
      const resultData = result?.data;
      console.log("scannerResult>", resultData);
  
      if (resultData && resultData.length>0) {
        setScannerName(result?.data[0]?.scanner_name);
        setDate(
          result?.data[0]?.updated_at
            ? result?.data[0]?.updated_at
            : result?.data[0]?.created_at
        );
        const entireObject = resultData[0]?.entire_object;
        if (entireObject) {
          try
          {
            setMainObj(JSON.parse(entireObject));
            setScanCondition(JSON.parse(entireObject)?.functionText1);
          } catch (parseError) {
            console.error("Error parsing entire_object JSON:", parseError);
          }
         
        }
        console.log("resultData",resultData);
        console.log("resultData3", JSON?.parse(resultData[0]?.entire_object));
        const filteredData = resultData.filter(item => item.is_trigger === 1);
        setCnt(filteredData?.length);
        setLength(true);
        console.log("filteredData>", filteredData);

        const tokens = filteredData?.map(item => parseInt(item.script_code));
        if (flag === 0) {
          setData(filteredData);
          setIsConditionLoading(false);
          if (filteredData[0]?.candle_interval_id != "1d") {
            let intervalId = parseInt(
              filteredData?.[0]?.candle_interval_id.replace(/[^0-9]/g, ""),
              10
            );
            setIntervalId(intervalId);
          } else {
            setIntervalId(61);
          }
          flag = 1;
        }
        console.log("909090",tokens,filteredData);
        getStocksData(tokens, filteredData);

      } else {
        console.log("No data returned from API.");
      }
    } catch (error) {
      console.error("Error fetching scanner result:", error);
    }
  };
  const getStocksData = async (tokens, data) => {
    console.log("iscalling stock data", tokens, data);
    const apiKey = "3wxj01wpd2rnnn9c";
    const queryParams = tokens?.map((token) => `i=${token}`).join("&");
    if (tokens?.length > 0) {
      const response = await getStockLTPDataAPI({
        apiKey: apiKey,
        token: token,
        queryParams: queryParams,
      });
      if (response) {
        if (response.data) updateStocksDataNew(data, response?.data);
      }
    }
  };
  const updateStocksDataNew = (stocks, data) => {
    Object.keys(data).forEach((instrumentToken) => {
      const stockToUpdate = stocks.find(
        (stock) => stock.script_code == instrumentToken
      );

      if (stockToUpdate) {
        const newData = data[instrumentToken];
        stockToUpdate.ltp = newData.last_price;
        stockToUpdate.closePrice = newData.ohlc.close;
      }
    });

    if (stocks?.length > 0) 
    {
      setScannerData(stocks);
      setIsConditionLoading(false);
    }
   
  };

  useEffect(() => {
    if (strategyId) {
      const interval = setInterval(() => {
        if (!clickLive) {
          if (
            (mainObj?.scan_on === "NIFTY 50" && allDataLength < 50) ||
            (mainObj?.scan_on === "NIFTY 100" && allDataLength < 101) ||
            (mainObj?.scan_on === "Energy" && allDataLength < 30) ||
            (mainObj?.scan_on === "Consumer Discretionary" &&
              allDataLength < 30) ||
            (mainObj?.scan_on === "Industrials" && allDataLength < 30) ||
            (mainObj?.scan_on === "Health Care" && allDataLength < 30) ||
            (mainObj?.scan_on === "Information Technology" &&
              allDataLength < 30) ||
            (mainObj?.scan_on === "Utilities" && allDataLength < 26)
          ) {
            fetchScannerResult();
          }
        }
       
      }, 1000);
      return () => clearInterval(interval);
    } else {
      const interval = setInterval(() => {
        if (!clickLive) {
          if (
            (mainObj?.scan_on === "NIFTY 50" && allDataLength < 50) ||
            (mainObj?.scan_on === "NIFTY 100" && allDataLength < 101) ||
            (mainObj?.scan_on === "Energy" && allDataLength < 30) ||
            (mainObj?.scan_on === "Consumer Discretionary" &&
              allDataLength < 30) ||
            (mainObj?.scan_on === "Industrials" && allDataLength < 30) ||
            (mainObj?.scan_on === "Health Care" && allDataLength < 30) ||
            (mainObj?.scan_on === "Information Technology" &&
              allDataLength < 30) ||
            (mainObj?.scan_on === "Utilities" && allDataLength < 26)
          ) {
            fetchScannerResult();
          }
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [allDataLength, clickeddata, clickLive]);

  const scanNow = async () => {
    localStorage.setItem(`${scanid}`, scanid);
    const startTimeKey = `${scanid}-startTime`;
    const startTime = Date.now();
    localStorage.setItem(startTimeKey, startTime);

    setonClickDisable(true);
    setAllData([]);
    setScannerData([]);
    setCnt(0);
    let data = { ...mainObj };
    data.access_token = token;
    data.scanner_id = scanid;
   
    const result = await addNewScannerAPI(data);
  
    if (result) {
      setAllDataLength(0);
      setCnt(0);
      const interval = setInterval(() => {
        if (
          (mainObj?.scan_on === "NIFTY 50" && allDataLength < 50) ||
          (mainObj?.scan_on === "NIFTY 100" && allDataLength < 101) ||
          (mainObj?.scan_on === "Energy" && allDataLength < 30) ||
          (mainObj?.scan_on === "Consumer Discretionary" &&
            allDataLength < 30) ||
          (mainObj?.scan_on === "Industrials" && allDataLength < 30) ||
          (mainObj?.scan_on === "Health Care" && allDataLength < 30) ||
          (mainObj?.scan_on === "Information Technology" &&
            allDataLength < 30) ||
          (mainObj?.scan_on === "Utilities" && allDataLength < 26)
        ) {
          fetchScannerResult();
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
    }
  };
  const noscanedit = () => {
    alert(
      "scanning is still in progress, you can edit after scanning is completed"
    );
  };
  function getTimeframe(intervalId) {
    const timeframeMap = {
      "1m": "1 Minute",
      "3m": "3 Minutes",
      "5m": "5 Minutes",
      "10m": "10 Minutes",
      "15m": "15 Minutes",
      "30m": "30 Minutes",
      "60m": "60 Minutes",
      "1d": "1 Day",
    };

    return timeframeMap[intervalId] || "";
  }

  const handleConfirm = async () => {
    handleClose();
    const data = await deleteScannerAPI({
      scanner_id: scanid,
      user_id: user.id,
    });
    if (data) {
      alert("Scanner Deleted!");
      handleCloseModal();
      navigate("/scanners");
    }
    handleClose();
  };
  return (
    <>
      <section className="mobile-view">
        <div className="container web-cotainer">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Container className="title-section p-0">
                  <Col className="d-flex justify-content-between align-items-center">
                    <div>
                      <i
                        className="fa fa-chevron-left pointer-cursor "
                        onClick={() => navigate(-1)}
                      ></i>
                      <span>My Scanner</span>
                    </div>
                    <div>
                      {/* <Button
                        className="primary na-cursor"
                        onClick={() => {
                          setCopyScannerShow(true);
                        }}
                        disabled={(isDisabled && isLive) || onClickDisable ||
                          (isCreatedClick &&
                            localStorage.getItem("IsCreated") == null) ||
                            (localStorage.getItem(`${scanid}`) == scanid)}
                      >
                        {isLive ? "Stop Live" : "Take Live"}
                      </Button> */}
                      &nbsp;&nbsp;
                   {(renderagain===null || renderagain===false) && 
                        <Button
                          disabled={
                            onClickDisable
                             || 
                             renderagain ||
                            (isCreatedClick &&
                              localStorage.getItem("IsCreated") == null) ||
                              localStorage.getItem(`${scanid}`) == scanid
                          }
                         onClick={() => {
                            scanNow();
                          }}
                        >
                          Scan Now
                        </Button>
                   } 
                    </div>
                  </Col>
                </Container>
              </Row>

              <Row className="mt-2 mb-2">
                <Col className="d-flex flex-row justify-content-between align-items-center">
                {isConditionLoading ? (
                      <SkeletonLoader
                        width="200px"
                        height="50px"
                        borderRadius="8px"
                      />
                    ):( <div>
                      <span className="fs-4 fw-bold">{scannerName}</span>
                      &nbsp;
                      <i className="fa fa-star"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span>
                        <Button
                          variant="outline-primary"
                          size="sm mb-2"
                          disabled={
                            (isCreatedClick &&
                              localStorage.getItem("IsCreated") == null) ||
                            onClickDisable ||
                            (localStorage.getItem(`${scanid}`) == scanid)
                          }
                          onClick={() => {
                            handleEditMenu();
                          }}
                        >
                          Edit Scanner
                        </Button>
                      </span>
                    </div>)}
                 

                  <div
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <i
                      className="fa fa-ellipsis-v text-muted dropdown-button"
                      aria-hidden="true"
                      onClick={handleClick}
                    ></i>
                    <Menu
                      id="menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          if (
                            (mainObj?.scan_on === "NIFTY 50" &&
                              allDataLength < 50) ||
                            (mainObj?.scan_on === "NIFTY 100" &&
                              allDataLength < 101) ||
                            (mainObj?.scan_on === "Energy" &&
                              allDataLength < 30) ||
                            (mainObj?.scan_on === "Consumer Discretionary" &&
                              allDataLength < 30) ||
                            (mainObj?.scan_on === "Industrials" &&
                              allDataLength < 30) ||
                            (mainObj?.scan_on === "Health Care" &&
                              allDataLength < 30) ||
                            (mainObj?.scan_on === "Information Technology" &&
                              allDataLength < 30) ||
                            (mainObj?.scan_on === "Utilities" &&
                              allDataLength < 26)
                          ) {
                            noscanedit();
                          } else {
                            handleEditMenu();
                          }
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg md sm>
                  <div className="d-flex align-items-center mb-2">
                  {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ):(<div style={{ fontWeight: "500", width: "110px" }}>
                      Scan on&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
                    </div>)}
                   
                    &nbsp;&nbsp;
                    {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) :(<div
                      style={{
                        fontWeight: "500",
                        color: "#293696",
                      }}
                    >
                      {data && data?.length > 0
                        ? data[0]?.scan_on
                        : allData && allData[0]?.scan_on}
                    </div>)}
                    
                  </div>
                  <span className="d-flex align-items-center mb-2">
                  {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ):(<div style={{ fontWeight: "500", width: "110px" }}>
                      Timeframe&nbsp;&nbsp; :
                    </div>)}
                   
                    &nbsp;&nbsp;
                    {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ):(   <div style={{ color: "#293696", fontWeight: "600" }}>
                      {data && data[0]?.entire_object
                            ? getTimeframe(
                                JSON?.parse(data[0]?.entire_object)
                                  ?.candle_interval_id
                              )
                            : allData &&
                              allData[0]?.entire_object &&
                              getTimeframe(
                                JSON?.parse(allData[0]?.entire_object)
                                  ?.candle_interval_id
                              )}
                      </div>)}
                 
                  </span>

                  <span style={{ fontWeight: "500" }}>
                    <div className="d-flex align-items-center">
                    {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ):(<> <div style={{ fontWeight: "500", width: "110px" }}>
                      Condition &nbsp;&nbsp; 
                    </div>
                    <span>:</span></>)}
                     
                      &nbsp;&nbsp;
                      {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ):(<div style={{ fontWeight: "600", color: "#293696" }}>
                      {scanCondition}
                    </div>)}
                     
                    </div>
                  </span>
                  {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ):(<span
                      className="d-flex align-items-center"
                      style={{ marginTop: "6px" }}
                    >
                      <div style={{ fontWeight: "500", width: "110px" }}>
                        Description &nbsp;:
                      </div>
                      &nbsp;&nbsp;
                      {data && data[0] && <div>{data[0]?.description}</div>}
                    </span>)}
                 
                </Col>
              </Row>
              {/* )} */}
            
              <>
                <Row className="mt-3 d-flex align-items-center justify-content-between">
                  {
                    <Col lg={12} md={12} sm={12}>
                         {(isCreatedClick &&
                      localStorage.getItem("IsCreated") == null) ||
                    localStorage.getItem(`${scanid}`) == scanid ? (
                      <SkeletonLoader
                        width="100%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) : isConditionLoading ? (
                      <SkeletonLoader
                        width="100%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) :( <div>
                      <span className="fs-5" style={{ fontWeight: "600" }}>
                        {cnt}
                      </span>
                      &nbsp;&nbsp;
                      <span>Scan Result</span>&nbsp;&nbsp;
                      <span className="text-muted">
                        <span style={{ fontWeight: "500" }}>
                          Last ran at &nbsp;
                        </span>
                        {scannerData &&
                          scannerData[0] ?
                          (scannerData[0]?.created_at
                            ? moment
                                .utc(scannerData[0]?.created_at)
                                .local()
                                .format("hh:mm A DD/MM/YYYY")
                            : moment
                                .utc(scannerData[0]?.created_at)
                                .local()
                                .format("hh:mm A DD/MM/YYYY")):(<span>{scandate && moment
                                      .utc(scandate)
                                      .local()
                                      .format("hh:mm A DD/MM/YYYY") }</span>)}
                      </span>
                    </div>)}
                     
                    </Col>
                  }
                </Row>
                {(isCreatedClick &&
                  localStorage.getItem("IsCreated") == null) ||
                localStorage.getItem(`${scanid}`) == scanid ? (
                  <SkeletonLoader
                    width="100%"
                    height="20px"
                    borderRadius="8px"
                  />
                ) : isConditionLoading ? (
                  <SkeletonLoader
                    width="100%"
                    height="20px"
                    borderRadius="8px"
                  />
                ) :(<ScannerTable 
                scannerData={scannerData}/>)}
              
              </>
              {/* )} */}
            </Col>
            {/* <Col lg={3} md={3} sm={12}>
              <DeployedBox />
            </Col> */}
          </Row>
        </div>
      </section>
      <DeleteStrategyModal
        show={show}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
        mode="Scanner"
      />
    </>
  );
};
export default SingleScanner;