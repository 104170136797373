import React, { useEffect, useState } from 'react'
import "../../styles/sortData.css" ;
import RestartAltIcon from "@mui/icons-material/RestartAlt";
export const SortData = ({ dataList, setDataList, isReset, isScanner }) => {
  const [sortValue, setValue] = useState("");
  
  const handleChange = (e) => {
    let selectType = e.target.value;
    setValue(selectType);
    const sortData = [...dataList].sort((a, b) => {
      let sortedData;
      let nameA = isScanner === "true" ? a.scanner_name : a.strategy_name;
      let nameB = isScanner === "true" ? b.scanner_name : b.strategy_name;

      let dateA =
        isScanner === "true"
          ? a.scanner_details.created_at
          : a.strategy_details.created_at;
      let dateB =
        isScanner === "true"
          ? b.scanner_details.created_at
          : b.strategy_details.created_at;

      if (selectType === "name") {
        sortedData = nameA.localeCompare(nameB);
      } else if (selectType === "date") {
        sortedData = new Date(dateA) - new Date(dateB);
      }
      return sortedData;
    });
    if (sortData) setDataList(sortData);
  };
  console.log("FILTER", dataList);
  useEffect(() => {
    setValue("");
  }, [isReset]);
  return (
    <>
      <div>
        <select
          name="HeadlineAct"
          id="HeadlineAct"
          className="select"
          onChange={handleChange}
          value={sortValue}
        >
          <option value="" disabled selected>
            select sort type
          </option>
          <option value="name">sort by name</option>
          <option value="date">sort by date</option>
        </select>
      </div>
    </>
  );
};

export const ResetList = ({ handleClickReset }) => {
  return (
    <>
      <button className="reset-button" onClick={handleClickReset} >
        <RestartAltIcon />
      </button>
    </>
  );
};