import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, ListGroup } from "react-bootstrap";
import SideBarScanOn from "./SideBarScanOn";
import { useNavigate } from "react-router-dom";
import { useMenuContextScanner } from "./ScannerCreateComponent";
import { addNewScannerAPI, getStocksUnderIndices } from "../../api/scannerapi";
import { checkConditionValid } from "../../Utils/checkConditionValid";

const ScannerReviewComponent = ({ formData, onFormChange, stocks }) => {
  const navigate = useNavigate();
  const { menu, setMenu, setStockItemName, stockItemName } =
    useMenuContextScanner();

  const [showList, setShowList] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState("");
  const [scanItem, setScanItem] = useState("Stocks");

  const handelScanItem = async (items) => {
    let stocks = await fetchStockUnderIndices(items);

    formData.stocks = stocks;
    onFormChange(formData);
  };

  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    onFormChange({
      ...formData,
      [name]: value,
    });
  };
  const handleClickCandleInterval = (timeid) => {
    let startDate = "";
    let endDate = "";
    if (timeid === 1) {
      startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      endDate = moment().subtract(31, "days").format("YYYY-MM-DD"); // Assign end date for 1min and 2min intervals
    } else if (
      timeid === 3 ||
      timeid === 5 ||
      timeid === 10 ||
      timeid === 15 ||
      timeid === 30
    ) {
      startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      endDate = moment().subtract(90, "days").format("YYYY-MM-DD"); // Assign
    } else if (timeid === 60) {
      startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      endDate = moment().subtract(1, "year").format("YYYY-MM-DD"); // Assign
    } else {
      startDate = moment().format("YYYY-MM-DD"); // Assign start date for 1min and 2min intervals
      endDate = moment().subtract(5, "year").format("YYYY-MM-DD"); // Assign
    }

    onFormChange({
      ...formData,
      candleIntervalId: timeid,
      candle_interval_id:
        timeid === 1
          ? "1m"
          : timeid === 3
          ? "3m"
          : timeid === 5
          ? "5m"
          : timeid === 10
          ? "10m"
          : timeid === 15
          ? "15m"
          : timeid === 30
          ? "30m"
          : timeid === 60
          ? "60m"
          : timeid === "1day"
          ? "1d"
          : "",
      start_date: dayjs(startDate),
      end_date: dayjs(endDate),
    });
  };

  const addNewScanner = async () => {

    try {
      console.log("CHECKING", "Is callll" )
      const dataSources = [
        { data: formData?.indicators, source: "entry" },
      ].filter(({ data }) => data);
      console.log("CHECKING", dataSources )
      let isMathFnValid = checkConditionValid(dataSources);
      if (isMathFnValid.length > 0) {
        alert(isMathFnValid.join("\n"));
        return;
      }
      const addNew = await addNewScannerAPI(formData);
      
      if (addNew === false) {
        console.log("Something went wrong");
      } else {
        localStorage.removeItem("IsCreated");
        localStorage.setItem(`${addNew?.strategy_id}`, addNew?.strategy_id);
        navigate("/scanner/result/" + `${addNew?.strategy_id}`, {
          state: {
            strategyId: addNew?.strategy_id,
            isCreated: true,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target; // Extract the value directly from the event target
    setSelectedIndices(value); // Update the selected indices
    setStockItemName(stocks[value]?.name); // Update the displayed stock item name (optional)
    setShowList(false); // Hide the dropdown after selecting an option
    formData.selectedIndices = value;
    onFormChange(formData);
    handelScanItem(stocks[value]);
  };

  const fetchStockUnderIndices = async (items) => {
    try {
      const response = await getStocksUnderIndices({
        id: items?.id,
      });
      return response;
    } catch (error) {
      console.error("Error fetching stocks:", error);
    }
  };
  return (
    <>
      <div className="row p-0" id="scanner-create-parameter">
        <Card className="p-0">
          <Card.Body className="p-0">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold">Scan On</Form.Label>
                <Form.Select
                  style={{ width: "30%" }}
                  id="indicesSelect"
                  name="Indices"
                  value={formData.selectedIndices}
                  onChange={handleChange}
                  onClick={() => setShowList(!showList)}
                >
                  <option value="" disabled hidden>
                    Select Indices...
                  </option>
                  {stocks?.map((stock, index) => (
                    <option key={index} value={index}>
                      {stock?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        <div className="pt-3 p-0" id="create-strategy-chart-tab">
          <h6>Chart</h6>
          <div
            className="col-lg-12 technical-nifty-tab"
            id="technical-nifty-tab"
          >
            <Card>
              <Card.Body>
                <div className="row p-0 justify-content-between">
                  <div className="col-lg-2 p-0">
                    <div style={{ marginLeft: "20px" }}>
                      <span className="fw-bold">Chart Type</span>

                      <div id="strategy-chart-type-btn" className="pt-2">
                        <ListGroup
                          horizontal
                          style={{ height: "60%" }}
                          className="p-0"
                        >
                          <ListGroup.Item
                            action
                            variant="primary"
                            className="active"
                          >
                            <i className="fa fa-sliders"></i>
                          </ListGroup.Item>
                          <ListGroup.Item action disabled variant="primary">
                            <i className="fa fa-sliders"></i>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-7 col-md-7 col-sm-7 me-auto"
                    style={{ paddingLeft: "125px" }}
                  >
                    <div>
                      <span className="fw-bold">Candle Interval</span>
                    </div>
                    <ListGroup
                      horizontal
                      style={{ height: "60%" }}
                      className="pt-2 p-0"
                    >
                      {[1, 3, 5, 10, 15, 30, 60, "1day"].map((interval) => (
                        <ListGroup.Item
                          key={interval}
                          action
                          variant="primary"
                          className={`custom-list-item${
                            formData.candleIntervalId === interval
                              ? " active"
                              : ""
                          }`}
                          onClick={() => handleClickCandleInterval(interval)}
                        >
                          {typeof interval === "number"
                            ? `${interval}min`
                            : interval}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="p-0 pt-4" id="scanner-review-condition">
          <Card>
            <Card.Body>
              <span className="fw-bold">Conditions </span>
              <div></div>
            </Card.Body>
          </Card>
        </div>
        <div className="p-0 pt-4" id="scanner-review-description">
          <Card className="p-0">
            <Card.Body className="p-0">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="fw-bold">
                  Strategy Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={formData.description}
                  onChange={handleChangeValues}
                  rows={4}
                  placeholder="Add your scanner description here"
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex align-items-end flex-column p-0">
          <div className="ml-auto pt-3">
            <Button className="btn-color" onClick={addNewScanner}>
              Scan and Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScannerReviewComponent;
