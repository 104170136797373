import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const SearchableDropdown = ({
  instanceName,
  formData,
  onFormChange,
  stocks,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [allStocks, setAllStocks] = useState();
  const [searchClick, setSearchClick] = useState(false);

  const options = ["open", "high", "low", "close", "volume"];

  useEffect(() => {
    const filteredOptions = stocks?.filter(
      (option) => option?.exchange === "NSE"
    );

    setAllStocks(filteredOptions);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSelect = (value) => {
    if (formData.indicators[instanceName]?.scripCode)
      formData.indicators[instanceName].scripCode = value.instrument_token;
    onFormChange({
      ...formData,
      formData,
    });
    setSearchTerm(value?.tradingsymbol);
    setSearchClick(!searchClick);
  };

  const filteredOptions = allStocks
    ?.filter((option) =>
      option?.tradingsymbol?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(0, 3000);
  console.log("filterStocks___", filteredOptions)
  return (
    <div style={{ position: "relative", width: "150px" }}>
      <Form.Control
        onClick={() => setSearchClick(!searchClick)}
        type="text"
        size="sm"
        placeholder="Search"
        value={searchTerm}
        onChange={handleChange}
        style={{ width: "100%", padding: "5px", fontSize: "12px" }}
      />
      {searchClick && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            background: "white",
            border: "1px solid #ced4da",
            borderRadius: "4px",
            zIndex: 1000,
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {filteredOptions?.length > 0 ? (
            filteredOptions?.map((option, index) => (
              <div
                key={index}
                onClick={() => handleSelect(option)}
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  borderBottom: "1px solid #ddd",
                  fontSize: "12px",
                }}
              >
                <span>
                  {option?.tradingsymbol}
                </span>
              </div>
            ))
          ) : (
            <div style={{ padding: "8px" }}>No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
