import React, { useEffect, useState } from "react";

import { Button, Form, ListGroup, Modal } from "react-bootstrap";

const CreateDeployPopupsingle = ({
  openDeployModal,
  handleClose,
  handelClickCreateStrategy,
  strategy,
  setOpentDeployModal,
  handleDeployed,
  trading_type,
  set_trading_Type,
  termCondition,
  settermCondition,
  selectedItems,
  strategyDetails,
  formData,
  onFormChange,
  getUpdatedSelected,
}) => {
  let flag = 0;
  const [isChecked, setIsChecked] = useState(false);
  const [selectedItemsBox, setSelectedItemsBox] = useState();
  const [updatedBox, setUpdatedBox] = useState([]);

  useEffect(() => {
    if (flag === 0) {
      setSelectedItemsBox(selectedItems);
      flag = 1;
    }
  }, [selectedItems]);

  useEffect(() => {
    console.log("selectedItemsBox", selectedItemsBox);
  }, [selectedItemsBox]);

  useEffect(() => {
    const updatedSelectedItemsBox = selectedItemsBox?.map((item) => ({
      ...item, // Copy existing properties
      daily_strategy_cycle: formData?.daily_strategy_cycle, // Add new key-value pair
    }));
    setSelectedItemsBox(updatedSelectedItemsBox);
  }, [formData?.daily_strategy_cycle]);

  useEffect(() => {
    const updatedSelectedItemsBox = selectedItemsBox?.map((item) => ({
      ...item, // Copy existing properties
      holding_type: formData?.holding_type, // Add new key-value pair
    }));
    setSelectedItemsBox(updatedSelectedItemsBox);
  }, [formData?.holding_type]);

  useEffect(() => {
    const updatedSelectedItemsBox = selectedItemsBox?.map((item) => ({
      ...item, // Copy existing properties
      position_sizing_type: formData?.position_sizing_type, // Add new key-value pair
    }));
    setSelectedItemsBox(updatedSelectedItemsBox);
  }, [formData?.position_sizing_type]);

  useEffect(() => {
    const updatedSelectedItemsBox = selectedItemsBox?.map((item) => ({
      ...item, // Copy existing properties
      previous_day: formData?.previous_day, // Add new key-value pair
    }));
    setSelectedItemsBox(updatedSelectedItemsBox);
  }, [formData?.previous_day]);

  useEffect(() => {
    setUpdatedBox(selectedItemsBox);
  }, [selectedItemsBox, formData]);

  const handleTradeType = (type) => {
    set_trading_Type(type);
  };
  const handleClickTearm = () => {
    setIsChecked(true);
    settermCondition(false);
  };
  const handleCloseNew = () => {
    setOpentDeployModal(false);
  };

  const handleChangeValues = (e) => {
    const { name, value } = e.target;

    onFormChange({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeQuantity = (e) => {
    const { name, value } = e.target;
    setSelectedItemsBox((prevSelectedItemBox) => {
      console.log("prevSelectedItemBox", prevSelectedItemBox);
      return prevSelectedItemBox.map((item) => {
        if (item.stock_name == name) {
          return {
            ...item,
            quntity: value ? parseInt(value) : "", // assuming quntity is the correct property name
            quantity: value ? parseInt(value) : "",
          };
        }
        return item;
      });
    });
  };
  const handleChangeMaxAllocation = (e) => {
    const { name, value } = e.target;
    const updatedValue = value ? parseInt(value) : "";

    setSelectedItemsBox((prevSelectedItemBox) => {
      return prevSelectedItemBox.map((item) => {
        if (item.stock_name === name) {
          const updatedItem = { ...item };
          if (formData?.position_sizing_type === "risk based") {
            updatedItem.maxSlPerTrade = updatedValue;
          } else {
            updatedItem.max_allocation = updatedValue;
          }
          return updatedItem;
        }
        return item;
      });
    });
  };

  const handleCheckboxChange = (value) => {
    onFormChange({
      ...formData,
      previous_day: value === true ? 1 : 0,
    });
  };
  const evaluatedValue =
    formData?.quantity > 0 &&
    (formData?.max_allocation === 0 || formData?.max_allocation === "")
      ? formData?.quantity
      : formData?.max_allocation !== 0 &&
        formData?.max_allocation !== "" &&
        formData?.position_sizing_type === "capital based"
      ? formData?.max_allocation
      : formData?.position_sizing_type === "risk based"
      ? formData?.maxSlPerTrade
      : formData?.max_allocation;
  const handleSelectChange = (event) => {
    onFormChange((prevData) => ({
      ...prevData,
      position_sizing_type: event.target.value,
    }));
  };

  return (
    <Modal
      show={openDeployModal}
      onHide={handleClose}
      animation={false}
      centered
      size="lg"
    >
      <Modal.Body>
        <div>
          <h5 className="text-start fw-bolder ps-2">Deploy</h5>
          <div className="container">
            <div className="row pt-3">
              <div className="col-6">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="reddot"></div>
                    <h6 className="ps-2 mb-0">Instruments</h6>
                  </div>
                  <h6 className="mb-0">
                    {formData?.quantity > 0 &&
                    (formData?.max_allocation === 0 ||
                      formData?.max_allocation === "")
                      ? "Quantity"
                      : (formData?.max_allocation !== 0 ||
                          formData?.max_allocation !== "") &&
                        formData?.position_sizing_type === "capital based"
                      ? "Max Allocation"
                      : formData?.position_sizing_type === "risk based"
                      ? "Max SL per trade"
                      : "Max Allocation"}
                  </h6>
                </div>
                {selectedItemsBox?.map((item) => (
                  <div
                    className="d-flex justify-content-between align-items-center mt-3"
                    key={item.stock_name}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        alt="nt found"
                        src="/algoTradeImageLogo.png"
                        style={{ height: "27px", width: "27px" }}
                      ></img>
                      <div className="ps-2">
                        <div className="fw-bolder">
                          {item?.stock_name?.split(" ")[0]}
                        </div>
                        <div style={{ fontSize: "12px" }}>
                          {item?.stock_name?.split(" ")[1]}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "120px",
                      }}
                    >
                      <Form.Group
                        style={{ marginTop: "5px", width: "120px" }}
                        controlId={`exampleForm.ControlInput1-${item?.stock_name}`}
                      >
                        <Form.Control
                          type="number"
                          name={item?.stock_name}
                          onChange={handleChangeMaxAllocation}
                          value={evaluatedValue}
                          placeholder="Amount per trade (Max allocation)"
                        />
                      </Form.Group>
                    </div>
                    {/* </div> */}
                  </div>
                ))}
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center">
                  <div className="greendot"></div>
                  <h6 className="ps-2 mb-0">Deployment Type</h6>
                  <br></br>
                </div>
                <div className="d-flex flex-row mt-2">
                  <ListGroup horizontal>
                    <ListGroup.Item
                      onClick={() => handleTradeType(1)}
                      className={`${trading_type === 1 ? "active" : ""}`}
                    >
                      <i className="fa fa-newspaper-o"></i> &nbsp;
                      {trading_type === 1 ? "Live trade" : ""}
                    </ListGroup.Item>
                    <ListGroup.Item
                      onClick={() => handleTradeType(2)}
                      className={`${trading_type === 2 ? "active" : ""}`}
                    >
                      <i className="fa fa-wifi"></i>&nbsp;{" "}
                      {trading_type === 2 ? "Paper trade" : ""}
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                <div className="d-flex pt-3">
                  <span className="fw-bold"> Option </span>
                </div>
                <div className="d-flex flex-column">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formGroupEmail"
                  >
                    <Form.Label className="ml-auto">Strategy Cycle</Form.Label>
                    {/* <span></span> */}
                    <Form.Select
                      // disabled
                      aria-label="Default select example"
                      name="daily_strategy_cycle"
                      value={formData?.daily_strategy_cycle}
                      onChange={handleChangeValues}
                    >
                      <option>Select option </option>
                      <option value="-">-</option>
                      {[...Array(20)].map((_, index) => (
                        <option key={index} value={(index + 1) * 5}>
                          {(index + 1) * 5}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="d-flex flex-column">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>Holding Type</Form.Label>
                    <Form.Select
                      // disabled
                      aria-label="Default select example"
                      name="holding_type"
                      value={formData?.holding_type}
                      onChange={handleChangeValues}
                    >
                      <option value="CNC/NRML">CNC/NRML</option>
                      <option value="MIS">MIS</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="d-flex flex-column">
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>Order Type</Form.Label>
                    <Form.Select
                      disabled
                      value="1"
                      aria-label="Default select example"
                    >
                      <option value="1">Market</option>
                      <option value="2">Limit</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="d-flex flex-column">
                  {(formData?.max_allocation > 0 ||
                    formData?.maxSlPerTrade > 0) && (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label style={{ fontWeight: "600" }}>
                        Position Sizing type
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleSelectChange}
                        value={formData?.position_sizing_type}
                      >
                        <option value="">-</option>
                        <option value="capital based">Capital Based</option>
                        <option value="risk based">Risk Based</option>
                      </Form.Select>
                    </Form.Group>
                  )}
                </div>
                {/* {formData?.max_allocation ||
                  (formData?.maxSlPerTrade && (
                    <div>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Position Sizing type</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={handleSelectChange}
                          value={formData?.position_sizing_type}
                        >
                          <option value="">-</option>
                          <option value="capital based">Capital Based</option>
                          <option value="risk based">Risk Based</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  ))} */}

                <div className="previous-day-alert-candle">
                  <div>
                    <span className="fw-normal">Previous Day Alert Candle</span>
                  </div>
                  <div className="mt-2 d-flex" style={{ paddingLeft: "30px" }}>
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id={`default-checkbox`}
                      label={`Yes`}
                      value={1}
                      name="previous_day"
                      checked={formData?.previous_day}
                      onChange={() => handleCheckboxChange(true)}
                      className="fw-5"
                    />{" "}
                    &nbsp;&nbsp;
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id={`default-checkbox`}
                      label={`No`}
                      value={0}
                      checked={!formData?.previous_day}
                      onChange={() => handleCheckboxChange(false)}
                      name="previous_day"
                      className="fw-5"
                    />
                  </div>
                </div>

                <div className="d-flex pt-3">
                  <Form.Check // prettier-ignore
                    type="checkbox"
                    onChange={handleClickTearm}
                  />
                  &nbsp;&nbsp; I accept the trading terms
                </div>
                {termCondition && (
                  <div className="d-flex">
                    <span className="text-error">
                      Please select trading terms
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleCloseNew}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="btn-color"
          onClick={() => {
            handleDeployed(isChecked, updatedBox);
          }}
        >
          {trading_type === 1 ? "Live Trade" : "Paper Trade"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CreateDeployPopupsingle;
