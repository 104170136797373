// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.select, .discoverselect {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #e5e7eb;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    color: rgb(91, 90, 90);
}
.discoverselect{
    display: flex;
    justify-content: end;
    width: 25%;
}

.select:focus {
    outline: none;
    border-color: #4b89dc;
    box-shadow: 0 0 5px rgba(75, 137, 220, 0.5);
}

/* // reset styles // */
.reset-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-top: 8px;
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    color: white;
    background: linear-gradient(to right, #2d3597, #0e84d1);
}
`, "",{"version":3,"sources":["webpack://./src/styles/sortData.css"],"names":[],"mappings":";;AAEA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,sBAAsB;IACtB,wCAAwC;IACxC,sBAAsB;IACtB,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,oBAAoB;IACpB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,2CAA2C;AAC/C;;AAEA,uBAAuB;AACvB;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,uDAAuD;AAC3D","sourcesContent":["\n\n.select, .discoverselect {\n    width: 100%;\n    padding: 10px;\n    margin-top: 8px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    font-size: 14px;\n    border: 1px solid #e5e7eb;\n    background-color: #fff;\n    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);\n    box-sizing: border-box;\n    color: rgb(91, 90, 90);\n}\n.discoverselect{\n    display: flex;\n    justify-content: end;\n    width: 25%;\n}\n\n.select:focus {\n    outline: none;\n    border-color: #4b89dc;\n    box-shadow: 0 0 5px rgba(75, 137, 220, 0.5);\n}\n\n/* // reset styles // */\n.reset-button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: 10px;\n    margin-top: 8px;\n    height: 40px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 14px;\n    color: white;\n    background: linear-gradient(to right, #2d3597, #0e84d1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
