import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import NumberInput from '../NumberInput';
import {indicatorConfigs} from '../../Utils/indicatorConfigs';

const IndicatorModal = ({
  key,
  show,
  setIsShow,
  handleModalData,
  mainData,
  clickedFunction,
  exit,
  index,
  instance,
  name,
}) => {
    const [config, setConfig] = useState([]) ;
    let data;

    if (exit) {
      const exitIndicators = mainData?.exit_indicators?.[clickedFunction];

      if (exitIndicators?.body) {
        data = exitIndicators.body[index];
      } else if (instance && exitIndicators?.body1) {
        data = exitIndicators[instance]?.[0];
      } else if (exitIndicators?.body2) {
        data = exitIndicators[instance]?.[0];
      } else {
        data = exitIndicators;
      }
    } else {
      const indicators = mainData?.indicators?.[clickedFunction];

      if (indicators?.body) {
        data = indicators.body[index];
      } else if (instance && indicators?.body1) {
        data = indicators[instance]?.[0];
      } else if (indicators?.body2) {
        data = indicators[instance]?.[0];
      } else {
        data = indicators;
      }
    }

    const [formData, setFormData] = useState(data);

    useEffect(() => {
      // Update formData when data changes
      const config = indicatorConfigs[name];
      
      setFormData(data);
      setConfig(config) ;
    }, [data]);
    const handleClose = () => {
      setIsShow();
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      // let adjustName = exit  ? "e_" + name : name ;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
    const handleDone = () => {
      console.log("DONE", formData)
      handleModalData(formData);
      setIsShow();
    };
       
  console.log("MODALDATA", formData)
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        dialogClassName="formula-modal"
      >
        <Modal.Header className="pt-4 border-0">
          <Modal.Title className="fs-5 fw-bold px-4">
            {config?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form>
            {config?.fields
              ?.reduce((acc, field, index) => {
                if (index % 2 === 0) {
                  acc.push([field]);
                } else {
                  acc[acc.length - 1].push(field);
                }
                return acc;
              }, [])
              ?.map((fieldPair, pairIndex) => (
                <Row key={pairIndex}>
                  {fieldPair?.map((field, fieldIndex) => (
                    <Form.Group
                      as={Col}
                      sm="6"
                      className="mb-3"
                      key={fieldIndex}
                    >
                      <Form.Label>{field.label}</Form.Label>
                      {field.type === "number" ? (
                        <NumberInput
                          type="text"
                          name={exit ? `e_${field?.name}` : field?.name}
                          onChange={handleChange}
                          value={
                            exit
                              ? formData && formData[`e_${field?.name}`]
                              : formData && formData[field?.name]
                          }
                        />
                      ) : (
                        <Form.Select
                          name={exit ? `e_${field?.name}` : field?.name}
                          onChange={handleChange}
                          value={
                            exit
                              ? formData && formData[`e_${field.name}`]
                              : formData && formData[field?.name]
                          }
                        >
                          {field.options?.map((option, optionIdx) => (
                            <option key={optionIdx} value={option}>
                              {option === "truerange" ? "true range" : option}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    </Form.Group>
                  ))}
                </Row>
              ))}
          </Form>
        </Modal.Body>
        <Modal.Footer className="pb-1   border-0">
          <Button variant="danger" size="sm" onClick={handleClose}>
            Delete
          </Button>
          <Button variant="primary" size="sm" onClick={handleDone}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IndicatorModal