import React, { useState } from 'react'

const Pagination = ({currentPage, totalPages, handleNext, handlePrev, handleChange }) => {
    const getVisiblePages = () => {
      const pages = [];
      const delta = 2; // Number of pages to show before and after the current page
      const startPage = Math.max(2, currentPage - delta); // Ensure first page is visible
      const endPage = Math.min(totalPages - 1, currentPage + delta); // Ensure last page is visible
      // Always show the first page
      pages.push(1);
      // Add "..." for pages before the current range
      if (startPage > 2) {
        pages.push("...");
      }
      // Add the range of pages
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      // Add "..." for pages after the current range
      if (endPage < totalPages - 1) {
        pages.push("...");
      }
      // Always show the last page
      if (totalPages > 1) {
        pages.push(totalPages);
      }
      return pages;
    };

    const visiblePages = getVisiblePages();
    console.log("visiblePages", visiblePages);
  return (
    <>
      <div className="d-flex justify-content-center">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item cursor-pointer">
              <a
                className="page-link"
                onClick={handlePrev}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
            {visiblePages.map((page, index) => {
              if (page === "...") {
                return (
                  <li key={index} className="page-item">
                    <span className="page-link">...</span>
                  </li>
                );
              }
              return (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage === page ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link cursor-pointer"
                    onClick={() => handleChange(page)}
                  >
                    {page}
                  </a>
                </li>
              );
            })}

            <li className="page-item cursor-pointer">
              <a className="page-link" onClick={handleNext} aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Pagination