import React from "react";
import { Form } from "react-bootstrap";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const parseDate = (dateStr) => {
  if (!dateStr) return NaN;

  const mmddyyyyRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
  const ddmmyyyyRegex = /^\d{1,2}-\d{1,2}-\d{4}$/;
  const humanReadableRegex = /^\d{1,2} [a-zA-Z]+ \d{4}$/;

  // Check for MM/DD/YYYY format
  if (mmddyyyyRegex.test(dateStr)) return new Date(dateStr);
  // Check for DD-MM-YYYY format
  if (ddmmyyyyRegex.test(dateStr)) {
    const [day, month, year] = dateStr.split("-");
    return new Date(`${year}-${month}-${day}`);
  }
  // Check for human-readable date format
  if (humanReadableRegex.test(dateStr)) return new Date(dateStr);

  // Attempt native parsing and return NaN if invalid
  const parsedDate = new Date(dateStr);
  return isNaN(parsedDate.getTime()) ? NaN : parsedDate;
};

export const SortByList = ({
  sortByData,
  setSortByData,
  setSelectedSort,
  selectedSort,
  sortComponentName,
}) => {
  const handleSort = (e) => {
    const sortType = e.target.value;
    setSelectedSort(sortType);

    let nameKey, dateKey;

    if (sortComponentName === "coupons") {
      nameKey = "code";
      dateKey = "expiry_date";
    } else {
      nameKey = "user_name";
      dateKey = "start_date";
    }
    let sortedData = [...sortByData];

    if (sortType === "sName") {
      sortedData.sort((a, b) => a[nameKey].localeCompare(b[nameKey]));
    } else if (sortType === "sDate") {
      sortedData.sort((a, b) => {
        const dateA = parseDate(a[dateKey]);
        const dateB = parseDate(b[dateKey]);

        return dateB - dateA;
      });
    }

    setSortByData(sortedData);
  };

  return (
    <>
      <Form>
        <Form.Group controlId="formBasicSort" className="mx-2">
          <Form.Select onChange={handleSort} value={selectedSort}>
            <option value="" disabled>
              Sort by
            </option>
            <option value="sName">Sort by Name</option>
            <option value="sDate">Sort by Date</option>
          </Form.Select>
        </Form.Group>
      </Form>
    </>
  );
};

export function ResetDataList({ handleReset }) {
  const resetList = () => {
    handleReset();
  };
  return (
    <>
      <button className="btn btn-primary btn-color" onClick={resetList}>
        <span className="hidden-sm-down">
          <RestartAltIcon />
        </span>
      </button>
    </>
  );
}
