import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useEffect } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from "axios";
import { Box } from "@mui/material";
import { API_BASE_URL } from "../../config";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
const ScannerModal = ({
    showCreatePopup,
    handelClickCreateScanner,
    setShowCreatePopup,
    nameError,
    setNameerror,
    setError,err
  }) => {
    const [show, setShow] = useState();
    const [scannnerName, setScannnerName] = useState();
    const [isDisable , setIsDisable] = useState(false);
    const [Category, setCategory] = React.useState('');
    const [Categoryarr,setCategoryarray]=React.useState([])
   // const Categoryarr=["aa","dd"]
   
     const handleChangeCategory = (event) => {
       setCategory(event.target.value);
     };
const create=()=>{
  if(scannnerName && Category)
  handelClickCreateScanner(scannnerName,Category)
  else
{
  // handelClickCreateScanner();
  alert("Please Enter all details to create Scanner");
}
}

    const handleClose = () => {
      setShowCreatePopup(false);
    };
    const handelChange = (e) => {
      const value = e.target.value;
      setScannnerName(value);
      setError(false);
      setNameerror(false);
    };
  
useEffect(()=>{
  getCategoryStrategy();
},[])
const getCategoryStrategy=async()=>{ 
  try {
   const response=await axios.get(`${API_BASE_URL}/strategies/getScannerCategories`);
   if (response.data.success) {
    console.log("CATEGORYSCANNER",response.data.data);
    setCategoryarray(response.data.data);
 
  } else {
      console.log("Error")
  }
} catch (error) {
  console.log("Error :", error);
}
};
useEffect(()=>{
    console.log("++++",err)
},[err])
const handleKeyPress=(e)=>{
  if(e.key==="Enter")
  {
    e.preventdefault();
    create();
    
  }
  else
  {
    setNameerror(false);
  }
}
    return (
      <>
        <Modal
          show={showCreatePopup}
          onHide={handleClose}
          animation={false}
          centered
        >
          <Modal.Body
            id="create-strategy-modal"
            className="d-flex align-items-center justify-content-center"
          >
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Create new Scanner</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Scanner Name"
                  value={scannnerName}
                  onChange={handelChange}
                  autoComplete="off"
                  onKeyDown={handleKeyPress}
                />
                {nameError ? <span className="text-error">Name cannot be empty</span> :null}
                {err && <span className="text-error">Name already exist</span>}
              </Form.Group>
              <Form.Label>Select Category</Form.Label><br></br>
              
              <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Category}
        label="Age"
        style={{height:"50px"
        }}
        onChange={handleChangeCategory} 
        >
          {Categoryarr?.map((item)=>{
          return(
            <MenuItem value={item?.id}>{item?.name}</MenuItem>
          );
        })}
        </Select>
      </FormControl>
    </Box>
              <Button
                className="btn-color mt-4"
                variant="primary"
                disabled={isDisable} 
                onClick={() => create()}
                size="sm"
              >
                Create
              </Button>
              <br />
              <Button variant="default" onClick={handleClose} size="sm">
                Cancel
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  

export default ScannerModal