import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Form } from "react-bootstrap";
import IOSSwitch from "../Components/IOSSwitch";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
import { GetExpiredListApi } from "../api/adminapi"
import {GetActiveList,ExportActive,ExportExpired} from "../api/adminapi"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { BASE_URL } from "../config";
import { ResetDataList, SortByList } from "../Components/componentCommon/SortByList";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function  Subscriptions() {
  const [value, setValue] = React.useState(0);
  const [userRecords , setUserRecords] = useState([]);
  const [userAllData,setUserAllData] = useState([]);
  const [usersCount ,setUsersCount] = useState('');
  const [expiredSubscriptionsAll , setExpiredSubscriptionsAll] = useState([]);
  const [expiredSubscriptions , setExpiredSubscriptions] = useState([]);
  // sort and reset state //
  const [activeSearchQuery, setActiveSearchQuery] = useState("");
  const [expiredSearchQuery, setExpiredSearchQuery] = useState("");
  const [selectedSort, setSelectedSort] = useState('');      
  //
  const customStyles = {
    rows: {
      style: {
        border : "1px solid #8080805e", // override the row height
        minHeight: '40px',
      },
    },
    color:"#ffffff",
    headCells: {
      style: {
        border : "1px solid #8080805e",
        fontSize : "medium",
        fontWeight : "bold",
        borderBottom : "none",
        minHeight: '30px',
      },
    },
    cells: {
      style: {
        borderRight : "1px solid #8080805e", // override the row height
        // color:"#76838F"
        // height : "2px"
      },
    },
    
  };

  const columns =  [
    {
      id : "id",
      selector : row => row.id,
      omit : true
    },
    {
      name : "Name",
      selector : row => row.user_name,
      // sortable :true
    },
    {
      name : "Email",
      selector : row => row.email,
      // sortable :true
    },
    {
      name : "Phone",
      selector : row => row.phone,
    },
    {
      name : "Plan",
      selector : row => row.plan_name
    },
    {
      name : "From",
      selector :row=> row.start_date
    },
    {
      name : "To",
      selector :row=> row.end_date
    },
  
  ]
  const fetchUsersData = async() =>{
    const result = await GetActiveList();
  
    console.log("result@@@",result);
    setUserRecords(result?.data);
    setUserAllData(result?.data);
  }
  const fetchExpiredSubscriptions = async () => {
    const result =  await GetExpiredListApi();
    console.log("result@",result);
    setExpiredSubscriptionsAll(result?.data);
    setExpiredSubscriptions(result?.data);
  }
  useEffect(() => {
    fetchUsersData();
    fetchExpiredSubscriptions();
  } ,[]);
  const handleFilter = (event) => {
    const searchTerm = event.target.value?.toLowerCase();
    setActiveSearchQuery(searchTerm);
    const newData = userAllData?.filter(
      (row) =>
        row.user_name?.toLowerCase().includes(searchTerm) ||
        row.email?.toLowerCase().includes(searchTerm)
    );
    setUserRecords(newData);
    console.log("Filter", searchTerm, newData, userRecords);
  };
  const handleFilterExpired = (event) => {
    const searchTerm = event.target.value?.toLowerCase();
    setExpiredSearchQuery(searchTerm);
    const newData = expiredSubscriptionsAll?.filter(
      (row) =>
        row.user_name?.toLowerCase().includes(searchTerm) ||
        row.email?.toLowerCase().includes(searchTerm)
    );
    setExpiredSubscriptions(newData);
    console.log("Filter12", searchTerm, newData, expiredSubscriptions);
  };

  const exportActive=async()=>{
    const result=await ExportActive();
    console.log("Exported file",result);
    const downloadUrl = `${BASE_URL}/exports/${result.filename}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', result.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const handleExportExpired=async()=>{
    const result=await ExportExpired();
    console.log("Exported file",result);
    const downloadUrl = `${BASE_URL}/exports/${result.filename}`;
      const link = document.createElement('a');
      link.href = downloadUrl;
    link.setAttribute('download', result.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleReset = () => {
    setUserRecords(userAllData);
    setExpiredSubscriptions(expiredSubscriptionsAll);
    setActiveSearchQuery("");
    setExpiredSearchQuery('');
    setSelectedSort("");
  }
  return (
    <div className="page">
      <div className="page w-100">
        <div className="page-content">
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Subscriptions</h3>
            </div>
            <div
              className="row justify-content-center"
              style={{ margin: "0px 30px" }}
            >
              <Box
                sx={{
                  width: "100%",
                  margin: "10px auto",
                  backgroundColor: "white",
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Active" {...a11yProps(0)} />
                    <Tab label="Expired" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <div className="row mt-4">
                    <div className="col-xl-12 col-lg-12">
                      <div className="panel">
                        <div className="panel-heading">
                          <div className="flex items-center justify-between">
                            <Form className="d-flex align-items-baseline justify-content-start">
                              <Form.Label style={{ color: "#76838F" }}>
                                Search:
                              </Form.Label>
                              <Form.Control
                                className="w-25 float-end ms-2"
                                type="text"
                                size="md"
                                placeholder="Search..."
                                style={{ color: "#76838F" }}
                                onChange={handleFilter}
                                value={activeSearchQuery}
                              />
                            </Form>
                            <div className="d-flex panel-actions text-white">
                              <SortByList sortComponentName='subscription' sortByData={userRecords} setSortByData={setUserRecords} setSelectedSort={setSelectedSort} selectedSort={selectedSort} />
                              <ResetDataList handleReset={handleReset} />
                              <a
                                className="btn btn-success mx-2"
                                id="export-users"
                                onClick={exportActive}
                              >
                                <i className="fa fa-download"></i> Export
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="panel-body mt-4">
                          <DataTable
                            columns={columns}
                            data={userRecords}
                            pagination
                            fixedHeader
                            customStyles={customStyles}
                            highlightOnHover
                            striped
                            pointerOnHover
                            responsive
                          // onRowClicked={handleShowUser}
                          ></DataTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <div className="row mt-4">
                    <div className="col-xl-12 col-lg-12">
                      <div className="panel">
                        <div className="panel-heading">
                          <div className="flex items-center justify-between">
                            <Form className="d-flex align-items-baseline justify-content-start">
                              <Form.Label style={{ color: "#76838F" }}>
                                Search:
                              </Form.Label>
                              <Form.Control
                                className="w-25 float-end ms-2"
                                type="text"
                                size="md"
                                placeholder="Search..."
                                style={{ color: "#76838F" }}
                                onChange={handleFilterExpired}
                                value={expiredSearchQuery}
                              />
                            </Form>
                            <div className="d-flex panel-actions text-white">
                              <SortByList sortComponentName='subscription' sortByData={expiredSubscriptions} setSortByData={setExpiredSubscriptions} setSelectedSort={setSelectedSort} selectedSort={selectedSort} />
                              <ResetDataList handleReset={handleReset} />
                              <a
                                className="btn btn-success mx-2"
                                id="export-users"
                                onClick={handleExportExpired}
                              >
                                <i className="fa fa-download"></i> Export
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="panel-body mt-4">
                          <DataTable
                            columns={columns}
                            data={expiredSubscriptions}
                            pagination
                            fixedHeader
                            customStyles={customStyles}
                            highlightOnHover
                            striped
                            pointerOnHover
                            responsive
                          // onRowClicked={handleShowUser}
                          ></DataTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  );
}

