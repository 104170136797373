
const formateFnObject = (item, functionName) => {
  const comparisonOperators = ["<", ">", "<=", ">="];

  console.log("FINDFUNCTIon", item, functionName)
  if (functionName && functionName.length > 1) {
    const lastFunctionValue = functionName[functionName.length - 1];
    const functionValues = lastFunctionValue.split(",");
    console.log("functionValues", functionValues, item)
    const includesComparisonOperator = comparisonOperators.some((operator) =>
      lastFunctionValue.includes(operator)
    );

    const comparatorObject = includesComparisonOperator
      ? { comparator: lastFunctionValue }
      : {};
      if(functionValues?.length === 8) {
        if (item?.name === "Alligator") {
          const newItem = {
            ...Object.fromEntries(
              Object.entries({
                ...item,
                function: functionName || null,
                signal: functionValues.length > 0 ? functionValues[0] : null,
                jawPeriod: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
                jawOffset: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
                teethPeriod: functionValues.length > 0 ? parseInt(functionValues[3]) : null,
                teethOffset: functionValues.length > 0 ? parseInt(functionValues[4]) : null,
                lipsPeriod: functionValues.length > 0 ? parseInt(functionValues[5]) : null,
                lipsOffset: functionValues.length > 0 ? parseInt(functionValues[6]) : null,
                offset: functionValues.length > 0 ? parseInt(functionValues[7]) : null,
              }).filter(([_, value]) => value !== null && value !== "")
            )
          }

          return newItem;
         }
      }
    if (functionValues?.length === 7) {
      if (item?.name === "Bollinger %B Moving Average") {
         const newItem = {
          ...Object.fromEntries(
            Object.entries({
               ...item,
               function : functionName || null,
               field : functionValues.length > 0 ? functionValues[0] : null,
              bPeriod : functionValues.length > 0 ?parseInt(functionValues[1]) : null ,
              deviation : functionValues.length > 0 ? parseInt(functionValues[2]) : null ,
              bType : functionValues.length > 0 ? functionValues[3] : null ,
              maType : functionValues.length > 0 ? functionValues[4] : null ,
              maPeriod: functionValues.length > 0 ? parseInt(functionValues[5]) : null,
               offset :  functionValues.length > 0 ? parseInt(functionValues[6]) : null ,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        }

        return newItem ;
      }
    }
    if(functionValues?.length === 6) {
      if (item?.name === "TSI (True Strength Indicator)") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
               ...item,
               function : functionName || null,
               field : functionValues.length > 0 ? functionValues[0] : null,
               type : functionValues.length > 0 ? functionValues[1] : null ,
               long : functionValues.length > 0 ? parseInt(functionValues[2]) : null ,
               short : functionValues.length > 0 ? parseInt(functionValues[3]) : null ,
               signal : functionValues.length > 0 ? parseInt(functionValues[4]) : null ,
               offset :  functionValues.length > 0 ? parseInt(functionValues[5]) : null ,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        }

        return newItem ;
      }
      if (item?.name === "Schaff Trend Cycle") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              field: functionValues.length > 0 ? functionValues[1] : null,
              short: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              long: functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              maType: functionValues.length > 0 ? functionValues[4] : null,
              offset: functionValues.length > 0 ? parseInt(functionValues[5]) : null,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        }
        return newItem;
      }
      if (item?.name === "Stochastic Momentum Index") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              type: functionValues.length > 0 ? functionValues[0] : null,
              kPeriod: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              smoothPeriod: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              doublePeriod: functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              dPeriod: functionValues.length > 0 ? parseInt(functionValues[4]) : null,
              offset: functionValues.length > 0 ? parseInt(functionValues[5]) : null,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        }
        return newItem;
      }
    }
    if (functionValues.length === 5) {
      console.log("arrLength", item?.name)
      if (item?.name === "Bollinger %B") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              field: functionValues.length > 0 ? functionValues[0] : null,
              period:
                functionValues.length > 0 ? parseInt(functionValues[1], 10) : null,
              deviation: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              type: functionValues.length > 0 ? functionValues[3] : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
         
        };

        if (
          (newItem.field &&
            newItem.period &&
            newItem.deviation &&
            newItem.type) ||
          newItem.offset === 0
        )
          return newItem;
      };
      if(item?.name === "Standard Deviation"){
        const newItem = {
          ... Object.fromEntries(
             Object.entries({
               ...item,
               function: functionName || null,
               period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
               field: functionValues.length > 0 ? functionValues[1] : null,
               stdDev: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
               maType:
                 functionValues.length > 0 ? functionValues[3] : null,
               offset:
                 functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
               ...comparatorObject,
             }).filter(([_, value]) => value !== null && value !== "")
          )
          // ...item,
          // function: functionName || null,
          // period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
          // field: functionValues.length > 0 ? functionValues[1] : null,
          // stdDev: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
          // maType:
          //   functionValues.length > 0 ? functionValues[3] : null,
          // offset:
          //   functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
          // ...comparatorObject,
        };
          return newItem;
      }
      if (item?.name === "UBB (Upper Bollinger Band)" || item?.name === "MBB (Middle Bollinger Band)" || item?.name === "LBB (Lower Bollinger Band)" || item?.name === "Bollinger Bandwidth"){
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              field: functionValues.length > 0 ? functionValues[0] : null,
              period: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              deviation: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              maType:
                functionValues.length > 0 ? functionValues[3] : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "NVI (Negative Volume Index)" || item?.name === "PVI (Positive Volume Index)" ) {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              field: functionValues.length > 0 ? functionValues[0] : null,
              period: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              type: functionValues.length > 0 ? functionValues[2] : null,
              line:
                functionValues.length > 0 ? functionValues[3] : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "TII (Trend Intensity Index)") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              field: functionValues.length > 0 ? functionValues[0] : null,
              type: functionValues.length > 0 ? functionValues[1] : null,
              period: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              signal:
                functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "Phase Power Moving Average") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              field: functionValues.length > 0 ? functionValues[0] : null,
              powerLength: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              phase: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              power:
                functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "Detrended Price Oscillator") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              field: functionValues.length > 0 ? functionValues[0] : null,
              period: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              type: functionValues.length > 0 ? functionValues[2] : null,
              displacement:
                functionValues.length > 0 ? functionValues[3] : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "Klinger Volume Oscillator") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period: functionValues.length > 0 ? parseInt(functionValues[0]): null,
              short: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              long: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              type:
                functionValues.length > 0 ? functionValues[3] : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "True Range Moving Average" ) {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              type: functionValues.length > 0 ? functionValues[1] : null,
              maType: functionValues.length > 0 ? functionValues[2] : null,
              maPeriod:
                functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "Vortex Moving Average") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              signal: functionValues.length > 0 ? functionValues[1] : null,
              type: functionValues.length > 0 ? functionValues[2] : null,
              maPeriod:
                functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "Keltner Channel") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              band: functionValues.length > 0 ? functionValues[1] : null,
              maType: functionValues.length > 0 ? functionValues[2] : null,
              shift:
                functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "ADX Moving Average (Average Directional Index Moving Average)") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              type: functionValues.length > 0 ? functionValues[1] : null,
              maType: functionValues.length > 0 ? functionValues[2] : null,
              maPeriod:
                functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "Chande Kroll Stop") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              p: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              x:
                functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              q:
                functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              mode: functionValues.length > 0 ? functionValues[3] === "stopsh||t" ? "stopshort" : functionValues[3] : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "Price Rate Of Change Moving Average (Proc Moving Average)") {

        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              field: functionValues.length > 0 ? functionValues[0] : null,
              period: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              type:
                functionValues.length > 0 ? functionValues[2] : null,
              maPeriod:
                functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "MACD OI (Moving Average Convergence/Divergence Open Interest)"){
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period1:
                functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              period2:
                functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              period3:
                functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              type: functionValues.length > 0 ? functionValues[3] : null ,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        }
        return newItem;
      }
      if (item?.name === "ATR Bands") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              shift: functionValues.length > 0 ? functionValues[1] : null,
              field: functionValues.length > 0 ? functionValues[2] : null,
              band:
                functionValues.length > 0 ? functionValues[3] : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
      if (item?.name === "Coppock curve") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              field: functionValues.length > 0 ? functionValues[0] : null,
              period: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              sroc: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              lroc:
                functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[4], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      } 
      if (item?.name === "Moving Average Deviation (MA Dev)") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              field: functionValues.length > 0 ? functionValues[0] : null,
              period: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              type: functionValues.length > 0 ? functionValues[2] : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[3]) : null,
              pop:
                functionValues.length > 0 ? functionValues[4] : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
    }
    if (functionValues.length === 4) {

      if (item?.name === "RSI Moving Average") {
        const newItem = {
          ... Object.fromEntries(
             Object.entries({
               ...item,
               function: functionName || null,
               period:
                 functionValues.length > 0 ? parseInt(functionValues[0]) : null,
               maType: functionValues.length > 0 ? functionValues[1] : null,
               maPeriod:
                 functionValues.length > 0 ? parseInt(functionValues[2]) : null,
               offset:
                 functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
               ...comparatorObject,
             }).filter(([_, value]) => value !== null && value !== "")
          )
          
        };

        return newItem;
      }
      if (item?.name === "MACD (Moving Average Convergence/Divergence)") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period1:
                functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              period2:
                functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              period3:
                functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
          
        };

        if (
          newItem.period1 ||
          newItem.period2 ||
          newItem.period3 === 0 ||
          newItem.offset === 0
        )
          return newItem;
      }
      if (item?.name === "MACD-signal") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period1:
                functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              period2:
                functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              period3:
                functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
          
        };

        if (
          newItem.period1 ||
          newItem.period2 ||
          newItem.period3 === 0 ||
          newItem.offset === 0
        )
          return newItem;
      }
      if (item?.name === "MACD-histogram") {
        const newItem = {
         ...Object.fromEntries(
              Object.entries({
                ...item,
                function: functionName || null,
                period1:
                  functionValues.length > 0 ? parseInt(functionValues[0]) : null,
                period2:
                  functionValues.length > 0 ? parseInt(functionValues[1]) : null,
                period3:
                  functionValues.length > 0 ? parseInt(functionValues[2]) : null,
                offset:
                  functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
                ...comparatorObject,
              }).filter(([_, value]) => value !== null && value !== "")
          )
          
        };

        if (
          newItem.period1 ||
          newItem.period2 ||
          newItem.period3 === 0 ||
          newItem.offset === 0
        )
          return newItem;
      }
      if (item?.name === "Donchian Channel") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              channel: functionValues.length > 0 ? functionValues[0] : null,
              period:
                functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              period1:
                functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
          
        };

        if (
          newItem.channel ||
          newItem.period ||
          newItem.period1 === 0 ||
          newItem.offset === 0
        )
          return newItem;
      }
      if (item?.name === "Stochastic" || item?.name === "Stochastic RSI") {
        console.log("functionValues", functionValues)
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period:
                functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              type: functionValues.length > 0 ? functionValues[1] : null,
              smooth: functionValues.length > 0 ? functionValues[2] : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
          
        };

        if (
          newItem.channel ||
          newItem.period ||
          newItem.period1 === 0 ||
          newItem.offset === 0
        )
          return newItem;
      }

      if (
        item?.name === "MFI Moving Average (Money Flow Index Moving Average)"
      ) {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              period:
                functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              maType: functionValues.length > 0 ? functionValues[1] : null,
              maPeriod:
                functionValues.length > 0 ? parseInt(functionValues[2]) : null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
          
        };

        if (
          (newItem.period && newItem.maType) ||
          newItem.period === 0 ||
          newItem.offset === 0
        )
          return newItem;
      }
      if (item?.name === "Ultimate Oscillator"){
        const newItem = {
          ...item ,
          function : functionName || null ,
          length1: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
          length2: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
          length3: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
          offset: functionValues.length > 0 ? parseInt(functionValues[3]) : null,
        };
          return newItem ;
      }
      if (item?.name === "Option Greeks"){
        const newItem = {
          ...item,
          function: functionName || null,
          type: functionValues.length > 0 ? functionValues[0] : null,
          dividend: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
          interest: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
          offset: functionValues.length > 0 ? parseInt(functionValues[3]) : null,
        };
        return newItem;
      }
      if (item?.name === "VWAP Standard Deviation") {
        const newItem = {
          ...item,
          function: functionName || null,
          period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
          dev: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
          type: functionValues.length > 0 ? functionValues[2] : null,
          offset: functionValues.length > 0 ? parseInt(functionValues[3]) : null,
        };
        return newItem;
      }
      if (item?.name === "Median Price Moving Average") {
        const newItem = {
          ...item,
          function: functionName || null,
          period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
          type: functionValues.length > 0 ? functionValues[1] : null,
          maPeriod: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
          offset: functionValues.length > 0 ? parseInt(functionValues[3]) : null,
        };
        return newItem;
      }
      if (item?.name === "Moving Average") {
        const newItem = {
          ...item,
          function: functionName || null,
          field: functionValues.length > 0 ? functionValues[0] : null,
          period: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
          type: functionValues.length > 0 ? functionValues[2] : null,
          offset: functionValues.length > 0 ? parseInt(functionValues[3]) : null,
        };
        return newItem;
      }
      if (item?.name === "Intraday Momentum Index Moving Average (IMI Moving Average)" || item?.name === "Choppiness Index Moving Average" || item?.name === "CCI Moving Average (Commodity Channel Index Moving Average)") {
        const newItem = {
          ...item,
          function: functionName || null,
          period: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
          type: functionValues.length > 0 ? functionValues[1] : null,
          maPeriod: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
          offset: functionValues.length > 0 ? parseInt(functionValues[3]) : null,
        };
        return newItem;
      }
      if (item?.name === "Volume Oscillator") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              short: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              long: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
              pop: functionValues.length > 0 ? functionValues[2] : null,
              offset: functionValues.length > 0 ? parseInt(functionValues[3]) : null,
            }).filter(([_, value]) => value !== null && value !== "")
          )

        };

        return newItem;

      }
    }

    if (functionValues.length === 1) {
      if (item?.name === "Central Pivot Range") {

        const newItem = {
          ...item,
          function: functionName || null,
          signal: functionValues.length > 0 ? functionValues[0] : null,
          ...comparatorObject,
        }
        return newItem;
      }
      if (item?.name === 'Camarilla Pivot Points') {
        console.log("MERGEDATA", item)

        const newItem = {
          ...item,
          function: functionName || null,
          signal: functionValues.length > 0 ? functionValues[0] : null,
          ...comparatorObject,
        }
        return newItem;
      }
      if (item.name === "Number") {

        const newItem = {
          ...item,
          function: functionName || null,
          period:
            functionValues.length > 0 ? parseInt(functionValues[0]) : null,
          ...comparatorObject,
        };
        // Push the modified item to the current array
        if (newItem.period || newItem.period === 0) return newItem;
      }  if (item.name === "Close") {
        const newItem = {
          ...item,
          function: functionName || null,
          offset:
            functionValues.length > 0 ? parseInt(functionValues[0]) : null,
          ...comparatorObject,
        };
        // Push the modified item to the current array
        if (newItem.offset || newItem.offset === 0) return newItem;
      } if (item?.name === "Day High Low") {
        const newItem = {
         ... Object.fromEntries(
           Object.entries({
             ...item,
             function: functionName || null,
             option:
               functionValues.length > 0 ? functionValues[0] : null,
             ...comparatorObject,
           }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
       if (
        item?.name === "Volume of a candle" ||
        item?.name === "OBV (On Balance Volume)" ||
        item?.name === "High price" ||
        item?.name === "Low price" ||
        item?.name === "Open price" ||
        "VWAP (Volume Weighted Average Price)" ||
        "Awesome Oscillator" ||
        "TR (True Range)" || item?.name === "Open interest"
      ) {
        const newItem = {
          ... Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              offset:
                functionValues.length > 0 ? parseInt(functionValues[0]) : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }
     
      if (item?.name === "Candle Time") {
        const newItem = {
          ...Object.fromEntries(
            Object.entries({
              ...item,
              function: functionName || null,
              type: functionValues.length > 0 ? functionValues[0] : null,
              ...comparatorObject,
            }).filter(([_, value]) => value !== null && value !== "")
          )
        };
        return newItem;
      }

    }

    if (functionValues.length === 2) {
      if (item.name === "Swing Index") {
        const newItem = {
          ...item,
          function: functionName || null,
          limit:
            functionValues.length > 0 ? functionValues[0] : null,
          offset:
            functionValues.length > 0 ? parseInt(functionValues[1], 10) : null,
          ...comparatorObject,
        };
        // Push the modified item to the current array
        return newItem;
      }
      if (item.name === "Trade Volume Index") {
        const newItem = {
          ...item,
          function: functionName || null,
          value:
            functionValues.length > 0 ? functionValues[0] : null,
          offset:
            functionValues.length > 0 ? parseInt(functionValues[1], 10) : null,
          ...comparatorObject,
        };
        // Push the modified item to the current array
        return newItem;
      }
      if (item.name === "Opening Range") {
        const newItem = {
          ...item,
          function: functionName || null,
          field:
            functionValues.length > 0 ? functionValues[0] : null,
          range:
            functionValues.length > 0 ? functionValues[1] : null,
          ...comparatorObject,
        };
        // Push the modified item to the current array
        return newItem;
      }
      else {
        const newItem = {
          ...item,
          function: functionName || null,
          period:
            functionValues.length > 0 ? parseInt(functionValues[0], 10) : null,
          offset:
            functionValues.length > 0 ? parseInt(functionValues[1], 10) : null,
          ...comparatorObject,
        };
        // Push the modified item to the current array
        return newItem;
      }
      
    }

    if (functionValues.length === 3) {
      if (item.name === "Vortex Indicator") {
        const newItem = {
          ...item,
          function: functionName || null,
          period:
            functionValues.length > 0 ? parseInt(functionValues[0]) : null,
          signal: functionValues.length > 1 ? functionValues[1] : null,
          offset:
            functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
          ...comparatorObject,
        };
        // Push the modified item to the current array
        return newItem;
      } else if (item.name === "Parabolic SAR") {
        const newItem = {
          ...item,
          function: functionName || null,
          minAF: functionValues.length > 0 ? parseFloat(functionValues[0]) : null,
          maxAF: functionValues.length > 1 ? parseFloat(functionValues[1]) : null,
          offset:
            functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
          ...comparatorObject,
        };
        return newItem;
      } else if (item.name === "Supertrend") {
        const newItem = {
          ...item,
          function: functionName || null,
          period:
            functionValues.length > 0 ? parseInt(functionValues[0]) : null,
          multiplier:
            functionValues.length > 1 ? parseFloat(functionValues[1]) : null,
          offset:
            functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
          ...comparatorObject,
        };
        // Push the modified item to the current array
        return newItem;
      } else if (item.name === "Nth Candle") {
        const newItem = {
           ...item ,
           function : functionName || null ,
          field: functionValues.length > 0 ? functionValues[0] : null,
          range: functionValues.length > 0 ? functionValues[1] : null,
          num: functionValues.length > 0 ? parseInt(functionValues[2]) : null,

        }
        return newItem ;
      }
      else if (item?.name === "Pivot Points") {
        const newItem = {
          ...item,
          function: functionName || null,
          signal: functionValues.length > 0 ? functionValues[0] : null,
          type: functionValues.length > 0 ? functionValues[1] == "st&&ard" ? "standard" : functionValues[1] : null,
          continous: functionValues.length > 0 ? functionValues[2] : null,

        }
        return newItem;
      } else if (item?.name === "Elder Ray Index") {
        const newItem = {
          ...item,
          function: functionName || null,
          power: functionValues.length > 0 ? functionValues[0] : null,
          period:
            functionValues.length > 1 ? parseInt(functionValues[1], 10) : null,
          offset:
            functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
          ...comparatorObject,
        };
        console.log("PRICERATE", newItem);
        // Push the modified item to the current array
        return newItem;
      } else if (item?.name === "ATR Trailing Stoploss") {
        const newItem = {
          ... item,
          function : functionName || null ,
          multiplier: functionValues.length > 0 ? parseInt(functionValues[0], 10) : null,
          period:
            functionValues.length > 1 ? parseInt(functionValues[1], 10) : null,
          offset:
            functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
          ...comparatorObject,
        }
        return newItem ;
      } else if (item?.name === "Open Interest Moving Average") {
        const newItem = {
          ...item,
          function: functionName || null,
          type: functionValues.length > 0 ? functionValues[0] : null,
          period:
            functionValues.length > 1 ? parseInt(functionValues[1], 10) : null,
          offset:
            functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
          ...comparatorObject,
        }
        return newItem;
      } else if (item?.name === "VWAP Moving Average" || item?.name === "OBV Moving Average (On Balance Volume Moving Average)") {
        const newItem = {
           ...Object.fromEntries(
             Object.entries({
               ...item,
               function: functionName || null,
               type: functionValues.length > 0 ? functionValues[0] : null,
               period:
                 functionValues.length > 1 ? parseInt(functionValues[1], 10) : null,
               offset:
                 functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
               ...comparatorObject,
             }).filter(([_, value]) => value !== null && value !== "") 
           )
         
        }
        return newItem;
      } else if (item?.name === "Moving Average Volume") {
        const newItem = {
          ...item,
          function: functionName || null,
          type: functionValues.length > 0 ? functionValues[0] : null,
          period:
            functionValues.length > 1 ? parseInt(functionValues[1], 10) : null,
          offset:
            functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
          ...comparatorObject,
        };
        return newItem;
      }
       else if (item?.name === "Ehler Fisher") {
        const newItem = {
          ...item,
          function: functionName || null,
          period:
            functionValues.length > 1 ? parseInt(functionValues[0], 10) : null,
          line: functionValues.length > 0 ? functionValues[1] : null,
          offset:
            functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
          ...comparatorObject,
        }
        return newItem;
      }
      if (item?.name === "Previous N") {
        const newItem = {
          ...item,
          function: functionName || null,
          field:
            functionValues.length > 0 ? functionValues[0] : null,
          offset:
            functionValues.length > 0 ? parseInt(functionValues[1], 10) : null,
          candle: functionValues.length > 0 ? functionValues[2] : null,
          ...comparatorObject,
        }
        return newItem;
      }
       else {
        const newItem = {
          ...item,
          function: functionName || null,
          field: functionValues.length > 0 ? functionValues[0] : null,
          period:
            functionValues.length > 1 ? parseInt(functionValues[1], 10) : null,
          offset:
            functionValues.length > 2 ? parseInt(functionValues[2], 10) : null,
          ...comparatorObject,
        };
        // Push the modified item to the current array
        return newItem;
      }
    }

    if (item.name === "Ichimoku") {
      const newItem = {
        ...item,
        function: functionName || null,
        conversion: functionValues.length > 0 ? parseInt(functionValues[0]) : null,
        base: functionValues.length > 0 ? parseInt(functionValues[1]) : null,
        leadingSpanB: functionValues.length > 0 ? parseInt(functionValues[2]) : null,
        laggingSpan:
          functionValues.length > 0 ? parseInt(functionValues[3], 10) : null,
        line : functionValues.length > 0 ? functionValues[4] : null,
        cloudshift : functionValues.length > 0 ? functionValues[5] : null,
        offset:
          functionValues.length > 0 ? parseInt(functionValues[6], 10) : null,
        ...comparatorObject,
      };
        return newItem;
    }
    
  }
};

export default formateFnObject;
