import React, { useEffect, useState, useRef } from "react";
import {
    UploadStacticContentImage,
    GetImagesData,
    RemoveImages,
} from "../api/stacticcontentmanageapi";
import CustomAlert from "../Components/componentCommon/CustomAlert";
import { IMAGE_URL } from "../config";

const StaticContentManage = () => {
    const [selectNavbarImage, setSelectNavbarImage] = useState(null);
    const [navbarImagePreview, setNavbarImagePreview] = useState(null);
    const [selectFeaturedImage, setSelectFeaturedImage] = useState(null);
    const [featuredImagePreview, setFeaturedImagePreview] = useState(null);
    const [isNavbarImageUploaded, setIsNavbarImageUploaded] = useState(false);
    const [isStockImageUploaded, setIsStockImageUploaded] = useState(false);
    const [imagesData, setImagesData] = useState([]);
    const [alertMessage, setAlertMessage] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(null);

    const navbarInputRef = useRef(null);
    const featuredInputRef = useRef(null);

    const handleNavbarImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectNavbarImage(file);
            setNavbarImagePreview(URL.createObjectURL(file));
            setIsNavbarImageUploaded(false);
        }
    };

    const handleFeaturedImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectFeaturedImage(file);
            setFeaturedImagePreview(URL.createObjectURL(file));
            setIsStockImageUploaded(false);
        }
    };

    const uploadTopNavBarImage = async (e) => {
        e.preventDefault();

        if (!selectNavbarImage) {
            setAlertMessage({
                message: "Please select an image first.",
                type: "alert-warning",
            });
            return;
        }

        const endpoint = "AdminAddTopNavBarImage";
        const fileKey = "navbar_image";
        try {
            const result = await UploadStacticContentImage(
                endpoint,
                selectNavbarImage,
                fileKey
            );
            console.log("@@navbarImage", result);
            if (result.success) {
                setAlertMessage({
                    message: "Navbar Image uploaded successfully.",
                    type: "alert-success",
                });
                setIsNavbarImageUploaded(true);
                setNavbarImagePreview(null);
                setSelectNavbarImage(null);
                if (navbarInputRef.current) {
                    navbarInputRef.current.value = "";
                }
                await fetchImageData();
            }
        } catch (error) {
            setAlertMessage({
                message: "Error uploading Navbar Image.",
                type: "alert-danger",
            });
        }
    };

    const uploadFeaturedImage = async (e) => {
        e.preventDefault();

        if (!selectFeaturedImage) {
            setAlertMessage({
                message: "Please select an image first.",
                type: "alert-warning",
            });
            return;
        }

        const endpoint = "AdminAddFeaturedImage";
        const fileKey = "featured_image";
        try {
            const result = await UploadStacticContentImage(
                endpoint,
                selectFeaturedImage,
                fileKey
            );
            console.log("@featueImage", result);
            if (result.success) {
                setAlertMessage({
                    message: "Featured Image uploaded successfully.",
                    type: "alert-success",
                });
                setIsStockImageUploaded(true);
                setFeaturedImagePreview(null);
                setSelectFeaturedImage(null);
                if (featuredInputRef.current) {
                    featuredInputRef.current.value = "";
                }
                await fetchImageData();
            }
        } catch (error) {
            setAlertMessage({
                message: "Error uploading Featured Image.",
                type: "alert-danger",
            });
        }
    };

    const fetchImageData = async () => {
        try {
            const result = await GetImagesData();
            console.log("@getRes:", result);
            if (result) {
                setImagesData(result);
            } else {
                console.error("Invalid response structure: ", result);
            }
        } catch (error) {
            console.error("Error fetching image data: ", error);
        }
    };

    const confirmDeleteImage = (id, type) => {
        setConfirmDelete({ id, type });
    };

    const handleDeleteConfirmation = async () => {
        if (!confirmDelete) return;

        const { id, type } = confirmDelete;
        try {
            const colName = type === "navbar" ? "top_navbar_image" : "featured_image";
            const result = await RemoveImages(id, colName);
            if (result) {
                setImagesData((prevImages) =>
                    prevImages.filter((image) => image.id !== id)
                );
                setAlertMessage({
                    message: `${type === "navbar" ? "Navbar" : "Featured"
                        } image deleted successfully.`,
                    type: "alert-success",
                });
                setIsNavbarImageUploaded(false);
                setIsStockImageUploaded(false);
                await fetchImageData();
            }
        } catch (error) {
            setAlertMessage({
                message: `Error deleting ${type === "navbar" ? "Navbar" : "Featured"
                    } image.`,
                type: "alert-danger",
            });
        } finally {
            setConfirmDelete(null);
        }
    };

    const cancelDelete = () => {
        setConfirmDelete(null);
    };

    useEffect(() => {
        fetchImageData();
    }, []);

    return (
        <>
            {alertMessage && (
                <CustomAlert
                    message={alertMessage.message}
                    type={alertMessage.type}
                    setAlertMessage={() => setAlertMessage(null)}
                />
            )}
            {confirmDelete && (
                <CustomAlert
                    message={`Are you sure you want to delete this ${confirmDelete.type === "navbar" ? "Navbar" : "Featured"
                        } image?`}
                    type="alert-warning"
                    setAlertMessage={() => setConfirmDelete(null)}
                    onConfirm={handleDeleteConfirmation}
                    onCancel={cancelDelete}
                    isConfirmation
                />
            )}
            <div className="page w-100">
                <div className="page-content">
                    <div className="panel">
                        <div className="panel-heading">
                            <h4 className="panel-title">Static Content Management</h4>

                            <div className="panel-actions text-white"></div>
                        </div>
                        <div className="panel-body mt-3">
                            <h5 className="font-size-12">Logo Management</h5>
                            <div className="my-4">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="card">
                                            <h6 className="card-header">Top Navbar Logo</h6>
                                            <div className="card-body text-center m-2">
                                                {imagesData.map((image, index) => (
                                                    <>
                                                        <div className="m-4 image-container" key={index}>
                                                            {image?.top_navbar_image ? (
                                                                <img
                                                                    src={`${IMAGE_URL}${image.top_navbar_image}`}
                                                                    title={image.top_navbar_image}
                                                                    alt=""
                                                                    accept="image/*"
                                                                />
                                                            ) : navbarImagePreview ? (
                                                                <img
                                                                    src={navbarImagePreview}
                                                                    alt="Preview"
                                                                    accept="image/*"
                                                                />
                                                            ) : (
                                                                <p className="p-4">Upload another image</p>
                                                            )}
                                                        </div>
                                                        <form onSubmit={uploadTopNavBarImage}>
                                                            <div className="mb-4">
                                                                <input
                                                                    ref={navbarInputRef}
                                                                    className="form-control"
                                                                    type="file"
                                                                    onChange={handleNavbarImageChange}
                                                                />
                                                            </div>
                                                            <div>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary m-3"
                                                                    disabled={isNavbarImageUploaded}
                                                                >
                                                                    Upload
                                                                </button>
                                                                {image?.top_navbar_image && (
                                                                    <button
                                                                        className="btn btn-danger"
                                                                        onClick={() =>
                                                                            confirmDeleteImage(image.id, "navbar")
                                                                        }
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </form>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="card">
                                            <h6 className="card-header">Stock Logo</h6>
                                            <div className="card-body text-center m-2">
                                                {imagesData.map((image, index) => (
                                                    <>
                                                        <div className="m-4 image-container" key={index}>
                                                            {image?.featured_image ? (
                                                                <img
                                                                    src={`${IMAGE_URL}${image.featured_image}`}
                                                                    title={image.featured_image}
                                                                    alt=""
                                                                    accept="image/*"
                                                                />
                                                            ) : featuredImagePreview ? (
                                                                <img
                                                                    src={featuredImagePreview}
                                                                    alt="Preview"
                                                                    accept="image/*"
                                                                />
                                                            ) : (
                                                                <p className="p-4">Upload another image</p>
                                                            )}
                                                        </div>
                                                        <form onSubmit={uploadFeaturedImage}>
                                                            <div className="mb-4">
                                                                <input
                                                                    ref={featuredInputRef}
                                                                    className="form-control"
                                                                    type="file"
                                                                    onChange={handleFeaturedImageChange}
                                                                />
                                                            </div>
                                                            <div>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary m-3"
                                                                    disabled={isStockImageUploaded}
                                                                >
                                                                    Upload
                                                                </button>
                                                                {image?.featured_image && (
                                                                    <button
                                                                        className="btn btn-danger"
                                                                        onClick={() =>
                                                                            confirmDeleteImage(image.id, "featured")
                                                                        }
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </form>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StaticContentManage;
