import axios from "axios";
import { API_BASE_URL } from "../config";
// const API_BASE_URL = "http://192.168.1.22:8008/api/v1";
// const API_BASE_URL = "http://3.111.147.17:8008/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "http://localhost:8008/api/v1";

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const paisaCreateAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaAddAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const paisaGetAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaGetAccount`,
      data
    );
    console.log("response", response);
    if (response.data.success) {
      return response.data.accountDetails;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const paisaDeleteAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaDeleteAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
export const getallfeedback=async()=>{
  try {
    const response = await axios.get(
      `${API_BASE_URL}/users/get-all-feedbacks`);
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
}
// https://dalrotibackend.shrikanttapkeer.com/api/v1/users/get-all-feedbacks-popup
export const getallfeedbacks=async(data)=>{
  try {
    const response = await axios.post(
      `${API_BASE_URL}/users/get-all-feedbacks-popup`,data);
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
}
export const paisaEditAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaEditAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
// http://localhost:8008/api/v1
export const getintouchdetails= async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/get-in-touch`);
    console.log("+++",response);
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};
