import axios from "axios";
import { API_BASE_URL } from "../config";
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "http://192.168.0.128:8008/api/v1";

// const API_BASE_URL = "http://192.168.1.22:8008/api/v1";
// const API_BASE_URL = "http://3.111.147.17:8008/api/v1"; // live URL
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "http://localhost:8008/api/v1";
export const fetchingdemonumbers=async()=>{
  try {  
    const response = await axios.get(`${API_BASE_URL}/dashboard/getDemoRequests`);
    if (response.data.success) {
      return response.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
}
export const update_status=async(phone)=>{
  try {  
    const response = await axios.post(`${API_BASE_URL}/dashboard/updateDemoStatus`,{phone:phone,status:1});
    if (response.data) {
      console.log("first",response.data);
      return response.data.success;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
}
export const freeSubscribeApi = async (user) => {
  try {
    console.log("PPPPPP", user);
    const responce = await axios.post(
      `${API_BASE_URL}/sales/AddFreePack`,
      user
    );
    console.log("users-data", responce);
    if (responce.data.success) {
      return responce.data.success;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const primeSubscribeApi = async () => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/`);
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getActivePlanApi = async (user) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/users/get-active-plan`,
      user
    );
    console.log("users-data", responce);
    if (responce.data.success) {
      return responce.data.activePlan;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const requestPaymentApi = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/sales/request-payment`,
      data
    );
    if (responce.data.success) {
      return responce.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const paymenHandler = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sales/confirm`, data);
    if (response.data.success) {
      console.log("responce", response);
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error : ", error);
  }
};
export const getAllAccounts = async (user) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/GetAllAccounts`,
      user
    );
    if (response.data.success) {
      console.log("GETALLACC", response?.data);
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error in getting all demat acc", error);
  }
};


