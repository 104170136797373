import React, { useEffect, useMemo, useState } from "react";

import ZerodhaAddAccountMsg from "./zerodha/ZerodhaAddAccountMsg";
import ZerodhaSignUp from "./zerodha/ZerodhaSignUp";
import ZerodhaDetails from "./zerodha/ZerodhaDetails";
import { useNavigate } from "react-router-dom";

const ZerodhaPage = (props) => {
  const navigate = useNavigate();

  const {
    setShow,
    setConfirmationMsg,
    isDeleteAccount,
    setIsDeleteAccount,
    isAccountAdd,
    setIsAccountAdd,
    featchAccountDetails,
    zerodhaData,
    setActivePage,
  } = props;

  const memoizedComponent = useMemo(() => {
    return isAccountAdd === "newAccount" ? (
      <ZerodhaAddAccountMsg setIsAccountAdd={setIsAccountAdd} />
    ) : isAccountAdd === "signupform" ? (
      <ZerodhaSignUp
        zerodhaData={zerodhaData}
        setIsAccountAdd={setIsAccountAdd}
        featchAccountDetails={featchAccountDetails}
      />
    ) : isAccountAdd === "detailsform" ? (
      <ZerodhaDetails
        zerodhaData={zerodhaData}
        setIsAccountAdd={setIsAccountAdd}
        setShow={setShow}
        setConfirmationMsg={setConfirmationMsg}
        isDeleteAccount={isDeleteAccount}
        setIsDeleteAccount={setIsDeleteAccount}
        featchAccountDetails={featchAccountDetails}
      />
    ) : null;
  }, [
    isAccountAdd,
    setConfirmationMsg,
    isDeleteAccount,
    zerodhaData,
    setShow,
    setIsDeleteAccount,
  ]);
  const handleNavigateBack = (page) => {
    navigate(page);
    setActivePage(page);
  };
  return (
    <>
      <div className="d-flex mt-3">
        <div className="mt-2">
          <i
            className="fa fa-chevron-left pointer-cursor back-button"
            onClick={() => handleNavigateBack("/account?tab=trading")}
          ></i>
        </div>
        <div
          className="d-flex ms-2"
          style={{ width: "100%" }}
        >
          {memoizedComponent}
        </div>
      </div>
    </>
  );
}

export default ZerodhaPage;
