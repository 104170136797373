import React , {useState , useEffect} from "react";
import ScannerModal from "../Components/scanner/ScannerModal";
import { useNavigate } from "react-router-dom";
import { existScannerNameApi, getAllDiscoveredScannerApi } from "../api/scannerapi";
import { getUser } from "../Session";
import { Button } from "react-bootstrap";
import { deleteScanner } from "../api/scannerapi";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "../Components/componentCommon/myspinner.css";
import Pagination from "../Components/componentCommon/Pagination";
import InputSearch from "../Components/componentCommon/InputSearch";
import { ResetList, SortData } from "../Components/componentCommon/SortReset";
import CustomAlert from "../Components/componentCommon/CustomAlert";
const Scanner = () => {
  const styles = {
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50vh', // Full height of the viewport
    },
  };
  const navigate = useNavigate();
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [strategiesNameError, setStrategiesNameError] = useState(false);
  const [ scannerList , setScannerList] = useState([]);
  const [del,setDelete]=useState();
  const [err,setNameerror]=useState(false);
  const [loading,setLoading]=useState(true);
  // Search funcationality //
  const [originalScannerList, setOriginalScannerList] = useState([]);
  // sort functionality //
  const [isReset, setIsReset] = useState(false);

  // pagination logic //
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(scannerList?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = scannerList.slice(startIndex, endIndex);
  // alert state //
  const [alertMessage, setAlertMessage] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);    
  //
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
 
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleChange = (page) => {
    console.log("page", page)
    setCurrentPage(page);
  }

  const handelClickCreateScanner =async (name,Category) => {
    console.log("ScannerDet", name, Category )
    localStorage.setItem("categoryId", Category )
    const user = getUser();
    if (name !== undefined && user) {
      const data=await 
      existScannerNameApi({user_id:user.id,scanner_name:name?.trim()})
      if(data===1)
      {
        setNameerror(true);
      }
      else
      {
        setNameerror(false);
        setShowCreatePopup(false);
        navigate(`/scanners/page?scannid=1&scannername=${name}&Category=${Category}`);
      }
     
    } else {
      setStrategiesNameError(true);
      setNameerror(false);
    }
  };
  // delete scanner fn //
  const handleDeleteCoupon = async () => {
    if (!confirmDelete) return;

    const { id, i } = confirmDelete;

    try {
      const user = getUser();
      const result = await deleteScanner({ user_id: user.id, scanner_id: id });
      // console.log("@@AnkitaCou::", result)
      if (result) {
        setAlertMessage({
          message: `Scanner deleted successfully.`,
          type: "alert-success",
        });
      }
      setDelete(i);
    } catch (error) {
      setAlertMessage({
        message: `Error deleting`,
        type: "alert-danger",
      });
    } finally {
      setConfirmDelete(null);
    }
  };

  const cancelDelete = () => {
    setConfirmDelete(null);
  };
  //
const deletescanner=async(id,i)=>{
  const user = getUser();
  const isconfirmed=window.confirm("Are you sure you want to delete this Scanner?");
  if(isconfirmed)
  {
      const result=await deleteScanner({user_id:user.id,scanner_id:id});
      setDelete(i);
  }
}
  const fetchAllScanner = async () => {
    const result = await getAllDiscoveredScannerApi();
    setScannerList(result);
    setOriginalScannerList(result) ;
    setLoading(false);
  }

   useEffect(() => {
    fetchAllScanner();
  },[del]);

  const handelClickOpenScannerModal = () => {
    setShowCreatePopup(true);
  };
  const handleClickReset = () => {
    setIsReset(!isReset)
    fetchAllScanner()
  }
  // alert //
  const confirmDeleteScanner = (id, i) => {
    setConfirmDelete({ id, i });
  };
    return(
    <>
        {alertMessage && (
          <CustomAlert
            message={alertMessage.message}
            type={alertMessage.type}
            setAlertMessage={() => setAlertMessage(null)}
          />
        )}
        {confirmDelete && (
          <CustomAlert
            message={`Are you sure you want to delete this Scanner.`}
            type="alert-warning"
            setAlertMessage={() => setConfirmDelete(null)}
            onConfirm={handleDeleteCoupon}
            onCancel={cancelDelete}
            isConfirmation
          />
        )}
      <div className="page w-100">
        <div className="page-content">
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Scanner</h3>

              <div className="panel-actions text-white">
                  <button className="btn btn-primary addNewStrategyBtn" disabled={loading}
                 onClick={handelClickOpenScannerModal}
                 >
                  <span className="hidden-sm-down">
                    <i className="fa fa-plus" aria-hidden="true"></i> Add New
                    Scanner
                  </span>
                </button>
              </div>
            </div>
            <div className="panel-body">
                <div className="d-flex justify-content-between mb-2">
                  <div >
                    <InputSearch dataList={scannerList} setDataList={setScannerList} originalDataList={originalScannerList} isReset={isReset} isScanner="true" />
                  </div>
                  <div className="d-flex">
                    <SortData dataList={scannerList} setDataList={setScannerList} isReset={isReset} isScanner="true" />
                    <ResetList handleClickReset={handleClickReset} />
                  </div>
                </div>
            {loading && 
              <>  
                <div style={styles.spinnerContainer}>
                <CircularProgress color="inherit" />
                </div>
              </>
              }
              <table
                className="table table-hover table-bordered dataTable table-striped w-full"
                id="user-list"
              >
                 {!loading && 
                <thead>
                  <tr>
                    <th>SR. No.</th>
                    <th>Scanner Name</th>
                    <th className="d-flex justify-content-around">Action</th>
                  </tr>
                </thead>
                }
                <tbody>
                    {currentData && currentData?.map((data,i) => (
                        <tr key={i}>
                            <td>{++i}</td>
                            <td>{data?.scanner_name.length > 20 ? (data?.scanner_name).slice(0,20)+'......':data?.scanner_name}</td>
                        <td className="d-flex justify-content-around tableBtn">
                                  <div>
                                    <Button onClick={() => navigate(`/scanner/result/${data?.scanner_id}`)}><i className="fa fa-eye me-2"></i>View</Button>
                            <Button className="ms-3" onClick={() => confirmDeleteScanner(data?.scanner_id, i)}><i className="fa fa-trash"></i></Button>
                                  </div>
                        </td>
                        </tr>
                    ))}
                </tbody>
              </table>
            </div>
              <Pagination currentPage={currentPage} totalPages={totalPages} handleNext={handleNext} handlePrev={handlePrev} handleChange={handleChange} />

          </div>
        </div>
      </div>
      <ScannerModal
          setNameerror={setNameerror}
          err={err}
          showCreatePopup={showCreatePopup}
          handelClickCreateScanner={handelClickCreateScanner}
          setShowCreatePopup={setShowCreatePopup}
          nameError={strategiesNameError}
          setError={setStrategiesNameError}
        />
        </>
    )
}

export default Scanner;