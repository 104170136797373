import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { fetchingdemonumbers,update_status } from '../api/dashboardApi';
import moment from 'moment';
import { Button } from "react-bootstrap";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Pagination from '../Components/componentCommon/Pagination';
import CustomAlert from "../Components/componentCommon/CustomAlert";
function Demorequest() {
    const [demodata,setDemodata]=useState([]);
    const [done,setDone]=useState();
    const [update,setUpdate]=useState(false);
    // pagination state //
    const [currentPage, setCurrentPage] = useState(1) ;
    const [cloneData, setCloneData] = useState([])
    const itemsPerPage = 10;
    const totalPages = Math.ceil(cloneData?.length/itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage ;
    const endIndex = startIndex + itemsPerPage ;
    const currentData = cloneData?.slice(startIndex, endIndex);
    const [alertMessage, setAlertMessage] = useState(null);   
    const [sortValue, setSortValue] = useState("")
     const handleNext = () => {
      if(currentPage < totalPages) {
        setCurrentPage(currentPage+1) ;
      }
    }
    const handlePrev = () => {
      if(currentPage > 1) {
        setCurrentPage(currentPage-1)
      }
    }
    const handleChange = (page) => {
      setCurrentPage(page);
      
    }
  const handleChangeSort = (e) => {
    let selectType = e.target.value;
    setSortValue(selectType);
    setCurrentPage(1)

    let filterData = demodata.filter((item, index) => {
        if(selectType === "done") return item.status === 1
        else if(selectType === "pending") return item.status !== 1
        
      });
      console.log("filter", filterData)
      setCloneData(filterData) ;
    
    }
    const handleClickReset = () => {
      setSortValue("");
      setCurrentPage(1)
      apitofetchdemonumbers();
    }
    useEffect(()=>{
        apitofetchdemonumbers();
      },[update]);

      const apitofetchdemonumbers=async()=>
      {
        const result=await fetchingdemonumbers();
        console.log("==++",result);
        setDemodata(result);
        setCloneData(result)
      }
      const markasdone=(i,data)=>{
        setDone(i);
        console.log("marking as done",i,data);
            updatestatusAPI(data);
      }
      const updatestatusAPI=async(phone)=>{
          const result=await update_status(phone);
          if(result===true)
            {
                  apitofetchdemonumbers();
                  setUpdate(true);
            }
      }
     
      // alert fn //
  const complete = () => {
    try {
      setAlertMessage({
        message: `Already demo completed.`,
        type: "alert-success",
      });
    } catch (error) {
      setAlertMessage({
        message: `Error deleting`,
        type: "alert-danger",
      });
    }
  };               
      // 
  return (
    <>
   
    { alertMessage && (
      <CustomAlert
        message={alertMessage.message}
        type={alertMessage.type}
        setAlertMessage={() => setAlertMessage(null)}
      />
    )}
          <div className="page w-100">
        <div className="page-content">
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Demo Request</h3>

              <div className="panel-actions text-white">
                {/* <a className="text-dark mr-10" id="reset-filter-btn">
                  Reset Filter <i className="fa fa-times-circle-o"></i>
                </a> */}
              </div>
            </div>
            <div className="panel-body">
            <div className='d-flex justify-content-end mb-2'>
              <select
                name="HeadlineAct"
                id="HeadlineAct"
                className="discoverselect"
                onChange={handleChangeSort}
                value={sortValue}
              >
                <option value="" disabled selected>
                  select sort type
                </option>
                <option value="done">sort by Done</option>
                <option value="pending">sort by Pending</option>
              </select>
              <button className="reset-button" onClick={handleClickReset} >
                <RestartAltIcon />
              </button>
            </div>
              <table
                className="table table-hover table-bordered dataTable table-striped w-full"
                id="user-list"
              >
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                {currentData?.map((data,i) => (
                    <tr key={i}>
                      <td>{++i}</td>
                      <td>{data.phone}</td>
                      <td>{data.email}</td>
                      <td>
                        {data.status===1 ? 
                        (
                        <Button varient="success" onClick={()=> complete()}> Done 
                        </Button>
                        ):(
                        <Button varient="success" onClick={()=> markasdone(i,data?.phone)}> Demo Pending
                        </Button>
                        )}
                      
                      </td>
                  </tr>
                  ))}
                  
                </tbody>
              </table>
            </div>
            <Pagination currentPage={currentPage} totalPages={totalPages} handleNext={handleNext} handlePrev={handlePrev} handleChange={handleChange}/>
          </div>
        </div>
      </div>
      
    </>
  )
}

export default Demorequest;