import { useState, useEffect, createContext } from "react"
import { API_BASE_URL, IMAGE_URL } from "../config";
import axios from "axios";

export const DataContent = createContext() ;
export const ContentDataProvider = ({ children }) => {
    const [contentData, setContentData] = useState(null) ;

    useEffect(() => {
        axios.get(`${API_BASE_URL}/adminpanel/GetStaticContentData`)
            .then((res) => {
                if (res.data.success === true) {
                    setContentData(res.data.data[0]);
                    const favicon = document.querySelector('link[rel="icon"]');
                    if (favicon) {
                        favicon.setAttribute("href", `${IMAGE_URL}${res.data.data[0]?.featured_image}`);
                    }
                } else {
                    console.error('No data found or response is malformed.');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [])

    return <DataContent.Provider value={contentData}>{children}</DataContent.Provider>

}