import React, { useContext, useEffect } from "react";
import InputBox from "../Components/InputBox";
import { useState } from "react";
import App from "../App";
import { loginapi } from "../api/apiService";
import { getzerodhaacc } from "../api/apiService";
import { saveData ,saveloginuser,getid} from "../Session";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IMAGE_URL } from "../config";
import { DataContent } from "../context/manageContent";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CustomAlert from "../Components/componentCommon/CustomAlert";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isVisiblePass, setIsVisiblePass] = useState(false) ;
  const [alertMessage, setAlertMessage] = useState(null);
  const contentData = useContext(DataContent);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleVisbilePassword = () => {
    setIsVisiblePass(!isVisiblePass);

  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      console.log("event", e)

      e.preventDefault();
      handlelogin() ;
    }
  };
  useEffect(() => {
   console.log("details",email, password)
  }, [email, password])
  const handlelogin = async () => {
    if(email && password)
    {
    const result = await loginapi(email,password);
    console.log("USERDATA", result)
    const setLoginuserdata=saveloginuser(result);
        if (result?.success === true)
         {
               localStorage.clear();
              navigate("/Dashboard");
              getdetail();
         }else {
      setAlertMessage({ message: "Invalid credentials. Please try again.", type: "alert-danger" });
    }
        
  }
  else
  {
      setAlertMessage({ message: "Please Enter valid credential", type: "alert-info" })
    // alert("Please Enter login credential");
  }
  };
  const getdetail = async () => {
    const id=await getid();
    console.log("MYID",id);
    const accdetails = await getzerodhaacc(id);
    console.log("MYID12", accdetails);
    const setSessionData = saveData(accdetails);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const h = {
    overflow: "hidden"
  };
  const w = {
    width: "100%",
    border: "none",
  };
  const logo = {
    width: "350px",
  };
  return (
    <>
    { alertMessage && (
      <CustomAlert
        message={alertMessage.message}
        type={alertMessage.type}
        setAlertMessage={setAlertMessage}
      />
    )}
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-8">
        
          <div className="bg-light p-4 rounded shadow-sm">
            {/* <div className="login-image-container" > */}
              <div class="text-center text-primary mb-2">   
                <img
                src={`${IMAGE_URL}${contentData?.top_navbar_image}`}
                className="mb-2 login_logo_img"
                alt="logo"
                loading="lazy"
                width={350}
              ></img>
              </div>
           
            {/* </div> */}

            <div className="mb-3">
              <label for="email" className="form-label">Email</label>
              <div className="input-group">
                        <InputBox
                          type="email"
                          placeholder="Email"
                          onChange={handleEmailChange}
                          value={email}
                          handleKeyPress={handleKeyPress}
                        />
                <span className="input-group-text">
                  <AlternateEmailIcon/>
                </span>
              </div>
            </div> 

            <div className="mb-3">
              <label for="password" className="form-label">Password</label>
              <div className="input-group">
                <InputBox
                  type={isVisiblePass ? "text" : "password" }
                  placeholder="Password"
                  onChange={handlePasswordChange}
                  value={password}
                  onKeyDown={handleKeyPress}
                />
                <span className="input-group-text cursor-pointer" onClick={handleVisbilePassword}>
                  {
                            isVisiblePass ? <VisibilityOffIcon /> : <VisibilityIcon />
                  }
                </span>
              </div>
            </div>

            <button type="submit" onClick={handlelogin} className="btn w-100 text-white loginButton">Sign in</button>

          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Login;
