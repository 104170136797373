import React from 'react'
import "../../styles/dataNotFound.css" ;
const DataNotFound = () => {
  return (
    <div className="data-not-found-container">
      <i className="fas fa-exclamation-triangle"></i>
      <h1>No scan results found</h1>
      <p>
        {/* It seems like we couldn't find any results matching your search.  */}
        {/* Please try again later. */}
      </p>
    </div>
  );
}

export default DataNotFound ;