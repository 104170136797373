import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ScannerCategoriesSideBar = ({scannerCategory}) => {
    const navigate = useNavigate();

    const redirect = (item) =>{
        const url = `/scans/popular?filter=${item}` ;
        navigate(url);
    }

    const generateSubtitle = (name) => {
        if (name.includes("Bullish")) {
          return "Bullish pattern scanners";
        } else if (name.includes("Bearish")) {
          return "Bearish pattern scanners";
        } else if (name.includes("Intraday")) {
          return "Intraday pattern scanners";
        } else if(name.includes("Webinar Scanners")) {
            return "Scanners as seen in webinars";
        } else if(name.includes("Bullish")) {
            return "Bullish Scanners";
        }

        return `${name} scanners`; // Default fallback
      };
      const generateimage=(name)=>{
        if (name?.includes("Chart Pattern")) {
          return <img src="/icons/Group.png" alt="trend-following"/>;
        } else if (name?.includes("Webinar Scanners")) {
          return <img src="/icons/price-action.png" alt="Price-Action" />;
        } else if (name?.includes("Bulish")) {
          return <img
          src="/icons/Mean-Reversion.png"
          alt="Price-Action"
          className="bg-color-grey"
        />;
        } else if(name?.includes("Intraday Bearish")) {
            return <img
            src="/icons/Mumentum.png"
            alt="Price-Action"
            className="bg-color-grey"
          />;
        } else if(name?.includes("Range Breakout")) {
            return <img src="/icons/miscellaneous.png" alt="Momentum" />;
        } else if (name?.includes("Top Gainers")){
              return <img src="/icons/pivot-point.png" alt="Momentum" />;
        }else{
        }
    }
    return(
        <div className="px-4">
            <Row>
                <Col className="fw-bold">
                    Categories
                </Col>
            </Row>
            {scannerCategory?.map((scanner,index)=>{
                return(
                <>
            <Row className="d-flex justify-content-center align-items-center mt-3 scanner-menu" 
            onClick={()=>{redirect(index+1)}}>
                <Col lg={2} md={2} sm={2}>
                {generateimage(scanner.name)}
                    {/* <i className="fa fa-plus"></i> */}
                </Col>
                <Col lg={8} md={8} sm={8} className="d-flex flex-column">
                    <span className="title">{scanner?.name}</span>
                    <span className="sub-title">
                        {generateSubtitle(scanner.name)}
                    </span>
                </Col>
                <Col lg={2} md={2} sm={2}>
                    <div className="d-flex justify-content-center align-items-center"
                    style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        border: "1px solid #2c2c2c",
                        backgroundColor: "#f5f5f5", // Light background for contrast
                        color: "#2c2c2c", // Text color
                        fontWeight: "bold", // Make text bold
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add light shadow
                        cursor: "pointer", // Pointer for interactivity
                        transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effects
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = "#e0e0e0") // Slight darkening on hover
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "#f5f5f5") // Back to normal on mouse out
                      }>
                    <span>{scanner?.scanner_count}</span>
                    </div>           
                </Col>
            </Row>
            <hr/>
                </>
                );
            })
            }
          
         
        </div>
    )
}

export default ScannerCategoriesSideBar;