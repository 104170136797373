import React from 'react'
import { Button, Form, Modal } from "react-bootstrap";
import moment from "moment";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import axios from "axios";
import Switch from '@mui/material/Switch';
function Createcouponpopup({show,closeModal,couponDetails,onChange,addcoupon,setEditcoupon,updatecoupon,handleChangeswitch}) {
  return (
    <Modal
    show={show}
    onHide={closeModal}
    // animation={false}
    centered
  >
    <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Code</Form.Label>
            <Form.Control
              type="text"
              name="code"
              value={couponDetails.code}
              disabled={setEditcoupon}
              onChange={onChange}
              required
              placeholder="Enter coupon code"
            />
          </Form.Group>
          <br></br>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={couponDetails.description}
              disabled={setEditcoupon}
              onChange={onChange}
              required
              placeholder="Enter coupon description"
            />
          </Form.Group>
          <br></br>
        <div className='d-flex justify-content-between'>
          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              value={couponDetails.amount}
              disabled={setEditcoupon}
              onChange={onChange}
              required
              placeholder="Enter coupon amount"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Expiry Date</Form.Label>
            <Form.Control
              type="date"
              name="expiry_date"
              value={moment(couponDetails.expiry_date).format('YYYY-MM-DD')}
              disabled={setEditcoupon}
              onChange={onChange}
              required
            />
          </Form.Group>
          </div>
          <div className='text-end'>
          {setEditcoupon === true ? (
            <>
            <div className='my-3'>
              <p className='mb-0'>Is Active?</p>
              <Switch
                 checked={couponDetails?.is_active}
                 onChange={handleChangeswitch}
                 inputProps={{ 'aria-label': 'controlled' }}
             />
          </div>
            <Button variant="primary" onClick={updatecoupon}>
            Update Coupon
          </Button >
          </>):(<Button variant="primary" onClick={addcoupon}>
            Save Coupon
          </Button >)}
        
          <Button variant="primary" className="ms-3" onClick={closeModal}>Cancel</Button>
          </div>
        </Form>
      </Modal.Body>

</Modal>
  )
}

export default Createcouponpopup