import React, { useEffect, useState } from "react";
import ProfileCard from "../mcomponents/ProfileCard";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import TradingIcon from "./TradingIcon";
import Paisa5Page from "./Paisa5Page";
import { paisaGetAccount } from "../api/profileapi";
import { getUser } from "../Session";
import ConfirmationPopup from "./ConfirmationPopup";
import ZerodhaPage from "./ZerodhaPage";
import { zerodhaGetAccount } from "../api/zerodhaapi";
import PaperTrading from "./TradingComponents/PaperTrading";
import GrowwTrading from "./TradingComponents/GrowwTrading";
import Choice from "./TradingComponents/Choice";
import Fyers from "./TradingComponents/Fyers";
import AngleOne from "./TradingComponents/AngleOne";
import HDFCSecurities from "./TradingComponents/HDFCSecurities";
import ICICIdirect from "./TradingComponents/ICICIdirect";
import IIFLFinance from "./TradingComponents/IIFLFinance";
import AliceBlue from "./TradingComponents/AliceBlue";
import Axis from "./TradingComponents/Axis";
// import { getUser } from "../Session";
const tradingIcons = [
  { imgSrc: "/5Paisa.png", alt: "5paisa", title: "5paisa", page: "5paisa" },
  {
    imgSrc: "icon-trading/Zerodha.png",
    alt: "Zerodha",
    title: "Zerodha",
    page: "zerodha",
  },
  {
    imgSrc: "icon-trading/paper-trading.jpg",
    alt: "papertrading",
    title: "PaperTrading",
    page: "paperTrading",
  },
  {
    imgSrc: "icon-trading/AngleOne.jpeg",
    alt: "AngleOne",
    title: "AngleOne",
    page: "angleone",
  },
  {
    imgSrc: "icon-trading/groww.png",
    alt: "Groww",
    title: "Groww",
    page: "groww",
  },
  { imgSrc: "icon-trading/axis.png", alt: "Axis", title: "Axis", page: "axis" },
  {
    imgSrc: "icon-trading/choice.jpeg",
    alt: "choice",
    title: "Choice",
    page: "choice",
  },
  {
    imgSrc: "icon-trading/fyers.jpeg",
    alt: "Fyers",
    title: "Fyers",
    page: "fyers",
  },

  {
    imgSrc: "icon-trading/hdfc-securities.jpeg",
    alt: "hdfc-securities",
    title: "hdfc-securities",
    page: "hdfc-securities",
  },
  {
    imgSrc: "icon-trading/icicidirect.png",
    alt: "icicidirect",
    title: "icicidirect",
    page: "icicidirect",
  },
  {
    imgSrc: "icon-trading/iifl-finance.jpeg",
    alt: "iifl",
    title: "iifl-finance",
    page: "iifl-finance",
  },
  {
    imgSrc: "icon-trading/alice-blue.jpeg",
    alt: "AliceBlue",
    title: "alice-blue",
    page: "alice-blue",
  },
];
const TradingAccount = ({ setActivePage }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeAcc = searchParams.get("acc");

  console.log("MyActiveAccount", activeAcc);
  const [paisa5Page, setPaisa5page] = useState(false);
  const [isAccountAdd, setIsAccountAdd] = useState("");
  const [paisa5FormData, setPaisa5FormData] = useState("");
  const [isDeleteAccount, setIsDeleteAccount] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [show, setShow] = useState(false); //confirmation popup
  const [page, setPage] = useState("");

  const [zerodhaData, setZerodhaData] = useState("");

  // useEffect(() => {
  //     featchAccountDetails()
  // },[]);

  console.log("isAccountAdd", isAccountAdd);

  const handleClose = () => setShow(false); //confirmation popup
  const handleConfirm = () => {
    setConfirmationMsg("Are you sure you want to delete this account ?");
    setIsDeleteAccount(true);
    setShow(true);
  }; //confirmation popup

  const handleClickPaisa5 = async () => {
    try {
      const user = getUser();
      console.log("user", user);
      const response = await paisaGetAccount({ user_id: user.id });
      console.log("response", response);
      if (response?.length > 0) {
        setPaisa5FormData(response[0]);
        setIsAccountAdd("detailsform");
      } else {
        setIsAccountAdd(false);
      }
      setPaisa5page(true);
      console.log("response", response);
    } catch (error) {
      console.log("Error :", error);
    }
  };
  const handleClickpapertrading = async () => {
    console.log("firsthandleclickpapertrading");
  };
  const handleChangePage = (title) => {
    console.log("title", title)
    switch (title) {
      case "5paisa" : 
        setPage("5paisa");
        break;
      case "paperTrading":
        setPage("paperTrading");
        break;
      case "groww":
        setPage("groww");
        break;
      case "zerodha": 
        setPage("zerodha");
        featchAccountDetails();
        break;
      case "axis":
        setPage("axis");
        break;
      case "choice":
        setPage("choice");
        break;
      case "fyers":
        setPage("fyers");
        break;
      case "angleone":
        setPage("angleone");
        break;
      case "hdfc-securities":
        setPage("hdfc-securities");
        break;
      case "icicidirect":
        setPage("icicidirect");
        break;
      case "iifl-finance":
        setPage("iifl-finance");
        break;
      case "5paisa": 
        setPage("5paisa");
        handleClickPaisa5();
        break;
      case "PaperTrading": 
        setPage("PaperTrading");
        handleClickpapertrading();
        break;
      case "alice-blue":
        setPage("alice-blue");
        break;

      default:
        setPage("");
    }
  };

  useEffect(() => {
    if (activeAcc !== null) {
      handleChangePage(activeAcc);
    }
  }, [activeAcc]);

  useEffect(() => {}, [paisa5FormData, isAccountAdd, paisa5Page, page]);

  const handleGetPaisaDetails = async () => {
    try {
      const user = getUser();
      const data = await paisaGetAccount({ user_id: user.id });
      setPaisa5FormData(data[0]);
    } catch (error) {
      console.log("Error :", error);
    }
  };
  const featchAccountDetails = async () => {
    const user = getUser();
    const fetchData = await zerodhaGetAccount({ user_id: user.id });
    if (fetchData?.length === 0) {
      setIsAccountAdd("newAccount");
      setZerodhaData("");
    } else if (fetchData?.length > 0) {
      setIsAccountAdd("detailsform");
      setZerodhaData(fetchData[0]);
    }
  };

  return (
    <>
      {/* <div className="p-3 pt-5">
              <h4 className="fw-bold">Funds</h4>
              <Link to="#" className="text-decoration-none">
                Terms of use
              </Link>
              <br />
              <Link to="#" className="text-decoration-none">
                Privacy Policy
              </Link>
            </div> */}
      {/* // </div> */}
      {page === "5paisa" ? (
        <Paisa5Page
          isAccountAdd={isAccountAdd}
          paisa5FormData={paisa5FormData}
          handleGetPaisaDetails={handleGetPaisaDetails}
          setIsAccountAdd={setIsAccountAdd}
          setShow={setShow}
          show={show}
          isDeleteAccount={isDeleteAccount}
          setIsDeleteAccount={setIsDeleteAccount}
          setConfirmationMsg={setConfirmationMsg}
          setActivePage={setActivePage}
        />
      ) : page === "zerodha" ? (
        <ZerodhaPage
          isAccountAdd={isAccountAdd}
          zerodhaData={zerodhaData}
          setIsAccountAdd={setIsAccountAdd}
          setShow={setShow}
          setConfirmationMsg={setConfirmationMsg}
          isDeleteAccount={isDeleteAccount}
          setIsDeleteAccount={setIsDeleteAccount}
          featchAccountDetails={featchAccountDetails}
          setActivePage={setActivePage}
        />
      ) : page === "paperTrading" ? (
        <PaperTrading setActivePage={setActivePage} />
      ) : page === "groww" ? (
        <GrowwTrading setActivePage={setActivePage} />
      ) : page === "axis" ? (
        <Axis setActivePage={setActivePage} />
      ) : page === "choice" ? (
        <Choice setActivePage={setActivePage} />
      ) : page === "fyers" ? (
        <Fyers setActivePage={setActivePage} />
      ) : page === "angleone" ? (
        <AngleOne setActivePage={setActivePage} />
      ) : page === "hdfc-securities" ? (
        <HDFCSecurities setActivePage={setActivePage} />
      ) : page === "icicidirect" ? (
        <ICICIdirect setActivePage={setActivePage} />
      ) : page === "iifl-finance" ? (
        <IIFLFinance setActivePage={setActivePage} />
      ) : page === "alice-blue" ? (
        <AliceBlue setActivePage ={setActivePage}/>
      ) : (
        // <div className="col-lg-8">
        <div className="info-div">
          {/* <h2 className="mobile-title">Select Broker</h2> */}
          <div className="container p-1">
            <div className="row">
              {tradingIcons?.map((icon, index) => {
                return (
                  <TradingIcon
                    key={index}
                    imgSrc={icon.imgSrc}
                    alt={icon.alt}
                    title={icon.title}
                    page={icon.page}
                    handleClick={icon.page ? handleChangePage : undefined}
                  />
                );
              })}
              {/* <TradingIcon
                imgSrc="icon-trading/paper-trading.jpg"
                alt="papertrading"
                title="PaperTrading"
                page="PaperTrading"
                handleClick={handleChangePage}
              />
              <TradingIcon
                imgSrc="icon-trading/groww.png"
                alt="Groww"
                title="Groww"
              />

              <TradingIcon
                imgSrc="icon-trading/Zerodha.png"
                alt="Zerodha"
                title="Zerodha"
                handleClick={handleChangePage}
                page="zerodha"
              />

              <TradingIcon
                imgSrc="icon-trading/axis.png"
                alt="Axis"
                title="Axis"
              />

              <TradingIcon
                imgSrc="icon-trading/choice.jpeg"
                alt="choice"
                title="Choice"
              />

              <TradingIcon
                imgSrc="icon-trading/fyers.jpeg"
                alt="Fyers"
                title="Fyers"
              />

              <TradingIcon
                imgSrc="icon-trading/AngleOne.jpeg"
                alt="AngleOne"
                title="AngleOne"
              />

              <TradingIcon
                imgSrc="icon-trading/hdfc-securities.jpeg"
                alt="hdfc-securities"
                title="hdfc-securities"
              />

              <TradingIcon
                imgSrc="icon-trading/icicidirect.png"
                alt="icicidirect"
                title="icicidirect"
              />

              <TradingIcon
                imgSrc="icon-trading/iifl-finance.jpeg"
                alt="iifl"
                title="iifl-finance"
              />

              <TradingIcon
                imgSrc="/5Paisa.png"
                alt="5paisa"
                title="5paisa"
                handleClick={handleChangePage}
                page="5paisa"
              />

              <TradingIcon
                imgSrc="icon-trading/alice-blue.jpeg"
                alt="AliceBlue"
                title="alice-blue"
              /> */}
            </div>
          </div>
        </div>
        // </div>
      )}
      {/* // </div> */}
      {/* // </div> */}
      <ConfirmationPopup
        show={show}
        handleClose={handleClose}
        handleShow={handleConfirm}
        confirmationMsg={confirmationMsg}
      />
      {/* // </section> */}
    </>
  );
};

export default TradingAccount;
