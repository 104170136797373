import React from 'react'
import { useNavigate } from 'react-router-dom';
import TradingForm from '../componentCommon/TradingForm';

const IIFLFinance = ({ setActivePage }) => {
  const navigate = useNavigate();

  const handleNavigateBack = (page) => {
    navigate(page);
    setActivePage(page);
  };
  const onSubmitDetails =(data) => {
    console.table(data);
  }
  return (
    <div className="d-flex mt-3">
      <div className="mt-2">
        <i
          className="fa fa-chevron-left pointer-cursor fa-lg back-button"
          onClick={() => handleNavigateBack("/account?tab=trading")}
        ></i>
      </div>
      <div className="d-flex ms-2" style={{ width: "100%" }}>
        <TradingForm
          tradeName="iifl"
          title="IIFL-Finance"
          onSubmitDetails={onSubmitDetails}
        />
      </div>
    </div>
  );
};

export default IIFLFinance