import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Form } from "react-bootstrap";
import {Addnewcoupon,GetCouponListApi,GetAppliedCouponListApi,
  Exportcouponlist,Exportappliedcouponlist,editCouponApi,deleteCouponApi} from "../api/adminapi"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Createcouponpopup from "../Components/modal/Createcouponpopup";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import moment from "moment";
import { BASE_URL } from "../config";
import CustomAlert from "../Components/componentCommon/CustomAlert";
import { ResetDataList, SortByList } from "../Components/componentCommon/SortByList";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  export default function Coupons() {
    const [value, setValue] = React.useState(0);
    const [userRecords , setUserRecords] = useState([]);
    const [userAllData,setUserAllData] = useState([]);
    const [usersCount ,setUsersCount] = useState('');
    const [expiredSubscriptionsAll , setExpiredSubscriptionsAll] = useState([]);
    const [expiredSubscriptions , setExpiredSubscriptions] = useState([]);
    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [strategiesNameError, setStrategiesNameError] = useState(false);
    const [createStratgyPage, setCreateStratgyPage] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editcoupon,setEditcoupon]=useState(false);
    // sort and alert state //
    const [alertMessage, setAlertMessage] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [activeSearchQuery, setActiveSearchQuery] = useState("");
    const [expiredSearchQuery, setExpiredSearchQuery] = useState("");
    const [selectedSort, setSelectedSort] = useState('');
    // 
    const [id,setId]=useState();
    const [updatedata,setUpdatedata]=useState({ 
       couponId : '',
       is_active : ''
    });
    const [couponDetails,setCoupondetails]=useState({
        code:'',
        description:'',
        amount:'',
        expiry_date:'',
        added_by:2,
        is_active:"",
    })
    const [checked, setChecked] = React.useState(false);
    const customStyles = {
      rows: {
        style: {
          border : "1px solid #8080805e", // override the row height
          minHeight: '40px',
        },
      },
      color:"#ffffff",
      headCells: {
        style: {
          border : "1px solid #8080805e",
          fontSize : "medium",
          fontWeight : "bold",
          borderBottom : "none",
          minHeight: '30px',
        },
      },
      cells: {
        style: {
          borderRight : "1px solid #8080805e", // override the row height
          // color:"#76838F"
          // height : "2px"
        },
      },
      
    };
  
    const columns =  [
      {
        id : "id",
        selector : row => row.id,
        omit : true
      },
      {
        name : "Code",
        selector : row => row.code,
        // sortable :true
      },
      {
        name : "Amount",
        selector : row => row.amount,
        // sortable :true
      },
      {
        name : "Description",
        selector : row => row.description,
      },
      {
        name : "Expiry date",
        selector : (row) => moment(row.expiry_date).format('DD MMMM YYYY'),
      },
      {
        name : "Added By",
        selector :row=> row.user_name,
      },
      {
        name: "Action",
        cell: (row) => (
          <>
            <IconButton onClick={() => handleEditClick(row)}>
              <EditIcon style={{ color: 'green' }} />
            </IconButton>
            <IconButton onClick={() => confirmDeleteCoupon(row.id)}>
              <DeleteIcon style={{ color: 'red' }} />
            </IconButton>
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ]
    const fetchUsersData = async() =>{
      const result = await GetCouponListApi();
      setUserRecords(result?.data);
      setUserAllData(result?.data);
    }
    const fetchExpiredSubscriptions = async () => {
      const result =  await GetAppliedCouponListApi();
      console.log("result@",result);
      setExpiredSubscriptionsAll(result?.data);
      setExpiredSubscriptions(result?.data);
    }
   
    useEffect(() => {
      fetchUsersData();
      fetchExpiredSubscriptions();
    } ,[]);
    const handleFilter = (event) => {
      const searchTerm = event.target.value?.toLowerCase();
      setActiveSearchQuery(searchTerm);
      const newData = userAllData?.filter(row =>
      (row.code?.toLowerCase().includes(searchTerm) ||
        row.email?.toLowerCase().includes(searchTerm))
      );
      setUserRecords(newData);
      console.log("Filter", searchTerm, newData, userRecords);
    }

    const handleFilterExpired = (event) => {
      const searchTerm = event.target.value?.toLowerCase();
      setExpiredSearchQuery(searchTerm);
      const newData = expiredSubscriptionsAll?.filter(row =>
      (row.user_name?.toLowerCase().includes(searchTerm) ||
        row.email?.toLowerCase().includes(searchTerm))
      );
      // setExpiredSubscriptionsAll(newData);
      setExpiredSubscriptions(newData);
      console.log("Filter12", searchTerm, newData, expiredSubscriptions);
    }
    const exportActive=async()=>{
      const result=await Exportcouponlist();
      console.log("Exported file",result);
      const downloadUrl = `${BASE_URL}/exports/${result.filename}`;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', result.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    const handleExportExpired=async()=>{
      const result=await Exportappliedcouponlist();
      console.log("Exported file",result);
      const downloadUrl = `${BASE_URL}/exports/${result.filename}`;
        const link = document.createElement('a');
        link.href = downloadUrl;
      link.setAttribute('download', result.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    const handleChange = (event, newValue) => {
      setValue(newValue);
      handleReset();
    };
    const handleChangecoupondetails=(e)=>{
    
       const {name,value}=e.target;
       setCoupondetails({...couponDetails,[name]:name==="amount"? parseInt(value):value});
    }
    const handleChangeswitch=(event)=>{
        setChecked(event.target.checked);
        setCoupondetails({...couponDetails,is_active:event.target.checked ? true:false});
    }
    const handleClickStrategyModal = () => {
       setCoupondetails({});
       setEditcoupon(false);
       setIsModalOpen(true);
      };
    const addcoupon = async () => {
      try {
        const { code, description, amount, expiry_date } = couponDetails;
        const added_by = 2;
        const couponData = { code, description, amount, expiry_date, added_by };
        const result = await Addnewcoupon(couponData);
        if (result.success === true) {
          setAlertMessage({
            message: `Coupons created successfully!!!...`,
            type: "alert-success",
          });
        }
        setIsModalOpen(false);
        fetchUsersData();
      } catch (error) {
        setAlertMessage({
          message: `Error deleting`,
          type: "alert-danger",
        });
      }
      // http://localhost:8008/api/v1/adminpanel/AddSingleCoupon
    }
    const updatecoupon = async () => {
      try {
        setUpdatedata({ ...updatedata, is_active: updatedata.is_active === "on" ? "off" : "on" });
        const result = await editCouponApi({
          couponId: parseInt(id)
          , is_active: checked ? "on" : "off"
        });
        if (result) {
          setAlertMessage({
            message: `Coupons updated successfully.`,
            type: "alert-success",
          });
        }
        setIsModalOpen(false);
        fetchUsersData();
      } catch (error) {
        setAlertMessage({
          message: `Error deleting`,
          type: "alert-danger",
        });
      }
    }



      const handleEditClick=(row)=>{
        setIsModalOpen(true);
        setEditcoupon(true);
        setCoupondetails({code:row.code,
          description:row.description,
          amount:row.amount,
          expiry_date:row.expiry_date,
          is_active:row.is_active,
          
      });
      setId(row?.id);
      setUpdatedata({...updatedata,couponId:parseInt(row.id)})
      }

      const handleRemoveClick=async(id)=>{
        const isconfirmed=window.confirm("Are you want to delete this coupon?");
        if(isconfirmed)
        {
          const result=await deleteCouponApi({couponId:id});
          if(result===true)
          {
            fetchUsersData();
            alert("Records Deleted Successfully...")
          }
        }
      }

    // delete function for coupons with alert //
    const confirmDeleteCoupon = (id) => {
      setConfirmDelete({ id });
    };

    const handleDeleteCoupon = async () => {
      if (!confirmDelete) return;

      const { id } = confirmDelete;

      try {
        const result = await deleteCouponApi({ couponId: id });
        // console.log("@@AnkitaCou::", result)
        if (result) {
          setAlertMessage({
            message: `Coupon deleted successfully.`,
            type: "alert-success",
          });
        }
        fetchUsersData();
      } catch (error) {
        setAlertMessage({
          message: `Error deleting`,
          type: "alert-danger",
        });
      } finally {
        setConfirmDelete(null);
      }
    };

    const cancelDelete = () => {
      setConfirmDelete(null);
    };

    const handleReset = () => {
      setUserRecords(userAllData);
      setExpiredSubscriptions(expiredSubscriptionsAll);
      setActiveSearchQuery("");
      setExpiredSearchQuery("");
      setSelectedSort("");
    }
    //
    return (
      <>
        <>
          {alertMessage && (
            <CustomAlert
              message={alertMessage.message}
              type={alertMessage.type}
              setAlertMessage={() => setAlertMessage(null)}
            />
          )}
          {confirmDelete && (
            <CustomAlert
              message={`Are you sure you want to delete this account.`}
              type="alert-warning"
              setAlertMessage={() => setConfirmDelete(null)}
              onConfirm={handleDeleteCoupon}
              onCancel={cancelDelete}
              isConfirmation
            />
          )}
          <div className="page">
            <div className="page w-100">
              <div className="page-content">
                <div className="panel">
                  <div className="panel-heading">
                    <h3 className="panel-title">Coupons</h3>
                    <div className="panel-actions text-white">
                      <button className="btn btn-success"
                        onClick={handleClickStrategyModal}
                      // disabled={loading}
                      >
                        <span className="hidden-sm-down">
                          <i className="fa fa-plus" aria-hidden="true"
                          ></i> Add New
                          Coupon
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="row justify-content-center" style={{ margin: "0px 30px" }}>
                    <Box sx={{ width: '100%', margin: "0px auto", backgroundColor: "white" }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                          <Tab label="Coupons" {...a11yProps(0)} />
                          <Tab label="Applied Coupons" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={value} index={0}>
                        <div className="row mt-4">
                          <div className="col-xl-12 col-lg-12">
                            <div className="panel">
                              <div className="panel-heading">
                                <div className="flex items-center justify-between">
                                  <Form className="d-flex align-items-baseline justify-content-start">
                                    <Form.Label style={{ color: "#76838F" }}>
                                      Search:
                                    </Form.Label>
                                    <Form.Control
                                      className="w-25 float-end ms-2"
                                      type="text"
                                      size="md"
                                      placeholder="Search..."
                                      style={{ color: "#76838F" }}
                                      onChange={handleFilter}
                                      value={activeSearchQuery}
                                    />
                                  </Form>
                                  <div className="d-flex panel-actions text-white">
                                    <SortByList sortComponentName='coupons' sortByData={userRecords} setSortByData={setUserRecords} setSelectedSort={setSelectedSort} selectedSort={selectedSort} />
                                    <ResetDataList handleReset={handleReset} />
                                    <a
                                      className="btn btn-success mx-2"
                                      id="export-users"
                                      onClick={exportActive}
                                    >
                                      <i className="fa fa-download"></i> Export
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="panel-body mt-4">
                                <DataTable
                                  columns={columns}
                                  data={userRecords}
                                  pagination
                                  fixedHeader
                                  customStyles={customStyles}
                                  highlightOnHover
                                  striped
                                  pointerOnHover
                                  responsive
                                // onRowClicked={handleShowUser}
                                >
                                </DataTable>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={1}>
                        <div className="row mt-4">
                          <div className="col-xl-12 col-lg-12">
                            <div className="panel">
                              <div className="panel-heading">
                                <div className="flex items-center justify-between">
                                  <Form className="d-flex align-items-baseline justify-content-start">
                                    <Form.Label style={{ color: "#76838F" }}>
                                      Search:
                                    </Form.Label>
                                    <Form.Control
                                      className="w-25 float-end ms-2"
                                      type="text"
                                      size="md"
                                      placeholder="Search..."
                                      style={{ color: "#76838F" }}
                                      onChange={handleFilterExpired}
                                      value={expiredSearchQuery}
                                    />
                                  </Form>
                                  <div className="d-flex panel-actions text-white">
                                    <SortByList sortComponentName='coupons' sortByData={expiredSubscriptions} setSortByData={setExpiredSubscriptions} setSelectedSort={setSelectedSort} selectedSort={selectedSort} />
                                    <ResetDataList handleReset={handleReset} />
                                    <a
                                      className="btn btn-success mx-2"
                                      id="export-users"
                                      onClick={handleExportExpired}
                                    >
                                      <i className="fa fa-download"></i> Export
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="panel-body mt-4">
                                <DataTable
                                  columns={columns}
                                  data={expiredSubscriptions}
                                  pagination
                                  fixedHeader
                                  customStyles={customStyles}
                                  highlightOnHover
                                  striped
                                  pointerOnHover
                                  responsive
                                // onRowClicked={handleShowUser}
                                >

                                </DataTable>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CustomTabPanel>
                    </Box>
                  </div>
                </div>

                <Createcouponpopup show={isModalOpen}
                  closeModal={() => setIsModalOpen(false)}
                  couponDetails={couponDetails}
                  setEditcoupon={editcoupon}
                  onChange={handleChangecoupondetails}
                  addcoupon={addcoupon}
                  updatecoupon={updatecoupon}
                  handleChangeswitch={handleChangeswitch}
                  checked={checked}
                />
              </div>
            </div>
          </div>

        </>
      </>

     
    );
}