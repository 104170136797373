// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General styles for the search container */
.search-container {
    position: relative;
    width: 100%;
    margin-top: 5px;
}
.search-input {
    width: 100%;
    padding: 0.625rem;
    padding-right: 2.5rem;
    border-radius: 0.375rem;
    border: 1px solid #e5e7eb;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-size: 0.875rem;
}
.search-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    place-content: center;
    width: 2.5rem;
    height: 100%;
}
/* Styling the search button */
.search-button {
    background: transparent;
    border: none;
    color: #4b5563;
    /* cursor: pointer; */
    transition: color 0.3s ease;
}

.search-button:hover {
    color: #1f2937;
}
.material-icons {
    font-size: 10px;
    color: #4b5563;
    transition: color 0.3s ease;
}

.search-button:hover .material-icons {
    color: #1f2937;
}`, "",{"version":3,"sources":["webpack://./src/styles/inputSearch.css"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;IACI,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB;AACA;IACI,WAAW;IACX,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,yBAAyB;IACzB,wCAAwC;IACxC,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,aAAa;IACb,qBAAqB;IACrB,aAAa;IACb,YAAY;AAChB;AACA,8BAA8B;AAC9B;IACI,uBAAuB;IACvB,YAAY;IACZ,cAAc;IACd,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB;AACA;IACI,eAAe;IACf,cAAc;IACd,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* General styles for the search container */\n.search-container {\n    position: relative;\n    width: 100%;\n    margin-top: 5px;\n}\n.search-input {\n    width: 100%;\n    padding: 0.625rem;\n    padding-right: 2.5rem;\n    border-radius: 0.375rem;\n    border: 1px solid #e5e7eb;\n    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);\n    font-size: 0.875rem;\n}\n.search-icon {\n    position: absolute;\n    top: 0;\n    right: 0;\n    display: grid;\n    place-content: center;\n    width: 2.5rem;\n    height: 100%;\n}\n/* Styling the search button */\n.search-button {\n    background: transparent;\n    border: none;\n    color: #4b5563;\n    /* cursor: pointer; */\n    transition: color 0.3s ease;\n}\n\n.search-button:hover {\n    color: #1f2937;\n}\n.material-icons {\n    font-size: 10px;\n    color: #4b5563;\n    transition: color 0.3s ease;\n}\n\n.search-button:hover .material-icons {\n    color: #1f2937;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
