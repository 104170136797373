// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    text-align: center;
    color: #555;
    padding: 20px;
}

.data-not-found-container h1 {
    font-size: 1rem;
    color: #333;
    margin: 0;
}

.data-not-found-container i {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 1rem;
}

.data-not-found-container p {
    font-size: 1rem;
    color: #777;
    max-width: 500px;
}`, "",{"version":3,"sources":["webpack://./src/styles/dataNotFound.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,SAAS;AACb;;AAEA;IACI,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".data-not-found-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 60vh;\n    text-align: center;\n    color: #555;\n    padding: 20px;\n}\n\n.data-not-found-container h1 {\n    font-size: 1rem;\n    color: #333;\n    margin: 0;\n}\n\n.data-not-found-container i {\n    font-size: 2rem;\n    color: #007bff;\n    margin-bottom: 1rem;\n}\n\n.data-not-found-container p {\n    font-size: 1rem;\n    color: #777;\n    max-width: 500px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
