
const modalConfigs = [
    "Median Price",
    "SMA (Simple Moving Average)",
    "Ulcer",
    "TriMA (Triangular Moving Average)",
    "VWMA (Volume Weighted Moving Average)",
    "Time Series Forecast",
    "DEMA (Double Exponential Moving Average)",
    "TEMA (Triple Exponential Moving Average)",
    "Open price",
    "Low price",
    "High price",
    "Close",
    "RSI (Relative Strength Index)",
    "EMA (Exponential Moving Average)",
    "WMA (Weighted Moving Average)",
    "Number",
    "Money Flow Index",
    "Williams %R",
    "Moving Average Deviation (MA Dev)",
    "MACD (Moving Average Convergence/Divergence)",
    "OBV (On Balance Volume)",
    "Aroon-Down",
    "Aroon-Up",
    "Awesome Oscillator",
    "VWAP (Volume Weighted Average Price)",
    "Parabolic SAR",
    "Vortex Indicator",
    "Volume of a candle",
    "Donchian Channel",
    "Stochastic",
    "Chaikin Money Flow",
    "Commodity Channel Index",
    "Price Rate of Change",
    "Center Of Gravity",
    "MACD-signal",
    "MACD-histogram",
    "RSI Moving Average",
    "Remove Formula Modal",
    "Bollinger %B",
    "TR (True Range)",
    "ATR (Average True Range)",
    "NATR (Normalized Average True Range)",
    "Supertrend",
    "MFI Moving Average (Money Flow Index Moving Average)",
    "Know Sure Thing",
    "Ichimoku",
    "MOM (Momentum Indicator)",
    "Elder Force Index",
    "Standard Deviation",
    "Chande Momentum Oscillator",
    "Vwap Standard deviation",
    "Ultimate Oscillator",
    "Nth Candle",
    "Median Price",
    "ATR Trailing Stoploss",
    "Choppiness Index",
    "Central Pivot Range",
    "Camarilla Pivot Points",
    "Pivot Points",
    "Elder Ray Index",
    "Bollinger Bandwidth",
    "LBB (Lower Bollinger Band)",
    "UBB (Upper Bollinger Band)",
    "MBB (Middle Bollinger Band)",
    "Phase Power Moving Average",
    "-DI (Minus Directional Index)",
    "ADX (Average Directional Index)",
    "TSI (True Strength Indicator)",
    "TII (Trend Intensity Index)",
    "Linear Regression Forecast",
    "Open Interest Moving Average",
    "NVI (Negative Volume Index)",
    "PVI (Positive Volume Index)" ,
    "Option Greeks",
    "Detrended Price Oscillator",
    "Klinger Volume Oscillator",
    "VWAP Standard Deviation",
    "True Range Moving Average",
    "McGinley Dynamic Indicator" ,
    "Vortex Moving Average",
    "Trade Volume Index",
    "Swing Index",
    "DI (Plus Directional Index)",
    "Aroon Oscillator",
    "Twiggs Money Flow" ,
    "Keltner Channel" ,
    "Moving Average Volume" ,
    "VWAP Moving Average",
    "Median Price Moving Average" ,
    "Moving Average" ,
    "Bollinger %B Moving Average" ,
    "ADX Moving Average (Average Directional Index Moving Average)" ,
    "Day High Low" ,
    "Chande Kroll Stop",
    "Ehler Fisher" ,
    "Price Rate Of Change Moving Average (Proc Moving Average)",
    "MACD OI (Moving Average Convergence/Divergence Open Interest)",
    "Previous N" ,
    "Intraday Momentum Index Moving Average (IMI Moving Average)" ,
    "Choppiness Index Moving Average" ,
    "CCI Moving Average (Commodity Channel Index Moving Average)" ,
    "Opening Range" ,
    "Open interest" ,
    "Schaff Trend Cycle" ,
    "Stochastic RSI" ,
    "ATR Bands" ,
    "Stochastic Momentum Index" ,
    "Candle Time" ,
    "Coppock curve",
    "Alligator",
    "Volume Oscillator",
    "OBV Moving Average (On Balance Volume Moving Average)",
    "Intraday Momentum Index" ,

]
export default modalConfigs;