import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Card, ListGroup } from "react-bootstrap";
import TopMoverRightSideBar from "./TopMoverRightSideBar";
import TechnicalTable from "./TechnicalTable";
import TopMoverRightSideBar2 from "./TopMoveRightSideBar2";
import TechnicalBox from "./TechnicalBox";
import StocksCarousel from "./StocksCarousel";
import TechnicalCreateModal from "../components/technical/TechnicalCreateModal";
import StocksTechnicalList from "../components/technical/StocksTechnicalList";
import { Spinner } from "reactstrap";
import {
  getIndices,
  getPercentageChange,
  gettechnicalindicator,
} from "../api/technicalapi";
import { addwatchlist, getTopMovers } from "../api/technicalapi";
import { getUser, getActiveAccountdata } from "../Session";
import { updatewatchlist } from "../api/technicalapi";
import { useCallback, useRef } from "react";
const Technical = () => {
  const intervalRef = useRef(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [subscriptionRequestToken, setsubscriptionRequestToken] = useState();
  const [tokenMapping, settokenMapping] = useState();
  const [indices, setIndices] = useState();
  const [stocks, setStocks] = useState([]);
  const [showList, setShowList] = useState(false);
  const [allStocks, setAllStocks] = useState();
  const [nameError, setNameError] = useState();
  const [selectedIndicesId, setSelectedIndicesId] = useState(null);
  const [technicalData, setTechnicalData] = useState(null);
  const [activeTab, setActiveTab] = useState("1minute");
  const [loading, setLoading] = useState(false);
  const [scriptcode, setScriptcode] = useState();
  const [text, setText] = useState(false);
  const [requestdata, setRequestdata] = useState();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [topmovers, setTopmovers] = useState([]);
  const [toplossers, setTopLossers] = useState([]);
  const [topmoversid, setTopmoversid] = useState();
  const [topmoverusercreate, setTopmoverusercreate] = useState();
  const [clickindices, setClickindices] = useState([]);
  const [accountdata, setAccountData] = useState(null);
  const [percentageData, setPercentageData] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [transparentBackground, setTransparentBackground] = useState(false);
  // const body = {
  //   apiKey: accountdata?.api_key,
  //   token: accountdata?.access_token,
  //   user_id: accountdata?.user_id,
  //   id: selectedIndicesId,
  // };

  // console.log("accountdata", accountdata);
  const user = getUser();
  const fetchTechnicalData = async () => {
    const user = getUser();
    // topmoversid
    if (selectedIndicesId) {
      let requestdatafordefaultlist = {
        id: selectedIndicesId,
      };

      let req = {
        id: selectedIndicesId,
        scanner_user_id: user?.id,
      };
      let apiBody = requestdata ? req : requestdatafordefaultlist;
      // console.log("apiBody", apiBody);
      try {
        const response = await axios.post(
          `https://dalrotibackend.shrikanttapkeer.com/api/v1/strategies/getStocksUnderIndices`,
          apiBody
        );
        if (response.data.success) {
          // console.log("first", response.data);
          setTechnicalData(response.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching technical data", error);
      }
    }
  };
  useEffect(() => {
    fetchTechnicalData();
  }, [selectedIndicesId, user?.id]);
  useEffect(() => {
    const data = getActiveAccountdata();
    setAccountData(data);
  }, []);
  const [showCreateModal, setShowCreateModal] = useState(false);
  useEffect(() => {
    const gettopmoverstechnical = async (requestdata) => {
      let requestdatafordefaultlist = {
        apiKey: accountdata?.api_key,
        token: accountdata?.access_token,
        id: topmoversid,
      };

      let req = {
        apiKey: accountdata?.api_key,
        token: accountdata?.access_token,
        id: topmoverusercreate,
        user_id: user?.id,
      };
      let apiBody = requestdata ? req : requestdatafordefaultlist;
      const result = await getTopMovers(apiBody);
      setTopmovers(result?.topGainers);
      setTopLossers(result?.topLosers);
    };
    gettopmoverstechnical(requestdata);
    // const intervalId=setInterval(()=>{
    //   gettopmoverstechnical(requestdata);
    // },300000)
    // return()=>clearInterval(intervalId);

    // if (accountdata) {
    gettopmoverstechnical();
    intervalRef.current = setInterval(gettopmoverstechnical, 300000);
    return () => clearInterval(intervalRef.current);
    // }
  }, [accountdata, topmoversid, topmoverusercreate, user?.id]);

  // [requestdata, accountdata, topmoversid, topmoverusercreate, user?.id]

  const handleItemClick = (indices_id, itemid) => {
    // console.log("12first", indices_id, itemid);
    setRequestdata(itemid);
    if (indices_id) {
      setSelectedIndicesId(indices_id);
      if (itemid) setText(true);
      else {
        setText(false);
        setLoading(true);
      }
    }
    if (indices_id === itemid) {
      setTopmoverusercreate(indices_id);
    } else {
      setTopmoversid(indices_id);
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://apiconnect.angelbroking.com/rest/secure/angelbroking/marketData/v1/gainersLosers",
        {
          datatype: "PercOIGainers",
          expirytype: "NEAR",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer YOUR_ACCESS_TOKEN",
            "X-PrivateKey": "ErHC7ZAQ",
            "X-UserType": "USER",
            "X-SourceID": "WEB",
            Accept: "application/json",
            "X-ClientLocalIP": "192.168.1.26",
            "X-ClientPublicIP": "58.84.60.138",
            "X-MACAddress": "A0-A4-C5-00-A6-54",
          },
        }
      );

      if (response.data.status) {
        // setGainersData(response.data.data);
      } else {
        console.error("API request failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const getSelectedItems = (items) => {
    // console.log("items>", items);
    const itemArray = [];
    items.map((i) => {
      itemArray.push(i.token);
    });
    setsubscriptionRequestToken(itemArray);
    // console.log("tokenMapping----", tokenMapping);
    settokenMapping(tokenMapping);
    setStocks(items);
  };

  const handleCreateList = async (strategiesName) => {
    const user = getUser();
    setShowCreateModal(false);
    // console.log("addwatchlist@@", user.id, strategiesName);
    const result = await addwatchlist({
      user_id: user.id,
      name: strategiesName,
    });
    if (result === true) {
      alert("Created new list successfully");

      gettingindices();
    } else {
      console.log("Error in topmovers API");
    }
    // console.log("addwatchlist", result);
  };

  const gettingindices = async () => {
    const user = getUser();
    const result = await getIndices({ user_id: user.id });
    // console.log("indices", result);
    setIndices(result);
  };

  const handleitemid = (id) => {
    console.log("@@@###", id);
  };

  const updatelist = useCallback(
    async (props) => {
      console.log("props", props);
      const transformdata = props.map((item) => ({
        name: item.name?.replace(/^"|"$/g, ""),
        scripCode: item?.scripCode,
      }));
      const Requestdata = {
        id: requestdata,
        stocks: transformdata,
      };
      console.log("add_stock?", Requestdata);
      const result = await updatewatchlist(Requestdata);
      if (result) {
        // console.log("REAULT OF UPDATED ", result);
        setShowList(false);
        alert("Watchlist updated successfully...");
        setIsAlert(!isAlert);
        fetchTechnicalData();
        fetchPercentageChange();
        setTriggerUpdate(false);
        setTransparentBackground(false);
      }
    },
    [requestdata]
  );

  const fetchscriptcode = async (scriptcode) => {
    console.log("first11", scriptcode);
    setScriptcode(scriptcode);
    const result = await gettechnicalindicator({
      interval: activeTab,
      scripCode: scriptcode,
    });
    // console.log("first111", result);
  };
  const addstocks = () => {
    setShowList(true);
    setTransparentBackground(!transparentBackground);
  };
  useEffect(() => {
    gettingindices();
  }, [triggerUpdate]);
  const body = {
    apiKey: accountdata?.api_key,
    token: accountdata?.access_token,
    // user_id: accountdata?.user_id,
    id: selectedIndicesId,
  };

  const fetchPercentageChange = async () => {
    try {
      let requestdatafordefaultlist = {
        apiKey: accountdata?.api_key,
        token: accountdata?.access_token,
        id: selectedIndicesId,
      };

      const body = {
        apiKey: accountdata?.api_key,
        token: accountdata?.access_token,
        user_id: accountdata?.user_id,
        id: selectedIndicesId,
      };
      // console.log( ">>>>",requestdata,requestdatafordefaultlist, req )
      // console.log("body", body)
      let apiBody = requestdata ? body : requestdatafordefaultlist;
      // console.log(">>>>>>", apiBody)
      let getPercentageChng = await getPercentageChange(apiBody);
      if (getPercentageChng) {
        // console.log("getPercentageChng", getPercentageChng);
        setPercentageData(getPercentageChng);
      }
    } catch (error) {
      console.log("serror getting percentage change ", error);
    }
  };
  const handleCloseDoor = () => {
    setTransparentBackground(false);
    setShowList(false);
  };
  useEffect(() => {
    fetchPercentageChange();
  }, [accountdata, user?.id, selectedIndicesId]);
  // console.log("topmovers", topmovers)

  return (
    <>
      <section className={`mobile-view`}>
        <div className="container web-cotainer">
          <div className="row">
            <div className="col-lg-9 col-mg-8 col-sm-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginRight: "20px",
                }}
              >
                <h3 style={{ color: "#2c308e" }}>
                  <b>Technicals</b>
                </h3>
                <Button
                  variant="primary"
                  className="btn-color w-lg-50"
                  onClick={() => {
                    setShowCreateModal(true);
                  }}
                >
                  Create New List
                </Button>
              </div>

              <h6 className="fw-normal">
                My Watchlist
                {/* <Button>Create new list</Button> */}
              </h6>
              <div className="col-lg-12">
                <StocksCarousel
                  indices={indices}
                  handleItemClick={handleItemClick}
                  handleitemid={handleitemid}
                />
              </div>
              <div
                className="col-lg-12 technical-nifty-tab pt-4"
                id="technical-nifty-tab"
              >
                <Card>
                  <Card.Body>
                    <div className="row p-0 justify-content-center align-items-center">
                      <div className="col-lg-2 p-0">
                        <div style={{ marginLeft: "20px" }}>
                          {/* <span style={{ color: "#303684", fontWeight: "600" }}>
                            {clickindices?.Indices}
                          </span> */}
                          <div className="fw-bolder">STOCK NAME</div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8 p-0"></div>
                      <div className="col-lg-1 col-lg-1 col-md-1 col-sm-1 p-0 fw-bolder">
                        LTP
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div className="row add-stocks-btn pt-3">
                <div className="col-lg-12" onClick={() => addstocks()}>
                  {text === true && (
                    <>
                      <i className="fa fa-plus"></i>{" "}
                      <span
                        style={{
                          color: "#6baddb",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        Add stocks eg SBIN, F&O etc.
                      </span>
                    </>
                  )}
                </div>
              </div>

              <div className="pt-4">
                {loading ? (
                  <div className="text-center">
                    <Spinner color="primary" />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <TechnicalBox
                    technicalData={technicalData}
                    fetchscriptcode={fetchscriptcode}
                    percentageData={percentageData}
                  />
                )}
              </div>
            </div>
            {topmovers && (
              <TopMoverRightSideBar2
                topmovers={topmovers}
                toplossers={toplossers}
              />
            )}
            {/* {topmovers && <TopMoverRightSideBar2 topmovers={topmovers} />} */}
          </div>
        </div>
        <TechnicalCreateModal
          showCreateModal={showCreateModal}
          setShowCreateModal={setShowCreateModal}
          handleCreateList={handleCreateList}
        />
        <div
          className={transparentBackground && "transparent_background"}
          onClick={handleCloseDoor}
        ></div>
        {showList && (
          <StocksTechnicalList
            storeSelectedItems={getSelectedItems}
            setShowList={setShowList}
            updatelist={updatelist}
            selectedStock={stocks}
            technicalData={technicalData}
            formData=""
            allStocks={allStocks}
          />
        )}
      </section>
    </>
  );
};

export default Technical;
