import getCandleId from "./CommonFunction/getCandleId";
import { indicatorExitConfigs } from "./CommonFunction/indicatorsEntryList";
import { helperModalReplaceFn888 } from "./helperModalSwitchFn";

  const replaceNewExistData = (formData,item, showInstead) => {
    let newFormData = { ...formData };
    let indicators = newFormData?.exit_indicators;
    let candleId = getCandleId(formData);
    let baseObj = {
        function_id: item?.id,
        name: item.name,
        function_type_id: item?.function_type_id,
        candleIntervalId: candleId,
    };
    if (item?.function_type_id === 2) {
      formData.isIncludeMathFunction = true;
      if (item.name === "Max") {
        baseObj.body1 = [];
        baseObj.body2 = [];
      } else if (item.name === "Min") {
        baseObj.body1 = [];
        baseObj.body2 = [];
      } else if (
        item.name === "Ceil" ||
        item.name === "Floor" ||
        item.name === "Abs"
      ) {
        baseObj.body = [];
      } else if (item.name === "Symbol") {
        baseObj.body = [];
        baseObj.scripCode = "";
      } else {
        baseObj.body = [];
        baseObj.period = 10;
      }
    }
  
  const valuesArray = Object.values(indicators);
  const itemAtIndex = valuesArray[showInstead?.index];
  console.log("itemAtIndex", itemAtIndex, baseObj);
    const specificConfig = indicatorExitConfigs[item?.name] || {};
    const objOf = { ...baseObj, ...specificConfig };

    const getMaxSuffix = (indicatorObj, item) => {
        let maxSuffix = 0;
        Object.keys(indicatorObj).forEach((key) => {
          if (key.startsWith(item?.name)) {
            const suffix = key?.slice(item?.name?.length).match(/\d+$/);
            if (suffix) {
              maxSuffix = Math.max(maxSuffix, parseInt(suffix[0], 10));
            }
          }
        });
        return maxSuffix;
    };
    const createMinMaxIndex = (itemAtIndex) => {
      const body1Exists = itemAtIndex.hasOwnProperty("body1");
      const body2Exists = itemAtIndex.hasOwnProperty("body2");
      const body1Count = body1Exists ? itemAtIndex?.body1?.length : 0;
      const body2Count = body2Exists ? itemAtIndex?.body2?.length : 0;
      const endCount = body1Count + body2Count + 1;
      return endCount;
    };
    const createMathFnIndex = (itemAtIndex) => {
      const body1Exists = itemAtIndex.hasOwnProperty("body");
      const body1Count = body1Exists ? itemAtIndex?.body?.length : 0;
      const endCount = body1Count + 1;
      return endCount;
    };
    let index = showInstead?.index;
    let entries = Object.entries(indicators);
    if (index >= 0 && index < entries.length) {
      let [keyToDelete, value] = entries[index];
      let numericSuffix = keyToDelete.match(/\d+$/);
      let num = numericSuffix;
      let keyName;
      console.log("keyChange", keyName, item.name, num);
      if (item?.function_type_id == 4) {
        keyName = "operators" + num;
      } else if (item?.function_type_id == 5) {
        keyName = "comparator" + num;
      } else if (item?.function_type_id == 6) {
        keyName = item.name + (getMaxSuffix(newFormData?.exit_indicators, item) + 1);
      } else {
        keyName = item.name + (getMaxSuffix(newFormData?.exit_indicators, item) + 1);
      }

      entries[index] = [keyName, objOf];
      let newData = Object.fromEntries(entries);
      newFormData.clickedFunction = keyName;
      newFormData = { ...newFormData, exit_indicators: newData };
      console.log("newData111", objOf);
      if (newFormData?.order) {
        newFormData?.order.splice(showInstead.index, 1, keyName);
      }
    }
    for (let i = 0; i < newFormData?.exit_functions?.length; i++) {
        let replaceObje = {
          function_id: item?.id,
          name: item.name,
          function_type_id: item?.function_type_id,
          candleIntervalId: candleId,
        };
  
        if (item.function_type_id == 3) {
          helperModalReplaceFn888(objOf,replaceObje, item )
        
        } else if (item.function_type_id == 4) {
          replaceObje.comparator = item?.name;
        } else if (item.function_type_id == 5) {
          switch (item.name) {
            case "crosses above":
              replaceObje.comparator = ">";
              replaceObje.isCrossover = 2;
              break;
            case "crosses below":
              replaceObje.comparator = "<";
              replaceObje.isCrossover = 1;
              break;
            case "higher than":
              replaceObje.comparator = ">";
              break;
            case "lower than":
              replaceObje.comparator = "<";
              break;
            case "lower than equal to":
              replaceObje.comparator = "<=";
              break;
            case "higher than equal to":
              replaceObje.comparator = ">=";
              break;
            case "equal to":
              replaceObje.comparator = ">=";
              break;
          }
        }
        let index = newFormData?.exit_functions[i].findIndex(
          (obj) =>
            obj.function_id == showInstead.function_id &&
            obj.function_type_id == showInstead.function_type_id
        );
  
        if (index !== -1) {
          newFormData.exit_functions[i][index] = replaceObje;
          console.log("isIt", newFormData.exit_functions[i][index]);
          break;
        }
      }
      if (newFormData && newFormData.functionsBox) {
        newFormData.functionsBox?.splice(showInstead.index, 1, item?.name);
      }
      if (newFormData && newFormData.functionsBox888) {
        const entries = Object.entries(formData.exit_indicators);
        const [keyAtIndex, nestedObject] = entries[index];
        const keys = Object.keys(formData.exit_indicators);
        const keysBeforeIndex = keys.slice(0, index);
        const counts = {
          periodCount: 0,
          minCount: 0,
          maxCount: 0,
          symbolCount: 0,
          floorCount: 0,
          ceilCount: 0,
          absCount: 0,
        };
        keysBeforeIndex.forEach((key) => {
          if (/Period/.test(key)) counts.periodCount++;
          if (/Min/.test(key)) counts.minCount++;
          if (/Max/.test(key)) counts.maxCount++;
          if (/Symbol/.test(key)) counts.symbolCount++;
          if (/Floor/.test(key)) counts.floorCount++;
          if (/Ceil/.test(key)) counts.ceilCount++;
          if (/Abs/.test(key)) counts.absCount++;
        });
        let adjustedIndex = showInstead.index;
  
        if (Object.values(counts).some((count) => count > 0)) {
          adjustedIndex +=
            counts.periodCount * 1 +
            (counts.minCount + counts.maxCount) * 2 +
            counts.symbolCount * 1 +
            counts.floorCount * 1 +
            counts.ceilCount * 1 +
            counts.absCount * 1;
            
            if (showInstead.function_id == 2 || showInstead.function_id == 3) {
              const endCount = createMinMaxIndex(itemAtIndex);
              newFormData?.functionsBox888?.splice(adjustedIndex, endCount, objOf);
            } else if (
              showInstead.function_id == 1 ||
              showInstead.function_id == 4 ||
              showInstead.function_id == 5 ||
              showInstead.function_id == 6 ||
              showInstead.function_id == 7 ||
              showInstead.function_id == 8
            ) {
              const endCount = createMathFnIndex(itemAtIndex);
              newFormData?.functionsBox888?.splice(adjustedIndex, endCount, objOf);
            } else {
              newFormData?.functionsBox888?.splice(adjustedIndex, 1, objOf);
            }
        } else {
          newFormData?.functionsBox888?.splice(adjustedIndex, 1, objOf);
        }
      }
      let obj12 = {
        function_id: objOf?.function_id,
        name: objOf?.name,
        function_type_id: objOf?.function_type_id,
      };
      if (newFormData && newFormData.functionsBox3) {
        newFormData.functionsBox3 = newFormData.functionsBox3.map(
          (func, index) => {
            if (
              func.function_id == showInstead?.function_id &&
              func.function_type_id == showInstead?.function_type_id &&
              index == showInstead?.index
            ) {
              return {
                function_type_id: objOf?.function_type_id,
                function_id: obj12.function_id,
                name: obj12.name,
              };
            } else {
              return func;
            }
          }
        );
      }
     return newFormData;
}

export default replaceNewExistData ;