import React from "react";

const Payment = () => {
  return (
    <section>
      <div className="container-fluid ">
        <div className="py-5">
          <div className="row py-5">
            <div className="col-12 col-lg-6 ps-5">
              <h1 style={{ color: "#2c3092", marginTop: "100px" }}>
                <span>Sign up to access</span>
                <span>
                  {" "}
                  <strong> ProductName</strong>
                </span>
              </h1>
            </div>
            <div className="col-12 col-lg-6">
              <div
                style={{
                  width: "70%",
                  margin: "0px auto",
                  // height: "300px",
                  backgroundColor: "#f7f7f7",
                }}
              >
                <div className="p-5 position-relative">
                  <h5>Payment Methods</h5>
                  <div
                    className="mt-3"
                    style={{
                      backgroundColor: "white",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "4px 4px 15px -8px",
                    }}
                  >
                    <div className="w-100 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          src="icon-images/bank.png"
                          style={{
                            height: "35px",
                            width: "",
                            paddingLeft: "18px",
                          }}
                        ></img>
                        <span className="ps-3" style={{ fontSize: "17px" }}>
                          Bank
                        </span>
                      </div>
                      <div style={{ marginRight: "17px" }}>
                        <input type="radio"></input>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      backgroundColor: "white",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "4px 4px 15px -8px",
                    }}
                  >
                    <div className="w-100 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          src="icon-images/credit-card.png"
                          style={{
                            height: "35px",
                            width: "",
                            paddingLeft: "18px",
                          }}
                        ></img>
                        <span className="ps-3" style={{ fontSize: "17px" }}>
                          Credit Card
                        </span>
                      </div>
                      <div style={{ marginRight: "17px" }}>
                        <input type="radio"></input>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      backgroundColor: "white",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "4px 4px 15px -8px",
                    }}
                  >
                    <div className="w-100 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          src="icon-images/bhim-upi.png"
                          style={{
                            height: "35px",
                            width: "",
                            paddingLeft: "18px",
                          }}
                        ></img>
                        <span className="ps-3" style={{ fontSize: "17px" }}>
                          UPI
                        </span>
                      </div>
                      <div style={{ marginRight: "17px" }}>
                        <input type="radio"></input>
                      </div>
                    </div>
                  </div>
                  <button
                    className="mt-3"
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #0d88d5, #2d3395)",
                      margin: "0px auto",
                      width: "130px",
                      height: "47px",
                      color: "white",
                      border: "none",
                      fontWeight: "bolder",
                      width: "80%",
                      position: "absolute",
                      bottom: "-25px",
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Payment;
