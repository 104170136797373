import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import CreateStrategyPopup from "../Components/CreateStrategyPopup";
import { getUser ,getActiveAccount} from "../Session";
import { existStrategyNameApi, getAllStrategyApi,deletingStrategy,getAllDiscoveredStrategyApi } from "../api/strategyapi";
import moment from "moment";
import StrategyCreateComonent from "../Components/Strategies/StrategyCreateComponent";
import CircularProgress from "@mui/material/CircularProgress";
import "../Components/componentCommon/myspinner.css";
import Pagination from "../Components/componentCommon/Pagination";
import InputSearch from "../Components/componentCommon/InputSearch";
import {ResetList, SortData} from "../Components/componentCommon/SortReset";
const Strategies = () => {
  const styles = {
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50vh',
    },
  };
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [strategiesNameError, setStrategiesNameError] = useState(false);
  const [createStratgyPage, setCreateStratgyPage] = useState(false);
  const [strategyName, setStrategyName] = useState("");
  const [categorynm,setCategoryname]=useState();
  const [strategiesList , setStrategiesList ] = useState([]);
  const [del,setDelete]=useState();
  const navigate = useNavigate();
  const [loading,setLoading]=useState(true);
  // Search funcationality //
  const [originalStrategiesList, setOriginalStrategiesList] = useState([]);
 // sort functionality //
  const [isReset, setIsReset] = useState(false) ;
  const [isShowReset, setShowReset] = useState(false) ;

  // pagination logic //
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(strategiesList?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = strategiesList.slice(startIndex, endIndex);
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // console.log("SEARCH", strategiesList );
  console.log("sortType", strategiesList);

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleChange = (page) => {
    console.log("page", page)
    setCurrentPage(page);
  }

    const handleClickStrategyModal = () => {
        setShowCreatePopup(true);
      };

      const handelClickCreateStrategy = async (name,Category) => {
        const user = getUser();
          const data = await existStrategyNameApi({
            strategy_name: name?.trim(),
            user_id: user?.id,
          });
          if (data?.data?.length >= 1) {
            setStrategiesNameError(true);
          } else {
            setStrategiesNameError(false);
            setShowCreatePopup(false);
            setCreateStratgyPage(true);
            setStrategyName(name);
            setCategoryname(Category);
          }
      };
      const featchListOfStrategy = async () => {
        const user = getUser();
        const result = await getAllDiscoveredStrategyApi({user_id : user?.id});
        setStrategiesList(result);
        setOriginalStrategiesList(result)
        // setStrategiesList([...Array(70)])
        setLoading(false);  
      }

      const handleConfirmDelete=async(id)=>{
        // console.log("strategyID11", id);
        const isconfirmed=window.confirm("Are you sure you want to delete this strategy?");
        if(isconfirmed)
          {
            const result=await deletingStrategy({strategy_id:id});
            setDelete(id);
            featchListOfStrategy();
          }
      }
      const handleClickReset = () => {
        console.log("reset", strategiesList)
        setIsReset(!isReset)
        featchListOfStrategy()
      }
      useEffect(() => {
        featchListOfStrategy();
      },[del]);

    return(
    <>
    {createStratgyPage?(<>
       <StrategyCreateComonent
        strategyName={strategyName}
        categorynm={categorynm}
        setStrategiesNameError={setStrategiesNameError}
        setCreateStratgyPage={setCreateStratgyPage}
      /></>):(
      <>
      <div className="page w-100">
        <div className="page-content">
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Strategies</h3>
              <div className="panel-actions text-white">
                        <button className="btn btn-primary addNewStrategyBtn" 
                    onClick={handleClickStrategyModal} disabled={loading}>
                  <span className="hidden-sm-down">
                    <i className="fa fa-plus" aria-hidden="true"></i> Add New
                    Strategies
                  </span>
                </button>
              </div>
            </div>
            <div className="panel-body">
             <div className="d-flex justify-content-between mb-2">
              <div >
              <InputSearch dataList={strategiesList} setDataList={setStrategiesList} originalDataList={originalStrategiesList} isReset={isReset} isScanner="" />
              </div>
              <div className="d-flex">
              <SortData dataList={strategiesList} setDataList={setStrategiesList} isReset={isReset} isScanner="" />
              <ResetList handleClickReset={handleClickReset } />

              </div>
             </div>
            {loading && 
              <>  
                <div style={styles.spinnerContainer}>
                <CircularProgress color="inherit" />
                </div>
              </>
              }
              <table
                className="table table-hover table-bordered dataTable table-striped w-full"
                id="user-list"
              >
                {!loading && 
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Strategy Name</th>
                    <th>Created Date</th>
                    <th className="d-flex justify-content-around">Action</th>
                  </tr>
                </thead>
                }
                <tbody>
                  {currentData?.map((data,i) => (
                    <tr key={i}>
                      <td>{++i}</td>
                      <td>{data?.strategy_name.length>20?(data?.strategy_name.slice(0,20))+'...':data?.strategy_name }</td>
                      <td>{moment(data?.strategy_details.created_at).format('DD-MM-YYYY')}</td>
                      <td className="d-flex justify-content-around tableBtn">
                        <div>
                          <Button onClick={() => navigate(`/strategies/${data?.strategy_id}`)} varient="success"><i className="fa fa-eye"></i> View </Button>
                          <Button varient="success" className="ms-3" onClick={() => handleConfirmDelete(data.strategy_id)}><i className="fa fa-trash"></i> </Button>
                        </div>
                      </td>
                  </tr>
                  ))}
                  
                </tbody>
              </table>
            </div>
            {/* // pagination // */}
            <Pagination currentPage={currentPage} totalPages={totalPages} handleNext={handleNext} handlePrev={handlePrev} handleChange={handleChange} />
          </div>
        </div>
      </div>
      <CreateStrategyPopup
          showCreatePopup={showCreatePopup}
          strategiesNameError={strategiesNameError}
          setStrategiesNameError={ setStrategiesNameError}
          handelClickCreateStrategy={handelClickCreateStrategy}
          setShowCreatePopup={setShowCreatePopup}/>
          </>
          )}
      
    </>
    )
}

export default Strategies;