/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState,useEffect} from 'react'
import { Form, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AddNewBlog,GetBlogs,UpdateBlogs } from '../api/adminapi';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BASE_URL } from '../config';
function Editblogs() {
  const navigate=useNavigate();
  const [searchparam,setSearchparam]=useState('');
  const [userRecords,setUserRecords]=useState([]);
  const [formData,setFormData]=useState({title:'',description:'',blog_img:null});
  const {id}=useParams();
  const [editableblogs,setEditableblogs]=useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedfile,setSelectedfile]=useState();
  useEffect(()=>{
      setSearchparam(id);
      // const result = await GetBlogs(); 
      // console.log("result@@",result);
      // setUserRecords(result);
  },[id])

 const AddBlogs=async(e)=>{
  e.preventDefault();
  const data=new FormData();
  data.append('title',formData?.title);
  data.append('description',formData?.description);
  if(formData?.blog_img)
    {
      data.append('blog_image',formData?.blog_img);
    }
    if(formData?.title && formData?.description)
      {
        const result=await AddNewBlog(data);
        console.log("++first",result);
          if(result.success===true)
             {
                 alert("Added new blog successfully");
                 navigate('/user/Blogs');
             }
          else
            {   
              alert("Something went wrong...");
            }
    }
    else{
      alert("Please fill necessary details to create blog");
    }
 }
 useEffect(() => {
  console.log("Current id:", formData);
  // Fetch data or perform actions based on `id`
}, [id,formData,searchparam]);
 useEffect(()=>{
    if(searchparam !== 'new')
      {
        const fetchblogs=async()=>
          {
            const result = await GetBlogs(); 
            setUserRecords(result);
            const selectedblog=result.find(blog=> blog.id===parseInt(searchparam))
            setEditableblogs(selectedblog)
            setFormData(selectedblog);
            console.log("12result@@",selectedblog);
          }
          fetchblogs();
      }  
 },[id,searchparam]);

 const UpdatemyBlogs=async(e)=>{
      e.preventDefault();
  try {
  const data=new FormData();
  data.append('id',searchparam);
  data.append('title',formData?.title);
  data.append('description',formData?.description);
  if(formData?.blog_img)
    {
      data.append('updated_blog_image',formData?.blog_img);
    }
  else
  {
     data.append('img_removed',1);
  }
 
  const result=await UpdateBlogs(data);
  if(result.success===true)
    {
      alert("Blogs Details updated successfully...");
      navigate('/user/Blogs');
    }
  else{
    alert("Something went Wrong")
  }
  }
  catch(error)
  {
    console.log("error in updating information",error)
  }
 }
 const handleChange=(e)=>{
  const {name,value}=e.target;
  setFormData((prevdata)=>({
    ...prevdata,
    [name]:value,
  }))
  console.log("@#first",name,value);
 }
 const handleImageChange=(e)=>{
  const file=e.target.files[0];
  setSelectedfile(file);
  setImagePreview(URL.createObjectURL(file));
  if(file)
    {
        setFormData((prevdata)=>({
            ...prevdata,
            blog_img:file,
          }))
     }
    
 }
 const handleRemoveImage=()=>{
    setFormData({...formData,blog_img:''})
 }
 const handleChangeValues = (value) => {
  setFormData((prevData) => ({
    ...prevData,
    description: value,
  }));
};
 const toolbarOptions = [
  [{ 'font': [] }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],
  [{ 'direction': 'rtl' }],
  [{ 'align': [] }],
  ['clean']
];

const modules = {
  toolbar: toolbarOptions,
};

  return (
    <div className="container w-75 mx-auto my-3">
      {searchparam==='new' ? <h2 className='py-3'>Add Blog</h2>:<h2 className='py-3'>Edit Blog</h2>}
      <form>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            name="title"
            className="form-control"
            value={formData?.title}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mt-3">
          <label>Description</label>
          <ReactQuill
            name="description"
            className="form-control"
            rows={10}
            value={formData?.description}
            onChange={handleChangeValues}
          />
        </div>
        <div className="form-group mt-3">
    
          {/* {formData?.blog_img ? */}
                  {/* ( */}
                  {!formData?.blog_img ? (<div></div>):( <div style={{ position: 'relative', display: 'inline-block' }}>
                   <p>Blog Image:</p>
                  <img 
                  src={selectedfile ? imagePreview : formData?.blog_img && `${BASE_URL}/` + formData?.blog_img}
                    alt="Image Preview"
                    style={{ width: "90px", height: "90px" }}
                  />
                  <span
                    className="close-icon"
                    onClick={handleRemoveImage}
                    style={{
                      position: 'absolute',
                      top: '44px',
                      right: '5px',
                      cursor: 'pointer',
                      background: 'white',
                      borderRadius: '50%',
                      height:"15px",
                      width:"15px",
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center",
                      padding:"4px",
                    }}
                  >
                    &#x2715; {/* Close icon (X) */}
                  </span>
                </div>) } 
                 
                {/* // ):(<div></div>) */}
          {/* // } */}
          <br></br>
          <br></br>
        <label>Image URL</label><br></br>
          <input
            type="file"
            name="blog_image"
            className="form-control"
            onChange={handleImageChange}
          />
        </div>
         {searchparam==='new' ?  <button className="mt-3 btn btn-primary" onClick={AddBlogs}>
          Add
        </button>: <button className="mt-3 btn btn-primary" onClick={UpdatemyBlogs}>
          Update
        </button> }
       
        <button type="button" className="mt-3 btn btn-secondary ms-2" 
        // onClick={handleCancel}
        >Cancel</button>
      </form>
    </div>
  )
}

export default Editblogs