import axios from "axios";
import { API_BASE_URL } from "../config";

// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";

export const UploadStacticContentImage = async (
    endpoint,
    imageFile,
    fileKey
) => {
    try {
        const formData = new FormData();
        formData.append(fileKey, imageFile);

        const response = await axios.post(
            `${API_BASE_URL}/adminpanel/${endpoint}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        console.log(`${fileKey} uploaded successfully:`, response.data);
        console.log(response.data.success);
        if (response.data.success) {
            return response?.data;
        } else {
            return false;
        }
    } catch (error) {
        console.error(`Failed to upload ${fileKey}`, error);
        throw error;
    }
};

export const GetImagesData = async () => {
    try {
        const response = await axios.get(
            `${API_BASE_URL}/adminpanel/GetStaticContentData`
        );
        console.log(response.data.success);
        if (response.data.success) {
            return response?.data.data;
        } else {
            return false;
        }
    } catch (error) {
        console.log("Get Image Error", error);
    }
};

export const RemoveImages = async (id = 1, colName) => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/adminpanel/DeleteStaticContentData`,
            {
                colName: colName,
                id: id,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        // console.log("@@Response Delete: ",response.data);
        console.log(`${colName} removed successfully:`, response.data);
        console.log("@@remove", response.data.success);
        if (response.data.success) {
            return response?.data.success;
        } else {
            return false;
        }
    } catch (error) {
        console.log("Remove Image Error", error);
    }
};
