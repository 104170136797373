import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Form, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  deleteStrategyApi,
  deployStrategyApi,
  getAllStrategyApi,
} from "../../api/strategyapi";
import { getUser, getZerodhaToken } from "../../Session";
import moment from "moment";
import DeleteStrategyModal from "./DeleteStrategyModel";
import CreateDeployPopup from "./CreateDeployPopup";
import StrategyDeploySuccessModal from "./StrategyDeploySuccessModal";
import Note from "../Note";
import { getSingleSavedStrategyData } from "../../api/api";
import ScannerModal from "../scanner/ScannerModal";
import {
  deleteScannerAPI,
  getAllSavedScannersApi,
  getLiveScannerResultAPI,
} from "../../api/scannerapi";
import SkeletonLoader from "../componentCommon/SkeletonLoader";
import "../../styles/pagination.css" ;
import Pagination from "../componentCommon/Pagination";
const ScannersAll = () => {
  const navigate = useNavigate();
  const [scannerData, setScannerData] = useState([]);
  const [optionMenu, setOptionMenu] = useState(
    Array(scannerData?.length).fill(false)
  );
  const [scannerId, setScannerId] = useState();
  const [show, setShow] = useState(false);
  const [openDeployModal, setOpentDeployModal] = useState(false);
  const [strategyItemData, setStrategyItemData] = useState();
  const [termCondition, settermCondition] = useState(false);
  const [deployedSuccess, setDeployedSuccess] = useState(false);
  const [trading_type, set_trading_Type] = useState(1);
  const [search, searchtext] = useState("");
  const [scannerDatafilter, setScannerDatafilter] = useState([]);
  const [hoverEllipse, setHoverEllipse] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [createScannerPage, setCreateScannerPage] = useState(false);
  const [scannerName, setScannerName] = useState("");
  const [strategiesNameError, setStrategiesNameError] = useState(false);
  const [scannerLoading, setScannerLoading] = useState(true);
  const dropdownRefs = useRef([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(scannerData?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
   console.log("scannerData", scannerData);
   const currentItems = scannerDatafilter.slice(
     startIndex,
     startIndex + itemsPerPage
   );
  //  setScannerData(currentItems);
   useEffect(() => {
     
     console.log("currentItems", currentItems, scannerData);
   }, [currentPage]);
  const user = getUser();
  const token = getZerodhaToken();

  const handleClose = () => {
    setShow(false);
  };
  // useEffect(() => {
  //   setScannerDatafilter(scannerData);
  //   console.log("SDATA-", scannerData);
  // }, [scannerData]);

  const handleConfirmDelete = (id) => {
    setShow(true);
    setScannerId(id);
  };

  const handleConfirm = async () => {
    const data = await deleteScannerAPI({
      scanner_id: scannerId,
      user_id: user.id,
    });
    if (data) {
      handleGetAllScanners();
      alert("Scanner Deleted!");
    }
    handleClose();
  };

  const handleMenuShow = () => {
    setOptionMenu(!optionMenu);
  };
  const toggleMenu = (index) => {
    const updatedMenus = [...optionMenu];
    updatedMenus[index] = !updatedMenus[index];
    setOptionMenu(updatedMenus);
  };

  const handleGetAllScanners = async () => {
    const user = getUser();
    const result = await getAllSavedScannersApi({ user_id: user.id });
    console.log("getAllSavedScannersApi", result?.data);
    setScannerData(result?.data);
    setScannerDatafilter(result?.data);
    setScannerLoading(false);
  };

  const handleClickModalOpen = (item) => {
    setOpentDeployModal(true);
    setStrategyItemData(item);
    setScannerId(item.id);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-menu")) {
      setOptionMenu(Array(scannerData?.length).fill(false));
    }
  };

  useEffect(() => {
    handleGetAllScanners();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlesearch = (e) => {
    const text = e.target.value;
    searchtext(text);
   if (text === "") {
    setScannerDatafilter(scannerData);
    setCurrentPage(1) ;
   } else {
    const filteredData = scannerDatafilter?.filter((item) =>
      item?.scanner_name?.toLowerCase().includes(text.toLowerCase())
    );
    setScannerDatafilter(filteredData);
    // setScannerData(filteredData);
    setCurrentPage(1);
   }
    
  };

  const handelClickCreateScanner = (name) => {
    if (name !== undefined) {
      setShowCreatePopup(false);
      navigate(`/scanners/page?scannid=1&scannername=${name}`);
    } else {
      setStrategiesNameError(true);
    }
  };

  const handelClickOpenScannerModal = () => {
    setShowCreatePopup(true);
  };

  const getLiveResult = async (scannerId) => {
    const result = await getLiveScannerResultAPI({
      api_key: "3wxj01wpd2rnnn9c",
      access_token: token,
      scanner_id: scannerId,
      user_id: user.id,
    });
    if (result) {
      console.log("liveresult1>>", result?.data);
      if (result?.data?.length > 0) return true;
      else return false;
    }
  };

 
  return (
    <>
      <section className="mobile-view">
        <div className="container web-cotainer">
          {/* <Note /> */}
          <div className="row pt-3 title-section">
            <div className="d-flex justify-content-between">
              <span className="fs-3 fw-bold">
                <i
                  className="fa fa-chevron-left chev fs-5 pointer-cursor"
                  onClick={() => navigate("/scanners")}
                ></i>
                &nbsp; My Scanners
              </span>
              <span>
                <Button
                  onClick={() => {
                    handelClickOpenScannerModal();
                  }}
                  className="btn-color"
                >
                  Create New Scanner
                </Button>
              </span>
            </div>
          </div>
          <div className="row pt-3">
            <div className="d-flex justify-content-between">
              <div>
                <div class="custom-wrapper">
                  <input
                    type="text"
                    placeholder="Search for scanner"
                    id="myInput"
                    className="form-control w-100"
                    onChange={handlesearch}
                  />
                  <i class="fa fa-search input-icon"></i>
                </div>
              </div>
              <div>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                />
              </div>
            </div>
          </div>

          {scannerLoading
            ? Array.from({ length: 3 }).map((_, i) => {
                return (
                  <SkeletonLoader
                    width="100%"
                    height="100px"
                    borderRadius="8px"
                  />
                );
              })
            : currentItems?.map((item, index) => (
                <div
                  className="startegy-all-details pointer-cursor"
                  id="startegy-all-details"
                  key={index}
                  onClick={() => {
                    if (!hoverEllipse) {
                      navigate(`/scanner/result/${item?.scanner_id}`);
                    }
                  }}
                  style={{ marginTop: "20px", height: "95px" }}
                >
                  <Card
                    className="startegy-all-details pointer-cursor"
                    id="startegy-all-details"
                    key={index}
                    onClick={() => {
                      if (!hoverEllipse) {
                        navigate(`/scanner/result/${item?.scanner_id}`);
                      }
                    }}
                  >
                    <Card.Body>
                      <div className="row">
                        <div className="col-lg-12 col-mg-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <span className="fw-bold">
                                {item?.scanner_name}
                              </span>
                              &nbsp;&nbsp;
                              <span style={{ fontSize: "12px" }}>
                                {moment(
                                  item?.scanner_details?.created_at
                                ).format("DD-MM-YYYY")}
                              </span>
                            </div>
                            <div
                              className="dropdown"
                              ref={(ref) => (dropdownRefs.current[index] = ref)}
                              onMouseEnter={() => setHoverEllipse(true)}
                              onMouseLeave={() => setHoverEllipse(false)}
                            >
                              <i
                                className="fa fa-ellipsis-v text-muted dropdown-button"
                                onClick={() => toggleMenu(index)}
                              ></i>
                              {optionMenu[index] && (
                                <ListGroup className="dropdown-menu">
                                  <ListGroup.Item
                                    onClick={async () => {
                                      let isLive = await getLiveResult(
                                        item?.scanner_id
                                      );
                                      if (isLive) {
                                        alert("Please Stop the Live Scanner");
                                        return;
                                      }
                                      let data = JSON.parse(
                                        item?.scanner_details?.entire_object
                                      );
                                      // console.log("entire_object", data);
                                      data.scanner_id =
                                        scannerData[0]?.scanner_id;
                                      console.log(
                                        "entire_object",
                                        scannerData[0]?.scanner_id
                                      );
                                      navigate(
                                        `/scanners/page?scannid=${item?.scanner_id}&scannername=${item?.scanner_name}`,
                                        {
                                          state: data,
                                        }
                                      );
                                    }}
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                    &nbsp;&nbsp; Edit
                                  </ListGroup.Item>
                                  <ListGroup.Item
                                    onClick={() =>
                                      handleConfirmDelete(item?.scanner_id)
                                    }
                                    className="text-danger"
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                    &nbsp;&nbsp; Delete
                                  </ListGroup.Item>
                                </ListGroup>
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div>Scan on :</div>&nbsp;
                            <div
                              style={{ fontWeight: "500", color: "#0290d6" }}
                            >
                              {item?.scanner_details?.scan_on}
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              navigate(`/scanner/result/${item?.scanner_id}`);
                            }}
                          ></div>
                        </div>
                        <div className="col-lg-4 col-mg-4">
                          <div className="strategy-graph-data">
                            {item.is_running === 0 &&
                            item.is_completed === 0 ? (
                              <span>Backtest is running</span>
                            ) : item.is_completed === 1 ? (
                              <span>Backtest is completed</span>
                            ) : (
                              item.is_running === 1 && (
                                <span>Backtest is stopped</span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
        </div>
        <DeleteStrategyModal
          show={show}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          mode="Scanner"
        />
        <CreateDeployPopup
          openDeployModal={openDeployModal}
          setOpentDeployModal={setOpentDeployModal}
          strategy={strategyItemData}
          termCondition={termCondition}
          settermCondition={settermCondition}
          trading_type={trading_type}
          set_trading_Type={set_trading_Type}
        />
        <StrategyDeploySuccessModal
          deployedSuccess={deployedSuccess}
          setDeployedSuccess={setDeployedSuccess}
        />
        <ScannerModal
          showCreatePopup={showCreatePopup}
          handelClickCreateScanner={handelClickCreateScanner}
          setShowCreatePopup={setShowCreatePopup}
          nameError={strategiesNameError}
          setError={setStrategiesNameError}
        />
      </section>
    </>
  );
};

export default ScannersAll;
