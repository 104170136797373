import indicatorsSwitchCase from "../indicatorsSwitchCase";
import getCandleId from "./getCandleId";

const GetFunctionofHandle = (
  item,
  formData,
  onFormChange,
  setInnerAdd,
  setOuterAdd,
  handleRemiceInstead,
  setCurrentId,
  setConditionTab,
  handleClickAll,
  setSelectedOption1,
  setMode,
  setIsSelectMax,
  setIsSelectMin,
  setSearchQuery,
  setShowDropdown,
  setRemovedInsideMath,
  removedInsideMath,
  mathIndexRemove,
  flag,
  setFlag,
  outerAdd,
  isIncludeMathFunction,
  innerAdd,
  instance
) => {
  setInnerAdd(false);
  setOuterAdd(true);
  handleRemiceInstead();
  console.log("ISMATHCHECK", item, outerAdd, innerAdd, instance )
  formData.selectedOption1 = item?.name;
  if (formData?.indicators) {
    const existingKeys = Object.keys(formData.indicators);
    const existingInstances = existingKeys.filter((key) =>
      key.startsWith(item?.name)
    );
    if (existingInstances.length > 0) {
      let lastNumber = 0;
      existingInstances.forEach((key) => {
        const number = parseInt(key.replace(item?.name, ""));
        if (!isNaN(number) && number > lastNumber) {
          lastNumber = number;
        }
      });
      formData.clickedFunction = `${item?.name}${lastNumber + 1}`;
      console.log(
        `${item?.name} is present in formData.indicators`,
        formData.clickedFunction
      );
    } else {
      // If the name doesn't exist, append "1" to the name
      formData.clickedFunction = `${item?.name}1`;
      console.log(
        `${item?.name} is not present in formData.indicators`,
        formData.clickedFunction
      );
    }
  } else {
    formData.clickedFunction = `${item?.name}1`;
    console.log(
      `${item?.name} is not present in formData.indicators (formData.indicators doesn't exist)`,
      formData.clickedFunction
    );
  }
  
  formData.count++;
  formData.conditionTab = true;
  onFormChange({
    ...formData,
    formData,
  });

  formData.functionsBox.push(item?.name);
  let obj = {};
  obj.function_id = item?.id;

  const comparisonOperators = [
    "higher than",
    "lower than",
    "higher than equal to",
    "lower than equal to",
    "crosses above",
    "crosses below",
  ];
  const includesComparisonOperator = comparisonOperators.some((operator) =>
    item?.name.includes(operator)
  );
  if (includesComparisonOperator) {
    if (item?.name.includes("crosses above")) {
      obj.isCrossover = 2;
      obj.comparator = ">";
      obj.name = "crosses above";
    } else if (item?.name.includes("crosses below")) {
      obj.isCrossover = 1;
      obj.comparator = "<";
      obj.name = "crosses below"
    } else {
      obj.comparator = item?.name;
    }
  } else {
    obj.name = item?.name;
  }
  obj.field = "";
  obj.period = "";
  obj.offset = "";
  obj.maPeriod = "";
  obj.maType = "";
  obj.function_type_id = item.function_type_id;
  obj.name = item?.name ;
  let candleId = getCandleId(formData);

  obj.candleIntervalId = candleId;
  console.log("ISCHANGE", removedInsideMath, mathIndexRemove);

  if (removedInsideMath) {
    formData.functionsBox777.splice(mathIndexRemove, 0, obj);
  } else {
    let indicatorBody = formData.indicators[formData.selectedOption3];
    if (indicatorBody?.body || indicatorBody?.body1 || indicatorBody?.body2) {
      console.log("ISCHANGE", indicatorBody);

      if(obj?.name !== "Number") {
        formData.functionsBox777.push(obj);
      }
    }else {
      formData.functionsBox777.push(obj);
    }
  }

  setCurrentId(item?.id);
  if (item.function_type_id === 3) handleClickAll("5");
  if (item.function_type_id === 5) handleClickAll("3");
  if (item.function_type_id === 2) handleClickAll("3");

  formData.selectedOption1 = item?.name;

  setConditionTab(true);
  if (flag === 0) {
    setSelectedOption1(item?.name);
    formData.firstFunctionId = item?.id;
    onFormChange({
      ...formData,
      formData,
    });

    if (item?.function_type_id === 2) {
      setMode("Function");
      onFormChange({
        ...formData,
        formData,
      });

      onFormChange({
        ...formData,
        mode: "Function",
        conditionTab: true,
        selectedOption1: item?.name,
        function_name: item?.id,
      });
      let obj = {};
      obj.function_id = item?.id;
      obj.function_type_id = item.function_type_id;
      obj.name = item?.name;
      formData.functionsBox2.push(obj);
    }

    if (item?.function_type_id === 3) {
      setMode("Indicators");
      onFormChange({
        ...formData,
        mode: "Indicators",
        conditionTab: true,
        selectedOption1: item?.name,
        function_name: item?.id,
      });
    }

    setFlag(1);
  }
  if (
    formData.functionsBox2[0]?.function_type_id === 2 &&
    flag > 0 &&
    !outerAdd
  ) {
    let obj2 = {};
    obj2.function_id = item?.id;
    obj.function_type_id = item.function_type_id;
    obj2.name = item?.name;

    if (formData.functionsBox2[0].body)
      formData.functionsBox2[0].body.push(obj2);
    else {
      formData.functionsBox2[0].body = [];
    }
  }
  if (
    item?.function_type_id === 3 ||
    item?.function_type_id === 2 ||
    item?.function_type_id === 1 ||
    item?.function_type_id === 6
  ) {
    let functionName = item?.name;
    if (!formData.indicators) {
      formData.indicators = {};
    }
    const existingInstances = Object.keys(formData.indicators).filter((key) =>
      key.startsWith(functionName)
    );

    let instanceName;
    if (existingInstances.length > 0) {
      let lastNumber = 0;

      existingInstances.forEach((key) => {
        const number = parseInt(key.replace(functionName, ""));
        if (!isNaN(number) && number > lastNumber) {
          lastNumber = number;
        }
      });

      instanceName = `${functionName}${lastNumber + 1}`;
    } else {
      // If no existing instances, start with "1"
      instanceName = `${functionName}1`;
    }
    // Create a new instance of the function
    let candleId = getCandleId(formData);

    let obj = {
      function_id: item?.id,
      name: functionName,
      function_type_id: item?.function_type_id,
      candleIntervalId: candleId,
    };
    indicatorsSwitchCase(obj, formData.selectedOption1, "");
    // Add the new instance to the object with a unique name
    if (item?.function_type_id === 2) {
      formData.isIncludeMathFunction = true;
      isIncludeMathFunction.current = true;
      if (item.name === "Max") {
        obj.body1 = [];
        obj.body2 = [];
        setIsSelectMax(true);
        formData.indicators[instanceName] = obj;
      } else if (item.name === "Min") {
        obj.body1 = [];
        obj.body2 = [];
        setIsSelectMin(true);
        formData.indicators[instanceName] = obj;
      } else if (
        item.name === "Ceil" ||
        item.name === "Floor" ||
        item.name === "Abs"
      ) {
        obj.body = [];
        formData.indicators[instanceName] = obj;
      } else if (item.name === "Symbol") {
        obj.body = [];
        obj.scripCode = "";
        formData.indicators[instanceName] = obj;
      } else {
        obj.body = [];
        obj.period = 10;
        formData.indicators[instanceName] = obj;
      }
    }
    if (!formData.selectedOption3 || outerAdd){
      formData.indicators[instanceName] = obj;
    }
    if (formData.indicators[formData.selectedOption3]?.body && innerAdd){
      const output = formData?.selectedOption3.replace(/\d+$/, '');
      console.log("SELCTED", obj.name)

      const isPeriodArr = ["Period min", "Period max"] ;
      if (isPeriodArr.includes(output)) {
         if(obj.name !== "Number") {
           formData.indicators[formData.selectedOption3]?.body.push(obj);
         }
      }else {
        formData.indicators[formData.selectedOption3]?.body.push(obj);
      }
    }

    if (instance === "body1" && innerAdd){
        formData.indicators[formData.selectedOption3]?.body1?.push(obj);
    }

    if (instance === "body2" && innerAdd){
        formData.indicators[formData.selectedOption3]?.body2?.push(obj);
    }

    if (formData.mode === "Function" && !outerAdd) {
      console.log("INSIDEDATA", obj)
      obj.instance_name = instanceName;
      formData.functionsBox2[0]?.body?.push(obj);
    }

    // Update the order array
    formData.order.push(instanceName);

    setMode("Indicators");
  }
  if (item?.function_type_id === 4) {
    let functionName = item?.name;
    if (!formData.indicators) {
      formData.indicators = {};
    }
    const existingInstances = Object.keys(formData.indicators).filter((key) =>
      key.startsWith("operators")
    );
    let instanceName;
    if (existingInstances.length > 0) {
      let lastNumber = 0;

      existingInstances.forEach((key) => {
        const number = parseInt(key.replace("operators", ""));
        if (!isNaN(number) && number > lastNumber) {
          lastNumber = number;
        }
      });

      instanceName = `operators${lastNumber + 1}`;
    } else {
      instanceName = `operators1`;
    }

    let obj = {
      function_id: item?.id,
      name: item?.name,
      function_type_id: item?.function_type_id,
      candleIntervalId: candleId,
      operator: item?.name,
    };
    formData.order.push(instanceName);
    console.log("selectedOption3", formData?.selectedOption3)

    if (formData.indicators[formData.selectedOption3]?.body && innerAdd){
        formData.indicators[formData.selectedOption3]?.body.push(obj);
    }
    else if (instance === "body1" && formData.indicators[formData.selectedOption3]?.body1 && innerAdd){
      formData.indicators[formData.selectedOption3]?.body1.push(obj);
    }
    else if (instance === "body2" && formData.indicators[formData.selectedOption3]?.body2 && innerAdd){
      formData.indicators[formData.selectedOption3]?.body2?.push(obj);
    }
    else formData.indicators[instanceName] = obj;
  }

  if (item?.function_type_id === 5) {
    let functionName = item?.name;
    let comparatorObject = formData.indicators;
    if (!comparatorObject) {
      formData.indicators = {};
      comparatorObject = formData.indicators;
    }
    const existingInstances = Object.keys(comparatorObject);
    let instanceName;
    if (existingInstances.length > 0) {
      let lastNumber = 0;

      existingInstances.forEach((key) => {
        const number = parseInt(key.replace("comparator", ""));
        if (!isNaN(number) && number > lastNumber) {
          lastNumber = number;
        }
      });

      instanceName = `comparator${lastNumber + 1}`;
    } else {
      instanceName = "comparator1";
    }
    let obj = {
      function_id: item?.id,
      name: functionName,
      function_type_id: item?.function_type_id, 
    };
    formData.indicators[instanceName] = obj;
    formData.order.push(instanceName);

    setMode("Indicators");
  }

  if (outerAdd) {
    let obj2 = {};
    obj2.function_id = item?.id;
    obj2.name = item?.name;
    obj2.function_type_id = item?.function_type_id;
    formData.functionsBox2.push(obj2);
  }

  formData.secondFunctionId = item?.id;
  onFormChange({
    ...formData,
    formData,
  });
  setSearchQuery("");
  setShowDropdown(false);
  if (innerAdd) setRemovedInsideMath(false);
};

export default GetFunctionofHandle;
