import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
const Footer = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-4 footer-links">
                <ul>
                  <li>
                    <Link to="/about" 
                     style={{ fontSize: 19, color: activeLink === '/about' ? 'blue' : 'white' }}
                     onClick={() => handleLinkClick('/about')}>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact"  style={{ fontSize: 19, color: activeLink === '/contact' ? 'blue' : 'white' }}
                     onClick={() => handleLinkClick('/contact')}>
                      Contact
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="#" style={{ fontSize: 19 }}>
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to="#" style={{ fontSize: 19 }}>
                      Help
                    </Link>
                  </li> */}
                  <li>
                    {" "}
                    <Link to="/terms-condition"  style={{ fontSize: 19, color: activeLink === '/terms-condition' ? 'blue' : 'white' }}
                     onClick={() => handleLinkClick('/terms-condition')}>
                      Terms & Condition
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/privacy-policy" style={{ fontSize: 19, color: activeLink === '/privacy-policy' ? 'blue' : 'white' }}
                     onClick={() => handleLinkClick('/privacy-policy')}>
                      Privacy policy
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/disclaimer"  style={{ fontSize: 19, color: activeLink === '/disclaimer' ? 'blue' : 'white' }}
                     onClick={() => handleLinkClick('/disclaimer')}>
                      Cancellation & Refund Policy
                    </Link>
                  </li>
                 
                  {/* <li>
                    {" "}
                    <Link to="/contactus"  style={{ fontSize: 19, color: activeLink === '/contactus' ? 'blue' : 'white' }}
                     onClick={() => handleLinkClick('/contactus')}>
                    Contact Us
                    </Link>
                  </li> */}
                  <li>
                    {" "}
                    <Link to="/blogs"  style={{ fontSize: 19, color: activeLink === '/blogs' ? 'blue' : 'white' }}
                     onClick={() => handleLinkClick('/blogs')}>
                      Blogs
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/help"  style={{ fontSize: 19, color: activeLink === '/help' ? 'blue' : 'white' }}
                     onClick={() => handleLinkClick('/help')}>
                     Help
                    </Link>
                  </li>
                </ul>
                <div
                  className="social-links mt-3"
                  style={{
                    display: "flex",
                    width: "55%",
                    justifyContent: "space-around"
                  }}
                >
                  <Link to="#"
                    target="_blank"
                    className="instagram"
                  >
                    <InstagramIcon/>
                    {/* <img src="instagram.png" height={40} width={40} /> */}
                  </Link>
                  &nbsp;&nbsp;
                  <Link to="#"
                    target="_blank"
                    className="facebook"
                  >
                    < FacebookIcon/>
                  </Link>
                  &nbsp;&nbsp;
                  <Link to="#"
                    target="_blank"
                    className="twitter"
                  >
                    <XIcon/>
                  </Link>
                
                  {/* <Link to="#" className="linkedin">
                    <img src="linkedin.png" height={40} width={40} alt="nt found"/>
                  </Link> */}
                  &nbsp;&nbsp;
                  <Link to="#"
                    target="_blank"
                    className="whatsapp"
                  >
                    <WhatsAppIcon/>
                  </Link>
                  &nbsp;&nbsp;
              
                  <Link to="#"
                    target="_blank"
                    className="telegram"
                  >
                    <TelegramIcon/>
                  </Link>
                  {/* <Link to="#" className="linkedin">
                    <img src="paper-plane.png" height={40} width={40} alt="nt found" />
                  </Link> */}
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-8 footer-links">
                <h1 style={{ color: "#1D5EB6" }}>
                  Claim your 7 day free trial
                </h1>
                <h2>
                  Get your 7 day free trial, and experience ProductName without any
                  commitment. You will not be charged till you choose a plan
                </h2>
                <Link to="/signup" className="btn btn-primary sub-btn mt-3" type="submit">
                  Start Free Trial
                </Link>
                <div className="mt-5">
                  <p>
                    Reach us out at{" "}
                    <strong>
                      <span style={{ marginLeft: "10px" }}>
                        {" "}
                        support@ProductName.tech
                      </span>
                    </strong>
                  </p>
                </div>
              </div>
              <div className="container footer-bottom clearfix text-center">
                <div className="copyright">
                  &copy; 2023{" "}
                  <strong>
                    <span>ProductName Pvt Ltd.</span>
                  </strong>
                  . All Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
